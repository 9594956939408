import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  DatePicker,
  Space,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function EditPurchasingPO(props) {
  const [data, setData] = useState({
    item_code: "",
    item_name: "",
    item_id: "",
    process_code: "",
    process_name: "",
    process_id: "",
    manufactured_quantity: 0,
    month: "",
    year: "",
    items: [],
    processes: [],
    suppliers: [],
  });

  useEffect(() => {
    getData();
  }, []);

  
  const getData = () => {
    Axios.get(`${url}/purchase-order/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          supplier_label: res.data.data.supplier_name ,
          supplier_name: res.data.data.supplier_name,
          supplier_code: res.data.data.supplier_code,
          po_number: res.data.data.po_number,
          model: res.data.data.model,
          publisher_date: res.data.data.publisher_date,
          delivery_request_date: res.data.data.delivery_request_date,
          month: moment(res.data.data.month).format('MMMM'),
          year: moment(res.data.data.year).format('YY'),
        });

        setPurchasingDetail(
            res.data.data.details.map((x) => {
              return {
                ...x,
                id: Math.random(),
              };
            })
          );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const [dataPurchasing, setDataPurchasing] = useState({
    item_code: "",
    item_name: "",
    // parts_no: "",
    suggestion_qty: 0,
    purchase_price: 0,
    amount: 0,
    ppn: "",
    forecast_n1: "",
    forecast_n2: "",
    forecast_n3: "",
    supplier_name: "",
    model: "",
    date: "",
  });

  const [perpage, setPerpage] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [loadItem, setLoadItem] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [purchasingDetail, setPurchasingDetail] = useState([]);

  const getSupplier = async (keyword) => {
    await Axios.get(`${url}/supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
    // handleRemoveDetails();
  };

  const handleChangeSelect = (name, value) => {
    const find = data.suppliers.find((x) => x._id === value.key);
    // console.log(code, "code");
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`${name}_name`]: find.name,
      [`${name}_code`]: find.code,
    });

    console.log(name, value, "ini supplier");
  };

  const handleChangeDetail = (name, value) => {
    setDataPurchasing({
      ...dataPurchasing,
      [name]: value,
    });
  };

  const handleChangeSelectDetail = (name, value, nameField, sources) => {
    //const find = data.items.find((x) => x._id === value.value);
    const findName = sources.find((x) => x._id === value.value);
    // console.log(code, "code");
    setDataPurchasing({
      ...dataPurchasing,
      [`${name}_id`]: value.value,
      [`${name}_code`]: value.label,
      [`${name}_name`]: findName ? findName[nameField] : null,
      //[`${name}_name`]: find.item_name,
    });
  };

  const getItem = async (keyword) => {
    setLoadItem(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoadItem(false);
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifypurchasingDetail = [...purchasingDetail];
      const findpurchasingDetail = modifypurchasingDetail.find(
        (x) => x._id === selectedId
      );

      findpurchasingDetail.item_code = dataPurchasing.item_code;
      findpurchasingDetail.item_name = dataPurchasing.item_name;
      findpurchasingDetail.suggestion_qty = dataPurchasing.suggestion_qty;
      findpurchasingDetail.purchase_price = dataPurchasing.purchase_price;
      findpurchasingDetail.amount = dataPurchasing.amount;
      findpurchasingDetail.ppn = dataPurchasing.ppn;
      findpurchasingDetail.forecast_n1 = dataPurchasing.forecast_n1;
      findpurchasingDetail.forecast_n2 = dataPurchasing.forecast_n2;
      findpurchasingDetail.forecast_n3 = dataPurchasing.forecast_n3;

      console.log(findpurchasingDetail, modifypurchasingDetail, "modify");

      setPurchasingDetail(modifypurchasingDetail);
    } else {
      setPurchasingDetail([
        ...purchasingDetail,
        {
          _id: Math.random(),
          item_code: dataPurchasing.item_code,
          item_name: dataPurchasing.item_name,
          suggestion_qty: dataPurchasing.suggestion_qty,
          purchase_price: dataPurchasing.purchase_price,
          amount: dataPurchasing.amount,
          ppn: dataPurchasing.ppn,
          forecast_n1: dataPurchasing.forecast_n1,
          forecast_n2: dataPurchasing.forecast_n2,
          forecast_n3: dataPurchasing.forecast_n3,
        },
      ]);
    }

    setSelectedId("");

    setDataPurchasing({
      ...dataPurchasing,
      item_code: "",
      item_name: "",
      suggestion_qty: "",
      purchase_price: "",
      amount: "",
      ppn: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
      supplier_name: "",
      model: "",
      date: "",
    });

    setIsModalVisible(false);
  };

  const handleRemoveDetails = (id) => {
    const details = purchasingDetail.filter(
      (purchasingDetail) => purchasingDetail._id !== id
    );
    setPurchasingDetail(details);
  };

  const handleEditDetails = (id) => {
    const findPurchasing = purchasingDetail.find(
      (purchasingDetail) => purchasingDetail._id === id
    );
    console.log(id);
    setSelectedId(id);

    setDataPurchasing({
      ...dataPurchasing,
      item_code: findPurchasing.item_code,
      // parts_no: findPurchasing.item_code,
      item_name: findPurchasing.item_name,
      suggestion_qty: findPurchasing.suggestion_qty,
      purchase_price: findPurchasing.purchase_price,
      amount: findPurchasing.amount,
      ppn: findPurchasing.ppn,
      forecast_n1: findPurchasing.forecast_n1,
      forecast_n2: findPurchasing.forecast_n2,
      forecast_n3: findPurchasing.forecast_n3,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      supplier_name: "",
      supplier_code: "",
      po_number: "",
      publisher_date: "",
      delivery_request_date: "",
      items: [],
    });

    setPurchasingDetail([]);
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("details", JSON.stringify(purchasingDetail));
    fd.set("supplier_name", data.supplier_name);
    fd.set("supplier_code", data.supplier_code);
    fd.set("po_number", data.po_number);
    fd.set("publisher_date", data.publisher_date);
    fd.set("delivery_request_date", data.delivery_request_date);
    fd.set("month", data.month);
    fd.set("year", data.year);

    Axios.post(`${url}/purchase-order/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success" || res.data.type === "success") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
        // setLoading(false);
        props.history.push("/purchasing-po");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setDataPurchasing({
      ...dataPurchasing,
      item_code: "",
      item_name: "",
      // parts_no: "",
      suggestion_qty: "",
      purchase_price: "",
      amount: "",
      ppn: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
      supplier_name: "",
      model: "",
      date: "",
    });
  };

  const closeModal = () => {
    setDataPurchasing({
      ...dataPurchasing,
      item_code: "",
      item_name: "",
      // parts_no: "",
      suggestion_qty: "",
      purchase_price: "",
      amount: "",
      ppn: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
      supplier_name: "",
      model: "",
      date: "",
    });

    setSelectedId("");
    setIsModalVisible(false);
  };

  const columns = [
    {
      align: "center",
      title: "No",
      dataIndex: "no",
      render: (text, record, index) => index + page * perpage - (perpage - 1),
    },
    {
      align: "center",
      title: "Material Code",
      render: (data) => {
        return <p>{data.item_code}</p>;
      },
    },
    {
      align: "center",
      title: "Parts Name",
      render: (data) => {
        return <p>{data.item_name}</p>;
      },
    },
    {
      align: "center",
      title: "Qty No",
      children: [
        {
            title: "PCS",
            align: "center",
            dataIndex: "suggestion_qty",
            render: (suggestion_qty) => {
              return <AntNumberFormat value={suggestion_qty} type="text" />;
            },
        }
      ]
    },
    {
      align: "center",
      title: "Unit Price",
      children: [
        {
            title: "Rp",
            align: "center",
            dataIndex: "purchase_price",
            render: (purchase_price) => {
              return <AntNumberFormat value={purchase_price} type="text" />;
            },
        }
      ]
    },
    {
      align: "center",
      title: "Total Amount",
      children: [
        {
            title: "Rp.",
            align: "center",
            dataIndex: "amount",
            render: (amount) => {
              return <AntNumberFormat value={amount} type="text" />;
            },

        }
      ]
    },
    // {
    //     align: "center",
    //     title: "PPN",
    //     children: [
    //         {
    //             title: "%",
    //             dataIndex: "ppn",
    //             align: "center",
    //             render: (ppn) => {
    //               return <AntNumberFormat value={ppn} type="text" />;
    //             },
    //         }
    //     ]
    //   },
    {
      align: "center",
      title: "Forecast",
      children: [
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+1</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n1} type="text" />;
          },
        },
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+2</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n2} type="text" />;
          },
        },
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+3</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n3} type="text" />;
          },
        },
      ]
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>

          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditDetails(id)}
            size="small"
            type="primary"
          >
            <EditOutlined />
            Edit
          </Button>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Purchasing PO"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/purchasing-po">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Purchasing PO</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Purchasing PO"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={24} lg={18}>
                          <Form.Item
                              label="Supplier Name"
                              validateStatus={
                                error && error.supplier_name ? "error" : false
                              }
                              help={
                                error && error.supplier_name
                                  ? error.supplier_name[0]
                                  : false
                              }
                            >
                              <Col md={24} sm={24} xs={24}>
                                <Select
                                  labelInValue
                                  onChange={(value) =>
                                    handleChangeSelect("supplier", value)
                                  }
                                  onFocus={() => getSupplier("")}
                                  showSearch
                                  onSearch={(value) => getSupplier(value)}
                                  filterOption={false}
                                  value={{
                                    key: data.supplier_id,
                                    label: data.supplier_label,
                                  }}
                                >
                                  {data.suppliers &&
                                    data.suppliers.map((supplier) => {
                                      return (
                                        <Option
                                          value={supplier._id}
                                          key={supplier._id}
                                        >
                                          {`${supplier.code} - ${supplier.name}`}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Col>
                            </Form.Item>
                            <Form.Item
                              label="PO Number"
                              validateStatus={
                                error && error.po_number ? "error" : false
                              }
                              help={
                                error && error.po_number ? error.po_number[0] : false
                              }
                            >
                              <Input
                                value={data.po_number}
                                onChange={(e) =>
                                  handleChange("po_number", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.month ? "error" : false
                              }
                              help={error && error.month ? error.month[0] : false}
                              label="Month"
                            >
                              <div>{data.month}</div>
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.year ? "error" : false
                              }
                              help={error && error.year ? error.year[0] : false}
                              label="Year"
                            >
                              <div>{data.year}</div>
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}></Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail Purchasing PO"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add Detail Purchasing PO
                        </Button>

                        <Modal
                          title={
                            selectedId
                              ? "Edit Detail Purchasing PO"
                              : "Add Detail Purchasing PO"
                          }
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          validateStatus={
                                            error && error.item_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_code
                                              ? error.item_code[0]
                                              : false
                                          }
                                          label="Material Code"
                                        >
                                          <Input
                                            value={dataPurchasing.item_code}
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "item_code",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.item_name
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_name
                                              ? error.item_name[0]
                                              : false
                                          }
                                          label="Parts Name"
                                        >
                                          <Input
                                            value={dataPurchasing.item_name}
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "item_name",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.suggestion_qty
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.suggestion_qty
                                              ? error.suggestion_qty[0]
                                              : false
                                          }
                                          label="Qty No"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.suggestion_qty}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("suggestion_qty", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.purchase_price
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.purchase_price
                                              ? error.purchase_price[0]
                                              : false
                                          }
                                          label="Unit Price"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.purchase_price}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "purchase_price",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.amount
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.amount
                                              ? error.amount[0]
                                              : false
                                          }
                                          label="Total Amount"
                                        >
                                          <AntNumberFormat
                                            disabled
                                            value={
                                              dataPurchasing.suggestion_qty *
                                              dataPurchasing.purchase_price
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "amount",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        {/* <Form.Item
                                          validateStatus={
                                            error && error.ppn
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.ppn
                                              ? error.ppn[0]
                                              : false
                                          }
                                          label="PPN"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.ppn}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("ppn", e);
                                            }}
                                          />
                                        </Form.Item> */}
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n1
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n1
                                              ? error.forecast_n1[0]
                                              : false
                                          }
                                          label="Forecast N+1"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n1}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("forecast_n1", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n2
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n2
                                              ? error.forecast_n2[0]
                                              : false
                                          }
                                          label="Forecast N+2"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n2}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("forecast_n2", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n3
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n3
                                              ? error.forecast_n3[0]
                                              : false
                                          }
                                          label="Forecast N+3"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n3}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("forecast_n3", e);
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    {console.log(purchasingDetail, "purchasingDetail")}
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        size="small"
                        columns={columns}
                        scroll={{ x: 500 }}
                        dataSource={purchasingDetail}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        pagination={{
                          pageSize: 10,
                          current: page,
                          total: purchasingDetail.total,
                          showSizeChanger: false,
                        }}
                        loading={loading}
                      />
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditPurchasingPO;