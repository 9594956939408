/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Input,
  Table,
  message,
  Select,
  Space,
  DatePicker,
  Modal,
  Form,
  Button,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function StockOpnameFG() {
  const [keyword, setKeyword] = useState("");
  const [opnames, setOpnames] = useState([]);
  const [comodity, setComodity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [date, setDate] = useState(moment(new Date()));

  const [data, setData] = useState({
    comodity: "",
    date: "",
    item_fg: "",
    qty_adjustment: 0,
    notes: "",
  });

  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    getData();
  }, [keyword, page, sort, data.comodity, date]);

  useEffect(() => {
    getCommodity();
  }, []);

  const getCommodity = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Comodity",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/stock-opname-fg`, {
      params: {
        keyword,
        comodity: data.comodity,
        month: date ? moment(date).format("MM") : '',
        year: date ? moment(date).format("YYYY") : '',
        columns: [
          "part_no",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setOpnames(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    if(name === "date"){
      setDate(value);
    } else{
      setData({
        ...data,
        [name]: value,
      });  
    }
  };

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const handleChangeSelect = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const columns = [
    {
      title: "Date",
      sorter: false,
      width: 150,
      render: (data) => moment(data.month).format("MMMM") + ' ' + moment(data.year).format("YYYY"),
    },
    {
      title: "Item FG",
      dataIndex: "part_no",
      sorter: false,
      width: 200,
    },
    {
      title: <div style={{ textAlign: 'left' }}>Stock End Month After Adjustment</div>,
      sorter: false,
      width: 150,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DEC"
            value={data.stock_adjustment}
            type="text"
          />
        );
      },
    },
    {
      title: (
        <div style={{ textAlign: "left" }}>
          {" "}
          Stock End Month Before Adjustment
        </div>
      ),
      sorter: false,
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DEC"
            value={data.stock_month_before}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}> Stock In</div>,
      sorter: false,
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DEC"
            value={data.stock_in}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{textAlign: "left"}}>Stock Out</div>,
      sorter: false,
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DEC"
            value={data.stock_out}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: 'left' }}>Stock On Hand</div>,
      sorter: false,
      width: 150,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DEC"
            value={data.stock_on_hand}
            type="text"
          />
        );
      },
    },
    {
      title: "",
      align: "center",
      sorter: false,
      fixed: "right",
      width: 180,
      render: (record) => {
        return (
          <span>
            <Button type="primary" onClick={() => handleModal(record._id)}>
              Adjustment
            </Button>
          </span>
        );
      },
    },
  ];

  const handleModal = (id) => {
    const stock = opnames.data.find((opnames) => opnames._id === id);
    setSelectedId(id);

    setData({
      ...data,
      month: stock.month,
      year: stock.year,
      date: moment(stock.month).format('MMMM') + ' ' + stock.year,
      part_no: stock.part_no,
      qty_adjustment: stock.qty_adjustment,
      notes: stock.notes,
    });

    setIsModalVisible(true);

    console.log(stock)
  };

  const closeModal = () => {
    setData({
      ...data,
      date: "",
      item_fg: "",
      qty_adjustment: 0,
      notes: "",
    });

    setIsModalVisible(false);
  };

  const resetModal = () => {
    setData({
      ...data,
      qty_adjustment: 0,
      notes: "",
    });
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("qty_adjustment", data.qty_adjustment);
    fd.set("notes", data.notes);
    fd.set("part_no", data.part_no);
    fd.set("month", data.month);
    fd.set("year", data.year);

    Axios.post(`${url}/stock-opname-fg/adjustment`, fd, 
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success" || res.data.type === "success") {
          message.success(res.data.message);
        }
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            // localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });

    setIsModalVisible(false);
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Adjustment Stock Opname FG"
        visible={isModalVisible}
        width={1000}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Form {...layout} name="control-hooks" labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        required
                        validateStatus={error && error.date ? "error" : false}
                        help={error && error.date ? error.date[0] : false}
                        label="Date"
                      >
                        <Input
                          disabled
                          value={data.date}
                          onChange={(e) => {
                            handleChange("date", e.target.value);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        validateStatus={
                          error && error.part_no ? "error" : false
                        }
                        help={error && error.part_no ? error.part_no[0] : false}
                        label="Item FG"
                      >
                        <Input
                          disabled
                          value={data.part_no}
                          onChange={(e) => {
                            handleChange("part_no", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.qty_adjustment ? "error" : false
                        }
                        help={
                          error && error.qty_adjustment
                            ? error.qty_adjustment[0]
                            : false
                        }
                        label="Qty Adjustment"
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.qty_adjustment}
                          type="input"
                          onChange={(e) => handleChange("qty_adjustment", e)}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={error && error.notes ? "error" : false}
                        help={error && error.notes ? error.notes[0] : false}
                        label="Notes"
                      >
                        <Input
                          value={data.notes}
                          onChange={(e) => {
                            handleChange("notes", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Submit
              </Button>
              <Button htmlType="reset" onClick={resetModal}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Stock Opname Finish Good"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={12}>
                  <DatePicker
                    onChange={(value) => handleChange("date", value)}
                    value={date}
                    picker="month"
                    placeholder="Select Month"
                  />
                </Col>

                <Col xs={24} md={12} lg={12} className="align-right">
                  <Space>
                    <Select
                      style={{ width: 150 }}
                      placeholder="Select Comodity"
                      allowClear={true}
                      onChange={(value) =>
                        handleChangeSelect("comodity", value)
                      }
                    >
                      {comodity &&
                        comodity.map((select) => {
                          return (
                            <Option value={select.name} key={select._id}>
                              {select.name}
                            </Option>
                          );
                        })}
                    </Select>
                    <Search
                      placeholder="Search Item Code..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={opnames.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: opnames.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default StockOpnameFG;
