/* eslint-disable */
import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function CreateProductionSchedule(props) {
  const [data, setData] = useState({
    item_code: "",
    item_label: "",
    item_id: "",
    item_name: "",
    items: [],
    customer: [],
    customer_id: "",
    customer_code: "",
    customer_name: "",
    customer_label: "",
    dn_number: "",
    fiscal_year: "",
    qty: 0,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getItem = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = data.items.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
        [`model`]: find.model,
      });

      console.log(name, value, find.item_code, find.item_name);
    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });

      console.log(name, value, find.code, find.name);
    } else if (name === "model") {
      const find = data.customer.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.model,
      });

    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_code", data.item_code);
    fd.set("item_name", data.item_name);
    fd.set("customer", data.customer_code);
    fd.set("dn_number", data.dn_number);
    fd.set("qty", data.qty);
    // fd.set("jan_qty", data.jan_qty);
    // fd.set("feb_qty", data.feb_qty);
    // fd.set("mar_qty", data.mar_qty);
    // fd.set("apr_qty", data.apr_qty);
    // fd.set("may_qty", data.may_qty);
    // fd.set("jun_qty", data.jun_qty);
    // fd.set("jul_qty", data.jul_qty);
    // fd.set("agt_qty", data.agt_qty);
    // fd.set("sep_qty", data.sep_qty);
    // fd.set("oct_qty", data.oct_qty);
    // fd.set("nov_qty", data.nov_qty);

    Axios.post(`${url}/production-schedule`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-production-schedule");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      item_code: "",
      item_label: "",
      item_id: "",
      item_name: "",
      items: [],
      customer: [],
      customer_id: "",
      customer_code: "",
      customer_name: "",
      customer_label: "",
      dn_number: "",
      qty: 0,
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Production Schedule"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-production-schedule">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Production Schedule</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Item Code"
                        validateStatus={
                          error && error.item_code ? "error" : false
                        }
                        help={
                          error && error.item_code ? error.item_code[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("item", value)
                          }
                          onFocus={() => getItem("")}
                          showSearch
                          onSearch={(value) => getItem(value)}
                          filterOption={false}
                          value={{
                            key: data.item_id,
                            label: data.item_label,
                          }}
                        >
                          {data.items &&
                            data.items.map((item) => {
                              return (
                                <Option value={item._id} key={item._id}>
                                  {`${item.item_code} - ${item.item_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required
                        label="DN Number"
                        validateStatus={
                          error && error.dn_number ? "error" : false
                        }
                        help={
                          error && error.dn_number ? error.dn_number[0] : false
                        }
                      >
                        {/* <DatePicker onChange={onChangeYear} picker="year" /> */}
                        <Input
                          value={data.dn_number}
                          onChange={(e) =>
                            handleChange("dn_number", e.target.value)
                          }
                        />
                      </Form.Item>
                      {/* <Form.Item
                        required
                        label="Jan Qty"
                        validateStatus={
                          error && error.jan_qty ? "error" : false
                        }
                        help={error && error.jan_qty ? error.jan_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.jan_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("jan_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Feb Qty"
                        validateStatus={
                          error && error.feb_qty ? "error" : false
                        }
                        help={error && error.feb_qty ? error.feb_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.feb_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("feb_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Mar Qty"
                        validateStatus={
                          error && error.mar_qty ? "error" : false
                        }
                        help={error && error.mar_qty ? error.mar_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.mar_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("mar_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Apr Qty"
                        validateStatus={
                          error && error.apr_qty ? "error" : false
                        }
                        help={error && error.apr_qty ? error.apr_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.apr_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("apr_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="May Qty"
                        validateStatus={
                          error && error.may_qty ? "error" : false
                        }
                        help={error && error.may_qty ? error.may_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.may_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("may_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Jun Qty"
                        validateStatus={
                          error && error.jun_qty ? "error" : false
                        }
                        help={error && error.jun_qty ? error.jun_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.jun_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("jun_qty", e);
                          }}
                        />
                      </Form.Item> */}
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Customer"
                        validateStatus={
                          error && error.customer ? "error" : false
                        }
                        help={
                          error && error.customer ? error.customer[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("customer", value)
                          }
                          onFocus={() => getCustomer("")}
                          showSearch
                          onSearch={(value) => getCustomer(value)}
                          filterOption={false}
                          value={{
                            key: data.customer_id,
                            label: data.customer_label,
                          }}
                        >
                          {data.customer &&
                            data.customer.map((customers) => {
                              return (
                                <Option
                                  value={customers._id}
                                  key={customers._id}
                                >
                                  {`${customers.code} - ${customers.name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required
                        label="Qty"
                        validateStatus={error && error.qty ? "error" : false}
                        help={error && error.qty ? error.qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("qty", e);
                          }}
                        />
                      </Form.Item>
                      {/* <Form.Item
                        required
                        label="Jul Qty"
                        validateStatus={
                          error && error.jul_qty ? "error" : false
                        }
                        help={error && error.jul_qty ? error.jul_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.jul_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("jul_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Agt Qty"
                        validateStatus={
                          error && error.agt_qty ? "error" : false
                        }
                        help={error && error.agt_qty ? error.agt_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.agt_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("agt_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Sep Qty"
                        validateStatus={
                          error && error.sep_qty ? "error" : false
                        }
                        help={error && error.sep_qty ? error.sep_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.sep_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("sep_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Oct Qty"
                        validateStatus={
                          error && error.oct_qty ? "error" : false
                        }
                        help={error && error.oct_qty ? error.oct_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.oct_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("oct_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Nov Qty"
                        validateStatus={
                          error && error.nov_qty ? "error" : false
                        }
                        help={error && error.nov_qty ? error.nov_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.nov_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("nov_qty", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Des Qty"
                        validateStatus={
                          error && error.des_qty ? "error" : false
                        }
                        help={error && error.des_qty ? error.des_qty[0] : false}
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.des_qty}
                          type="input"
                          onChange={(e) => {
                            handleChange("des_qty", e);
                          }}
                        />
                      </Form.Item> */}
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateProductionSchedule;
