/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
  Select,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  ExportOutlined,
  FileImageOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import profile from "../../assets/img/item.png";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function WhCreateStockOpname() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [stockopname, setStockOpname] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });
  const [data, setData] = useState({
    file: null,
    warehouse_id: "",
    warehouse_name: "",
    warehouses: [],
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/warehouse`, {
      params: {
        keyword,
        columns: ["username", "full_name"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setStockOpname(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/stockopname/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleRemoveEvidence = () => {
    setData({
      ...data,
      images: null,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleBeforeUploadEvidence = (file) => {
    setData({
      ...data,
      images_url: "",
      images: file,
    });

    return false;
  };

  const handleReset = () => {
    setData({
      file: "",
      images: "",
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: true,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: "Qty Sistem",
      dataIndex: "stock_before_sto",
      sorter: false,
    },
    {
      title: "Qty Actual",
      dataIndex: "stock_on_hand",
      sorter: false,
    },
    {
      title: "Notes",
      dataIndex: "note",
      sorter: false,
    },
  ];

  const download = () => {
    Axios.get(`${url}/stock-opname/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Stock Opname" + ".xlsx");
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);
    fd.append("image", data.images);

    Axios.post(`${url}/stock-opname/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  // if (!localStorage.getItem('token')) {
  //     return <Redirect to="/login" />
  // }

  return (
    <Fragment>
      <Modal
        title="Import Stock Opname"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Choose File Excel <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div name="file" label="File" style={{ marginTop: 20 }}>
              <Fragment>
                <div className="mb-10">
                  <Avatar
                    src={
                      data.images_url
                        ? data.images_url
                        : data.images
                        ? URL.createObjectURL(data.images)
                        : profile
                    }
                    size={50}
                  />
                </div>
                <Upload
                  onRemove={handleRemoveEvidence}
                  beforeUpload={handleBeforeUploadEvidence}
                  fileList={[]}
                  accept="image/jpg, image/png"
                >
                  {data.images ? (
                    <Button
                      style={{ marginBottom: 10 }}
                      type="danger"
                      onClick={handleRemoveEvidence}
                    >
                      <i className="mdi mdi-close mr-5" />
                      Delete Evidence
                    </Button>
                  ) : (
                    <Button style={{ marginBottom: 10 }}>
                      Choose File Evidence <FileImageOutlined />
                    </Button>
                  )}
                </Upload>
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Upload
              </Button>
              <Button htmlType="reset" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Stock Opname"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/wh-stock-opname">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Stock Opname</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            {/* <Card>
              <Row justify="space-between" gutter={20}>
                <Col xs={24} md={24}>
                  <Row gutter={[20, 30]}>
                    <Col xs={24} md={8}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder=" Select Warehouse"
                        labelInValue
                        onChange={(value) =>
                          handleChangeSelect("warehouse", value)
                        }
                        onFocus={() => getWarehouse("")}
                        showSearch
                        onSearch={(value) => getWarehouse(value)}
                        filterOption={false}
                        value={{
                          key: data.warehouse_id,
                          label: data.warehouse_name,
                        }}
                      >
                        {data.warehouses &&
                          data.warehouses.map((part) => {
                            return (
                              <Select.Option key={part._id} value={part._id}>
                                {part.part_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>

                    <Col xs={24} md={8}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder=" Select Warehouse"
                        labelInValue
                        onChange={(value) =>
                          handleChangeSelect("warehouse", value)
                        }
                        onFocus={() => getWarehouse("")}
                        showSearch
                        onSearch={(value) => getWarehouse(value)}
                        filterOption={false}
                        value={{
                          key: data.warehouse_id,
                          label: data.warehouse_name,
                        }}
                      >
                        {data.warehouses &&
                          data.warehouses.map((part) => {
                            return (
                              <Select.Option key={part._id} value={part._id}>
                                {part.part_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>

                    <Col xs={24} md={8}>
                      <Select
                        style={{ width: "100%" }}
                        placeholder=" Select Warehouse"
                        labelInValue
                        onChange={(value) =>
                          handleChangeSelect("warehouse", value)
                        }
                        onFocus={() => getWarehouse("")}
                        showSearch
                        onSearch={(value) => getWarehouse(value)}
                        filterOption={false}
                        value={{
                          key: data.warehouse_id,
                          label: data.warehouse_name,
                        }}
                      >
                        {data.warehouses &&
                          data.warehouses.map((part) => {
                            return (
                              <Select.Option key={part._id} value={part._id}>
                                {part.part_name}
                              </Select.Option>
                            );
                          })}
                      </Select>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card> */}
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button onClick={showModal} type="primary">
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={download}>
                      <ExportOutlined />
                      Export
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right"></Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={stockopname.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: stockopname.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default WhCreateStockOpname;
