import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  Form,
  Collapse,
  Tabs,
  DatePicker,
  notification,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  DeleteOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
  CaretRightOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;
const { Panel } = Collapse;
const { TabPane } = Tabs;

function MasterProductionSchedule() {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleKanban, setIsModalVisibleMPS] = useState(false);
  const [error, setError] = useState(null);
  const [kanbanId, setKanbanId] = useState(null);
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [prodScheduleDetails, setProdScheduleDetails] = useState([]);
  const [prodScheduleDetailsTotal, setProdScheduleDetailsTotal] = useState(0);
  const [data, setData] = useState({
    dn_number: "",
    item_code: "",
    customer_code: "",
    qty: "",
    date: moment(new Date()),
    created_by: "",
    created_at: "",
    file: null,
    // month: "",
    // year: "",
  });

  const [date, setDate] = useState(moment(new Date()));

  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  const [sequences, setSequences] = useState([]);

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getProdScheduleDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      localStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  const handleChange = (name, value) => {
    if (name === "date") {
      setData({
        ...data,
        [name]: value,
        // month: moment(value).format("MM"),
        // year: moment(value).format("YYYY"),
      });
    }

    if (name === "date") {
      setDate(value);
    }
    // handleRemoveDetails();
    console.log("test", name, moment(value).format("MM-YYYY"), value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date, data.month, data.year]);

  useEffect(() => {
    if (selectedId) {
      getProdScheduleDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      select: "",
      job_number: "",
      item_code: "",
    });
    setIsModalVisibleMPS(false);
  };

  const handleReset = () => {
    setData({
      ...data,
      qty_generate: "",
    });
  };

  const exportProductionSchedule = () => {
    Axios.get(`${url}/production-schedule/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export MPS" + ".xlsx");
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/production-schedule/template`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "MPS Template" + ".xlsx");
    });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/production-schedule`, {
      params: {
        keyword,
        // date: date,
        month: data.date ? moment(data.date).format("MM") : "",
        year: data.date ? moment(data.date).format("YYYY") : "",
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = async (item) => {
    const index = item[item.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getProdScheduleDetails(data2[0], false);

      // get list all sequence

      const sequences = await Axios.get(
        `${url}/production-schedule/get-rev/${index}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ).then((res) => res.data.data);

      setSequences([
        {
          id: 0,
          name: "Current",
          is_rev: false,
        },
        ...sequences.map((x) => {
          return {
            id: x,
            name: `Rev #${x}`,
            is_rev: true,
          };
        }),
      ]);
    }
  };

  const getProdScheduleDetails = (
    id = selectedId,
    is_rev = false,
    sequence = null
  ) => {
    setLoadingDetail(true);
    Axios.get(`${url}/production-schedule/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["customer"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
        is_rev,
        sequence,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setProdScheduleDetails(res.data.data);
        setProdScheduleDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });

    console.log(id, is_rev, sequence);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/production-schedule/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const modals = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    console.log(id);

    setData({
      ...data,
      job_number: kanban.job_number,
      item_code: kanban.item_code,
    });

    setIsModalVisibleMPS(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/production-schedule/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      title: <div style={{ textAlign: "left" }}>Month</div>,
      align: "left",
      dataIndex: "month",
      sorter: false,
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: <div style={{ textAlign: "left" }}>Year</div>,
      align: "right",
      dataIndex: "year",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Working Days</div>,
      align: "right",
      dataIndex: "work_day",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Stock EM</div>,
      align: "right",
      dataIndex: "qty_stock_em_all",
      sorter: false,
      render: (qty_stock_em_all) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={qty_stock_em_all}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Plan PO</div>,
      align: "right",
      dataIndex: "qty_plan_po_all",
      sorter: false,
      render: (qty_plan_po_all) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={qty_plan_po_all}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Prod Plan</div>,
      align: "right",
      dataIndex: "qty_prod_plan_all",
      sorter: false,
      render: (qty_prod_plan_all) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={qty_prod_plan_all}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Stock Plan</div>,
      align: "right",
      dataIndex: "qty_stock_plan_all",
      sorter: false,
      render: (qty_stock_plan_all) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={qty_stock_plan_all}
            type="text"
          />
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    // {
    //   // title: "Option",
    //   dataIndex: "_id",
    //   align: "center",
    //   sorter: false,
    //   fixed: "right",
    //   width: 75,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="1">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const onSubmit = () => {
    setLoading(true);

    // console.log(kanbanId);

    let fd = new FormData();

    if (data.select === "Job Number") {
      fd.set("qty_generate", data.qty_generate);
      fd.set("type_qrcode", data.job_number);
    } else {
      fd.set("qty_generate", data.qty_generate);
      fd.set("type_qrcode", data.item_code);
    }

    Axios.post(`${url}/kanban/generate/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        // setLoading(false);
        closeModalKanban();
        getData();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          notification.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          // message.error(`Error!: ${err.response.data.message}
          // \n\r
          // Error Details: ${errorsKey.map((error) => {
          //   return errors[error][0] + "\n\r";
          // })}
          // `);
          // if (err.response.status === 422) {
          //   setError(err.response.data.errors);
          // }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const getProductionSchedule = (item) => {
    const columns = [
      {
        title: "Part No",
        dataIndex: "part_number",
        key: "part_number",
        align: "left",      
      },
      {
        title: "Item Code",
        dataIndex: "item_code",
        align: "left",
        key: "item_code",
      },
      {
        title: "Part Name",
        dataIndex: "part_name",
        align: "left",
        key: "part_name",
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        align: "left",
        sorter: false,
        key: "customer_name",      
      },
      {
        title: <div style={{ textAlign: "left" }}>Stock EM</div>,
        align: "right",
        key: "stock_em",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.stock_em}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "left" }}>FC Rev</div>,
        align: "right",
        key: "plan_fc_prev",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.plan_fc_prev}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "left" }}>Plan PO</div>,
        align: "right",
        key: "plan_po",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.plan_po}
              type="text"
            />
          );
        },
      },
      
      {
        title: <div style={{ textAlign: "left" }}>Prod Plan</div>,
        align: "right",
        key: "qty_prod_plan",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.qty_prod_plan}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "left" }}>Stock Plan</div>,
        align: "right",
        // width: 150,
        key: "qty_stock_plan",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.qty_stock_plan}
              type="text"
            />
          );
        },
      },
      // {
      //   title: <div style={{ textAlign: "left" }}>Plan OAP</div>,
      //   align: "right",
      //   // width: 150,
      //   // dataIndex: "plan_oap",
      //   key: "plan_oap",
      //   render: (data) => {
      //     return (
      //       <AntNumberFormat
      //         decimalScale={data.currency_name}
      //         value={data.plan_oap}
      //         type="text"
      //       />
      //     );
      //   },
      // },
      // {
      
      // {
      //   title: <div style={{ textAlign: "left" }}>Plan Domestic</div>,
      //   align: "right",
      //   // width: 150,
      //   // dataIndex: "plan_domestic",
      //   key: "plan_domestic",
      //   render: (data) => {
      //     return (
      //       <AntNumberFormat
      //         decimalScale={data.currency_name}
      //         value={data.plan_domestic}
      //         type="text"
      //       />
      //     );
      //   },
      // },
      {
        title: <div style={{ textAlign: "center" }}>Forecast</div>,
        children: [
          {
            title: <div style={{ textAlign: "center" }}>N+1</div>,
            align: "right",
            key: "forecast_n1_qty_prod_plan",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n1_qty_prod_plan}
                  type="text"
                />
              );
            },
          },
          {
            title: <div style={{ textAlign: "center" }}>N+2</div>,
            align: "right",
            key: "forecast_n2_qty_prod_plan",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n2_qty_prod_plan}
                  type="text"
                />
              );
            },
          },
          {
            title: <div style={{ textAlign: "center" }}>N+3</div>,
            align: "right",
            key: "forecast_n3_qty_prod_plan",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n3_qty_prod_plan}
                  type="text"
                />
              );
            },
          },
        ],
      },
    ];

    const handleChangeTab = (tabId) => {
      console.log(tabId, "tabId");
      console.log("Testing");
      getProdScheduleDetails(item._id, tabId === "0" ? false : true, tabId);
    };

    return (
      <>
        {/* <Row justify="end" gutter={[20, 30]}>
          <Col>
            <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            />
          </Col>
        </Row> */}
        <Row>
          <Col xs={24}>
            <Tabs defaultActiveKey="1" onChange={(e) => handleChangeTab(e)}>
              {sequences.map((sequence) => {
                return (
                  <TabPane tab={sequence.name} key={sequence.id}>
                    <Table
                      scroll={{ x: 1200 }}
                      columns={columns}
                      rowKey="_id"
                      loading={loadingDetail}
                      dataSource={prodScheduleDetails}
                      size="small"
                      pagination={{
                        pageSize: perPageDetail,
                        current: pageDetail,
                        total: prodScheduleDetailsTotal,
                        showSizeChanger: true,
                        style: {
                          marginTop: "2rem",
                          marginBottom: 0,
                        },
                      }}
                      onChange={handleChangeDetailsTable}
                    />
                  </TabPane>
                );
              })}
            </Tabs>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="MPS"
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Modal
                  title="MPS"
                  style={{ top: 20 }}
                  visible={isModalVisibleKanban}
                  width={1000}
                  footer={null}
                  onCancel={closeModalKanban}
                >
                  <Card className="body-data">
                    <div name="control-hooks">
                      <Collapse
                        bordered={false}
                        defaultActiveKey={["1", "2"]}
                        expandIcon={({ isActive }) => (
                          <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        className="site-collapse-custom-collapse"
                      >
                        <Panel
                          header="Item Finish Good"
                          key="1"
                          className="site-collapse-custom-panel"
                        >
                          <Form
                            labelAlign="left"
                            {...layout}
                            name="control-hooks"
                          >
                            <Row>
                              <Fragment>
                                <Col xs={24} md={12} lg={12}>
                                  <Form.Item
                                    label="Manufactured Item Name"
                                    validateStatus={
                                      error && error.item_name ? "error" : false
                                    }
                                    help={
                                      error && error.item_name
                                        ? error.item_name[0]
                                        : false
                                    }
                                  >
                                    <Input
                                      disabled
                                      value={data.item_name}
                                      onChange={(e) =>
                                        handleChange(
                                          "item_name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    validateStatus={
                                      error && error.bom_version
                                        ? "error"
                                        : false
                                    }
                                    help={
                                      error && error.bom_version
                                        ? error.bom_version[0]
                                        : false
                                    }
                                    label="BOM Version"
                                  >
                                    <Input
                                      placeholder="Default"
                                      value={data.bom_version}
                                      onChange={(e) => {
                                        handleChange(
                                          "bom_version",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xs={24} md={12} lg={12}>
                                  <Form.Item
                                    validateStatus={
                                      error && error.manufactured_quantity
                                        ? "error"
                                        : false
                                    }
                                    help={
                                      error && error.manufactured_quantity
                                        ? error.manufactured_quantity[0]
                                        : false
                                    }
                                    label="Manufactured Qty"
                                  >
                                    <AntNumberFormat
                                      //defaultValue="0"
                                      value={data.manufactured_quantity}
                                      type="input"
                                      onChange={(e) =>
                                        handleChange("manufactured_quantity", e)
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Fragment>
                            </Row>
                          </Form>
                        </Panel>
                        <Panel
                          header="Item Raw / Sub Material"
                          key="2"
                          className="site-collapse-custom-panel"
                        >
                          <Row justify="start">
                            <Col xs={24} md={12} lg={12}>
                              <Form.Item
                                label="Manufactured Item Name"
                                validateStatus={
                                  error && error.item_name ? "error" : false
                                }
                                help={
                                  error && error.item_name
                                    ? error.item_name[0]
                                    : false
                                }
                              >
                                <Input
                                  disabled
                                  value={data.item_name}
                                  onChange={(e) =>
                                    handleChange("item_name", e.target.value)
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                validateStatus={
                                  error && error.bom_version ? "error" : false
                                }
                                help={
                                  error && error.bom_version
                                    ? error.bom_version[0]
                                    : false
                                }
                                label="BOM Version"
                              >
                                <Input
                                  placeholder="Default"
                                  value={data.bom_version}
                                  onChange={(e) => {
                                    handleChange("bom_version", e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} md={12} lg={12}>
                              <Form.Item
                                validateStatus={
                                  error && error.manufactured_quantity
                                    ? "error"
                                    : false
                                }
                                help={
                                  error && error.manufactured_quantity
                                    ? error.manufactured_quantity[0]
                                    : false
                                }
                                label="Manufactured Qty"
                              >
                                <AntNumberFormat
                                  //defaultValue="0"
                                  value={data.manufactured_quantity}
                                  type="input"
                                  onChange={(e) =>
                                    handleChange("manufactured_quantity", e)
                                  }
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Space>
                                <Button
                                  type="primary"
                                  onClick={onSaveData}
                                  loading={loading}
                                  htmlType="submit"
                                  className="mr-button"
                                >
                                  Submit
                                </Button>

                                <Button
                                  onClick={handleReset}
                                  type="default"
                                  htmlType="button"
                                  className="mr-button"
                                >
                                  Reset
                                </Button>
                              </Space>
                            </Col>
                          </Row>
                        </Panel>
                      </Collapse>
                    </div>
                  </Card>
                </Modal>
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {/* <Link to="/master-production-schedule-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link> */}
                    <Button type="primary" onClick={showModal}>
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={exportProductionSchedule}>
                      <ExportOutlined />
                      Export
                    </Button>
                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                    {/* <Button type="primary" onClick={modals(id)}>
                      <DownloadOutlined />
                      Modal
                    </Button> */}
                    <Modal
                      title="Import MPS"
                      visible={isModalVisible}
                      footer={null}
                      onCancel={closeModal}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Fragment>
                              <Upload
                                onRemove={handleRemove}
                                beforeUpload={handleBeforeUpload}
                                fileList={[]}
                              >
                                <Button style={{ marginBottom: 10 }}>
                                  Choose File <ExportOutlined />
                                </Button>
                              </Upload>
                              {data.file && (
                                <React.Fragment
                                  children={[
                                    <Button
                                      onClick={handleRemove}
                                      type="danger"
                                      ghost
                                    >
                                      <DeleteOutlined />
                                    </Button>,
                                  ]}
                                >
                                  {data.file.name ? data.file.name : data.file}
                                </React.Fragment>
                              )}
                            </Fragment>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                                backgroundColor: "#1b2086",
                                borderColor: "#1b2086",
                              }}
                            >
                              Upload
                            </Button>
                            <Button htmlType="reset" onClick={handleRemove}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <DatePicker
                    onChange={(value) => handleChange("date", value)}
                    value={date}
                    picker="month"
                    placeholder="Select Month"
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    size="small"
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={data.data}
                    //dataSource={dataSource}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    expandable={{
                      expandedRowRender: (item) => getProductionSchedule(item),
                      onExpandedRowsChange: (item) => handleChangeDetails(item),
                      expandedRowKeys: rowDetails,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MasterProductionSchedule;
