import moment from 'moment'

export function DateFormat(DateVal){
    DateVal = moment(new Date(DateVal)).format('YYYY-MM-DD')
    return DateVal;
    
}

export function toLocaleDate(date){
    let month = (date.getMonth() + 1).toString();
    month = month.length === 1? "0" + month : month;
    let getDate = date.getDate().toString();
    getDate = getDate.length === 1? "0" + getDate : getDate;
    return `${date.getFullYear()}-${month}-${getDate}`;
}

export function stringComaToNumber(str){
    return parseInt(str.replace(/\D/g,''));
}

export function removePercent(str){
    return parseInt(str.replace('%',''));
}