/* eslint-disable */
import React, { useState } from "react";
import {
  Menu,
  Badge,
  Avatar,
  Space,
  Dropdown,
  Tabs,
  List,
  Button,
  Input,
} from "antd";
import {
  MenuFoldOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import "./Navbar.less";
import { Link } from "react-router-dom";
import moment from "moment";

const { TabPane } = Tabs;

const data = [
  {
    title: "Ant Design Title 1",
  },
  {
    title: "Ant Design Title 2",
  },
  {
    title: "Ant Design Title 3",
  },
];

function Navbar(props) {
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState(false);

  const handleNotification = () => {
    setVisible(!visible);
  };

  const handleSeeMore = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    console.log("terclick");
    localStorage.removeItem("token");

    return props.history.push("/login");
  };

  return (
    <div className="navbar">
      <div>
        {props.collapsed ? (
          <MenuUnfoldOutlined onClick={props.onToggleCollapsed} />
        ) : (
          <MenuFoldOutlined onClick={props.onToggleCollapsed} />
        )}
      </div>
      <div>
        <Menu mode="horizontal" className="menu-header">
          <div>{moment(new Date()).format("dddd, DD MMMM YYYY, H:mm")}</div>

          <Menu.Item key="profile">
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="3">
                    <span onClick={handleLogout}>
                      <LogoutOutlined style={{ marginRight: 5 }} />
                      Logout
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <Space>
                  {localStorage.getItem("avatar") ? (
                    <Avatar
                      size="small"
                      src={localStorage.getItem("avatar")}
                    />
                  ) : (
                    <Avatar size="small" icon={<UserOutlined />} />
                  )}
                  {!props.screens.xs && (
                    <span> {localStorage.getItem("fullname")} </span>
                  )}
                </Space>
              </a>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;
