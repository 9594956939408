import React from "react";
import BasicLayout from "./layouts/BasicLayout";
import Login from "./views/Login/Login";
import { Redirect } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import EmptyLayout from "./layouts/EmptyLayout";

//#region Dashboard
import Dashboard from "./views/Dashboard/Dashboard";
import Dash_prod from "./views/Dashboard/Dash_prod";
import Dash_inven from "./views/Dashboard/Dash_inven";
import Dash_deliv from "./views/Dashboard/Dash_deliv";
//#endregion

//#region Upload Data
import HeijunkaProd from "./views/UploadData/HeijunkaProd";
import HeijunkaProdManage from "./views/UploadData/HeijunkaProdManage";
import PlanInven from "./views/UploadData/PlanInven";
import PlanInvenManage from "./views/UploadData/PlanInvenManage";
import NoteDeliv from "./views/UploadData/NoteDeliv";
import NoteDelivManage from "./views/UploadData/NoteDelivManage";
//#endregion

//#region Master
//#region Master Shift
import Shift from "./views/Master/Shift/Shift";
import ManageShift from "./views/Master/Shift/ManageShift";
//#endregion

//#region Master Rute
import Route from "./views/Master/Route/Route";
import ManageRoute from "./views/Master/Route/ManageRoute";
import QRCodeRoute from "./views/Master/Route/QRCodeRoute";
//#endregion

//#endregion

//#region User
import User from "./views/User/User";
import CreateUser from "./views/User/CreateUser";
import EditUser from "./views/User/EditUser";
//#endregion

//#region Permission
import Permission from "./views/Permission/Permission";
import CreatePermission from "./views/Permission/CreatePermission";
import EditPermission from "./views/Permission/EditPermission";
import ViewPermission from "./views/Permission/ViewPermission";
//#endregion

//#region Role
import Role from "./views/Role/Role";
import CreateRole from "./views/Role/CreateRole";
import EditRole from "./views/Role/EditRole";
//#endregion

//#region Setting
import Setting from "./views/Setting/Setting";
import CreateSetting from "./views/Setting/CreateSetting";
import EditSetting from "./views/Setting/EditSetting";
//#endregion

//#region Scan
import ScanTruck from "./views/Scan/ScanTruck";
import ScanDN from "./views/Scan/ScanDN";
//#endregion

//#region No Template
import Dash_delivView from "./views/NoTemplate/Dash_delivView";
//#endregion

//#region WH Receiving
import ReceivingWip from "./views/Production/ReceivingWip";
import WhStockOpname from "./views/Warehousing/WhStockOpname";
import WhCreateStockOpname from "./views/Warehousing/WhCreateStockOpname";
import WhReceivingSupplier from "./views/Warehousing/WhReceivingSupplier";

//#region Master Data
import MasterPrice from "./views/Master/Price/MasterPrice";
import CreateMasterPrice from "./views/Master/Price/CreateMasterPrice";
import Item from "./views/Master/Item/Item";
import Category from "./views/Master/Category/Category";
import CreateCategory from "./views/Master/Category/CreateCategory";
import EditCategory from "./views/Master/Category/EditCategory";
import Location from "./views/Master/Location/Location";
import EditItem from "./views/Master/Item/EditItem";
import EditLocation from "./views/Master/Location/EditLocation";
import CreateLocation from "./views/Master/Location/CreateLocation";
import EditMasterPrice from "./views/Master/Price/EditMasterPrice";
import CreateItem from "./views/Master/Item/CreateItem";
import SupplierCustomer from "./views/Master/SupplierCustomer/SupplierCustomer";
import CreateSupplierCustomer from "./views/Master/SupplierCustomer/CreateSupplierCustomer";
import EditSupplierCustomer from "./views/Master/SupplierCustomer/EditSupplierCustomer";
import Kanban from "./views/Master/Kanban/Kanban";
import CreateKanban from "./views/Master/Kanban/CreateKanban";
import Bom from "./views/Master/Bom/Bom";
import CreateBom from "./views/Master/Bom/CreateBom";
import EditKanbanWIP from "./views/Master/Kanban/EditKanbanWIP";
import CreateProcess from "./views/Master/Process/CreateProcess";
import Process from "./views/Master/Process/Process";
import EditProcess from "./views/Master/Process/EditProcess";
import Packaging from "./views/Master/Packaging/Packaging";
import CreatePackaging from "./views/Master/Packaging/CreatePackaging";
import EditPackaging from "./views/Master/Packaging/EditPackaging";
import SuggestionPO from "./views/Purchasing/SuggestionPO";
import PurchasingPO from "./views/Purchasing/PurchasingPO";
import CreatePurchasingPO from "./views/Purchasing/CreatePurchasingPO";
import EditKanbanFinishGoods from "./views/Master/Kanban/EditKanbanFinishGoods";
import EditKanbanMaterial from "./views/Master/Kanban/EditKanbanMaterial";
import MasterProductionSchedule from "./views/Master/ProductionSchedule/ProductionSchedule";
import CreateProductionSchedule from "./views/Master/ProductionSchedule/CreateProductionSchedule";
import Approval from "./views/Master/Approval/Approval";
import CreateApproval from "./views/Master/Approval/CreateApproval";
import EditApproval from "./views/Master/Approval/EditApproval";
import EditProductionSchedule from "./views/Master/ProductionSchedule/EditProductionSchedule";
import EditBom from "./views/Master/Bom/EditBom";
import EditPurchasingPO from "./views/Purchasing/EditPurchasingPO";
import Currency from "./views/Master/Currency/Currency";
import CreateCurrency from "./views/Master/Currency/CreateCurrency";
import EditCurrency from "./views/Master/Currency/EditCurrency";
import DailyKanban from "./views/Transaction/DailyKanban";
import Warehouse from "./views/Warehousing/Warehouse";
import PODailyKanban from "./views/Transaction/PODailyKanban";
import SuggestionPPIC from "./views/Purchasing/SuggestionPPIC";
import MaterialSplitter from "./views/MaterialSplitter/MaterialSplitter";
import CreateMaterialSplitter from "./views/MaterialSplitter/CreateMaterialSplitter";
import EditMaterialSplitter from "./views/MaterialSplitter/EditMaterialSplitter";
import SuggestionApprovalPPIC from "./views/Purchasing/SuggestionApprovalPPIC";
import WarehouseScan from "./views/Warehousing/WarehouseScan";
import Invoicing from "./views/Warehousing/Invoicing";
import DnUploadCertificate from "./views/Warehousing/DnUploadCertificate";
import CreateDnUpload from "./views/Warehousing/CreateDnUpload";
import BeginStock from "./views/BeginStock/BeginStock";
import CreateBeginStock from "./views/BeginStock/CreateBeginStock";
import Dash_daily from "./views/Dashboard/Dash_daily";
import Dash_monthly from "./views/Dashboard/Dash_monthly";
import InvoicingCompare from "./views/Warehousing/InvoicingCompare";
import InvoiceCreate from "./views/Warehousing/InvoiceCreate";
import SuratJalan from "./views/SuratJalan/SuratJalan";
import CreateSuratJalan from "./views/SuratJalan/CreateSuratJalan";
import CreateNoteDeliv from "./views/UploadData/CreateNoteDeliv";
import ProductionResult from "./views/Result/ProductionResult";
import PullingResult from "./views/Result/PullingResult";
import CreateSuggestionApprvalPPIC from "./views/Purchasing/CreateSuggestionApprovalPPIC";
import WarehouseOutgoing from "./views/Warehousing/WarehouseOutgoing";
import Production from "./views/Production/Production";
import StockOpnameFG from "./views/StockOpname/StockOpnameFG";
import InvoicingCompareRev from "./views/Warehousing/InvoicingCompareRev";
import InvoicingGr from "./views/Report/InvoicingGr";
import ListTrackingPO from "./views/Report/ListTrackingPO";
import GensanKanri from "./views/Report/GensanKanri";
import ReportSuggestionPpic from "./views/Report/ReportSuggestionPpic";
import StockAdjustFG from "./views/StockOpname/StockAdjustFG";
import Calendar from "./views/Master/Calendar/Calendar";
import CreateCalendar from "./views/Master/Calendar/CreateCalendar";
import EditCalendar from "./views/Master/Calendar/EditCalendar";

export default [
  {
    path: "/",
    layout: BasicLayout,
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    path: "/login",
    layout: BasicLayout,
    component: Login,
    exact: true,
  },

  //#region Dashboard
  {
    path: "/dashboard",
    layout: FullLayout,
    component: Dashboard,
    exact: true,
  },
  {
    path: "/production",
    layout: FullLayout,
    component: Dash_prod,
    exact: true,
  },
  {
    path: "/productionView",
    layout: BasicLayout,
    component: Dash_prod,
    exact: true,
  },
  {
    path: "/inventory",
    layout: FullLayout,
    component: Dash_inven,
    exact: true,
  },
  {
    path: "/inventoryView",
    layout: BasicLayout,
    component: Dash_inven,
    exact: true,
  },
  {
    path: "/delivery",
    layout: FullLayout,
    component: Dash_deliv,
    exact: true,
  },
  {
    path: "/deliveryView",
    layout: BasicLayout,
    component: Dash_delivView,
    exact: true,
  },
  //#endregion

  //#region Upload Data

  //#region Production Heijunka
  {
    path: "/production-heijunka",
    layout: FullLayout,
    component: HeijunkaProd,
    exact: true,
  },
  {
    path: "/production-heijunka-edit-:id",
    layout: FullLayout,
    component: HeijunkaProdManage,
    exact: true,
  },
  //#endregion

  //#region Inventory Plan
  {
    path: "/inventory-plan",
    layout: FullLayout,
    component: PlanInven,
    exact: true,
  },
  {
    path: "/inventory-plan-edit-:id",
    layout: FullLayout,
    component: PlanInvenManage,
    exact: true,
  },
  //#endregion

  //#region Delivery Note
  {
    path: "/delivery-note",
    layout: FullLayout,
    component: NoteDeliv,
    exact: true,
  },
  {
    path: "/delivery-note-create",
    layout: FullLayout,
    component: CreateNoteDeliv,
    exact: true,
  },
  {
    path: "/delivery-note-edit-:id",
    layout: FullLayout,
    component: NoteDelivManage,
    exact: true,
  },
  //#endregion

  //#endregion

  //#region Master

  //#region Master Shift
  {
    path: "/shift",
    layout: FullLayout,
    component: Shift,
    exact: true,
  },
  {
    path: "/shift-create",
    layout: FullLayout,
    component: ManageShift,
    exact: true,
  },
  {
    path: "/shift-edit-:id",
    layout: FullLayout,
    component: ManageShift,
    exact: true,
  },
  //#endregion

  //#region Master Rute
  {
    path: "/route",
    layout: FullLayout,
    component: Route,
    exact: true,
  },
  {
    path: "/route-create",
    layout: FullLayout,
    component: ManageRoute,
    exact: true,
  },
  {
    path: "/route-edit-:id",
    layout: FullLayout,
    component: ManageRoute,
    exact: true,
  },
  {
    path: "/route/download/:id",
    layout: EmptyLayout,
    component: QRCodeRoute,
    exact: true,
  },
  //#endregion

  //#endregion

  //#region Scan
  {
    path: "/scan-truck",
    layout: FullLayout,
    component: ScanTruck,
    exact: true,
  },
  {
    path: "/scan-dn",
    layout: FullLayout,
    component: ScanDN,
    exact: true,
  },

  //#endregion

  //#region Skeleton
  {
    path: "/user",
    layout: FullLayout,
    component: User,
    exact: true,
  },
  {
    path: "/user-create",
    layout: FullLayout,
    component: CreateUser,
    exact: true,
  },
  {
    path: "/user-edit-:id",
    layout: FullLayout,
    component: EditUser,
    exact: true,
  },
  {
    path: "/permission",
    layout: FullLayout,
    component: Permission,
    exact: true,
  },
  {
    path: "/permission-create",
    layout: FullLayout,
    component: CreatePermission,
    exact: true,
  },
  {
    path: "/permission-edit-:id",
    layout: FullLayout,
    component: EditPermission,
    exact: true,
  },
  {
    path: "/permission/view/:id",
    layout: FullLayout,
    component: ViewPermission,
    exact: true,
  },
  {
    path: "/role",
    layout: FullLayout,
    component: Role,
    exact: true,
  },
  {
    path: "/role-create",
    layout: FullLayout,
    component: CreateRole,
    exact: true,
  },
  {
    path: "/role-edit-:id",
    layout: FullLayout,
    component: EditRole,
    exact: true,
  },
  {
    path: "/setting",
    layout: FullLayout,
    component: Setting,
    exact: true,
  },
  {
    path: "/setting-create",
    layout: FullLayout,
    component: CreateSetting,
    exact: true,
  },
  {
    path: "/setting-edit-:id",
    layout: FullLayout,
    component: EditSetting,
    exact: true,
  },
  //#endregion

  //#whreceiving
  {
    path: "/receiving-wip",
    layout: FullLayout,
    component: ReceivingWip,
    exact: true,
  },
  {
    path: "/wip",
    layout: FullLayout,
    component: Production,
    exact: true,
  },
  {
    path: "/wh-receiving-out",
    layout: FullLayout,
    component: WarehouseOutgoing,
    exact: true,
  },
  {
    path: "/wh-stock-opname",
    layout: FullLayout,
    component: WhStockOpname,
    exact: true,
  },
  {
    path: "/wh-stock-opname-create",
    layout: FullLayout,
    component: WhCreateStockOpname,
    exact: true,
  },
  {
    path: "/wh-receiving-supplier",
    layout: FullLayout,
    component: WhReceivingSupplier,
    exact: true,
  },

  //#purchasing
  {
    path: "/suggestion-po",
    layout: FullLayout,
    component: SuggestionPO,
    exact: true,
  },
  
  {
    path: "/purchasing-po",
    layout: FullLayout,
    component: PurchasingPO,
    exact: true,
  },
  {
    path: "/purchasing-po-create",
    layout: FullLayout,
    component: CreatePurchasingPO,
    exact: true,
  },
  {
    path: "/purchasing-po-edit-:id",
    layout: FullLayout,
    component: EditPurchasingPO,
    exact: true,
  },

  //#master
  {
    path: "/master-price",
    layout: FullLayout,
    component: MasterPrice,
    exact: true,
  },
  {
    path: "/master-price-create",
    layout: FullLayout,
    component: CreateMasterPrice,
    exact: true,
  },
  {
    path: "/master-price-edit-:id",
    layout: FullLayout,
    component: EditMasterPrice,
    exact: true,
  },
  {
    path: "/master-item",
    layout: FullLayout,
    component: Item,
    exact: true,
  },
  {
    path: "/master-category",
    layout: FullLayout,
    component: Category,
    exact: true,
  },
  {
    path: "/master-category-create",
    layout: FullLayout,
    component: CreateCategory,
    exact: true,
  },
  {
    path: "/master-category-edit-:id",
    layout: FullLayout,
    component: EditCategory,
    exact: true,
  },
  {
    path: "/master-location",
    layout: FullLayout,
    component: Location,
    exact: true,
  },
  {
    path: "/master-location-create",
    layout: FullLayout,
    component: CreateLocation,
    exact: true,
  },
  {
    path: "/master-location-edit-:id",
    layout: FullLayout,
    component: EditLocation,
    exact: true,
  },
  {
    path: "/master-item-create",
    layout: FullLayout,
    component: CreateItem,
    exact: true,
  },
  {
    path: "/master-item-edit-:id",
    layout: FullLayout,
    component: EditItem,
    exact: true,
  },
  {
    path: "/master-supplier-customer",
    layout: FullLayout,
    component: SupplierCustomer,
    exact: true,
  },
  {
    path: "/master-supplier-customer-create",
    layout: FullLayout,
    component: CreateSupplierCustomer,
    exact: true,
  },
  {
    path: "/master-supplier-customer-edit-:id",
    layout: FullLayout,
    component: EditSupplierCustomer,
    exact: true,
  },
  {
    path: "/master-kanban",
    layout: FullLayout,
    component: Kanban,
    exact: true,
  },
  {
    path: "/master-kanban-create",
    layout: FullLayout,
    component: CreateKanban,
    exact: true,
  },
  {
    path: "/master-kanban/edit/wip/:id",
    layout: FullLayout,
    component: EditKanbanWIP,
    exact: true,
  },
  {
    path: "/master-kanban/edit/finish-goods/:id",
    layout: FullLayout,
    component: EditKanbanFinishGoods,
    exact: true,
  },
  {
    path: "/master-kanban/edit/material/:id",
    layout: FullLayout,
    component: EditKanbanMaterial,
    exact: true,
  },
  {
    path: "/master-bom",
    layout: FullLayout,
    component: Bom,
    exact: true,
  },
  {
    path: "/master-bom-create",
    layout: FullLayout,
    component: CreateBom,
    exact: true,
  },
  {
    path: "/master-bom-edit-:id",
    layout: FullLayout,
    component: EditBom,
    exact: true,
  },
  {
    path: "/master-process",
    layout: FullLayout,
    component: Process,
    exact: true,
  },
  {
    path: "/master-process-create",
    layout: FullLayout,
    component: CreateProcess,
    exact: true,
  },
  {
    path: "/master-process-edit-:id",
    layout: FullLayout,
    component: EditProcess,
    exact: true,
  },
  {
    path: "/master-packaging",
    layout: FullLayout,
    component: Packaging,
    exact: true,
  },
  {
    path: "/master-packaging-create",
    layout: FullLayout,
    component: CreatePackaging,
    exact: true,
  },
  {
    path: "/master-packaging-edit-:id",
    layout: FullLayout,
    component: EditPackaging,
    exact: true,
  },
  {
    path: "/master-production-schedule",
    layout: FullLayout,
    component: MasterProductionSchedule,
    exact: true,
  },
  {
    path: "/master-production-schedule-create",
    layout: FullLayout,
    component: CreateProductionSchedule,
    exact: true,
  },
  {
    path: "/master-production-schedule-edit-:id",
    layout: FullLayout,
    component: EditProductionSchedule,
    exact: true,
  },
  {
    path: "/master-approval",
    layout: FullLayout,
    component: Approval,
    exact: true,
  },
  {
    path: "/master-approval-create",
    layout: FullLayout,
    component: CreateApproval,
    exact: true,
  },
  {
    path: "/master-approval-edit-:id",
    layout: FullLayout,
    component: EditApproval,
    exact: true,
  },
  {
    path: "/master-currency",
    layout: FullLayout,
    component: Currency,
    exact: true,
  },
  {
    path: "/master-currency-create",
    layout: FullLayout,
    component: CreateCurrency,
    exact: true,
  },
  {
    path: "/master-currency-edit-:id",
    layout: FullLayout,
    component: EditCurrency,
    exact: true,
  },
  {
    path: "/daily-kanban",
    layout: FullLayout,
    component: DailyKanban,
    exact: true,
  },
  {
    path: "/warehouse",
    layout: FullLayout,
    component: Warehouse,
    exact: true,
  },
  {
    path: "/po-daily-kanban",
    layout: FullLayout,
    component: PODailyKanban,
    exact: true,
  },
  {
    path: "/suggestion-ppic",
    layout: FullLayout,
    component: SuggestionPPIC,
    exact: true,
  },
  {
    path: "/suggestion-approval-ppic",
    layout: FullLayout,
    component: SuggestionApprovalPPIC,
    exact: true,
  },
  {
    path: "/suggestion-approval-ppic-create",
    layout: FullLayout,
    component: CreateSuggestionApprvalPPIC,
    exact: true,
  },

  {
    path: "/material-splitter",
    layout: FullLayout,
    component: MaterialSplitter,
    exact: true,
  },
  {
    path: "/material-splitter-create",
    layout: FullLayout,
    component: CreateMaterialSplitter,
    exact: true,
  },
  {
    path: "/material-splitter-edit-:id",
    layout: FullLayout,
    component: EditMaterialSplitter,
    exact: true,
  },
  {
    path: "/warehouse-receive-scan",
    layout: FullLayout,
    component: WarehouseScan,
    exact: true,
  },
  {
    path: "/invoicing",
    layout: FullLayout,
    component: Invoicing,
    exact: true,
  },
  {
    path: "/dn-upload-certificate",
    layout: FullLayout,
    component: DnUploadCertificate,
    exact: true,
  },
  {
    path: "/dn-upload-certificate-create",
    layout: FullLayout,
    component: CreateDnUpload,
    exact: true,
  },
  {
    path: "/dashboard-daily",
    layout: FullLayout,
    component: Dash_daily,
    exact: true,
  },
  {
    path: "/begin-stock",
    layout: FullLayout,
    component: BeginStock,
    exact: true,
  },
  {
    path: "/begin-stock-create",
    layout: FullLayout,
    component: CreateBeginStock,
    exact: true,
  },
  {
    path: "/dashboard-monthly",
    layout: FullLayout,
    component: Dash_monthly,
    exact: true,
  },
  {
    path: "/invoicing-compare",
    layout: FullLayout,
    component: InvoicingCompare,
    exact: true,
  },
  {
    path: "/invoicing-compare-rev",
    layout: FullLayout,
    component: InvoicingCompareRev,
    exact: true,
  },
  {
    path: "/invoice-create",
    layout: FullLayout,
    component: InvoiceCreate,
    exact: true,
  },
  {
    path: "/surat-jalan",
    layout: FullLayout,
    component: SuratJalan,
    exact: true,
  },
  {
    path: "/create-surat-jalan",
    layout: FullLayout,
    component: CreateSuratJalan,
    exact: true,
  },

  //result
  {
    path: "/production-result",
    layout: FullLayout,
    component: ProductionResult,
    exact: true,
  },
  {
    path: "/pulling-result",
    layout: FullLayout,
    component: PullingResult,
    exact: true,
  },
  {
    path: "/stock-opname-finish-good",
    layout: FullLayout,
    component: StockOpnameFG,
    exact: true,
  },
  {
    path: "/invoicing-gr",
    layout: FullLayout,
    component: InvoicingGr,
    exact: true,
  },
  {
    path: "/tracking-po",
    layout: FullLayout,
    component: ListTrackingPO,
    exact: true,
  },
  {
    path: "/gensan-kanri",
    layout: FullLayout,
    component: GensanKanri,
    exact: true,
  },
  {
    path: "/report-suggestion-ppic",
    layout: FullLayout,
    component: ReportSuggestionPpic,
    exact: true,
  },
  {
    path: "/stock-adjustment-finish-goods",
    layout: FullLayout,
    component: StockAdjustFG,
    exact: true,
  },
  {
    path: "/master-calendar",
    layout: FullLayout,
    component: Calendar,
    exact: true,
  },
  {
    path: "/master-calendar-create",
    layout: FullLayout,
    component: CreateCalendar,
    exact: true,
  },
  {
    path: "/master-calendar-edit-:id",
    layout: FullLayout,
    component: EditCalendar,
    exact: true,
  },
];
