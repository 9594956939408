/* eslint-disable */ 
import React, { useState, useEffect, Fragment } from 'react'
import {Card, Typography, Form, Layout, Row, Col, Input, message, Table} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url}  from '../../config/global'
import './Scan.less'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

const layout = {
    labelCol: {
        md: 8
    },
    wrapperCol: {
        md: 10
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}


function ScanTruck() {

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [scanData, setScanData] = useState('')
    const [resData, setResData] = useState('')

    const [loading, setLoading] = useState(false)

    //#region Get Data
    useEffect(() => {

        setLoading(true)

        getData(date)

        if (!loading){

            let rotationInterval = setInterval(() => {
                
                const CurrDate = moment(new Date()).format('YYYY-MM-DD')

                getData(CurrDate)

                setDate(CurrDate)

            }, 15000);

            return () => {
                clearInterval(rotationInterval);
            }
            
        }

    }, [date])

    const getData = async () => {

        setLoading(true)

        const results = await Axios.get(`${url}/delivery`, {

            params: {
                date, type: 1
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            
        }).then(res => {

            return res.data;
    
        })

        const res = results.data;

        setData(res);
        setLoading(false)

    }
    //#endregion
 
    //#region Update
    const handleScan = () => {

        setLoading(true)
        
        if (!scanData.includes(";")) {
            
            message.error('Invalid format input')
            setLoading(false)

        }

        const myArr = scanData.split(";");
        let soso;
        let customerAr = myArr[0].toLowerCase();
        let routeAr = myArr[1].toLowerCase();
        let truckAr = myArr[2].toLowerCase();
        let cycleAr = myArr[3].toString().toLowerCase();

        data.map(item => {

            let customer;
            let route;
            let truck;
            let cycle;

            customer = item.customer.toLowerCase();
            route = item.route.toLowerCase().trim();
            truck = item.truck.toLowerCase().trim();
            cycle = item.cycle.toString().toLowerCase();

            if (customer == customerAr && route == routeAr && truck == truckAr && cycle == cycleAr){
                soso = true
            }
        })

        if (scanData == resData && soso == false) {
            
            message.error('Invalid format input')
            setLoading(false)

        } else {

            let formData = new FormData()
    
            formData.set('scanData', `${scanData}`)
    
            Axios.post(`${url}/delivery`, formData, {
    
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
    
            }).then(res => {

                setResData(scanData)
                message.success(res.data.message)
    
            }).catch(err => {
    
                if (err.response) {
    
                    message.error(err.response.data.message)
                    setScanData('')
    
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
            })
        }
    
        setLoading(false)

        getData()
        setScanData('')

    }
    //#endregion

    //#region Table Settings
    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Customer',
            dataIndex: 'customer',
            align: 'center',
        },
        {
            title: 'Truck',
            dataIndex: 'truck',
            align: 'center',
        },
        {
            title: 'Route',
            dataIndex: 'route',
            align: 'center',
        },
        {
            title: 'Cycle',
            dataIndex: 'cycle',
            align: 'center',
        },
        {
            title: 'Arrival Plan',
            dataIndex: 'arrival_plan',
            align: 'center',
        },
        {
            title: 'Arrival',
            dataIndex: 'arrival_actual',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'arrival_status',
            align: 'center',
        },
        {
            title: 'Departure Plan',
            dataIndex: 'departure_plan',
            align: 'center',
        },
        {
            title: 'Departure',
            dataIndex: 'departure_actual',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'departure_status',
            align: 'center',
        },
    ]
    //#endregion

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }
    
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="ScanTruck" bodyStyle={{padding: "0"}} extra={moment(new Date(date)).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <div className="containerScan">
                            <Card>
                                <Form 
                                    name="control-hooks"
                                    onFinish={handleScan}
                                    className="formQRCode"
                                >
                                    <Input value={scanData} onChange={(e) => setScanData(e.target.value)} autoFocus={true} placeholder="Route Data" required={true} />
                                </Form>
                                {
                                    resData != '' ? (
                                        <div className="text">
                                            <h2>{resData} - {moment(new Date()).format('YYYY-MM-DD')}</h2>
                                        </div>
                                    ) : ('')
                                }
                                <Table
                                    size="small"
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={
                                        data => data.id
                                    }
                                    bordered
                                    scroll={{x: 'auto'}}
                                    pagination={false}
                                    loading={loading}
                                    style={{
                                        margin: '15px 0'
                                    }}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}



export default ScanTruck