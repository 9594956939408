import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  Space,
  Select,
  Radio,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function CreateBom(props) {
  const [data, setData] = useState({
    part_label: "",
    part_id: "",
    part_name: "",
    part_number: "",
    process_label: "",
    type: "",
    process_code: "",
    process_name: "",
    process_id: "",
    manufactured_quantity: 0,
    finish_goods: [],
    materials: [],
    processes: [],
  });

  useEffect(() => {
    getUnit();
  }, []);

  const [bomData, setBomData] = useState({
    item_id: "",
    item_code: "",
    item_name: "",
    item_label: "",
    item_category: "",
    item_type: "",
    lot: "",
    spec: "",
    unit: "",
    quantity: 0,
    location_id: "",
    location_code: "",
    location_name: "",
    location_label: "",
    supplier_id: "",
    supplier_code: "",
    supplier_name: "",
    supplier_label: "",
    process_label: "",
    process_id: "",
    process_code: "",
    process_name: "",
    notes: "",
    items: [],
    suppliers: [],
    processes: [],
    locations: [],
  });

  const [itemSupplier, setItemSupplier] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loadItem, setLoadItem] = useState(false);
  const [loadProcess, setLoadProcess] = useState(false);
  const [loadLocation, setLoadLocation] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [bomDetail, setBomDetail] = useState([]);
  const [units, setUnit] = useState([]);
  const [valCheckbox, setValCheckbox] = useState(false);

  const getUnit = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Unit",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setUnit(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
    // handleRemoveDetails();
  };

  const handleChangeSelect = (name, value, nameField, sources) => {
    if (name === "part") {
      const find = data.finish_goods.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_number`]: find.item_code,
        [`${name}_name`]: find.item_name,
      });

      console.log(find.part_name, find.part_number);
    } else {
      const find = data.processes.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.process_code,
        [`${name}_name`]: find.process_name,
      });
    }
  };

  const handleChangeBom = (name, value) => {
    setBomData({
      ...bomData,
      [name]: value,
    });
  };

  const handleChangeSelectBom = (name, value, nameField, sources) => {
    //const find = data.items.find((x) => x._id === value.value);
    if (name === "item") {
      const find = data.materials.find((x) => x._id === value.key);
      setBomData({
        ...bomData,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
        [`${name}_category`]: find.item_category,
        [`${name}_type`]: find.item_type,
        [`lot`]: find.lot,
      });
      setItemSupplier(find.item_code);
    } else if (name === "process") {
      const find = data.processes.find((x) => x._id === value.key);
      setBomData({
        ...bomData,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.process_code,
        [`${name}_name`]: find.process_name,
      });
    } else if (name === "location") {
      const find = bomData.locations.find((x) => x._id === value.key);
      setBomData({
        ...bomData,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    } else {
      const find = bomData.suppliers.find((x) => x._id === value.key);
      setBomData({
        ...bomData,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    }
  };

  const getItem = async (keyword, type) => {
    if (type === "raw_material") {
      setLoadItem(true);

      await Axios.get(`${url}/item/list-item-bom-detail`, {
        params: {
          keyword,
          columns: ["item_code", "item_name"],
          // item_category: type,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            materials: res.data.data,
          });
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
              // handleRemoveDetails();
            }
          }
        });
      setLoadItem(false);
    } else {
      setLoadItem(true);
      await Axios.get(`${url}/item/list`, {
        params: {
          keyword,
          columns: ["item_code", "item_name"],
          item_category: data.type,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            finish_goods: res.data.data,
          });
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
              // handleRemoveDetails();
            }
          }
        });
      setLoadItem(false);
    }
  };

  const getItemSupplier = async (keyword) => {
    setLoadItem(true);
    await Axios.get(`${url}/supplier/list`, {
      params: {
        item_code: itemSupplier,
        keyword,
        columns: ["supplier"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBomData({
          ...bomData,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoadItem(false);

    // console.log(itemSupplier, "ini supplier");
  };

  const getProcess = async (keyword) => {
    setLoadProcess(true);
    await Axios.get(`${url}/process/list`, {
      params: {
        keyword,
        columns: ["process_code", "process_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          processes: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoadProcess(false);
  };

  const getLocation = async (keyword) => {
    setLoadLocation(true);
    await Axios.get(`${url}/location/list`, {
      params: {
        keyword,
        columns: ["location_code", "location_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBomData({
          ...bomData,
          locations: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoadLocation(false);
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyBomDetail = [...bomDetail];
      const findBomDetail = modifyBomDetail.find((x) => x._id === selectedId);

      findBomDetail.item_code = bomData.item_code;
      findBomDetail.item_name = bomData.item_name;
      findBomDetail.item_category = bomData.item_category;
      findBomDetail.item_type = bomData.item_type;
      findBomDetail.lot = bomData.lot;
      findBomDetail.spec = bomData.spec;
      findBomDetail.unit = bomData.unit;
      findBomDetail.quantity = bomData.quantity;
      findBomDetail.supplier_code = bomData.supplier_code;
      findBomDetail.supplier_name = bomData.supplier_name;
      findBomDetail.process_code = bomData.process_code;
      findBomDetail.process_name = bomData.process_name;
      findBomDetail.location_code = bomData.location_code;
      findBomDetail.location_name = bomData.location_name;
      findBomDetail.notes = bomData.notes;

      setBomDetail(modifyBomDetail);
    } else {
      setBomDetail([
        ...bomDetail,
        {
          _id: Math.random(),
          item_code: bomData.item_code,
          item_name: bomData.item_name,
          item_category: bomData.item_category,
          item_type: bomData.item_type,
          lot: bomData.lot,
          spec: bomData.spec,
          unit: bomData.unit,
          quantity: bomData.quantity,
          supplier_code: bomData.supplier_code,
          supplier_name: bomData.supplier_name,
          process_code: bomData.process_code,
          process_name: bomData.process_name,
          location_code: bomData.location_code,
          location_name: bomData.location_name,
          notes: bomData.notes,
        },
      ]);
    }

    setSelectedId("");

    setBomData({
      ...bomData,
      item_id: "",
      item_code: "",
      item_name: "",
      item_label: "",
      item_category: "",
      item_type: "",
      lot: "",
      spec: "",
      unit: "",
      quantity: 0,
      location_id: "",
      location_code: "",
      location_name: "",
      location_label: "",
      supplier_id: "",
      supplier_label: "",
      supplier_code: "",
      supplier_name: "",
      process_id: "",
      process_code: "",
      process_name: "",
      process_label: "",
      notes: "",
    });

    setValCheckbox(false);
    setIsModalVisible(false);
  };

  const handleRemoveDetails = (id) => {
    const details = bomDetail.filter((bomDetail) => bomDetail._id !== id);
    setBomDetail(details);
  };

  const handleEditDetails = (id) => {
    const findBom = bomDetail.find((bomDetail) => bomDetail._id === id);
    setSelectedId(id);

    setBomData({
      ...bomData,
      item_label: `${findBom.item_code} - ${findBom.item_name}`,
      item_code: findBom.item_code,
      item_name: findBom.item_name,
      item_category: findBom.item_category,
      item_type: findBom.item_type,
      lot: findBom.lot,
      spec: findBom.spec,
      unit: findBom.unit,
      quantity: findBom.quantity,
      supplier_label: `${findBom.supplier_code} - ${findBom.supplier_name}`,
      supplier_code: findBom.supplier_code,
      supplier_name: findBom.supplier_name,
      location_label: `${findBom.location_code} - ${findBom.location_name}`,
      location_code: findBom.location_code,
      location_name: findBom.location_name,
      process_label: `${findBom.process_code} - ${findBom.process_name}`,
      process_code: findBom.process_code,
      process_name: findBom.process_name,
      notes: findBom.notes,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      type: "",
      part_label: "",
      part_id: "",
      part_name: "",
      part_number: "",
      process_label: "",
      process_code: "",
      process_name: "",
      process_id: "",
      manufactured_quantity: 0,
      finish_goods: [],
      materials: [],
      processes: [],
      bom_version: "",
      items: [],
    });

    setBomDetail([]);
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("details", JSON.stringify(bomDetail));
    fd.set("type", data.type);
    fd.set("part_id", data.part_id);
    fd.set("part_number", data.part_number);
    fd.set("part_name", data.part_name);
    fd.set("process_id", data.process_id);
    fd.set("process_code", data.process_code);
    fd.set("process_name", data.process_name);
    fd.set("manufactured_quantity", data.manufactured_quantity);

    Axios.post(`${url}/bom`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success") {
          message.success(res.data.message);
        }
        // setLoading(false);
        props.history.push("/master-bom");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setBomData({
      ...bomData,
      item_label: "",
      item_code: "",
      item_name: "",
      item_category: "",
      item_type: "",
      lot: "",
      item_id: "",
      spec: "",
      unit: "",
      quantity: 0,
      supplier_label: "",
      supplier_code: "",
      supplier_name: "",
      supplier_id: "",
      location_id: "",
      location_label: "",
      location_code: "",
      location_name: "",
      process_label: "",
      process_code: "",
      process_name: "",
      notes: "",
    });
  };

  const closeModal = () => {
    setBomData({
      ...bomData,
      item_label: "",
      item_code: "",
      item_name: "",
      item_category: "",
      item_type: "",
      lot: "",
      item_id: "",
      spec: "",
      unit: "",
      quantity: 0,
      supplier_label: "",
      supplier_code: "",
      supplier_name: "",
      supplier_id: "",
      location_id: "",
      location_label: "",
      location_code: "",
      location_name: "",
      process_label: "",
      process_code: "",
      process_name: "",
      notes: "",
    });

    setSelectedId("");
    setIsModalVisible(false);
    setValCheckbox(false);
  };

  const columns = [
    {
      align: "left",
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      align: "left",
      title: "Item Name",
      dataIndex: "item_name",
    },
    {
      align: "left",
      title: "Item Category",
      dataIndex: "item_category",
    },
    {
      align: "left",
      title: "Item Type",
      dataIndex: "item_type",
    },
    {
      align: "left",
      title: "Lot",
      dataIndex: "lot",
    },
    {
      align: "left",
      title: "Spec",
      dataIndex: "spec",
    },
    {
      align: "left",
      title: "Unit",
      dataIndex: "unit",
    },
    {
      align: "right",
      title: <div style={{ textAlign: "left" }}>Quantity</div>,
      dataIndex: "quantity",
    },
    {
      align: "left",
      title: "Supplier",
      dataIndex: "supplier_code",
    },
    {
      align: "left",
      title: "Process",
      dataIndex: "process_name",
    },
    {
      align: "left",
      title: "Location",
      dataIndex: "location_name",
    },
    {
      align: "left",
      title: "Notes",
      dataIndex: "notes",
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>

          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditDetails(id)}
            size="small"
            type="primary"
          >
            <EditOutlined />
            Edit
          </Button>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Bill of Material"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-bom">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Bill of Material</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
         
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Item Finish Good"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              required
                              label="Type"
                              validateStatus={
                                error && error.type ? "error" : false
                              }
                              help={error && error.type ? error.type[0] : false}
                            >
                              <Radio.Group
                                onChange={(e) =>
                                  handleChange("type", e.target.value)
                                }
                                value={data.type}
                              >
                                <Radio value="finish_goods">Finish Goods</Radio>
                                <Radio value="WIP">WIP</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.item_code_fg ? "error" : false
                              }
                              help={
                                error && error.item_code_fg
                                  ? error.item_code_fg[0]
                                  : false
                              }
                              label="Manufactured Item Code"
                            >
                              <Select
                                labelInValue
                                disabled={!data.type}
                                onChange={(value) =>
                                  handleChangeSelect(
                                    "part",
                                    value,
                                    "part_number"
                                  )
                                }
                                onFocus={() => getItem("", "finish_goods")}
                                showSearch
                                onSearch={(value) =>
                                  getItem(value, "finish_goods")
                                }
                                filterOption={false}
                                value={{
                                  key: data._id,
                                  label: data.part_label,
                                }}
                              >
                                {data.finish_goods &&
                                  data.finish_goods.map((part) => {
                                    return (
                                      <Option value={part._id} key={part._id}>
                                        {`${part.item_code} - ${part.item_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              label="Manufactured Item Name"
                              validateStatus={
                                error && error.part_name ? "error" : false
                              }
                              help={
                                error && error.part_name
                                  ? error.part_name[0]
                                  : false
                              }
                            >
                              <Input
                                disabled
                                value={data.part_name}
                                onChange={(e) =>
                                  handleChange("part_name", e.target.value)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              validateStatus={
                                error && error.bom_version ? "error" : false
                              }
                              help={
                                error && error.bom_version
                                  ? error.bom_version[0]
                                  : false
                              }
                              label="BOM Version"
                            >
                              <Input
                                placeholder="Default"
                                value={data.bom_version}
                                onChange={(e) => {
                                  handleChange("bom_version", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.process ? "error" : false
                              }
                              help={
                                error && error.process
                                  ? error.process[0]
                                  : false
                              }
                              label="Process"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect(
                                    "process",
                                    value,
                                    "process_name",
                                    data.processes
                                  )
                                }
                                onFocus={() => getProcess("")}
                                showSearch
                                loading={loadProcess}
                                onSearch={(value) => getProcess(value)}
                                filterOption={false}
                                value={{
                                  key: data._id,
                                  label: data.process_label,
                                }}
                              >
                                {data.processes &&
                                  data.processes.map((process) => {
                                    return (
                                      <Option
                                        value={process._id}
                                        key={process._id}
                                      >
                                        {`${process.process_code} - ${process.process_name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.manufactured_quantity
                                  ? "error"
                                  : false
                              }
                              help={
                                error && error.manufactured_quantity
                                  ? error.manufactured_quantity[0]
                                  : false
                              }
                              label="Manufactured Qty"
                            >
                              <AntNumberFormat
                                //defaultValue="0"
                                value={data.manufactured_quantity}
                                type="input"
                                onChange={(e) =>
                                  handleChange("manufactured_quantity", e)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Item Raw / Sub Material"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title={
                            selectedId
                              ? "Edit Sub Material"
                              : "Add Sub Material"
                          }
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_code_material
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_code_material
                                              ? error.item_code_material[0]
                                              : false
                                          }
                                          label="Item Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectBom(
                                                "item",
                                                value,
                                                "item_name",
                                                data.items
                                              )
                                            }
                                            onFocus={() =>
                                              getItem("", "raw_material")
                                            }
                                            showSearch
                                            loading={loadItem}
                                            onSearch={(value) =>
                                              getItem(value, "raw_material")
                                            }
                                            filterOption={false}
                                            value={{
                                              value: bomData.item_id,
                                              label: bomData.item_label,
                                            }}
                                          >
                                            {data.materials &&
                                              data.materials.map((material) => {
                                                return (
                                                  <Option
                                                    value={material._id}
                                                    key={material._id}
                                                  >
                                                    {`${material.item_code} - ${material.item_name}`}
                                                    {/* {item_material.item_code} */}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_name
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_name
                                              ? error.item_name[0]
                                              : false
                                          }
                                          label="Item Name"
                                        >
                                          <Input
                                            disabled
                                            value={bomData.item_name}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "item_name",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_category
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_category
                                              ? error.item_category[0]
                                              : false
                                          }
                                          label="Item Category"
                                        >
                                          <Input
                                            disabled
                                            value={bomData.item_category}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "item_category",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_type
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_type
                                              ? error.item_type[0]
                                              : false
                                          }
                                          label="Item Type"
                                        >
                                          <Input
                                            disabled
                                            value={bomData.item_type}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "item_type",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.lot ? "error" : false
                                          }
                                          help={
                                            error && error.lot
                                              ? error.lot[0]
                                              : false
                                          }
                                          label="Lot"
                                        >
                                          <Input
                                            disabled
                                            value={bomData.lot}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "lot",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.spec
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.spec
                                              ? error.spec[0]
                                              : false
                                          }
                                          label="Spec"
                                        >
                                          <Input
                                            value={bomData.spec}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "spec",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.unit
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.unit
                                              ? error.unit[0]
                                              : false
                                          }
                                          label="Unit"
                                        >
                                          <Select
                                            value={bomData.unit}
                                            onChange={(value) =>
                                              handleChangeBom("unit", value)
                                            }
                                          >
                                            {units &&
                                              units.map((unit) => {
                                                return (
                                                  <Option
                                                    value={unit.name}
                                                    key={unit._id}
                                                  >
                                                    {unit.name}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.quantity
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.quantity
                                              ? error.quantity[0]
                                              : false
                                          }
                                          label="Quantity"
                                        >
                                          <AntNumberFormat
                                            value={bomData.quantity}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeBom("quantity", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          label="Supplier"
                                          validateStatus={
                                            error && error.supplier
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.supplier
                                              ? error.supplier[0]
                                              : false
                                          }
                                        >
                                          <Col md={24} sm={24} xs={24}>
                                            <Select
                                              labelInValue
                                              onChange={(value) =>
                                                handleChangeSelectBom(
                                                  "supplier",
                                                  value
                                                )
                                              }
                                              onFocus={() =>
                                                getItemSupplier("")
                                              }
                                              showSearch
                                              onSearch={(value) =>
                                                getItemSupplier(value)
                                              }
                                              filterOption={false}
                                              value={{
                                                key: bomData.supplier_id,
                                                label: bomData.supplier_label,
                                              }}
                                            >
                                              {bomData.suppliers &&
                                                bomData.suppliers.map(
                                                  (supplier) => {
                                                    return (
                                                      <Option
                                                        value={supplier._id}
                                                        key={supplier._id}
                                                      >
                                                        {`${supplier.code} - ${supplier.name}`}
                                                      </Option>
                                                    );
                                                  }
                                                )}
                                            </Select>
                                          </Col>
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.process
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.process
                                              ? error.process[0]
                                              : false
                                          }
                                          label="Process"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectBom(
                                                "process",
                                                value,
                                                "process_name",
                                                data.processes
                                              )
                                            }
                                            onFocus={() => getProcess("")}
                                            showSearch
                                            loading={loadProcess}
                                            onSearch={(value) =>
                                              getProcess(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              key: bomData.process_id,
                                              label: bomData.process_label,
                                            }}
                                          >
                                            {data.processes &&
                                              data.processes.map((process) => {
                                                return (
                                                  <Option
                                                    value={process._id}
                                                    key={process._id}
                                                  >
                                                    {`${process.process_code} - ${process.process_name}`}
                                                  </Option>
                                                );
                                              })}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.location
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.location
                                              ? error.location[0]
                                              : false
                                          }
                                          label="Location"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectBom(
                                                "location",
                                                value,
                                                "name",
                                                data.locations
                                              )
                                            }
                                            onFocus={() => getLocation("")}
                                            showSearch
                                            loading={loadProcess}
                                            onSearch={(value) =>
                                              getLocation(value)
                                            }
                                            filterOption={false}
                                            value={{
                                              key: bomData.location_id,
                                              label: bomData.location_label,
                                            }}
                                          >
                                            {bomData.locations &&
                                              bomData.locations.map(
                                                (location) => {
                                                  return (
                                                    <Option
                                                      value={location._id}
                                                      key={location._id}
                                                    >
                                                      {`${location.code} - ${location.name}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.notes
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.notes
                                              ? error.notes[0]
                                              : false
                                          }
                                          label="Notes"
                                        >
                                          <Input
                                            value={bomData.notes}
                                            onChange={(e) => {
                                              handleChangeBom(
                                                "notes",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        size="small"
                        columns={columns}
                        scroll={{ x: 500 }}
                        dataSource={bomDetail}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        // pagination={{
                        //   pageSize: 10,
                        //   current: page,
                        //   total: bomDetail.total,
                        //   showSizeChanger: false,
                        // }}
                        // loading={loading}
                      />
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateBom;
