/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Form,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { PlusOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

function DailyKanban() {
  const [keyword, setKeyword] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [error, setError] = useState(null);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState({
    supplier_name: "",
    item_code: "",
    qty_daily_order: "",
    qty_adjustment_ppic: "",
    qty_adjustment_daily: "",
    dailyKanban: [],
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [sort, setSort] = useState({
    sort: "supplier_name",
    order: "ascend",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [generate, setGenerate] = useState({
    supplier: "",
  });

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword]);

  const columns = [
    {
      title: "DN Date",
      dataIndex: "dn_date",
      key: "dn_date",
      width: 120,
    },
    {
      title: "Approval Date",
      dataIndex: "approval_date",
      key: "approval_date",
      width: 120,
      render: (approval_date) =>
        moment(approval_date).format("YYYY-MM-DD H:m:s"),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 150,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      key: "item_code",
      width: 120,
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty Adjustment PPIC</div>,
      key: "qty_adjustment_ppic",
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            value={
              data.qty_adjustment_ppic === 0
                ? data.qty_daily_order
                : data.qty_adjustment_ppic
            }
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty BOM</div>,
      key: "qty_bom",
      align: "right",
      width: 85,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DES"
            value={data.qty_bom}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty Daily Order</div>,
      key: "qty_daily_order",
      align: "right",
      width: 100,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DES"
            value={data.qty_daily_order}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty Kanban</div>,
      key: "qty_kanban",
      align: "right",
      width: 85,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DES"
            value={data.qty_kanban}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty Adjustment Daily</div>,
      key: "qty_adjustment_daily",
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale="DES"
            value={data.qty_adjustment_purchase}
            type="text"
          />
        );
      },
    },
    {
      title: "",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => showModalSuggest(data._id)}>
                      <EditOutlined />
                      Edit
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/daily-kanban`, {
      params: {
        keyword,
        columns: ["supplier_name", "item_code"],
        // perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dailyKanban: res.data.data.map((x) => {
            return {
              ...x,
              _id: Math.random().toString().slice(2, 25),
            };
          }),
        });
        // console.log(res.data.data.map((x) => {
        //   return {
        //     ...x,
        //     _id: Math.random().toString().slice(2, 25)
        //   }
        // }))
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = () => {
    setLoading(true);

    // const newData = [...data];
    const findData = data.dailyKanban.find((x) => x._id === selectedId);
    console.log(findData, "findData");
    if (findData) {
      findData.qty_adjustment_purchase = data.qty_adjustment_daily;

      setData({
        ...data,
        dailyKanban: [...data.dailyKanban, findData],
      });
    }

    closeModal();
    setLoading(false);
  };

  const handleReset = () => {
    setData({
      ...data,
      qty_adjustment_daily: "",
    });
  };

  const showModalSuggest = (id) => {
    const suggest = data.dailyKanban.find((data) => data._id === id);

    // setSuggestId(suggest.mbom_material_id);

    setData({
      ...data,
      qty_adjustment_daily: suggest.qty_adjustment_purchase,
      supplier_name: suggest.supplier_name,
      item_code: suggest.item_code,
    });
    setIsModalVisible(true);
    setSelectedId(id);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });

    setPerPage(pagination.pageSize);
  };

  const confirmGenerate = () => {
    console.log(generate.supplier.supplier_name, selectedRowsData);
    setLoading(true);

    Axios.post(
      `${url}/daily-kanban`,
      {
        supplier_name: generate.supplier.supplier_name,
        supplier_code: generate.supplier.supplier_code,
        details: selectedRowsData,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    setSelectedRowsData([]);
    setSelectedRowKeysData([]);

    setVisible(false);
  };

  const closeModal = () => {
    setData({
      ...data,
      qty_adjustment_ppic: "",
      item_code: "",
      supplier_name: "",
    });
    setIsModalVisible(false);
  };

  const hasSelected = selectedRowsData.length > 0;

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        // title="Are you sure?"
        title={
          <div>
            <span style={{ fontSize: "22px" }}>
              <b>Are you sure?</b>{" "}
            </span>
            <br />
            <span style={{ fontSize: "13px" }}>Are you sure? </span>
          </div>
        }
        centered
        visible={visible}
        onOk={() => confirmGenerate()}
        onCancel={() => setVisible(false)}
        width={1000}
      >
        <Table
          scroll={{ x: 750, y: 350 }}
          columns={columns}
          size="small"
          dataSource={selectedRowsData}
          // dataSource={dataSource}
          rowKey={(data) => data._id}
          onChange={handleTableChange}
          pagination={{
            pageSize: perpage,
            current: page,
            total: selectedRowsData.length,
            showSizeChanger: false,
          }}
          loading={loading}
        />
      </Modal>
      <Modal
        title="Edit Daily Kanban"
        style={{ top: 20 }}
        visible={isModalVisible}
        width={1000}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Form {...layout} name="control-hooks" labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        validateStatus={
                          error && error.qty_adjustment_daily ? "error" : false
                        }
                        help={
                          error && error.qty_adjustment_daily
                            ? error.qty_adjustment_daily[0]
                            : false
                        }
                        label="Qty Adjustment Daily"
                      >
                        <AntNumberFormat
                          type="input"
                          value={data.qty_adjustment_daily}
                          onChange={(e) => {
                            handleChange("qty_adjustment_daily", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          error && error.item_code ? "error" : false
                        }
                        help={
                          error && error.item_code ? error.item_code[0] : false
                        }
                        label="Item Code"
                      >
                        <Input
                          disabled
                          value={data.item_code}
                          onChange={(e) => {
                            handleChange("item_code", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          error && error.supplier_name ? "error" : false
                        }
                        help={
                          error && error.supplier_name
                            ? error.supplier_name[0]
                            : false
                        }
                        label="Supplier Name"
                      >
                        <Input
                          disabled
                          value={data.supplier_name}
                          onChange={(e) => {
                            handleChange("supplier_name", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.select !== currentValues.select
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("select") === "Job Number" ? (
                            <Form.Item
                              label="Job Number"
                              validateStatus={
                                error && error.job_number ? "error" : false
                              }
                              help={
                                error && error.job_number
                                  ? error.job_number[0]
                                  : false
                              }
                            >
                              <Input
                                disabled
                                value={data.job_number}
                                onChange={(e) =>
                                  handleChange("job_number", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.select !== currentValues.select
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("select") === "Item Code" ? (
                            <Form.Item
                              label="Item Code"
                              validateStatus={
                                error && error.item_code ? "error" : false
                              }
                              help={
                                error && error.item_code
                                  ? error.item_code[0]
                                  : false
                              }
                            >
                              <Input
                                disabled
                                value={data.item_code}
                                onChange={(e) =>
                                  handleChange("item_code", e.target.value)
                                }
                              />
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSubmit}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Submit
              </Button>
              <Button htmlType="reset" onClick={handleReset}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Daily Kanban"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button
                      loading={loading}
                      onClick={() => setVisible(true)}
                      type="primary"
                      disabled={!hasSelected}
                    >
                      <PlusOutlined />
                      Submit
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={data.dailyKanban}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: perpage,
                      current: page,
                      total: data.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        console.log(
                          selectedRows,
                          selectedRowKeysData,
                          "ini select"
                        );

                        const supplier = selectedRows ? selectedRows[0] : null;

                        setGenerate({
                          ...generate,
                          supplier,
                        });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            (checkDisable &&
                              checkDisable.supplier_name !==
                                record.supplier_name) ||
                            record.status === 1,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default DailyKanban;
