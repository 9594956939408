/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
// import './User.less'

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function MaterialSplitter() {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [keywordGenerateDetails, setKeywordGenerateDetails] = useState("");
  const [materialSplitter, setMaterialSplitter] = useState([]);
  const [materialSplitterDetails, setMaterialSplitterDetails] = useState([]);
  const [materialSplitterDetailsTotal, setMaterialSplitterDetailsTotal] =
    useState(0);
  const [detailsChildren, setDetailsChildren] = useState([]);

  const [visible, setVisible] = useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [selectedGenerateId, setSelectedGenerateId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [rowDetails, setRowDetails] = useState([]);

  const [pageGenerateDetail, setPageGenerateDetail] = useState(1);
  const [perPageGenerateDetail, setPerPageGenerateDetail] = useState(10);

  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingGenerateDetail, setLoadingGenerateDetail] = useState(false);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "asc",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });

  // const handleSearch = (value) => {
  //   setKeyword(value);
  // };

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getMaterialSplitterDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      localStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getMaterialSplitterDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/material-splitter`, {
      params: {
        keyword,
        columns: [""],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMaterialSplitter(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/material-splitter/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getMaterialSplitterDetails(data2[0]);
    }
  };

  const handleChangeGenerateDetailsTable = (pagination, filter, sorter) => {
    setPageGenerateDetail(pagination.current);
    setSortGenerateDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageGenerateDetail(pagination.pageSize);
  };

  const getMaterialSplitterDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/material-splitter/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: [""],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setMaterialSplitterDetails(res.data.data);
        setMaterialSplitterDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/material-splitter/pdf/download/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      // fileDownload(res.data, "Export Master Kanban" + ".xlsx");
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const generateDetails = (id, index) => {
    console.log(id, "ini tuh id");
    console.log("yang ini index", index);
    setVisible(true);
    setDetailsChildren(index.details);
  };

  const getGenerates = () => {
    const columnsDetail = [
      {
        title: <div style={{ textAlign: "left" }}>Quantity Generate</div>,
        align: "right",
        dataIndex: "qty_generate",
        key: "qty_generate",
        render: (id, index) => (
          <div>
            {" "}
            <a onClick={() => generateDetails(id, index)}>{id} </a>
          </div>
        ),
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        align: "center",
        key: "created_at",
      },
      {
        title: "Created by",
        dataIndex: "generate_by",
        align: "center",
        key: "generate_by",
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 100,
        render: (id) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <span onClick={() => printPdf(id)}>
                        <PrinterOutlined /> Print
                      </span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              columns={columnsDetail}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={materialSplitterDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: materialSplitterDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeGenerateDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columnDetails = [
    {
      title: "Kanban",
      dataIndex: "dn_number",
      sorter: true,
    },
    {
      title: "Kanban Split",
      dataIndex: "qrcode",
      sorter: true,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: "Quantity Kanban",
      dataIndex: "quantity_kanban",
      sorter: false,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: false,
    },
  ];

  const columns = [
    {
      title: "Kanban",
      dataIndex: "dn_number",
      sorter: true,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Quantity Kanban</div>,
      align: "right",
      dataIndex: "quantity_kanban",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Quantity Split</div>,
      align: "right",
      dataIndex: "quantity_splitter",
      sorter: false,
    },
    {
      title: "Unit",
      dataIndex: "unit_quantity",
      sorter: false,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {/* <Menu.Item key="1">
                    <Link to={`/material-splitter-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Material Splitter"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/material-splitter-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={materialSplitter.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: materialSplitter.total,
                      showSizeChanger: false,
                    }}
                    expandable={{
                      expandedRowRender: getGenerates,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
            <Modal
              title={
                <div>
                  <span style={{ fontSize: "13px" }}>
                    Details Kanban Split{" "}
                  </span>
                </div>
              }
              centered
              visible={visible}
              onCancel={() => setVisible(false)}
              onOk={() => setVisible(false)}
              width={1000}
            >
              <Table
                columns={columnDetails}
                rowKey={(data) => data._id}
                loading={loadingGenerateDetail}
                dataSource={detailsChildren}
                pagination={{
                  pageSize: perPageGenerateDetail,
                  current: pageGenerateDetail,
                  // total: purchasingDataDetailsTotalChildren,
                  showSizeChanger: true,
                }}
                onChange={handleChangeDetailsTable}
                size="small"
              />
            </Modal>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default MaterialSplitter;
