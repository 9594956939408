/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import moment from "moment";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Content } = Layout;
const { Option } = Select;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditCurrency(props) {
  const [data, setData] = useState({
    currency_name: "",
    exchange_idr: "",
  });

  const [currencys, setCurrencys] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    getCurrency();
  }, []);

  const getData = () => {
    Axios.get(`${url}/currency/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          currency_name: res.data.data.currency_name,
          exchange_idr: res.data.data.exchange_idr,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getCurrency = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Currency",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCurrencys(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    Axios.post(`${url}/currency/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-currency");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      currency_name: "",
      exchange_idr: "",
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Currency"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-currency">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Master Currency</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Currency Name"
                  validateStatus={
                    error && error.currency_name ? "error" : false
                  }
                  help={
                    error && error.currency_name
                      ? error.currency_name[0]
                      : false
                  }
                >
                  <Select
                    value={data.currency_name}
                    placeholder="Select Currency"
                    onChange={(value) => handleChange("currency_name", value)}
                  >
                    {currencys &&
                      currencys.map((currency) => {
                        return (
                          <Option value={currency.name} key={currency._id}>
                            {currency.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Exchange IDR"
                  validateStatus={error && error.exchange_idr ? "error" : false}
                  help={
                    error && error.exchange_idr ? error.exchange_idr[0] : false
                  }
                >
                  <AntNumberFormat
                    value={data.exchange_idr}
                    type="input"
                    onChange={(e) => {
                      handleChange("exchange_idr", e);
                    }}
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditCurrency;
