/* eslint-disable */
import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  DatePicker,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import AntNumberFormat from "../../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function CreateMasterPrice(props) {
  const [data, setData] = useState({
    purchase_price: "",
    item_code: "",
    item_label: "",
    item_id: "",
    item_name: "",
    sell_price: "",
    special_price: "",
    notes: "",
    validate_start_date: "",
    validate_end_date: "",
    items: [],
    customer: [],
    customer_id: "",
    customer_name: "",
    customer_label: "",
    model: "",
    currency: [],
    currency_label: "",
    currency_name: "",
    currency_id: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name,value)
  };

  const getItem = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getCurrency = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/currency/list`, {
      params: {
        keyword,
        columns: ["currency_name", "exchange_idr"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          currency: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = data.items.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
        [`model`]: find.model,
      });

    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });

    } else if (name === "model") {
      const find = data.customer.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.model,
      });

    } else if (name === "currency") {
      const find = data.currency.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.currency_name,
      });

    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_code", data.item_code);
    fd.set("item_name", data.item_name);
    fd.set("currency_name", data.currency_name);
    fd.set("sell_price", data.sell_price);
    fd.set("purchase_price", data.purchase_price);
    fd.set("special_price", data.special_price);
    fd.set("model", data.model);
    fd.set("validate_start_date", data.validate_start_date);
    fd.set("validate_end_date", data.validate_end_date);
    fd.set("customer", data.customer_name);
    fd.set("notes", data.notes);

    Axios.post(`${url}/price`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-price");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      purchase_price: "",
      item_code: "",
      item_label: "",
      item_id: "",
      item_name: "",
      sell_price: "",
      special_price: "",
      notes: "",
      validate_start_date: "",
      validate_end_date: "",
      items: [],
      customer: [],
      customer_id: "",
      customer_name: "",
      customer_label: "",
      currency: [],
      currency_id: "",
      currency_name: "",
      currency_label: "",
      model: "",
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Price"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-price">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Master Price</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Item Code"
                        validateStatus={
                          error && error.item_code ? "error" : false
                        }
                        help={
                          error && error.item_code ? error.item_code[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("item", value)
                          }
                          onFocus={() => getItem("")}
                          showSearch
                          onSearch={(value) => getItem(value)}
                          filterOption={false}
                          value={{
                            key: data.item_id,
                            label: data.item_label,
                          }}
                        >
                          {data.items &&
                            data.items.map((item) => {
                              return (
                                <Option value={item._id} key={item._id}>
                                  {`${item.item_code} - ${item.item_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Item Name"
                        validateStatus={
                          error && error.item_name ? "error" : false
                        }
                        help={
                          error && error.item_name ? error.item_name[0] : false
                        }
                      >
                        <Input
                          disabled
                          value={data.item_name}
                          onChange={(e) =>
                            handleChange("item_name", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Currency"
                        validateStatus={
                          error && error.currency ? "error" : false
                        }
                        help={
                          error && error.currency ? error.currency[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("currency", value)
                          }
                          onFocus={() => getCurrency("")}
                          showSearch
                          onSearch={(value) => getCurrency(value)}
                          filterOption={false}
                          value={{
                            key: data.currency_id,
                            label: data.currency_label,
                          }}
                        >
                          {data.currency &&
                            data.currency.map((currencys) => {
                              return (
                                <Option
                                  value={currencys._id}
                                  key={currencys._id}
                                >
                                  {currencys.currency_name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required
                        label="Purchase Price"
                        validateStatus={
                          error && error.purchase_price ? "error" : false
                        }
                        help={
                          error && error.purchase_price
                            ? error.purchase_price[0]
                            : false
                        }
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          decimalScale="DES"
                          value={data.purchase_price}
                          type="input"
                          onChange={(e) => {
                            handleChange("purchase_price", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        // required
                        label="Sell Price"
                        validateStatus={
                          error && error.sell_price ? "error" : false
                        }
                        help={
                          error && error.sell_price
                            ? error.sell_price[0]
                            : false
                        }
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          decimalScale="DES"
                          value={data.sell_price}
                          type="input"
                          onChange={(e) => {
                            handleChange("sell_price", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        // required
                        label="Special Price"
                        validateStatus={
                          error && error.special_price ? "error" : false
                        }
                        help={
                          error && error.special_price
                            ? error.special_price[0]
                            : false
                        }
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          decimalScale="DES"
                          value={data.special_price}
                          type="input"
                          onChange={(e) => {
                            handleChange("special_price", e);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Model"
                        validateStatus={error && error.model ? "error" : false}
                        help={error && error.model ? error.model[0] : false}
                      >
                        <Input
                          disabled
                          value={data.model}
                          onChange={(e) =>
                            handleChange("model", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Validate Start Date"
                        validateStatus={
                          error && error.validate_start_date ? "error" : false
                        }
                        help={
                          error && error.validate_start_date
                            ? error.validate_start_date[0]
                            : false
                        }
                      >
                        <DatePicker
                          onChange={(value) =>
                            handleChange("validate_start_date", value)
                          }
                          value={data.validate_start_date}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Validate End Date"
                        validateStatus={
                          error && error.validate_end_date ? "error" : false
                        }
                        help={
                          error && error.validate_end_date
                            ? error.validate_end_date[0]
                            : false
                        }
                      >
                        <DatePicker
                          onChange={(value) =>
                            handleChange("validate_end_date", value)
                          }
                          value={data.validate_end_date}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Customer"
                        validateStatus={
                          error && error.customer ? "error" : false
                        }
                        help={
                          error && error.customer ? error.customer[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("customer", value)
                          }
                          onFocus={() => getCustomer("")}
                          showSearch
                          onSearch={(value) => getCustomer(value)}
                          filterOption={false}
                          value={{
                            key: data.customer_id,
                            label: data.customer_label,
                          }}
                        >
                          {data.customer &&
                            data.customer.map((customers) => {
                              return (
                                <Option
                                  value={customers._id}
                                  key={customers._id}
                                >
                                  {`${customers.code} - ${customers.name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Notes"
                        validateStatus={error && error.notes ? "error" : false}
                        help={error && error.notes ? error.notes[0] : false}
                      >
                        <TextArea
                          value={data.notes}
                          onChange={(e) =>
                            handleChange("notes", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateMasterPrice;
