/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Card,
  Col,
  Layout,
  Table,
  Button,
  Modal,
  Upload,
  Space,
  Select,
  DatePicker,
  Carousel,
  Pagination,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
// import {} from "react-chartjs-2";
import moment from "moment";
import { extendMoment } from "moment-range";

import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// import "./Dash_monthly.less";
import "./Dashboard.less";
import fileDownload from "js-file-download";

import FilterCom from "../../components/Dashboard/FilterCom";
import { stringComaToNumber, removePercent } from "../../helper/function";
import { object } from "underscore";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

import { faker } from "@faker-js/faker";

const { Option } = Select;

const { Content } = Layout;
const { Column, ColumnGroup } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const range = extendMoment(moment);

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
}

function Dash_monthly() {
  //   const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  // const [date, setDate] = useState(moment('2021-06-17').format('YYYY-MM-DD'));

  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [comodity, setComodity] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [item, setItem] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataBarDate, setDataBarDate] = useState([]);
  const [dataBarAmount, setDataBarAmount] = useState([]);
  const [plan, setPlan] = useState([]);
  const [actual, setActual] = useState([]);
  const [percentage, setPercentage] = useState([]);
  const [amountPlan, setAmountPlan] = useState([]);
  const [amountActual, setAmountActual] = useState([]);
  const [checkAmount, setCheckAmount] = useState([]);
  const [total, setTotal] = useState([]);

  const [errors, setErrors] = useState(null);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(4);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [pieMap, setPieMap] = useState([]);

  const [data, setData] = useState({
    date: moment().startOf("month"),
    part_number: "",
    item_code: "",
    customer_name: "",
    customer_code: "",
    comodity_name: "",
  });

  useEffect(() => {
    getData();
    getRole();
    if (!loading) {
      let rotationInterval = setInterval(() => {
        getData();
      }, 300000);

      return () => {
        clearInterval(rotationInterval);
      };
    }
  }, [data]);

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        date: value,
      });
    } else {
      setData({
        ...data,
        date: undefined,
      });
    }
  };

  const handleChangeViewComodity = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
    console.log("value", value);
  };

  const handleChangeViewCustomer = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_code`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_code`]: null,
        [`${name}_name`]: null,
      });
    }
    console.log("value", value);
  };

  const handleChangeViewItem = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_code`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_code`]: null,
      });
    }

    // getData(data, value.label);

    console.log("value", value);
  };

  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
    }).format(number);
  };

  const getComodity = async (keyword) => {
    await Axios.get(`${url}/dashboard-monthly/list-comodity`, {
      params: {
        keyword,
        columns: ["comodity"],
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        date: moment(data.date).format("DD-MM-YYYY"),
        item_code: data.item_code,
        customer_code: data.customer_code,
        customer_name: data.customer_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/dashboard-monthly/list-customer`, {
      params: {
        keyword,
        columns: ["customer"],
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        customer_name: data.customer_name,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setCustomer(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getItemCode = async (keyword) => {
    await Axios.get(`${url}/dashboard-monthly/list-item`, {
      params: {
        keyword,
        columns: ["item_code"],
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        customer_name: data.customer_name,
        customer_code: data.customer_code,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItem(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = async (keyword) => {
    setLoading(true);

    let plan = [];
    let actual = [];
    let labelName = [];
    let amountPlan = [];
    let amountActual = [];
    let label = [];
    let labelDate = [];
    let nilai = [];

    await Axios.get(`${url}/dashboard-monthly`, {
      params: {
        keyword,
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        customer_name: data.customer_name,
        customer_code: data.customer_code,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // setItem(res.data.data);
        setPlan(res.data.total_plan);
        setActual(res.data.total_actual);
        setPercentage(res.data.percentage);
        setAmountPlan(res.data.amount_plan);
        setAmountActual(res.data.amount_actual);
        setTotal(res.data.total_pie);
        for (const dataObj of res.data.data) {
          labelName.push(dataObj.item_code);
          plan.push(parseInt(dataObj.plan));
          actual.push(parseInt(dataObj.actual));
        }
        for (const dataObj of res.data.data_bar_amount) {
          label.push(dataObj.label);
          amountPlan.push(parseInt(dataObj.amount_plan));
          amountActual.push(parseInt(dataObj.amount_actual));
        }
        for (const dataObj of res.data.bar_date) {
          labelDate.push(dataObj.date);
          nilai.push(dataObj.nilai);
        }

        setPieMap(res.data.data_pie);

        setDataBar({
          labels: labelName,
          datasets: [
            {
              label: "Plan",
              data: plan,
              type: "bar",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            {
              label: "Actual",
              data: actual,
              type: "bar",
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
            },
          ],
        });

        setDataBarAmount({
          labels: label,
          datasets: [
            {
              label: "Amount Plan",
              data: amountPlan,
              type: "bar",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            {
              label: "Amount Actual",
              data: amountActual,
              type: "bar",
              backgroundColor: "#0000CD",
              borderColor: "#0000CD",
            },
          ],
        });

        setDataBarDate({
          labels: labelDate,
          datasets: [
            {
              label: "Plan",
              data: nilai,
              type: "bar",
              backgroundColor: "#E67E00",
              borderColor: "#E67E00",
            },
            // {
            //   label: "Actual",
            //   data: actual,
            //   type: "bar",
            //   backgroundColor: "#0000CD",
            //   borderColor: "#0000CD",
            // },
          ],
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };

  const getRole = () => {
    setCheckAmount(localStorage.getItem("amount"));
  };

  // console.log("role", role);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  const downloadTemplateData = () => {
    setLoadingExport(true);

    Axios.get(`${url}/dashboard-monthly/export`, {
      params: {
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        customer_name: data.customer_name,
        customer_code: data.customer_code,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
        amount: localStorage.getItem("amount"),
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(
        res.data,
        "Report_Month_Delivery_Planning" +
          " " +
          moment(new Date()).format("yyyy-MMM-DD hh:mm:ss") +
          ".xlsx"
      );
      setLoadingExport(false);
    });
  };

  const ModalBtn = (
    <div
      style={{
        display: "flex",
      }}
    >
      <div>
        <Button
          loading={loadingExport}
          onClick={downloadTemplateData}
          type="primary"
          style={{
            display: "flex",
            marginBottom: 15,
            backgroundColor: "#1b2086",
            borderColor: "#1b2086",
            marginLeft: "auto",
          }}
        >
          Export
        </Button>
      </div>
    </div>
  );

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row className="header-body">
        <Col xs={24}>
          <Card
            type="inner"
            className="title-dashboard"
            title="Dashboard Monthly"
            bodyStyle={{ padding: "0" }}
            extra={ModalBtn}
          ></Card>
        </Col>
      </Row>
      <Content className="content">
        <Row gutter={[16, 20]}>
          {checkAmount === "true" ? (
            <Col xs={24} md={6} span={6}>
              <Card
                className="card-body-in-progress pointer"
                title="Plan (Pcs)"
                bordered={false}
                style={{
                  height: 257.5,
                }}
                loading={loading}
              >
                <div className="value-font-size ">
                  <div
                    style={{ textAlign: "center", fontSize: 40, marginTop: 30 }}
                  >
                    {formatNumber(plan)}
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            <Col xs={24} md={8} span={8}>
              <Card
                className="card-body-in-progress pointer"
                title="Plan (Pcs)"
                bordered={false}
                loading={loading}
              >
                <div className="value-font-size ">
                  <h2 style={{ textAlign: "center" }}>{formatNumber(plan)}</h2>
                </div>
              </Card>
            </Col>
          )}

          {checkAmount === "true" ? (
            <Col xs={24} md={6} span={6}>
              <Card
                className="card-body-received pointer"
                title="Actual (Pcs)"
                bordered={false}
                style={{
                  height: 257.5,
                }}
                loading={loading}
              >
                <div className="value-font-size ">
                  {" "}
                  <div
                    style={{ textAlign: "center", fontSize: 40, marginTop: 30 }}
                  >
                    {formatNumber(actual)}
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            <Col xs={24} md={8} span={8}>
              <Card
                className="card-body-received pointer"
                title="Actual (Pcs)"
                bordered={false}
                loading={loading}
              >
                <div className="value-font-size ">
                  {" "}
                  <h2 style={{ textAlign: "center" }}>
                    {formatNumber(actual)}
                  </h2>
                </div>
              </Card>
            </Col>
          )}

          {checkAmount === "true" ? (
            <Col xs={24} md={6} span={6}>
              <Card
                className="card-body-all pointer"
                title="Actual Vs Plan (%)"
                bordered={false}
                style={{
                  height: 257.5,
                }}
                loading={loading}
              >
                <div className="value-font-size ">
                  {" "}
                  <div
                    style={{ textAlign: "center", fontSize: 40, marginTop: 30 }}
                  >
                    {percentage} %
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            <Col xs={24} md={8} span={8}>
              <Card
                className="card-body-all pointer"
                title="Actual Vs Plan (%)"
                bordered={false}
                loading={loading}
              >
                <div className="value-font-size ">
                  {" "}
                  <h2 style={{ textAlign: "center" }}>{percentage} %</h2>
                </div>
              </Card>
            </Col>
          )}

          {checkAmount === "true" ? (
            <Col xs={24} md={6} span={6}>
              <Card
                className="card-body-received pointer"
                title="AMOUNT"
                bordered={false}
                loading={loading}
              >
                <div className="value-font-size ">
                  {" "}
                  Plan
                  <h2>{rupiah(amountPlan)}</h2>
                  <br></br>
                  Actual
                  <h2>{rupiah(amountActual)}</h2>
                </div>
              </Card>
            </Col>
          ) : null}

          {pieMap.map(function (datanya, index) {
            return (
              <Col xs={24} md={6} span={6} key={index}>
                <Card title={datanya.location} extra={datanya.percentage}>
                  <div className="value-font-size ">
                    {" "}
                    <Pie
                      data={{
                        labels: datanya.label,
                        datasets: [
                          {
                            // label: "# of Votes",
                            data: datanya.qty,
                            backgroundColor: [
                              "rgba(54, 162, 235, 0.2)",
                              "rgba(255, 159, 64, 0.2)",
                            ],
                            borderColor: [
                              "rgba(54, 162, 235, 1)",
                              "rgba(255, 159, 64, 1)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </div>
                </Card>
              </Col>
            );
          })}

          <Content className="content">
            <Row gutter={[16, 20]}>
              <Col xs={24} md={6} lg={4} className="sidebar-body">
                <Card style={{ marginBottom: 15 }}>
                  <Space direction="vertical" size={12}>
                    Date
                    <DatePicker
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      onChange={handleChangeDate}
                      picker="month"
                      value={data.date}
                      format="MMM YYYY"
                    />
                  </Space>
                </Card>

                <Card style={{ marginBottom: 15 }}>
                  <Space direction="vertical" size={12}>
                    Customer / Vendor Name
                    <Select
                      allowClear="true"
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      className="width"
                      labelInValue
                      onChange={(value) =>
                        handleChangeViewCustomer("customer", value)
                      }
                      onFocus={() => getCustomer("")}
                      showSearch
                      onSearch={(value) => getCustomer(value)}
                      filterOption={false}
                    >
                      {customer &&
                        customer.map((cust, index) => {
                          return (
                            <Option value={cust.code} key={cust.code}>
                              {cust.alias}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space>
                </Card>

                <Card style={{ marginBottom: 15 }}>
                  <Space direction="vertical" size={12}>
                    Comodity
                    <Select
                      allowClear="true"
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      className="width"
                      labelInValue
                      onChange={(value) =>
                        handleChangeViewComodity("comodity", value)
                      }
                      onFocus={() => getComodity("")}
                      showSearch
                      onSearch={(value) => getComodity(value)}
                      filterOption={false}
                    >
                      {comodity &&
                        comodity.map((com, index) => {
                          return (
                            <Option value={index} key={index}>
                              {com.comodity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space>
                </Card>

                <Card style={{ marginBottom: 15 }}>
                  <Space direction="vertical" size={12}>
                    Item Code
                    <Select
                      allowClear="true"
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      className="width"
                      labelInValue
                      onChange={(value) => handleChangeViewItem("item", value)}
                      onFocus={() => getItemCode("")}
                      showSearch
                      onSearch={(value) => getItemCode(value)}
                      filterOption={false}
                    >
                      {item &&
                        item.map((part, index) => {
                          return (
                            <Option value={index} key={index}>
                              {part.item_code}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space>
                </Card>
              </Col>

              {checkAmount === "true" ? (
                <Col xs={24} md={9} lg={10} className="main-body">
                  <Card
                    style={{
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    QUANTITY
                    {!loading ? (
                      <Bar height="292" options={options} data={dataBar} />
                    ) : (
                      <div className="loading">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="spinner"
                          size="2x"
                          pulse
                        />
                      </div>
                    )}
                  </Card>
                </Col>
              ) : null}

              {checkAmount === "true" ? (
                <Col xs={24} md={9} lg={10} className="main-body">
                  <Card
                    style={{
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    AMOUNT
                    {!loading ? (
                      <Bar
                        height="292"
                        options={options}
                        data={dataBarAmount}
                      />
                    ) : (
                      <div className="loading">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="spinner"
                          size="2x"
                          pulse
                        />
                      </div>
                    )}
                  </Card>
                </Col>
              ) : (
                <Col xs={24} md={18} lg={20} className="main-body">
                  <Card
                    style={{
                      marginBottom: 10,
                      textAlign: "center",
                    }}
                  >
                    QUANTITY
                    {!loading ? (
                      <Bar height="139" options={options} data={dataBar} />
                    ) : (
                      <div className="loading">
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className="spinner"
                          size="2x"
                          pulse
                        />
                      </div>
                    )}
                  </Card>
                </Col>
              )}
              <Col xs={24} md={6} lg={4} className="sidebar-body"></Col>
              <Col xs={24} md={18} lg={20} className="main-body">
                <Card
                  style={{
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                >
                  PLAN {data.item_code} By Date
                  {!loading ? (
                    <Bar height="139" options={options} data={dataBarDate} />
                  ) : (
                    <div className="loading">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="spinner"
                        size="2x"
                        pulse
                      />
                    </div>
                  )}
                </Card>
              </Col>
            </Row>
          </Content>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Dash_monthly;
