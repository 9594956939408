/* eslint-disable */
import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  DatePicker,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateSuggestionApprvalPPIC(props) {
  const [data, setData] = useState({
    adjustment_qty: 0,
    comodity: "",
    customer: "",
    date: "",
    manifest_no: "",
    item_code: "",
    qty: 0,
    shift_1: 0,
    shift_2: 0,
    shift_3: 0,
    items: [],
    customer: [],
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getItem = async (keyword) => {
    await Axios.get(`${url}/item/list-item-fg`, {
      params: {
        keyword,
        columns: ["item_code", "comodity"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = data.items.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`comodity`]: find.comodity,
      });
    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: find.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    }
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("adjustment_qty", data.adjustment_qty);
    fd.set("item_code", data.item_code);
    fd.set("comodity", data.comodity);
    fd.set("customer", data.customer_name);
    fd.set("date", data.date);
    fd.set("manifest_no", data.manifest_no);
    fd.set("qty", data.qty);
    fd.set("shift_1", data.shift_1);
    fd.set("shift_2", data.shift_2);
    fd.set("shift_3", data.shift_3);

    Axios.post(`${url}/manual-ppic`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/suggestion-approval-ppic");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      adjustment_qty: "",
      comodity: "",
      customer: "",
      date: "",
      manifest_no: "",
      item_code: "",
      qty: "",
      shift_1: 0,
      shift_2: 0,
      shift_3: 0,
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Suggestion Approval PPIC"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/suggestion-approval-ppic">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add Suggestion Approval PPIC</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Item Code"
                        validateStatus={
                          error && error.item_code ? "error" : false
                        }
                        help={
                          error && error.item_code ? error.item_code[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect(
                              "item",
                              value
                              // "item_code",
                              // data.items
                            )
                          }
                          onFocus={() => getItem("")}
                          showSearch
                          onSearch={(value) => getItem(value)}
                          filterOption={false}
                          value={{
                            key: data.item_id,
                            label: data.item_code,
                          }}
                        >
                          {data.items &&
                            data.items.map((part) => {
                              return (
                                <Option value={part._id} key={part._id}>
                                  {`${part.item_code} - ${part.part_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        label="Comodity"
                        validateStatus={
                          error && error.comodity ? "error" : false
                        }
                        help={
                          error && error.comodity ? error.comodity[0] : false
                        }
                      >
                        <Input
                          disabled
                          value={data.comodity}
                          onChange={(e) => {
                            handleChange("comodity", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Customer"
                        validateStatus={
                          error && error.customer ? "error" : false
                        }
                        help={
                          error && error.customer ? error.customer[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("customer", value)
                          }
                          onFocus={() => getCustomer("")}
                          showSearch
                          onSearch={(value) => getCustomer(value)}
                          filterOption={false}
                          value={{
                            key: data.customer_id,
                            label: data.customer_label,
                          }}
                        >
                          {data.customer &&
                            data.customer.map((customers) => {
                              return (
                                <Option
                                  value={customers._id}
                                  key={customers._id}
                                >
                                  {`${customers.code} - ${customers.name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        required
                        label="Date"
                        validateStatus={error && error.date ? "error" : false}
                        help={error && error.date ? error.date[0] : false}
                      >
                        <DatePicker
                          onChange={(value) => handleChange("date", value)}
                          value={data.date}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                       
                      <Form.Item
                        required
                        label="Manifest No"
                        validateStatus={
                          error && error.manifest_no ? "error" : false
                        }
                        help={
                          error && error.manifest_no
                            ? error.manifest_no[0]
                            : false
                        }
                      >
                        <Input
                          value={data.manifest_no}
                          onChange={(e) =>
                            handleChange("manifest_no", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Shift 1"
                        validateStatus={
                          error && error.shift_1 ? "error" : false
                        }
                        help={error && error.shift_1 ? error.shift_1[0] : false}
                      >
                        <AntNumberFormat
                          type="input"
                          value={data.shift_1}
                          onChange={(e) => {
                            handleChange("shift_1", e);
                          }}
                        />
                      </Form.Item>                      
                      <Form.Item
                        required
                        label="Shift 2"
                        validateStatus={
                          error && error.shift_2 ? "error" : false
                        }
                        help={error && error.shift_2 ? error.shift_2[0] : false}
                      >
                        <AntNumberFormat
                          type="input"
                          value={data.shift_2}
                          onChange={(e) => {
                            handleChange("shift_2", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Shift 3"
                        validateStatus={
                          error && error.shift_3 ? "error" : false
                        }
                        help={error && error.shift_3 ? error.shift_3[0] : false}
                      >
                        <AntNumberFormat
                          type="input"
                          value={data.shift_3}
                          onChange={(e) => {
                            handleChange("shift_3", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        label="QTY Adjustment"
                        validateStatus={
                          error && error.adjustment_qty ? "error" : false
                        }
                        help={
                          error && error.adjustment_qty
                            ? error.adjustment_qty[0]
                            : false
                        }
                      >
                        <AntNumberFormat
                          disabled
                          type="input"
                          value={data.shift_1 + data.shift_2 + data.shift_3}
                          onChange={(e) =>
                            handleChange("adjustment_qty", e)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        disabled={
                          data.adjustment_qty === 0 ||
                          !data.adjustment_qty
                        }
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateSuggestionApprvalPPIC;
