/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  DatePicker,
  Form,
  Breadcrumb,
  Typography,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;
const { Title } = Typography;
const { RangePicker } = DatePicker;

function InvoiceCreate(props) {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    date: "",
    no_manifest: "",
    part_number: "",
    customer: "",
    shipped_to: "",
    date_delivery: "",
    date_receiving: "",
    status: "",
    no_faktur_pajak: "",
    file: null,
    ppn: 0,
    after_ppn: 0,
    subtotal: 0,
    grand_total: 0,
    currency_name: "",
  });

  const [dates, setDates] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const [invoice, setInvoice] = useState([]);
  const [total, setTotal] = useState(0);

  const [select, setSelect] = useState("");

  const [generate, setGenerate] = useState({
    total_amount: 0,
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
  };

  const handleIcon = (name) => {
    setError(null);
    setData({
      ...data,
      [name]: "click",
    });
  };

  useEffect(() => {
    getData();
  }, [keyword, dates.from, dates.to]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/invoice/delivery-note`, {
      params: {
        keyword,
        columns: ["manifest_no", "part_no", "order_no", "customer"],
        start_date: moment(dates.from).format("DD-MM-YYYY"),
        end_date: moment(dates.to).format("DD-MM-YYYY"),
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        const invoice = res.data.data;
        setTotal(res.data.total);

        setInvoice(   
          invoice.map((invoice) => {
            return {
              ...invoice,
              amount:
                invoice.currency_name !== "IDR"
                  ? invoice.qty * invoice.exchange_idr
                  : invoice.qty * invoice.price,
            };
          })
        );

        console.log(
          "invoice",
          invoice.map((invoice, index) => {
            return {
              ...invoice,
              amount:
                invoice.currency_name !== "IDR"
                  ? invoice.qty * invoice.exchange_idr
                  : invoice.qty * invoice.price,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(generate.subtotal);
  };

  const onSaveData = () => {
    setLoading(true);

    console.log(
      generate.total_amount,
      totalPpn,
      generate.total_amount + totalPpn,
      data.no_faktur_pajak,
      data.ppn
    );

    Axios.post(
      `${url}/invoice/manual`,
      {
        no_faktur_pajak: data.no_faktur_pajak,
        details: selectedRowsData,
        date,
        month: parseInt(moment(select.date_delivery).format('MM')),
        year: parseInt(moment(select.date_delivery).format('YYYY')),
        subtotal: generate.total_amount,
        nominal_ppn: totalPpn,
        ppn: data.ppn,
        grand_total_amount: generate.total_amount + totalPpn,
        currency_name: select.currency_name,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        props.history.push("/invoicing");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const hasSelected = selectedRowsData.length > 0;

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });

    setPerPage(pagination.pageSize);

    console.log(pagination, sorter, "ini data page");
  };

  const handleChangeDate = (value) => {
    if (value !== null) {
      setDates({
        from: value[0],
        to: value[1],
      });
    } else {
      setDates({
        from: "",
        to: "",
      });
    }
  };

  const columns = [
    {
      title: "No Manifest",
      width: 150,
      dataIndex: "manifest_no",
      sorter: false,
      align: "left",
    },
    {
      title: "PO No",
      width: 150,
      dataIndex: "order_no",
      sorter: false,
      align: "left",
    },
    {
      title: "Part Number",
      width: 150,
      dataIndex: "part_no",
      sorter: false,
      align: "left",
    },
    {
      title: "Customer",
      width: 150,
      dataIndex: "customer",
      sorter: false,
      align: "left",
    },
    {
      title: "Shipped To",
      width: 150,
      dataIndex: "shipped_to",
      sorter: false,
      align: "left",
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty</div>,
      width: 150,
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.qty}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Price</div>,
      width: 150,
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            prefix={data.currency_name}
            value={data.price}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Exchange IDR</div>,
      width: 150,
      align: "right",
      sorter: false,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            prefix="IDR"
            value={data.exchange_idr}
            type="text"
          />
        );
      },
    },
    {
      title: "Currency Name",
      sorter: false,
      width: 150,
      dataIndex: "currency_name",
    },
    {
      title: "Amount",
      width: 150,
      sorter: false,
      render: (data) => {
        return (
          <AntNumberFormat
            value={
              data.currency_name !== "IDR"
                ? data.qty * data.exchange_idr
                : data.qty * data.price
            }
            prefix={data.currency_name}
            type="text"
          />
        );
      },
    },

    {
      title: "Date Delivery",
      width: 150,
      dataIndex: "date_delivery",
      sorter: false,
      render: (date_delivery) => moment(date_delivery).format("DD-MM-YYYY"),
    },
    {
      title: "Date Receiving",
      width: 150,
      dataIndex: "date_receiving",
      sorter: false,
      render: (date_receiving) => moment(date_receiving).format("DD-MM-YYYY"),
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const totalPpn = (data.ppn * generate.total_amount) / 100;

  const handleCancel = () => {
    setVisible(false);

    setData({
      ...data,
      date: moment().startOf("date"),
      ppn: 0,
      after_ppn: 0,
      subtotal: 0,
      grand_total: 0,
      icon: "",
      no_faktur_pajak: "",
    });
  };

  return (
    <Fragment>
      <Modal
        title={
          <div>
            <span style={{ fontSize: "22px" }}></span>
            <br />
          </div>
        }
        centered
        visible={visible}
        onOk={() => onSaveData()}
        onCancel={() => handleCancel()}
        width={1000}
      >
        <Row type="flex" gutter={[5, 10]} justify="space-around">
          <Col xs={24} md={24} lg={24} className="align-right">
            <Form>
              <Col xs={24} className="align-right">
                <Space>
                  <Form.Item
                    required
                    validateStatus={
                      error && error.no_faktur_pajak ? "error" : false
                    }
                    help={
                      error && error.no_faktur_pajak
                        ? error.no_faktur_pajak[0]
                        : false
                    }
                  >
                    <Input
                      placeholder="No Faktur Pajak"
                      style={{ width: 150 }}
                      value={data.no_faktur_pajak}
                      type="input"
                      onChange={(e) =>
                        handleChange("no_faktur_pajak", e.target.value)
                      }
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Space>
                <Col xs={24} className="align-right">
                  {select ? (
                    select.currency_name === "IDR" ? (
                      <Col xs={6} className="align-right">
                        <AntNumberFormat
                          placeholder="PPn"
                          value={data.ppn}
                          type="input"
                          suffix="percent"
                          decimalScale="DES"
                          onChange={(e) => {
                            handleChange("ppn", e);
                          }}
                          disabled={data.after_ppn}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <DatePicker
                    defaultValue={moment(date, "YYYY-MM-DD")}
                    onChange={(value) =>
                      setDate(moment(value).format("YYYY-MM-DD"))
                    }
                    inputReadOnly={true}
                    format={"YYYY-MM-DD"}
                    allowClear={false}
                  />
                </Col>
              </Space>
            </Form>
          </Col>
        </Row>

        <Col xs={24}>
          <Table
            style={{ marginTop: 20 }}
            scroll={{ x: 750, y: 350 }}
            columns={columns}
            size="small"
            dataSource={selectedRowsData}
            rowKey={(data) => data._id}
            onChange={handleTableChange}
            pagination={{
              pageSize: 10,
              current: page,
              total: selectedRowsData.length,
              showSizeChanger: false,
            }}
            loading={loading}
          />
          <Col xs={24}>
            <div style={{ marginLeft: "2%" }}>
              <b>
                <Space>
                  <Title
                    style={{
                      fontSize: "100%",
                      marginTop: "8%",
                      fontWeight: "bold",
                    }}
                  >
                    Subtotal :
                  </Title>
                  <AntNumberFormat
                    style={{ marginLeft: "1%" }}
                    value={generate.total_amount}
                    type="text"
                    prefix={select ? select.currency_name : ""}
                    decimalScale={data.currency_name}
                    onChange={(e) => {
                      handleChange("subtotal", e);
                    }}
                  />
                </Space>
              </b>
              <br />
              <b>
                {select ? (
                  select.currency_name === "IDR" ? (
                    <Col xs={12}>
                      <Space>
                        <Title
                          style={{
                            fontSize: "100%",
                            marginTop: "8%",
                            fontWeight: "bold",
                          }}
                        >
                          PPn ({data.ppn}%) :
                        </Title>

                        <AntNumberFormat
                          value={totalPpn}
                          type="text"
                          prefix="IDR"
                          decimalScale={data.currency_name}
                          onChange={(e) => {
                            handleChange("ppn", e);
                          }}
                        />

                        {data.ppn > 0 ? (
                          <EditOutlined
                            onClick={() => {
                              handleIcon("icon");
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {data.icon === "click" && data.ppn > 0 ? (
                          <AntNumberFormat
                            style={{ marginLeft: "1%" }}
                            value={data.after_ppn}
                            type="input"
                            prefix="IDR"
                            decimalScale={data.currency_name}
                            onChange={(e) => {
                              handleChange("after_ppn", e);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Space>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </b>
              <br />
              <b>
                <Space>
                  <Title
                    style={{
                      fontSize: "100%",
                      marginTop: "8%",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total :
                  </Title>
                  <AntNumberFormat
                    style={{ marginLeft: "1%" }}
                    value={
                      data.after_ppn
                        ? generate.total_amount + data.after_ppn
                        : generate.total_amount + totalPpn
                    }
                    type="text"
                    prefix={select ? select.currency_name : ""}
                    decimalScale={data.currency_name}
                    onChange={(e) => {
                      handleChange("grand_total", e);
                    }}
                  />
                </Space>
              </b>
            </div>
          </Col>
        </Col>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card type="inner" title="Create Invoicing">
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/invoicing">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Invoice</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} className="align-right">
                  <RangePicker
                    onChange={handleChangeDate}
                    value={[dates.from, dates.to]}
                    format="DD MMM YYYY"
                    picker="date"
                  />
                </Col>
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Button
                      disabled={!hasSelected}
                      onClick={() => setVisible(true)}
                      type="primary"
                    >
                      <PlusOutlined />
                      Create Invoice
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={invoice}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: perpage,
                      current: page,
                      total: total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        setSelect(selectedRows ? selectedRows[0] : null);

                        //ini buat ngejumlahin amount
                        const total_amount = selectedRows.reduce(
                          (total, item) => parseFloat(item.amount) + total,
                          0
                        );

                        setGenerate({
                          ...generate,
                          total_amount,
                        });
                      },
                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            (checkDisable &&
                              checkDisable.no_manifest !==
                                record.no_manifest) ||
                            record.status_inv === 2 ||
                            (checkDisable &&
                              checkDisable.currency_name !==
                                record.currency_name) ||
                            (checkDisable &&
                              moment(checkDisable.date_delivery).format(
                                "MM"
                              ) !==
                                moment(record.date_delivery).format("MM")) ||
                            (checkDisable &&
                              moment(checkDisable.date_delivery).format(
                                "YYYY"
                              ) !==
                                moment(record.date_delivery).format("YYYY")),
                        };
                      },
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default InvoiceCreate;
