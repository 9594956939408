/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { PlusOutlined } from "@ant-design/icons";

const { Search } = Input;
const { Content } = Layout;

function CreateSuratJalan() {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([
    {
      item_code: "",
      item_name: "",
      qty: "",
      unit: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const dataSource = [
    {
      key: "1",
      _id: "1",
      item_code: "DC-9912",
      item_name: "Baut",
      qty: 40,
      unit: "Pcs",
    },
    {
      key: "2",
      _id: "2",
      item_code: "DC-1882",
      item_name: "Skrup",
      qty: 88,
      unit: "Pcs",
    },
    {
      key: "3",
      _id: "3",
      item_code: "DC-1290",
      item_name: "Pallete",
      qty: 10,
      unit: "Pcs",
    },
  ];

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
      key: "item_code",
      width: 100,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 70,
    },
    {
      title: <div style={{ textAlign: "left" }}>Qty</div>,
      dataIndex: "qty",
      key: "qty",
      align: "right",
      width: 50,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 50,
    },
  ];

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/daily-kanban`, {
      params: {
        keyword,
        columns: ["supplier_name", "material_name"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/daily-kanban/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/daily-kanban/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  // const columns = [
  //   {
  //     title: "Supplier Name",
  //     dataIndex: "supplier_type",
  //     sorter: false,
  //     width: 150,
  //   },
  //   {
  //     title: "Material Name",
  //     dataIndex: "material_name",
  //     sorter: false,
  //     width: 150,
  //   },
  //   {
  //     title: "Qty PO",
  //     dataIndex: "qty_daily_order",
  //     sorter: false,
  //     width: 150,
  //   },
  //   {
  //     title: "Qty Generate Kanban",
  //     dataIndex: "qty_generate_kanban",
  //     sorter: false,
  //     width: 150,
  //   },

  //   {
  //     title: "Created By",
  //     dataIndex: "created_by",
  //     sorter: false,
  //     width: 150,
  //   },
  //   {
  //     title: "Created At",
  //     dataIndex: "created_at",
  //     sorter: false,
  //     render: (created_at) => moment(created_at).format("DD MMM YYYY"),
  //     width: 150,
  //   },
  //   {
  //     title: "Updated By",
  //     dataIndex: "updated_by",
  //     sorter: false,
  //     width: 150,
  //   },
  //   {
  //     title: "Updated At",
  //     dataIndex: "updated_at",
  //     sorter: false,
  //     render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
  //     width: 150,
  //   },
  //   {
  //     title: "",
  //     dataIndex: "_id",
  //     align: "right",
  //     sorter: false,
  //     fixed: "right",
  //     width: 100,
  //     render: (id) => {
  //       return (
  //         <Fragment>
  //           <Dropdown
  //             className="pointer"
  //             overlay={
  //               <Menu>
  //                 <Menu.Item key="1">
  //                   <Link to={`/master-packaging-edit-${id}`}>
  //                     <EditOutlined /> Edit
  //                   </Link>
  //                 </Menu.Item>
  //                 <Menu.Item key="2">
  //                   <span onClick={() => handleDelete(id)}>
  //                     <DeleteOutlined />
  //                     Delete
  //                   </span>
  //                 </Menu.Item>
  //               </Menu>
  //             }
  //             trigger={["click"]}
  //           >
  //             <span className="pointer" onClick={(e) => e.preventDefault()}>
  //               <MoreOutlined />
  //             </span>
  //           </Dropdown>
  //         </Fragment>
  //       );
  //     },
  //   },
  // ];

  const hasSelected = selectedRowsData.length > 0;

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Surat Jalan"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    {/* <Link to="/master-packaging-create"> */}
                    <Button type="primary" disabled={!hasSelected}>
                      <PlusOutlined />
                      Submit
                    </Button>
                    {/* </Link> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    dataSource={data.data}
                    // dataSource={dataSource}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        console.log(
                          selectedRows,
                          selectedRowKeysData,
                          "ini select"
                        );

                        // const total_amount = selectedRows.reduce(
                        //   (total, item) => parseFloat(item.amount) + total,
                        //   0
                        // );
                        // const total_suggestion_qty = selectedRows.reduce(
                        //   (total, item) =>
                        //     parseFloat(item.suggestion_qty) + total,
                        //   0
                        // );
                        // const supplier_name = selectedRows
                        //   ? selectedRows[0]
                        //   : null;

                        // setGenerate({
                        //   ...generate,
                        //   total_suggestion_qty,
                        //   total_amount,
                        //   supplier_name,
                        // });
                      },
                      getCheckboxProps: (record) => {
                        const checkDisable = selectedRowsData
                          ? selectedRowsData[0]
                          : null;

                        return {
                          disabled:
                            checkDisable &&
                            // checkDisable.supplier_name !== record.supplier_name,
                            checkDisable.name !== record.name,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateSuratJalan;
