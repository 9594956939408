import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Layout,
  Row,
  Col,
  Button,
  message,
  Input,
  Table,
  DatePicker,
  Select,
  Space,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import { FileExcelOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";
import moment from "moment";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

function ReportSuggestionPpic() {
  const [data, setData] = useState({
    // start_date_receipt: moment().startOf("month"),
    // end_date_receipt: moment().endOf("month"),
    start_date_approval: undefined,
    end_date_approval: undefined,
    date: moment().startOf("date"),
    comodity_name: "",
  });
  const [keyword, setKeyword] = useState({
    vendor_code: undefined,
    invoicing_code: undefined,
    receipt_number: undefined,
    tax_invoice_number: undefined,
  });
  const [keywordAll, setKeywordAll] = useState("");
  const [suggestionPpics, setSuggestionPpics] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sort, setSort] = useState({
    sort: "name",
    order: "ascend",
  });
  const [comodity, setComodity] = useState([]);
  const [errors, setErrors] = useState(null);

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        date: value,
      });
    } else {
      setData({
        ...data,
        date: null,
      });
    }
  };

  const handleChangeViewComodity = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
    console.log("value", value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, data]);

  console.log("date", data.date);

  const getComodity = async (keyword) => {
    await Axios.get(`${url}/report-suggestion-ppic/list-comodity`, {
      params: {
        keyword,
        // columns: ["part_number"],
        perpage: pageSize,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        date: moment(data.date).format("YYYY-MM-DD"),
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/report-suggestion-ppic`, {
      params: {
        keyword,
        //columns: ["part_number_internal", "part_number_external"],
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        date: data.date === "" ? null : moment(data.date).format("YYYY-MM-DD"),
        comodity_name: data.comodity_name,
        // start_date_approval: data.start_date_approval
        //   ? data.start_date_approval.format("YYYY-MM-DD")
        //   : null,
        // end_date_approval: data.end_date_approval
        //   ? data.end_date_approval.format("YYYY-MM-DD")
        //   : null,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSuggestionPpics(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const excelReport = () => {
    Axios.get(`${url}/suggestion-ppic/export`, {
      params: {
        perpage: pageSize,
        page,
        sort: sort.sort,
        order: sort.order,
        date: data.date === "" ? null : moment(data.date).format("YYYY-MM-DD"),
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "report_suggestion_ppic.xlsx");
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPageSize(pagination.pageSize);
  };

  const columns = [
    {
      title: "Line",
      dataIndex: "location_code",
      key: "location_code",
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      key: "item_code",
    },
    {
      title: "Unique No",
      dataIndex: "unique_no",
      key: "unique_no",
    },
    {
      title: "Pcs/Kbn",
      dataIndex: "pcs_kbn",
      key: "pcs_kbn",
    },
    {
      title: "Pcs/Hour",
      dataIndex: "pcs_hour",
      key: "pcs_hour",
    },
    {
      title: "Total Order",
      children: [
        {
          title: "Kbn",
          dataIndex: "total_order_kanban",
          key: "total_order_kanban",
        },
        {
          title: "Pcs",
          dataIndex: "total_order_pcs",
          key: "total_order_pcs",
        },
      ],
    },
    {
      title: "DATE",
      children: [
        {
          title: "SHIFT 3",
          children: [
            {
              title: "Plan (Kbn)",
              dataIndex: "shift_3_plan_kanban",
              key: "shift_3_plan_kanban",
            },
            {
              title: "Act (Kbn)",
              dataIndex: "line",
              key: "line",
            },
            {
              title: "Plan (Pcs)",
              dataIndex: "shift_3_plan_pcs",
              key: "shift_3_plan_pcs",
            },
            {
              title: "Act (Pcs)",
              dataIndex: "line",
              key: "line",
            },
          ],
        },
      ],
    },
    {
      title: "DATE",
      children: [
        {
          title: "SHIFT 1",
          children: [
            {
              title: "Plan (Kbn)",
              dataIndex: "shift_1_plan_kanban",
              key: "shift_1_plan_kanban",
            },
            {
              title: "Act (Kbn)",
              dataIndex: "line",
              key: "line",
            },
            {
              title: "Plan (Pcs)",
              dataIndex: "shift_1_plan_pcs",
              key: "shift_1_plan_pcs",
            },
            {
              title: "Act (Pcs)",
              dataIndex: "line",
              key: "line",
            },
          ],
        },
      ],
    },
    {
      title: "DATE",
      children: [
        {
          title: "SHIFT 2",
          children: [
            {
              title: "Plan (Kbn)",
              dataIndex: "shift_2_plan_kanban",
              key: "shift_2_plan_kanban",
            },
            {
              title: "Act (Kbn)",
              dataIndex: "line",
              key: "line",
            },
            {
              title: "Plan (Pcs)",
              dataIndex: "shift_2_plan_pcs",
              key: "shift_2_plan_pcs",
            },
            {
              title: "Act (Pcs)",
              dataIndex: "line",
              key: "line",
            },
          ],
        },
      ],
    },
    // {
    //   title: "Approval Date",
    //   dataIndex: "approval_date",
    //   key: "approval_date",
    //   //sorter: true,
    //   render: (approval_date) => moment(approval_date).format("DD.MM.YYYY"),
    // },
    // {
    //   title: "DN Date",
    //   dataIndex: "date",
    //   key: "date",
    //   //sorter: true,
    //   render: (date) => moment(date).format("DD.MM.YYYY"),
    // },
    // {
    //   title: "Item Code",
    //   dataIndex: "part_number",
    //   key: "part_number",
    //   //sorter: true,
    // },
    // {
    //   title: "Comodity",
    //   dataIndex: "comodity",
    //   key: "comodity",
    //   //sorter: true,
    // },
    // {
    //   title: "DN Qty",
    //   dataIndex: "qty",
    //   key: "qty",
    //   //sorter: true,
    //   render: (qty) => {
    //     return <AntNumberFormat value={qty} type="text" />;
    //   },
    // },
    // {
    //   title: "Adjustment Qty",
    //   dataIndex: "adjustment_qty",
    //   key: "adjustment_qty",
    //   //sorter: true,
    //   render: (adjustment_qty) => {
    //     return <AntNumberFormat value={adjustment_qty} type="text" />;
    //   },
    // },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card
              size="small"
              title="Report Daily Production Plan"
              className="body-data"
            >
              <Row type="flex" gutter={[10, 10]} justify="space-around">
                <Col xs={24} md={12}>
                  <Space>
                    Production
                    <DatePicker
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      onChange={handleChangeDate}
                      picker="date"
                      value={data.date}
                      format="DD MMM YYYY"
                    />
                    {/* <RangePicker
                      onChange={(value) =>
                        handleChangeDate("date_approval", value)
                      }
                      value={[data.start_date_approval, data.end_date_approval]}
                      format="DD-MM-YYYY"
                    /> */}
                    <Button onClick={excelReport} type="primary">
                      <FileExcelOutlined />
                      Export to Excel
                    </Button>
                  </Space>
                </Col>

                <Col xs={24} md={12}>
                  <Space>
                    Comodity
                    <Select
                      allowClear="true"
                      style={{
                        fontSize: 10,
                        width: 135,
                      }}
                      className="width"
                      labelInValue
                      onChange={(value) =>
                        handleChangeViewComodity("comodity", value)
                      }
                      onFocus={() => getComodity("")}
                      showSearch
                      onSearch={(value) => getComodity(value)}
                      filterOption={false}
                    >
                      {comodity &&
                        comodity.map((com, index) => {
                          return (
                            <Option value={index} key={index}>
                              {com}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    scroll={{ x: 2000 }}
                    columns={columns}
                    dataSource={suggestionPpics.data}
                    rowKey={(_, index) => index}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: pageSize,
                      current: page,
                      total: suggestionPpics.total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                    size="small"
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ReportSuggestionPpic;
