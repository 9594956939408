/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  DatePicker,
  TimePicker,
  message,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function NoteDelivManage(props) {
  const [data, setData] = useState({
    manifest_no: "",
    date: "",
    part_no: "",
    customer: [],
    shipped_to: [],
    billed_to: [],
    qty: 0,
    date_delivery: "",
    date_receiving: "",
    pcs_kanban: 0,
    kanban: "",
    status: 0,
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  //#region get Data
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    Axios.get(`${url}/deliverynotedetail`, {
      params: {
        id: props.match.params.id,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        res = res.data.data;

        setData({
          ...data,
          manifest_no: res.manifest_no,
          date: res.date,
          part_no: res.part_no,
          customer_label: res.customer,
          customer_alias: res.customer,
          shipped_alias: res.shipped_to,
          shipped_label: res.shipped_to,
          billed_label: res.billed_to,
          billed_alias: res.billed_to,
          qty: res.qty,
          date_delivery: res.date_delivery,
          date_receiving: res.date_receiving,
          status: res.status,
          pcs_kanban: res.pcs_kanban,
          kanban: res.kanban,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };
  //#endregion

  const onSaveData = () => {
    setLoading(true);

    let formData = new FormData();

    formData.set("manifest_no", data.manifest_no);
    formData.set("date", data.date);
    formData.set("part_no", data.part_no);
    formData.set("customer", data.customer_alias);
    formData.set("shipped_to", data.shipped_alias);
    formData.set("billed_to", data.billed_alias);
    formData.set("pcs_kanban", data.pcs_kanban);
    formData.set("kanban", data.kanban);
    formData.set("qty", data.pcs_kanban * data.kanban);

    Axios.post(`${url}/deliverynotedetail/${props.match.params.id}`, formData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/delivery-note");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);

          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }

          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }

        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });

      console.log(find.alias);
    } else if (name === "shipped") {
      const find = data.shipped_to.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else if (name === "billed") {
      const find = data.billed_to.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else {
      const find = data.items.find((x) => x._id === value.key);
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    }
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getShipped = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          shipped_to: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getBilled = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          billed_to: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  console.log(data);

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Delivery Note"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/delivery-note">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Note</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item required label="Manifest Number">
                  <Input
                    value={data.manifest_no}
                    onChange={(e) =>
                      handleChange("manifest_no", e.target.value)
                    }
                  />
                </Form.Item>
                <Form.Item required label="Date">
                  <DatePicker
                    value={moment(data.date, "YYYY-MM-DD")}
                    onChange={(Item) =>
                      handleChange("date", moment(Item).format("YYYY-MM-DD"))
                    }
                    inputReadOnly={true}
                    format={"YYYY-MM-DD"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
                <Form.Item required label="Part Number">
                  <Input
                    value={data.part_no}
                    onChange={(e) => handleChange("part_no", e.target.value)}
                  />
                </Form.Item>
                <Form.Item required label="Customer">
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("customer", value)}
                    onFocus={() => getCustomer("")}
                    showSearch
                    onSearch={(value) => getCustomer(value)}
                    value={{
                      key: data.customer_id,
                      label: data.customer_label,
                    }}
                  >
                    {data.customer &&
                      data.customer.map((customers) => {
                        return (
                          <Option value={customers._id} key={customers._id}>
                            {`${customers.code} - ${customers.alias}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item required label="Shipped To">
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("shipped", value)}
                    onFocus={() => getShipped("")}
                    showSearch
                    onSearch={(value) => getShipped(value)}
                    filterOption={false}
                    value={{
                      key: data.shipped_id,
                      label: data.shipped_label,
                    }}
                  >
                    {data.shipped_to &&
                      data.shipped_to.map((shipped_to) => {
                        return (
                          <Option value={shipped_to._id} key={shipped_to._id}>
                            {` ${shipped_to.alias}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item required label="Billed To">
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("billed", value)}
                    onFocus={() => getBilled("")}
                    showSearch
                    onSearch={(value) => getBilled(value)}
                    filterOption={false}
                    value={{
                      key: data.billed_id,
                      label: data.billed_label,
                    }}
                  >
                    {data.billed_to &&
                      data.billed_to.map((billed_to) => {
                        return (
                          <Option value={billed_to._id} key={billed_to._id}>
                            {` ${billed_to.alias}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item required label="PCS KBN">
                  <AntNumberFormat
                    //defaultValue="0"
                    value={data.pcs_kanban}
                    type="input"
                    onChange={(e) => handleChange("pcs_kanban", e)}
                  />
                </Form.Item>
                <Form.Item required label="Kanban">
                  <AntNumberFormat
                    //defaultValue="0"
                    value={data.kanban}
                    type="input"
                    onChange={(e) => {
                      handleChange("kanban", e);
                    }}
                  />
                </Form.Item>
                <Form.Item required label="Qty">
                  <AntNumberFormat
                    //defaultValue="0"
                    disabled
                    type="input"
                    value={data.pcs_kanban * data.kanban}
                    onChange={(e) => handleChange("manufactured_quantity", e)}
                  />
                </Form.Item>
                <Form.Item label="Date Delivery">
                  <Input
                    value={data.date_delivery != "" ? data.date_delivery : "-"}
                    onChange={(e) =>
                      handleChange("date_delivery", e.target.value)
                    }
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item label="Date Receiving">
                  <Input
                    value={
                      data.date_receiving != "" ? data.date_receiving : "-"
                    }
                    onChange={(e) =>
                      handleChange("date_receiving", e.target.value)
                    }
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default NoteDelivManage;
