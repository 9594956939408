/* eslint-disable */
import React, { useState } from "react";
import { Input, Tabs, Button, message, Form } from "antd";
import "./Login.less";
import { UserOutlined, LockOutlined, RedoOutlined } from "@ant-design/icons";
import QrReader from "react-qr-reader";
import Axios from "axios";
import { url } from "../../config/global";

const { TabPane } = Tabs;

function Login(props) {
  const [tab, setTab] = useState("1");
  const [success, setSuccess] = useState(true);
  const [login, setLogin] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleScan = async (value) => {
    if (value !== null) {
      await Axios.post(`${url}/login-qr`, {
        username: value,
      })
        .then((res) => {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("fullname", res.data.data.full_name);
          localStorage.setItem(
            "avatar",
            res.data.data.photo ? res.data.data.photo_url : ""
          );
          localStorage.setItem(
            "permissions",
            JSON.stringify(res.data.permissions)
          );
          localStorage.setItem("redirect", res.data.redirect);

          message.success(res.data.message);
          setLoading(false);
          props.history.push(res.data.redirect);
        })
        .catch((err) => {
          message.error(res.data.message);
          setLoading(false);
          setSuccess(false);
        });
    }
  };

  const handleChange = (name, value) => {
    setError(null);
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleError = (err) => {
    console.log(err);
  };

  const handleTab = (key) => {
    setTab(key);
  };

  const handleRetry = () => {
    setSuccess(true);
  };

  const handleLogin = async () => {
    setLoading(true);
    await Axios.post(`${url}/login`, {
      username: login.username,
      password: login.password,
    })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("fullname", res.data.data.full_name);
        localStorage.setItem("role_name", res.data.data.role_name);
        localStorage.setItem("amount", res.data.data.amount);
        localStorage.setItem("line_code", res.data.data.line_code);
        localStorage.setItem("line_name", res.data.data.line_name);
        localStorage.setItem(
          "avatar",
          res.data.data.photo ? res.data.data.photo_url : ""
        );
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        localStorage.setItem("redirect", res.data.redirect);

        message.success(res.data.message);
        setLoading(false);
        props.history.push(res.data.redirect);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
        if (err.response.status === 422) {
          setError(err.response.data.errors);
        }
      });

    setLoading(false);
  };

  return (
    <div className="main">
      <div>
        <div className="img-box">
          <img
            src={require("../../assets/img/logo-mini.png")}
            className="img-logo"
            alt="DEM"
          />
        </div>
        <Tabs activeKey={tab} onChange={handleTab}>
          <TabPane tab="Sign In" key="1">
            <Form onFinish={handleLogin}>
              <Form.Item
                validateStatus={error && error.username ? "error" : false}
                help={error && error.username ? error.username[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<UserOutlined />}
                  placeholder="Username"
                  onChange={(e) => handleChange("username", e.target.value)}
                  value={login.username}
                />
              </Form.Item>
              <Form.Item
                validateStatus={error && error.password ? "error" : false}
                help={error && error.password ? error.password[0] : false}
              >
                <Input
                  className="login-input"
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                  onChange={(e) => handleChange("password", e.target.value)}
                  value={login.password}
                />
              </Form.Item>
              <div>
                <Button
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                  className="button-login"
                >
                  Login
                </Button>
              </div>
            </Form>
          </TabPane>
          {/* {<TabPane tab="Sign in with Barcode" key="3">
                            {
                                tab === '3' && success ? (
                                    <QrReader
                                        delay={100}
                                        onError={handleError}
                                        onScan={handleScan}
                                        className="barcode"
                                        showViewFinder={false}
                                    />
                                ) : (
                                    
                                    <div className="barcode-fail">
                                        <p>Login Failed</p>
                                        <p>Retry</p>
                                        <Button type="link" size="large" onClick={handleRetry}><RedoOutlined /></Button>
                                    </div>    
                                )
                            }
                        </TabPane>} */}
        </Tabs>
      </div>
    </div>
  );
}

export default Login;
