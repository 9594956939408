import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  Checkbox,
  DatePicker,
  Radio,
  Space,
  Upload,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
import NumberFormat from "react-number-format";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreatePurchasingPO(props) {
  const [data, setData] = useState({
    supplier_name: "",
    supplier_code: "",
    supplier_id: "",
    supplier_label: "",
    po_number: "",
    publisher_date: "",
    suppliers: [],
  });

  const [dataPurchasing, setDataPurchasing] = useState({
    material_code: "",
    material_id: "",
    material_label: "",
    materials: [],
    parts_name: "",
    parts_no: "",
    item_type: "",
    qty_po: "",
    unit_price: "",
    amount: "",
    forecast_n1: "",
    forecast_n2: "",
    forecast_n3: "",
    supplier_name: "",
    po_number: "",
    lot: "",
    date: "",
  });

  const [perpage, setPerpage] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [loadItem, setLoadItem] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [purchasingDetail, setPurchasingDetail] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  const getSupplier = async (keyword) => {
    await Axios.get(`${url}/supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    const find = data.suppliers.find((x) => x._id === value.key);

    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`${name}_name`]: find.name,
      [`${name}_code`]: find.code,
    });

    console.log(name, value, "ini supplier");
  };

  const handleChangeDetail = (name, value) => {
    setDataPurchasing({
      ...dataPurchasing,
      [name]: value,
    });
  };

  const handleChangeSelectDetail = (name, value) => {
    const findName = dataPurchasing.materials.find(
      (x) => x._id === value.value
    );

    setDataPurchasing({
      ...dataPurchasing,
      [`${name}_id`]: value.value,
      [`${name}_label`]: value.label,
      [`${name}_code`]: findName.item_code,
      [`parts_no`]: findName.item_code,
      [`parts_name`]: findName.item_name,
      [`item_type`]: findName.item_type,
      [`lot`]: findName.lot,
    });
  };

  const getItem = async (keyword) => {
    setLoadItem(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataPurchasing({
          ...dataPurchasing,
          materials: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoadItem(false);
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifypurchasingDetail = [...purchasingDetail];
      const findpurchasingDetail = modifypurchasingDetail.find(
        (x) => x._id === selectedId
      );

      findpurchasingDetail.item_code = dataPurchasing.material_code;
      findpurchasingDetail.part_number = dataPurchasing.parts_no;
      findpurchasingDetail.item_name = dataPurchasing.parts_name;
      findpurchasingDetail.lot = dataPurchasing.lot;
      findpurchasingDetail.qty_po = dataPurchasing.qty_po;
      findpurchasingDetail.ppc_mps = dataPurchasing.ppc_mps;
      findpurchasingDetail.purchase_price = dataPurchasing.unit_price;
      findpurchasingDetail.amount = dataPurchasing.qty_po * dataPurchasing.purchase_price;
      findpurchasingDetail.forecast_n1 = dataPurchasing.forecast_n1;
      findpurchasingDetail.forecast_n2 = dataPurchasing.forecast_n2;
      findpurchasingDetail.forecast_n3 = dataPurchasing.forecast_n3;
      findpurchasingDetail.material_quantity = dataPurchasing.material_quantity;
      findpurchasingDetail.currency_name = "IDR";
      findpurchasingDetail.month = "";
      findpurchasingDetail.year = "";
      findpurchasingDetail.approved_status = 0;
      findpurchasingDetail.adjustment_quantity = dataPurchasing.qty_po;
      findpurchasingDetail.item_type = dataPurchasing.item_type;

      console.log(findpurchasingDetail, modifypurchasingDetail, "modify");

      setPurchasingDetail(modifypurchasingDetail);
    } else {
      setPurchasingDetail([
        ...purchasingDetail,
        {
          _id: Math.random(),
          item_code: dataPurchasing.material_code,
          part_number: dataPurchasing.parts_no,
          item_name: dataPurchasing.parts_name,
          qty_po: dataPurchasing.qty_po,
          lot: dataPurchasing.lot,
          material_quantity: dataPurchasing.material_quantity,
          ppc_mps: dataPurchasing.ppc_mps,
          unit_price: dataPurchasing.unit_price,
          amount: dataPurchasing.amount,
          forecast_n1: dataPurchasing.forecast_n1,
          forecast_n2: dataPurchasing.forecast_n2,
          forecast_n3: dataPurchasing.forecast_n3,
          currency_name: "IDR",
          month: "",
          year: "",
          approved_status: 0,
          adjustment_quantity: dataPurchasing.qty_po,
          item_type: dataPurchasing.item_type,
        },
      ]);
    }

    setSelectedId("");

    setDataPurchasing({
      ...dataPurchasing,
      material_id: "",
      material_code: "",
      material_label: "",
      parts_name: "",
      parts_no: "",
      qty_po: "",
      lot: "",
      unit_price: "",
      amount: "",
      material_quantity: "",
      ppc_mps: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
      supplier_name: "",
      po_number: "",
      date: "",
    });

    setIsModalVisible(false);
  };

  const handleRemoveDetails = (id) => {
    const details = purchasingDetail.filter(
      (purchasingDetail) => purchasingDetail._id !== id
    );
    setPurchasingDetail(details);
  };

  const handleEditDetails = (id) => {
    const findPurchasing = purchasingDetail.find(
      (purchasingDetail) => purchasingDetail._id === id
    );
    console.log(id);
    setSelectedId(id);

    setDataPurchasing({
      ...dataPurchasing,
      material_code: findPurchasing.item_code,
      parts_no: findPurchasing.part_number,
      parts_name: findPurchasing.item_name,
      qty_po: findPurchasing.qty_po,
      unit_price: findPurchasing.unit_price,
      amount: findPurchasing.amount,
      forecast_n1: findPurchasing.forecast_n1,
      forecast_n2: findPurchasing.forecast_n2,
      forecast_n3: findPurchasing.forecast_n3,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      item_code: "",
      item_name: "",
      item_id: "",
      process_code: "",
      process_name: "",
      process_id: "",
      manufactured_quantity: "",
      items: [],
    });

    setPurchasingDetail([]);
  };

  const onSaveData = () => {
    console.log(totalAmount, 'test')
    setLoading(true);
    let fd = new FormData();

    fd.set("details", JSON.stringify(purchasingDetail));
    fd.set("supplier_name", data.supplier_name);
    fd.set("supplier_code", data.supplier_code);
    fd.set("po_number", data.po_number);
    fd.set("month", moment(data.date).format("MM"));
    fd.set("year", moment(data.date).format("YYYY"));
    fd.set("grand_total_amount", totalAmount);

    Axios.post(`${url}/purchase-order`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success" || res.data.type === "success") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
        // setLoading(false);
        props.history.push("/purchasing-po");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setDataPurchasing({
      ...dataPurchasing,
      material_id: "",
      material_code: "",
      material_label: "",
      item_name: "",
      part_number: "",
      qty_po: "",
      lot: "",
      unit_price: "",
      amount: "",
      material_quantity: "",
      ppc_mps: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
      supplier_name: "",
      po_number: "",
      date: "",
    });

    setSelectedId("");
    // setIsModalVisible(false);
  };

  const closeModal = () => {
    setDataPurchasing({
      ...dataPurchasing,
      material_id: "",
      material_label: "",
      parts_name: "",
      parts_no: "",
      qty_po: "",
      unit_price: "",
      amount: "",
      forecast_n1: "",
      forecast_n2: "",
      forecast_n3: "",
    });

    setSelectedId("");
    setIsModalVisible(false);
  };

  const columns = [
    {
      align: "center",
      title: "No",
      dataIndex: "no",
      render: (text, record, index) => index + page * perpage - (perpage - 1),
    },
    {
      align: "center",
      title: "Material Code",
      render: (data) => {
        return <p>{data.item_code}</p>; //material code tapi simpen ke backend item_code, sama saja.
      },
    },
    {
      align: "center",
      title: "Parts No",
      render: (data) => {
        return <p>{data.part_number}</p>;
      },
    },
    {
      align: "center",
      title: "Parts Name",
      render: (data) => {
        return <p>{data.item_name}</p>;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'center' }}>Qty PO</div>,
      render: (data) => {
        return <AntNumberFormat value={data.qty_po} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'center' }}>Material Qty</div>,
      render: (data) => {
        return <AntNumberFormat value={data.material_quantity} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'center' }}>PPC MPS</div>,
      render: (data) => {
        return <AntNumberFormat value={data.ppc_mps} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'center' }}>Unit Price</div>,
      render: (data) => {
        return <AntNumberFormat value={data.unit_price} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'center' }}>Total Amount</div>,
      render: (data) => {
        return <AntNumberFormat value={data.amount} type="text" />;
      },
    },
    {
      align: "center",
      title: "Forecast",
      children: [
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+1</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n1} type="text" />;
          },
        },
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+2</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n2} type="text" />;
          },
        },
        {
          title: <div style={{ textAlign: 'center' }}>Forecast N+3</div>,
          align: "right",
          render: (data) => {
            return <AntNumberFormat value={data.forecast_n3} type="text" />;
          },
        },
      ],
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      fixed: "right",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>

          <Button
            style={{ marginLeft: 5 }}
            onClick={() => handleEditDetails(id)}
            size="small"
            type="primary"
          >
            <EditOutlined />
            Edit
          </Button>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Purchasing PO"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/purchasing-po">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Purchasing PO</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Purchasing PO"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={24} lg={18}>
                            <Form.Item
                              label="Supplier Name"
                              validateStatus={
                                error && error.supplier_name ? "error" : false
                              }
                              help={
                                error && error.supplier_name
                                  ? error.supplier_name[0]
                                  : false
                              }
                            >
                              <Col md={24} sm={24} xs={24}>
                                <Select
                                  labelInValue
                                  onChange={(value) =>
                                    handleChangeSelect("supplier", value)
                                  }
                                  onFocus={() => getSupplier("")}
                                  showSearch
                                  onSearch={(value) => getSupplier(value)}
                                  filterOption={false}
                                  value={{
                                    key: data.supplier_id,
                                    label: data.supplier_label,
                                  }}
                                >
                                  {data.suppliers &&
                                    data.suppliers.map((supplier) => {
                                      return (
                                        <Option
                                          value={supplier._id}
                                          key={supplier._id}
                                        >
                                          {`${supplier.code} - ${supplier.name}`}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </Col>
                            </Form.Item>
                            <Form.Item
                              label="PO Number"
                              validateStatus={
                                error && error.po_number ? "error" : false
                              }
                              help={
                                error && error.po_number
                                  ? error.po_number[0]
                                  : false
                              }
                            >
                              <Input
                                value={data.po_number}
                                onChange={(e) =>
                                  handleChange("po_number", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              validateStatus={
                                error && error.date ? "error" : false
                              }
                              help={
                                error && error.date
                                  ? error.date[0]
                                  : false
                              }
                              label="Date"
                            >
                              <DatePicker
                                value={data.date}
                                picker="month"
                                onChange={(value) => {
                                  handleChange("date", value);
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}></Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Detail Purchasing PO"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add Detail Purchasing PO
                        </Button>

                        <Modal
                          title={
                            selectedId
                              ? "Edit Detail Purchasing PO"
                              : "Add Detail Purchasing PO"
                          }
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          validateStatus={
                                            error && error.material_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.material_code
                                              ? error.material_code[0]
                                              : false
                                          }
                                          label="Material Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelectDetail(
                                                "material",
                                                value
                                              )
                                            }
                                            onFocus={() => getItem("")}
                                            showSearch
                                            loading={loadItem}
                                            onSearch={(value) => getItem(value)}
                                            filterOption={false}
                                            value={{
                                              value: dataPurchasing.material_id,
                                              label:
                                                dataPurchasing.material_label,
                                            }}
                                          >
                                            {dataPurchasing.materials &&
                                              dataPurchasing.materials.map(
                                                (item_material) => {
                                                  return (
                                                    <Option
                                                      value={item_material._id}
                                                      key={item_material._id}
                                                    >
                                                      {`${item_material.item_code} - ${item_material.item_name}`}
                                                      {/* {item_material.item_code} */}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.parts_no
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.parts_no
                                              ? error.parts_no[0]
                                              : false
                                          }
                                          label="Parts No"
                                        >
                                          <Input
                                            disabled
                                            value={dataPurchasing.parts_no}
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "parts_no",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.parts_name
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.parts_name
                                              ? error.parts_name[0]
                                              : false
                                          }
                                          label="Parts Name"
                                        >
                                          <Input
                                            disabled
                                            value={dataPurchasing.parts_name}
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "parts_name",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.qty_po
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.qty_po
                                              ? error.qty_po[0]
                                              : false
                                          }
                                          label="Qty PO"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.qty_po}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("qty_po", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.material_quantity
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.material_quantity
                                              ? error.material_quantity[0]
                                              : false
                                          }
                                          label="Material Qty"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.material_quantity}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("material_quantity", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.ppc_mps
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.ppc_mps
                                              ? error.ppc_mps[0]
                                              : false
                                          }
                                          label="PPC MPS"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.ppc_mps}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail("ppc_mps", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.unit_price
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.unit_price
                                              ? error.unit_price[0]
                                              : false
                                          }
                                          label="Unit Price"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.unit_price}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "unit_price",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.amount
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.amount
                                              ? error.amount[0]
                                              : false
                                          }
                                          label="Total Amount"
                                        >
                                          <AntNumberFormat
                                            disabled
                                            value={
                                              dataPurchasing.qty_po *
                                              dataPurchasing.unit_price
                                            }
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "amount",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n1
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n1
                                              ? error.forecast_n1[0]
                                              : false
                                          }
                                          label="Forecast N+1"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n1}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "forecast_n1",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n2
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n2
                                              ? error.forecast_n2[0]
                                              : false
                                          }
                                          label="Forecast N+2"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n2}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "forecast_n2",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.forecast_n3
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.forecast_n3
                                              ? error.forecast_n3[0]
                                              : false
                                          }
                                          label="Forecast N+3"
                                        >
                                          <AntNumberFormat
                                            value={dataPurchasing.forecast_n3}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDetail(
                                                "forecast_n3",
                                                e
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    {console.log(purchasingDetail, "purchasingDetail")}
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        size="small"
                        columns={columns}
                        scroll={{ x: 1200 }}
                        dataSource={purchasingDetail}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                        pagination={{
                          pageSize: 10,
                          current: page,
                          total: purchasingDetail.total,
                          showSizeChanger: false,
                        }}
                        loading={loading}
                        summary={(pageData) => {
                          let grandTotalAmount = 0;
                          pageData.forEach(
                            ({
                              amount,
                            }) => {                
                              grandTotalAmount += amount;
                            }
                          );

                          setTotalAmount(grandTotalAmount);
                          console.log(data, grandTotalAmount, 'ini data')
                        }}
                      />
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreatePurchasingPO;
