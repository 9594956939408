import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

function BeginStock() {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    item_code: "",
    month: "",
    year: "",
    begin_stock: "",
    file: null,
  });
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/begin-stock`, {
      params: {
        keyword,
        columns: ["item_code", "month", "year", "begin_stock"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/begin-stock/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/begin-stock/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Begin Stock" + ".xlsx");
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/begin-stock/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Month",
      width: 150,
      dataIndex: "month",
      sorter: false,
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: <div style={{ textAlign: 'left' }}>Year</div>,
      width: 150,
      align: "right",
      dataIndex: "year",
      sorter: false,
    },
    {
      title: "Item Code",
      width: 150,
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Begin Stock</div>,
      align: "right",
      width: 150,
      sorter: false,
      render: (data) => {
        return <AntNumberFormat value={data.begin_stock} type="text" />;
      },
    },
    {
      title: "Created By",
      width: 120,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 120,
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      width: 120,
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      width: 120,
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Begin Stock"
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/begin-stock-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button type="primary" onClick={showModal}>
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                    <Modal
                      title="Import"
                      visible={isModalVisible}
                      footer={null}
                      onCancel={closeModal}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Fragment>
                              <Upload
                                onRemove={handleRemove}
                                beforeUpload={handleBeforeUpload}
                                fileList={[]}
                              >
                                <Button style={{ marginBottom: 10 }}>
                                  Choose File <ExportOutlined />
                                </Button>
                              </Upload>
                              {data.file && (
                                <React.Fragment
                                  children={[
                                    <Button
                                      onClick={handleRemove}
                                      type="danger"
                                      ghost
                                    >
                                      <DeleteOutlined />
                                    </Button>,
                                  ]}
                                >
                                  {data.file.name ? data.file.name : data.file}
                                </React.Fragment>
                              )}
                            </Fragment>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                                backgroundColor: "#1b2086",
                                borderColor: "#1b2086",
                              }}
                            >
                              Upload
                            </Button>
                            <Button htmlType="reset" onClick={handleRemove}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    size="small"
                    scroll={{ x: 850 }}
                    columns={columns}
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default BeginStock;
