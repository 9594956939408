/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import "./Shift.less";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Shift() {
  const [keyword, setKeyword] = useState("");
  const [shifts, setShift] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/shift`, {
      params: {
        keyword,
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setShift(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/shift/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Start",
      dataIndex: "start",
      sorter: false,
    },
    {
      title: "End",
      dataIndex: "end",
      sorter: false,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/shift-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Shift"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/shift-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={shifts.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: shifts.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Shift;
