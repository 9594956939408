/* eslint-disable */
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Menu,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  BarcodeOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

function WarehouseOutgoing() {
  const [warehouseReceiving, setWarehouseReceiving] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [dataHistory, setDataHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [data, setData] = useState({
    kanban_number: "",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  useEffect(() => {
    getDataHistory();
  }, [keyword, page, sort]);

  const scanNumberRef = useRef(null);

  const getData = () => {
    //setLoading(true);
    //cek barcode apakah sudah di scan
    let type = "not input";
    warehouseReceiving.map((datanya) => {
      // return (scannn = datanya.scanned_barcode);
      if (datanya.dn_number === data.kanban_number) {
        message.error("DN Number Already Inputed");
        setData({
          ...data,
          kanban_number: "",
        });
        type = "input";
      }
    });

    if (type === "not input") {
      Axios.get(`${url}/warehouse-outgoing/scan`, {
        params: {
          kanban_number: data.kanban_number,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setWarehouseReceiving([...warehouseReceiving, res.data.data]);
          scanNumberRef.current.focus();
          console.log("dataa", res.data.data);
          setData([]);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status == 401) {
              localStorage.removeItem("token");
            }
          }
        });
    }
  };

  const handleReset = () => {
    setData([]);
    setWarehouseReceiving([]);
  };

  console.log("w", warehouseReceiving);

  const onSaveData = () => {
    setLoading(true);

    Axios.post(
      `${url}/warehouse-outgoing`,
      {
        details: warehouseReceiving,
      },
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getDataHistory();
        setData([]);
        setWarehouseReceiving([]);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
          setLoading(false);
        } else {
          message.error("Backend not yet Started");
        }
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleRemoveReceiving = (id) => {
    console.log(id);
    const filter = warehouseReceiving.filter((row) => row._id !== id);
    setWarehouseReceiving(filter);
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/warehouse-receive-scan/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getDataHistory = () => {
    setLoadingHistory(true);
    Axios.get(`${url}/warehouse-outgoing`, {
      params: {
        keyword,
        columns: ["unit", "dn_number", "created_by", "item_code"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDataHistory(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoadingHistory(false);
      });
  };

  const columnsHistory = [
    {
      title: "Kanban Number",
      width: 200,
      dataIndex: "dn_number",
      sorter: false,
    },
    {
      title: "Item Code",
      width: 200,
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: "Item Name",
      width: 200,
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: 'left' }}>Quantity</div>,
      width: 200,
      align: "right",
      sorter: false,
      render: (data) => {
        return <AntNumberFormat value={data.quantity} type="text" />;
      },
    },
    {
      title: "Unit",
      width: 200,
      dataIndex: "unit",
      sorter: false,
    },
    {
      title: "Created By",
      width: 200,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 200,
      dataIndex: "created_at",
      sorter: false,
      render: (date) => moment(date).format("DD MMM YYYY H:m:s"),
    },
  ];

  const columns = [
    {
      title: "Kanban Number",
      width: 200,
      dataIndex: "dn_number",
      sorter: false,
    },
    {
      title: "Item Code",
      width: 200,
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Quantity</div>,
      width: 150,
      align: "right",
      // dataIndex: "qty_kanban",
      sorter: false,
      render: (data) => {
        return (
          <AntNumberFormat
            //decimalScale={data.currency_name}
            value={data.quantity}
            type="text"
          />
        );
      },
    },
    // {
    //   title: "Option",
    //   dataIndex: "option",
    //   render: (option, warehouseReceiving) => (
    //     <Button
    //       onClick={() =>
    //         handleRemoveReceiving(warehouseReceiving.finish_goods_id)
    //       }
    //       size="small"
    //       type="danger"
    //     >
    //       <DeleteOutlined />
    //       Delete
    //     </Button>
    //   ),
    // },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id, warehouseReceiving) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  {/* <Menu.Item key="1">
                    <Link to={`/master-item-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item> */}

                  <Menu.Item key="2">
                    <span
                      onClick={() =>
                        handleRemoveReceiving(warehouseReceiving._id)
                      }
                    >
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  console.log(warehouseReceiving, "warehouseReceiving");

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Receiving Out"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24}>
                  <Space>
                    Kanban Number :
                    <Input
                      onPressEnter={(e) => getData(e, "kanban_number")}
                      type="primary"
                      prefix={<BarcodeOutlined />}
                      style={{ width: 300 }}
                      onChange={(e) =>
                        handleChange("kanban_number", e.target.value)
                      }
                      value={data.kanban_number}
                      ref={scanNumberRef}
                    />
                  </Space>
                </Col>
                {/* <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col> */}

                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={warehouseReceiving}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col style={{ marginTop: 20 }}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={onSaveData}
                      loading={loading}
                      htmlType="submit"
                      className="mr-button"
                      // disabled={!data.data}
                    >
                      Submit
                    </Button>

                    <Button
                      onClick={handleReset}
                      type="default"
                      htmlType="button"
                      className="mr-button"
                    >
                      Reset
                    </Button>
                  </Space>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columnsHistory}
                    dataSource={dataHistory}
                    rowKey="_id"
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loadingHistory}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default WarehouseOutgoing;
