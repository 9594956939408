/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function WhStockOpname() {
  const getItems = () => {
    const columnsDetail = [
      {
        title: "Item Code",
        dataIndex: "item_code",
        sorter: true,
      },
      {
        title: "Item Name",
        dataIndex: "item_name",
        sorter: false,
      },
      {
        title: "Qty Sistem",
        dataIndex: "stock_before_sto",
        sorter: false,
      },
      {
        title: "Qty Actual",
        dataIndex: "stock_after_sto",
        sorter: false,
      },
      {
        title: "Notes",
        dataIndex: "notes",
        sorter: false,
      },
    ];

    return (
      <div>
        <Table
          size="large"
          columns={columnsDetail}
          rowKey={(data, index) => index}
          loading={loadingItems}
          dataSource={manifestItems}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: manifestItemsTotal,
            showSizeChanger: true,

            style: {
              marginTop: "2rem",
              marginBottom: 0,
            },
          }}
          onChange={handleChangeItemsTable}
        />
      </div>
    );
  };

  const [keyword, setKeyword] = useState("");
  const [stockopname, setStockOpname] = useState([]);
  const [loading, setLoading] = useState(false);

  const [loadingItems, setLoadingItems] = useState(false);
  const [manifestItems, setManifestItems] = useState([]);
  const [manifestItemsTotal, setManifestItemsTotal] = useState(0);
  const [rowItems, setRowItems] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [keywordItems, setKeywordItems] = useState("");

  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/stock-opname`, {
      params: {
        keyword,
        columns: [],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setStockOpname(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/stockopname/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: false,
      render: (date) => moment(date).format("DD MMM YYYY H:m:s"),
      // align: "center",
    },
    {
      title: "Item Not Balance",
      dataIndex: "item_not_balance",
      sorter: false,
      // align: "center",
    },
    {
      title: "Evidence",
      dataIndex: "photo_url",
      key: "photo_url",
      sorter: false,
      // width: 100,
      render: (photo_url) =>
        photo_url && (
          <img style={{ width: 120, height: 100 }} src={photo_url} />
        ),
    },
  ];

  const handleChangeItems = (data) => {
    const index = data[data.length - 1];
    setRowItems([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getManifestItems(data2[0]);
    }
  };

  const getManifestItems = (id = selectedId) => {
    setLoadingItems(true);
    Axios.get(`${url}/stock-opname/details/${id}`, {
      params: {
        keyword: keywordItems,
        columns: [
          "warehouse_name",
          "lemari_name",
          "rack_name",
          "item_name",
          "stock_min",
          "stock_max",
          "quantity",
          "quantity_adjudgement",
          "notes",
        ],
        perpage: perPageDetail,
        page: pageDetail,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setManifestItems(res.data.data);
        setManifestItemsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet started");
        }
      })
      .finally(() => {
        setLoadingItems(false);
      });
  };

  const handleChangeItemsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  // if (!localStorage.getItem('token')) {
  //     return <Redirect to="/login" />
  // }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Adjustment Stock Warehouse"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/wh-stock-opname-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    expandable={{
                      expandedRowRender: getItems,
                      onExpandedRowsChange: (data) => handleChangeItems(data),
                      expandedRowKeys: rowItems,
                    }}
                    columns={columns}
                    dataSource={stockopname.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: stockopname.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default WhStockOpname;
