/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Radio,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import moment from "moment";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateCalendar(props) {
  const [data, setData] = useState({
    date: "",
    month: "",
    year: "",
    production: "",
    notes: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([]);
  const [month, setMonth] = useState([]);

  useEffect(() => {
    getDate();
  }, []);

  const getDate = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "date",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDate(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });

      await Axios.get(`${url}/settings/find`, {
        params: {
          keyword,
          variable: "month",
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setMonth(res.data.data);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status === 401) {
              localStorage.removeItem("token");
            }
          }
        });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("date", data.date);
    fd.set("month", data.month);
    fd.set("year", data.year);
    fd.set("production", data.production);
    fd.set("notes", data.notes);

    Axios.post(`${url}/calendar`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-calendar");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      date: "",
      month: "",
      year: "",
      production: "",
      notes: "",
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card type="inner" title="Master Calendar">
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-calendar">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Master Calendar</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Date"
                  validateStatus={error && error.date ? "error" : false}
                  help={error && error.date ? error.date[0] : false}
                >
                  <Select
                    placeholder="Select Date"
                    onChange={(value) => handleChange("date", value)}
                    style={{ width: '150px' }}
                  >
                    {date &&
                      date.map((item) => {
                        return (
                          <Option key={item.id} value={item.name}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Month"
                  validateStatus={error && error.month ? "error" : false}
                  help={error && error.month ? error.month[0] : false}
                >
                  <Select
                    placeholder="Select Month"
                    onChange={(value) => handleChange("month", value)}
                    style={{ width: '150px' }}
                  >
                    {month &&
                      month.map((item) => {
                        return (
                          <Option key={item.id} value={item.name}>
                            {moment(item.name).format('MMMM')}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Year"
                  validateStatus={error && error.year ? "error" : false}
                  help={error && error.year ? error.year[0] : false}
                >
                  <DatePicker
                    picker="year"
                    value={data.year}
                    onChange={(value) => handleChange("year", value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Production"
                  validateStatus={error && error.production ? "error" : false}
                  help={error && error.production ? error.production[0] : false}
                >
                  <Radio.Group
                    onChange={(e) => handleChange("production", e.target.value)}
                    value={data.production}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  required
                  label="Notes"
                  validateStatus={error && error.notes ? "error" : false}
                  help={error && error.notes ? error.notes[0] : false}
                >
                  <Input
                    value={data.notes}
                    onChange={(e) => handleChange("notes", e.target.value)}
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateCalendar;
