/* eslint-disable */ 
import React, { useState, useEffect, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Row,Col,Breadcrumb,Button,Input,Table,Modal, message, Space, Avatar} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import {url} from '../../config/global'
import { UserOutlined, MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined, ReloadOutlined} from '@ant-design/icons'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function WhReceivingSupplier() {

    const [keyword, setKeyword] = useState('')
    const [rowDetails, setRowDetails] = useState([]);
    const [poList, setPoList] = useState([])
    const [whReceivingSupplierDataDetails, setWhReceivingSupplierDataDetails] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loadingDetail, setLoadingDetail] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'full_name',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/po-list-supplier`, {
            params: {
                keyword,
                columns: [
                    'po_number',
                    'tanggal_terbit',
                    'tanggal_req_pengiriman',
                    'status',
                    'total'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setPoList(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            } 
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {

        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {

        setLoading(true)
        Axios.delete(`${url}/po-list-supplier/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            } 
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleTableChange = (pagination, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    
    const handleChangeDetails = (data) => {
        const index = data[data.length - 1];
        setRowDetails([index]);
        if (index) {
          const data2 = index.split(";");
          setSelectedId(data2[0]);
          getWhReceivingSupplierDetails(data2[0]);
        }
      };

    const getWhReceivingSupplierDetails = (id = selectedId) => {
        setLoadingDetail(true);
        Axios.get(`${url}/wh-receiving-supplier/details/${id}`, {
          params: {
            // keyword: keywordDetails,
            columns: [
                "material_id",
                "material_name",
                "qty",
                "unit",
                "price",
                "currency",
                "sub_total",
                "ppn",
                "total"
            ],
            perpage: perPageDetail,
            page: pageDetail,
            sort: sortDetail.sort,
            order: sortDetail.order,
          },
    
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            setWhReceivingSupplierDataDetails(res.data);
            // setDataProductionDetailsTotal(res.data.total);
          })
          .catch((err) => {
            if (err.response) {
              message.error(err.response.data.message);
            } else {
              message.error("Backend not yet Started");
            }
          })
          .finally(() => {
            setLoadingDetail(false);
          });
      };

        const handleChangeDetailsTable = (pagination, filter, sorter) => {
            setPageDetail(pagination.current);
            setSortDetail({
            sort: sorter.field,
            order: sorter.order,
            });
            setPerPageDetail(pagination.pageSize);
        };

    const whReceivingSupplierDetails = () => {
        const columnsReceivingSupplier = [
            {
                title: 'Material ID',
                dataIndex: 'material_id',
                sorter: true,
            },
            {
                title: 'Material Name',
                dataIndex: 'material_name',
                sorter: false,
            },
            {
                title: 'Qty',
                dataIndex: 'qty',
                sorter: false,
            },
            {
                title: 'Unit',
                dataIndex: 'unit',
                sorter: false,
            },
            {
                title: 'Price',
                dataIndex: 'price',
                sorter: false,
            },
            {
                title: 'Currency',
                dataIndex: 'currency',
                sorter: false,
            },
            {
                title: 'Sub Total',
                dataIndex: 'sub_total',
                sorter: false,
            },
            {
                title: 'PPN',
                dataIndex: 'ppn',
                sorter: false,
            },
            {
                title: 'Total',
                dataIndex: 'total',
                sorter: false,
            },
            {
                title: '',
                dataIndex: '_id',
                align: 'right',
                sorter: false,
                fixed: 'right',
                width: 100,
                  render: (id) => {
      
                      return (
                           <Fragment>
                              <Dropdown className="pointer" overlay={
                                   <Menu>
                                        <Menu.Item key="1">
                                            <Link 
                                            >
                                              <EditOutlined />QR Code
                                            </Link>
                                        </Menu.Item>
                                        <Menu.Item key="3">
                                            <span onClick={() => handleDelete(id) }><DeleteOutlined />Delete</span>
                                        </Menu.Item>
                                  </Menu>
                              } trigger={['click']}>
                                  <span className="pointer" onClick={e => e.preventDefault()}>
                                      <MoreOutlined />
                                  </span>
                              </Dropdown>
                          </Fragment>
                      )
                  }
              }
        ]
    
        return (
          <>
            <Row>
              <Col xs={24}>
                <Table
                  scroll={{ x: 350 }}
                  style={{ marginTop: 25, marginBottom: 25, marginRight: 30 }}
                  columns={columnsReceivingSupplier}
                  rowKey={(data) => data._id}
                  loading={loadingDetail}
                  dataSource={whReceivingSupplierDataDetails}
                  pagination={{
                    pageSize: perPageDetail,
                    current: pageDetail,
                    total: 10,
                    showSizeChanger: true,
                  }}
                  onChange={handleChangeDetailsTable}
                  size="small"
                />
              </Col>
            </Row>
          </>
        );
      };


    const columns = [
        {
            title: 'PO Number',
            dataIndex: 'po_number',
            sorter: false,
        },
        {
            title: 'Tanggal Terbit',
            dataIndex: 'tanggal_terbit',
            sorter: true
        },
        {
            title: 'Tanggal Req Pengiriman',
            dataIndex: 'tanggal_req_pengiriman',
            sorter: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true
        },
        {
            title: 'Totall',
            dataIndex: 'total',
            sorter: true
        },
    ]

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }
    
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Master Price" bodyStyle={{padding: "0"}} extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                                
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                    <Col xs={24} md={12} lg={18}>
                                        <Space>
                                            <Button type="primary"><PlusOutlined />Add</Button>
                                        </Space>
                                    </Col>
                                    <Col xs={24} md={12} lg={6} className="align-right">
                                        <Search
                                            placeholder="Search ..."
                                            onSearch={(value) => handleSearch(value)}
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <Table
                                        scroll={{ x: 500 }}
                                        columns={columns}
                                        expandable={{
                                            expandedRowRender: whReceivingSupplierDetails,
                                            onExpandedRowsChange: (data) => handleChangeDetails(data),
                                            expandedRowKeys: rowDetails,
                                          }}
                                        dataSource={poList.data}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{ pageSize: 10, current: page, total: poList.total, showSizeChanger: false }}
                                        loading={loading}
                                        />   
                                    </Col>
                            </Row>
                               
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}

export default WhReceivingSupplier