/* eslint-disable */ 
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Typography, Layout, Input, Button, Select, DatePicker, Modal, Upload } from 'antd'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Dash_inven.less'
import './Dashboard.less'

const { Content } = Layout
const { Meta } = Card;
const { Search } = Input;
const { Dragger } = Upload;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Dash_inven() {

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    // const [date, setDate] = useState(moment('2021-06-17').format('YYYY-MM-DD'));

    const [inventory, setInventory] = useState([]);
    const [inventoryExport, setInventoryExport] = useState([]);

    const [inventoryPlan, setInventoryPlan] = useState([]);
    const [inventoryPlanExport, setInventoryPlanExport] = useState([]);
    
    const [filterType, setFilterType] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [groupBy, setGroupBy] = useState('customer');

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    //#region Get Data

        //#region Inventory Data
        useEffect(() => {

            getData(date, filterType, filterValue, groupBy)
            getDataExport(date, filterType, filterValue)

            if (!loading){

                let rotationInterval = setInterval(() => {
                    getData(date, filterType, filterValue, groupBy)
                }, 15000);

                return () => {
                    clearInterval(rotationInterval);
                }
                
            }

        }, [date, filterType, filterValue, groupBy])

        const getData = async () => {

            setLoading(true)

            const results = await Axios.get(`${url}/inventory`, {

                params: {
                    date, filterType, filterValue, groupBy
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })

            const res = results.data;
            setInventory(res);
            setLoading(false)

        }

        const getDataExport = async() => {

            const results = await Axios.get(`${url}/inventory`, {

                params: {
                    date, filterType, filterValue, groupBy: 'part_no', Export: 1
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                setInventoryExport(res.data.data)
        
            })

        }
        //#endregion

        //#region Inventory Data Plan
        useEffect(() => {

            getDataPlan(date, filterType, filterValue, groupBy)
            getDataPlanExport(date, filterType, filterValue)

            if (!loading){

                let rotationInterval = setInterval(() => {
                    getDataPlan(date, filterType, filterValue, groupBy)
                }, 15000);

                return () => {
                    clearInterval(rotationInterval);
                }
                
            }

        }, [date, filterType, filterValue, groupBy])

        const getDataPlan = async () => {

            setLoading(true)

            const results = await Axios.get(`${url}/inventoryh`, {

                params: {
                    date, filterType, filterValue, groupBy
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })

            const res = results.data;
            setInventoryPlan(res);
            setLoading(false)

        }

        const getDataPlanExport = async() => {

            const results = await Axios.get(`${url}/inventoryh`, {

                params: {
                    date, filterType, filterValue, groupBy: 'part_no', Export: 1
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                setInventoryPlanExport(res.data.data)
        
            })

        }
        //#endregion
        
    //#endregion

    //#region Olah Data
    let ChartLabel = [];
    let ChartData = [];
    let ChartDataMin = [];
    let ChartDataMax = [];

    for (let i = 0; i < inventoryPlan.length; i++) {
        
        let qty = 0;
        let label = '';

        for (let x = 0; x < inventory.length; x++) {
            
            if ( inventory[x][groupBy] == inventoryPlan[i][groupBy] ){

                qty = qty + inventory[x].qty / inventoryPlan[i].standard
                label = inventory[x][groupBy];

            }
            
        }

        if (label != '' && qty != 0){
            ChartLabel.push(label);
            ChartData.push(qty.toFixed(1));
            ChartDataMin.push(inventoryPlan[i].min_stock);
            ChartDataMax.push(inventoryPlan[i].max_stock);
        }
        
    }

    let DataExcel = [];
    

    for (let i = 0; i < inventoryPlanExport.length; i++) {

        let qty = 0;

        for (let x = 0; x < inventoryExport.length; x++) {
            
            if ( inventoryExport[x].part_no == inventoryPlanExport[i].part_no ){
    
                qty = qty + inventoryExport[x].qty / inventoryPlanExport[i].standard
    
                let tempObj = {
                    scanin: inventoryExport[x].qtyProd,
                    scanout: inventoryExport[x].qtyInven,
                    part_no: inventoryExport[x].part_no,
                    comodity: inventoryExport[x].comodity,
                    customer: inventoryExport[x].customer,
                    date: inventoryExport[x].prod_dt,
                    balance: inventoryExport[x].qty,
                    hasil: qty.toFixed(1),
                }
                DataExcel.push(tempObj);
    
            }
            
        }

    }
    //#endregion

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    const ModalBtn = (
        <div
            style={{
                display: 'flex'
            }}
        >
            <div>
                <ExcelFile element={<Button type="primary">Export Data</Button>} filename={`Inventory-${moment(new Date()).format('YYYYMMDD')}`}>
                    <ExcelSheet data={DataExcel} name={`Data`}>
                        <ExcelColumn label="Customer" value="customer" />
                        <ExcelColumn label="Comodity" value="comodity" />
                        <ExcelColumn label="Part Number" value="part_no" />
                        <ExcelColumn label="Date" value="date"/>
                        <ExcelColumn label="Scan In" value="scanin"/>
                        <ExcelColumn label="Scan Out" value="scanout"/>
                        <ExcelColumn label="Balance" value="balance"/>
                        <ExcelColumn label="Hasil" value="hasil"/>
                    </ExcelSheet>
                </ExcelFile>
            </div>
        </div>
    )

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" className="title-dashboard" title="Dashboard Inventory" bodyStyle={{padding: "0"}} extra={ModalBtn}></Card>
                </Col>
            </Row>
            <Content className="content">
                <Row gutter={[16, 20]}>
                    <Col sm={24} className="main-body">
                        <Card>
                            <div className="header-data">
                                <DatePicker
                                    defaultValue={moment(date, 'YYYY-MM-DD')}
                                    onChange={(value) => setDate(moment(value).format('YYYY-MM-DD'))}
                                    inputReadOnly={true}
                                    format={'YYYY-MM-DD'}
                                    allowClear={false}
                                />
                                <div className="header-button">
                                    <Button onClick={() => setGroupBy('customer')}>Customer</Button>
                                    <Button onClick={() => setGroupBy('comodity')}>Comodity</Button>
                                    <Button onClick={() => setGroupBy('part_no')}>Part Number</Button>
                                </div>
                                <form className="header-filter">
                                    <Select
                                        showSearch
                                        style={{ width: 200, marginRight: 4 }}
                                        placeholder="Select Filter"
                                        optionFilterProp="children"
                                        onChange={(value) => setFilterType(value)}
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        <Option value="customer">Customer</Option>
                                        <Option value="comodity">Comodity</Option>
                                        <Option value="part_no">Part Number</Option>
                                    </Select>
                                    <Search
                                        style={{ width: 200 }}
                                        placeholder="Kata Kunci"
                                        onSearch={(value) => setFilterValue(value)}
                                        enterButton
                                    />
                                </form>
                            </div>
                            <div>
                                {
                                    !loading ? (<Bar
                                        data={{
                                            labels: ChartLabel,
                                            datasets: [
                                                {
                                                    label: 'Stock of days',
                                                    data: ChartData,
                                                    backgroundColor: 'rgba(0, 0, 255, .7)',
                                                    borderColor: 'rgba(0, 0, 255, 1)',
                                                    borderWidth: 1
                                                },
                                                {
                                                    label: 'Min Stock',
                                                    data: ChartDataMin,
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'rgba(255, 0, 0, 1)',
                                                    borderWidth: 1,
                                                    type: 'line'
                                                },
                                                {
                                                    label: 'Max Stock',
                                                    data: ChartDataMax,
                                                    backgroundColor: 'transparent',
                                                    borderColor: 'rgba(0, 255, 0, 1)',
                                                    borderWidth: 1,
                                                    type: 'line'
                                                }
                                            ]
                                        }}
                                        options={{
                                            scales: {
                                                xAxes: [
                                                    {
                                                        gridLines: {
                                                            display: false
                                                        },
                                                    },
                                                ]
                                            },
                                        }}
                                    />

                                    ) : (<div className="loading"><FontAwesomeIcon icon={faSpinner} className="spinner" size='2x' pulse /></div>)
                                }
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default Dash_inven