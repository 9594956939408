/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Radio,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import moment from "moment";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditLocation(props) {
  const [data, setData] = useState({
    code: "",
    name: "",
    type: "",
    type_id: "",
    rack: "",
    active: "",
    outsourching_name: "",
    outsourching_label: "",
    process: [],
    process_id: "",
    process_name: "",
    process_label: "",
    outsourching: [],
  });

  const [types, setTypes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    Axios.get(`${url}/location/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        getType();
        setData({
          ...data,
          code: res.data.data.code,
          name: res.data.data.name,
          type: res.data.data.type,
          process_name: res.data.data.process_name,
          process_label: res.data.data.process_name,
          outsourching_label: res.data.data.outsourching_name,
          outsourching_name: res.data.data.outsourching_name,
          rack: res.data.data.rack,
          active: res.data.data.active,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("code", data.code);
    fd.set("name", data.name);
    fd.set("type", data.type);
    fd.set("process_name", data.process_name);
    fd.set("outsourching_name", data.outsourching_name);
    fd.set("rack", data.rack);
    fd.set("active", data.active);

    Axios.post(`${url}/location/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-location");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      code: "",
      name: "",
      type: "",
      type_id: "",
      process_name: "",
      outsourching_name: "",
      rack: "",
      active: "",
      outsourching_name: "",
      outsourching_label: "",
      process: [],
      process_id: "",
      process_name: "",
      process_label: "",
      outsourching: [],
    });
  };

  const getType = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Type",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getOutsourching = async (keyword) => {
    await Axios.get(`${url}/supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          outsourching: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getProcess = async (keyword) => {
    await Axios.get(`${url}/process/list`, {
      params: {
        keyword,
        // columns: ["process_code", "process_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          process: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "process") {
      const find = data.process.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.process_name,
      });
    } else if (name === "outsourching") {
      const find = data.outsourching.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.name,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }

    console.log(name, value, find.name);
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Location"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-location">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Master Location</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Code"
                  validateStatus={error && error.code ? "error" : false}
                  help={error && error.code ? error.code[0] : false}
                >
                  <Input
                    value={data.code}
                    onChange={(e) => handleChange("code", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Name"
                  validateStatus={error && error.name ? "error" : false}
                  help={error && error.name ? error.name[0] : false}
                >
                  <Input
                    value={data.name}
                    onChange={(e) => handleChange("name", e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  required
                  label="Type"
                  validateStatus={error && error.type ? "error" : false}
                  help={error && error.type ? error.type[0] : false}
                >
                  <Select
                    onChange={(value) => handleChange("type", value)}
                    value={data.type}
                  >
                    {types &&
                      types.map((type) => {
                        return (
                          <Option value={type.name} key={type._id}>
                            {type.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Process Name"
                  validateStatus={error && error.process_name ? "error" : false}
                  help={
                    error && error.process_name ? error.process_name[0] : false
                  }
                >
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("process", value)}
                    onFocus={() => getProcess("")}
                    showSearch
                    onSearch={(value) => getProcess(value)}
                    filterOption={false}
                    value={{
                      key: data.process_id,
                      label: data.process_label,
                    }}
                  >
                    {data.process &&
                      data.process.map((proces) => {
                        return (
                          <Option value={proces._id} key={proces._id}>
                            {`${proces.process_code} - ${proces.process_name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Outsourching Name"
                  validateStatus={
                    error && error.outsourching_name ? "error" : false
                  }
                  help={
                    error && error.outsourching_name
                      ? error.outsourching_name[0]
                      : false
                  }
                >
                  <Select
                    labelInValue
                    onChange={(value) =>
                      handleChangeSelect("outsourching", value)
                    }
                    onFocus={() => getOutsourching("")}
                    showSearch
                    onSearch={(value) => getOutsourching(value)}
                    filterOption={false}
                    value={{
                      key: data.outsourching_id,
                      label: data.outsourching_label,
                    }}
                  >
                    {data.outsourching &&
                      data.outsourching.map((outsourchings) => {
                        return (
                          <Option
                            value={outsourchings._id}
                            key={outsourchings._id}
                          >
                            {`${outsourchings.code} - ${outsourchings.name}`}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  validateStatus={error && error.rack ? "error" : false}
                  help={error && error.rack ? error.rack[0] : false}
                  label="Rack"
                >
                  <AntNumberFormat
                    defaultValue="0"
                    value={data.rack}
                    type="input"
                    onChange={(e) => handleChange("rack", e)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Active"
                  validateStatus={error && error.active ? "error" : false}
                  help={error && error.active ? error.active[0] : false}
                >
                  <Radio.Group
                    onChange={(e) => handleChange("active", e.target.value)}
                    value={data.active}
                  >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditLocation;
