import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  notification,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  PlusOutlined,
  ExportOutlined,
  DeleteOutlined,
  ImportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;

function Bom() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState({
    file: null,
  });
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [boms, setBoms] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [rowDetails, setRowDetails] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [bomDetails, setBomDetails] = useState([]);
  const [bomDetailsTotal, setBomDetailsTotal] = useState(0);

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getBomDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      localStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getBomDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/bom`, {
      params: {
        keyword,
        columns: ["part_number", "part_name"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBoms(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getBomDetails(data2[0]);
    }
  };

  const getBomDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/bom/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setBomDetails(res.data.data);
        setBomDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/bom/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/bom/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          notification.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          // message.error(`Error!: ${err.response.data.message}
          // \n\r
          // Error Details: ${errorsKey.map((error) => {
          //   return errors[error][0] + "\n\r";
          // })}
          // `);
          // if (err.response.status === 422) {
          //   setError(err.response.data.errors);
          // }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const exportBom = () => {
    Axios.get(`${url}/bom/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master BOM" + ".xlsx");
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/master-bom/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master BOM" + ".xlsx");
    });
  };

  const getBillOfMaterialDetails = () => {
    const columns = [
      {
        title: "Item Code",
        dataIndex: "item_code",
        align: "left",
        key: "item_code",
        sorter: true,
        width: 100,
      },
      {
        title: "Item Name",
        dataIndex: "item_name",
        align: "left",
        width: 100,
        key: "item_name",
      },
      {
        title: "Item Category",
        dataIndex: "item_category",
        align: "left",
        width: 100,
        key: "item_category",
      },
      {
        title: "Spec",
        dataIndex: "spec",
        align: "left",
        width: 50,
        key: "spec",
      },
      {
        title: "Unit",
        dataIndex: "unit",
        align: "left",
        width: 50,
        key: "unit",
      },
      {
        title: <div style={{ textAlign: "left" }}>Quantity</div>,
        // dataIndex: "quantity",
        width: 50,
        key: "quantity",
        align: "right",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale="DEC"
              value={data.quantity}
              type="text"
            />
          );
        },
      },
      {
        title: "Supplier",
        dataIndex: "supplier_name",
        align: "left",
        width: 150,
        key: "supplier_name",
      },
      {
        title: "Location",
        dataIndex: "location_code",
        align: "left",
        width: 100,
        key: "location_code",
      },
      {
        title: "Process",
        dataIndex: "process_name_material",
        align: "left",
        width: 100,
        key: "process_name_material",
      },
      {
        title: "Notes",
        dataIndex: "notes",
        align: "left",
        width: 200,
        key: "notes",
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col>
            {/* <Input.Search
              placeholder="Search details..."
              defaultValue={keywordDetails}
              onSearch={(value) => handleSearch(value, "details")}
            /> */}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Table
              scroll={{ x: 750 }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={bomDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: bomDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: "Part Number",
      dataIndex: "part_number",
      key: "part_number",
      align: "left",
      width: 120,
      sorter: false,
    },
    {
      title: "Part Name",
      dataIndex: "part_name",
      key: "part_name",
      width: 130,
      align: "left",
      sorter: false,
    },
    {
      title: "Process",
      dataIndex: "process_name",
      width: 150,
      align: "left",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: "left" }}>Manufactured Quantity</div>,
      align: "right",
      dataIndex: "manufactured_quantity",
      width: 150,
      sorter: false,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      sorter: false,
      align: "left",
      width: 150,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: false,
      align: "left",
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
      width: 150,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      key: "updated_by",
      sorter: false,
      align: "left",
      width: 150,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      sorter: false,
      align: "left",
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
      width: 150,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span>
                      <Link to={`/master-bom-edit-${id}`}>
                        <EditOutlined /> Edit
                      </Link>
                    </span>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import BOM"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Choose File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                }}
              >
                Upload
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master BOM"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/master-bom-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button onClick={showModal} type="primary">
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={exportBom}>
                      <ExportOutlined />
                      Export
                    </Button>
                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    bordered
                    size="small"
                    scroll={{ x: 1500 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getBillOfMaterialDetails,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    dataSource={boms.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: boms.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Bom;
