/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  DatePicker,
  Form,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function StockAdjustFG() {
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState({
    date_adjustment: "",
    part_no: "",
    month: "",
    year: "",
    qty_adjustment: 0,
    notes: "",
  });
  const [opnames, setOpnames] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "item_fg",
    order: "ascend",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date.from, date.to]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/stock-opname-fg/adjustment`, {
      params: {
        keyword,
        start_date: moment(date.from).format("YYYY-MM-DD"),
        end_date: moment(date.to).format("YYYY-MM-DD"),
        columns: ["part_no"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setOpnames(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModal = (id) => {
    const stock = opnames.data.find((opnames) => opnames._id === id);

    setSelectedId(stock._id)

    setData({
      ...data,
      date_adjustment: moment(stock.date_adjustment).format('DD-MM-YYYY'),
      month: stock.month,
      year: stock.year,
      part_no: stock.part_no,
      qty_adjustment: stock.qty_adjustment,
      notes: stock.notes,
    });

    setIsModalVisible(true);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      date: "",
      item_fg: "",
      qty_adjustment: 0,
      notes: "",
    });

    setIsModalVisible(false);
  };

  const resetModal = () => {
    setData({
      ...data,
      date: "",
      item_fg: "",
      qty_adjustment: 0,
      notes: "",
    });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();
    const id = selectedId;

    fd.set("month", data.month);
    fd.set("year", data.year);
    fd.set("date_adjustment", data.date_adjustment);
    fd.set("part_no", data.part_no);
    fd.set("qty_adjustment", data.qty_adjustment);
    fd.set("notes", data.notes);

    Axios.post(`${url}/stock-opname-fg/adjustment/${id}`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success" || res.data.type === "success") {
          message.success(res.data.message);
        }
        getData();
        // props.history.push("/stock-adjustment-fg");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });

    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Date Adjustment",
      dataIndex: "date",
      sorter: false,
      width: 150,
      render: (date) => moment(date).format("DD-MMMM-YYYY"),
    },
    {
      title: "Month",
      dataIndex: "month",
      sorter: false,
      width: 150,
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: "Year",
      dataIndex: "year",
      sorter: false,
      width: 150,
    },
    {
      title: "Item FG",
      dataIndex: "part_no",
      sorter: false,
      width: 150,
    },
    {
      title: "Qty Adjustment",
      dataIndex: "qty_adjustment",
      sorter: false,
      width: 150,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      sorter: false,
      width: 150,
    },
    {
      title: "",
      align: "center",
      sorter: false,
      fixed: "right",
      width: 180,
      render: (record) => {
        return (
          <span>
            <Button type="primary" onClick={() => handleModal(record._id)}>
              Edit
            </Button>
          </span>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Adjustment Stock Opname FG"
        visible={isModalVisible}
        width={1000}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Form {...layout} name="control-hooks" labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        required
                        validateStatus={error && error.date_adjustment ? "error" : false}
                        help={error && error.date_adjustment ? error.date_adjustment[0] : false}
                        label="Date"
                      >
                        <Input
                          disabled
                          value={data.date_adjustment}
                          onChange={(e) => {
                            handleChange("date_adjustment", e.target.value);
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        validateStatus={
                          error && error.part_no ? "error" : false
                        }
                        help={error && error.part_no ? error.part_no[0] : false}
                        label="Item FG"
                      >
                        <Input
                          disabled
                          value={data.part_no}
                          onChange={(e) => {
                            handleChange("part_no", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.qty_adjustment ? "error" : false
                        }
                        help={
                          error && error.qty_adjustment
                            ? error.qty_adjustment[0]
                            : false
                        }
                        label="Qty Adjustment"
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.qty_adjustment}
                          type="input"
                          onChange={(e) => handleChange("qty_adjustment", e)}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={error && error.notes ? "error" : false}
                        help={error && error.notes ? error.notes[0] : false}
                        label="Notes"
                      >
                        <Input
                          value={data.notes}
                          onChange={(e) => {
                            handleChange("notes", e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Submit
              </Button>
              <Button htmlType="reset" onClick={resetModal}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Stock Adjustment Finish Goods"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <RangePicker
                    onChange={handleChangeDate}
                    value={[date.from, date.to]}
                    format="DD MMM YYYY"
                    picker="date"
                  />
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 700 }}
                    columns={columns}
                    dataSource={opnames.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default StockAdjustFG;
