/* eslint-disable */ 
import React, { useState, useEffect, Fragment } from 'react'
import {Card,Typography,Menu,Dropdown,Layout, Row,Col,Button,Modal,Input,Table, message} from 'antd';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import {url} from '../../config/global'
import { MoreOutlined, EditOutlined, DeleteOutlined, PlusOutlined} from '@ant-design/icons'
//import './User.less'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout


function Role() {

    const [keyword, setKeyword] = useState('')
    const [role, setRole] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({
        sort: 'description',
        order: 'ascend'
    })

    const handleSearch = (value) => {
        setKeyword(value)
    }

    useEffect(() => {
        getData()
    }, [keyword, page, sort])

    const handleTableChange = (pagination, filters, sorter) => {
        setPage(pagination.current)
        setSort({
            sort: sorter.field,
            order: sorter.order
        })
    }

    const getData = () => {
        setLoading(true)
        Axios.get(`${url}/role`, {
            params: {
                keyword,
                columns: [
                    'name'
                ],
                perpage: 10,
                page,
                sort: sort.sort,
                order: sort.order
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            setRole(res.data)
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            } 
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = (id) => {

        Modal.confirm({
            title: 'Are you sure?',
            content: (
              <div>
                <p>Deleted data cannot be restored</p>
              </div>
            ),
            okText: 'Delete',
            showCancel: true,
            onCancel: () => {
                return
            },
            cancelText: 'Cancel',
            confirmLoading: loading,
            onOk: async () => {
                confirmDelete(id)
            }
        })
    }

    const confirmDelete = (id) => {

        setLoading(true)
        Axios.delete(`${url}/role/${id}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then(res => {
            message.success(res.data.message)
            getData()
        }).catch(err => {
            if (err.response) {  
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            } 
        }).finally(() => {
            setLoading(false)
        })
    }

    const columns = [
        {
            title: 'Role',
            dataIndex: 'name',
            sorter: true
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true
        },
        {
          title: '',
          dataIndex: '_id',
          align: 'right',
          sorter: false,
          fixed: 'right',
          width: 100,
            render: (id) => {
                return (
                     <Fragment>
                        <Dropdown className="pointer" overlay={
                             <Menu>
                                {/* <Menu.Item key="0">
                                    <Link to={`/role/view/${id}`}><InfoCircleOutlined /> View</Link>
                                </Menu.Item> */}
                                <Menu.Item key="1">
                                    <Link to={`/role-edit-${id}`}><EditOutlined /> Edit</Link>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <span onClick={() => handleDelete(id) }><DeleteOutlined />Delete</span>
                                </Menu.Item>
                            </Menu>
                        } trigger={['click']}>
                            <span className="pointer" onClick={e => e.preventDefault()}>
                                <MoreOutlined />
                            </span>
                        </Dropdown>
                    </Fragment>
                )
            }
        }
    ]

    // if (!localStorage.getItem('token')) {
    //     return <Redirect to="/login" />
    // }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Role" bodyStyle={{padding: "0"}} extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                                
                            <Row type="flex" gutter={[10, 20]} justify="space-around">
                                    <Col xs={24} md={12} lg={18}>
                                        <Link to="/role-create">
                                            <Button type="primary"><PlusOutlined />Add Role</Button>
                                        </Link>
                                    </Col>
                                    <Col xs={24} md={12} lg={6} className="align-right">
                                        <Search
                                            placeholder="Search ..."
                                            onSearch={(value) => handleSearch(value)}
                                        />
                                    </Col>

                                    <Col xs={24}>
                                        <Table
                                        columns={columns}
                                        dataSource={role.data}
                                        rowKey={(data) => data._id}
                                        onChange={handleTableChange}
                                        pagination={{ pageSize: 10, total: role.total }}
                                        loading={loading}
                                        />   
                                    </Col>
                            </Row>
                               
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}


export default Role