import React, { Fragment, useState } from "react";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import "./Sidebar.less";

const { SubMenu } = Menu;

function Sidebar(props) {
  const [openKeys, setOpenKeys] = useState("");
  const pathname = props.location.pathname.split("/");

  const handleOpenSub = (path) => {
    if (path !== openKeys) {
      setOpenKeys(path);
      localStorage.setItem("openKeys", path);
    } else {
      setOpenKeys("");
      localStorage.setItem("openKeys", "");
    }

    // console.log("cek sidebar", path);
  };

  const permissions = localStorage.getItem("permissions")
    ? JSON.parse(localStorage.getItem("permissions")).filter(
        (permission) => permission !== null
      )
    : [];
  const defaultSelectedKeys = localStorage.getItem("redirect");

  return (
    <Fragment>
      <div className="logo">
        <Link to={defaultSelectedKeys}>
          {props.collapsed ? (
            <img
              style={{ padding: 30 }}
              src={require("../assets/img/logo-mini.png")}
              alt="Sekiso Logo"
            />
          ) : (
            <img
              style={{ padding: 35 }}
              src={require("../assets/img/logo.png")}
              alt="Sekiso Logo"
            />
          )}
        </Link>
      </div>
      <Menu
        mode="inline"
        theme="light"
        // defaultSelectedKeys={defaultSelectedKeys}
        className="sidebar"
        selectedKeys={pathname}
        defaultOpenKeys={pathname[1]}
        openKeys={[
          localStorage.getItem("openKeys")
            ? localStorage.getItem("openKeys")
            : openKeys,
        ]}
        // openKeys={openKeys}
      >
        {permissions &&
          permissions.length > 0 &&
          permissions.map((permission) => {
            return permission.children && permission.children.length > 0 ? (
              <SubMenu
                key={permission.url}
                onTitleClick={() => handleOpenSub(permission.url)}
                title={
                  <span style={{ display: "flex", height: "40px" }}>
                    <FontAwesomeIcon
                      icon={["fas", permission.icon]}
                      className="icon"
                      fixedWidth={props.collapsed ? false : true}
                    />
                    {props.collapsed ? "" : <span>{permission.name}</span>}
                  </span>
                }
              >
                {permission.children &&
                  permission.children.map((child) => {
                    return (
                      child && (
                        <Menu.Item
                          // onClick={() => handleOpenMenuItem(child.url)}
                          key={child.url}
                        >
                          <Link to={`/${child.url}`}>{child.name}</Link>
                        </Menu.Item>
                      )
                    );
                  })}
              </SubMenu>
            ) : (
              <Menu.Item key={permission.url}>
                <Link to={`/${permission.url}`}>
                  <FontAwesomeIcon
                    icon={["fas", permission.icon]}
                    className="icon"
                    fixedWidth={props.collapsed ? false : true}
                  />
                  {props.collapsed ? "" : <span>{permission.name}</span>}
                </Link>
              </Menu.Item>
            );
          })}
      </Menu>
    </Fragment>
  );
}

export default Sidebar;
