import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  Form,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
  PrinterOutlined,
  BarcodeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;

function Kanban(props) {
  const [keyword, setKeyword] = useState("");
  const [keywordDetails, setKeywordDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleKanban, setIsModalVisibleKanban] = useState(false);
  const [error, setError] = useState(null);
  const [kanbanId, setKanbanId] = useState(null);
  const [kanbanDetails, setKanbanDetails] = useState([]);
  const [kanbanDetailsTotal, setKanbanDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [rowDetails, setRowDetails] = useState([]);
  const [data, setData] = useState({
    kanban_type: "",
    item_code: "",
    item_name: "",
    quantity_kanban: "",
    qty_generate: "",
    select: "",
    job_number: "",
  });
  const [sortDetail, setSortDetail] = useState({
    sort: "updated_at",
    order: "descend",
  });
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value, type) => {
    if (type === "details") {
      setPageDetail(1);
      setKeywordDetails(value);
      if (value === "") {
        getKanbanDetails();
      }
    } else {
      setPage(1);
      setKeyword(value);
      localStorage.setItem("keyword", value);
      if (value === "") {
        getData();
      }
    }
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getKanbanDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const exportKanban = () => {
    Axios.get(`${url}/kanban/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Kanban" + ".xlsx");
    });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/kanban/pdf/download/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      // fileDownload(res.data, "Export Master Kanban" + ".xlsx");
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/kanban`, {
      params: {
        keyword,
        columns: [
          "kanban_type",
          "item_code",
          "item_name",
          "job_number",
          "customer_name",
          "route",
          "quantity_kanban",
          "packaging_name",
          "packaging_number",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
        console.log(res.data.data, "ini kanban getdata");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    // console.log(setSelectedKanban)
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/kanban/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getKanbanDetails(data2[0]);
    }
  };

  const getKanbanDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/kanban/history/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["_id", "quantity_kanban"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setKanbanDetails(res.data.data);
        setKanbanDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleReset = () => {
    setData({
      ...data,
      qty_generate: "",
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalKanban = (id) => {
    const kanban = data.data.find((data) => data._id === id);
    setKanbanId(id);

    console.log(id);

    setData({
      ...data,
      job_number: kanban.job_number,
      item_code: kanban.item_code,
    });

    setIsModalVisibleKanban(true);
  };

  const editKanbans = (id) => {
    console.log(id);
    const edit = data.data.find((data) => data._id === id);
    // setSelectedId(id);

    if (edit.kanban_type === "WIP") {
      props.history.push(`/master-kanban/edit/wip/${id}`);
    } else if (edit.kanban_type === "Finish Goods") {
      props.history.push(`/master-kanban/edit/finish-goods/${id}`);
    } else {
      props.history.push(`/master-kanban/edit/material/${id}`);
    }
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const closeModalKanban = () => {
    setData({
      ...data,
      select: "",
      job_number: "",
      item_code: "",
    });
    setIsModalVisibleKanban(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
    // handleRemoveDetails();
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/kanban/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Kanban" + ".xlsx");
    });
  };

  const onSubmit = () => {
    setLoading(true);

    // console.log(kanbanId);

    let fd = new FormData();

    if (data.select === "Job Number") {
      fd.set("qty_generate", data.qty_generate);
      fd.set("type_qrcode", data.job_number);
    } else {
      fd.set("qty_generate", data.qty_generate);
      fd.set("type_qrcode", data.item_code);
    }

    Axios.post(`${url}/kanban/generate/${kanbanId}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "success") {
          message.success(res.data.message);
        }

        // setLoading(false);
        closeModalKanban();
        getData();
        getKanbanDetails();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/kanban/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Kanban Type",
      dataIndex: "kanban_type",
      sorter: false,
      width: 100,
    },
    {
      title: "Job Number",
      dataIndex: "job_number",
      sorter: false,
      width: 100,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: false,
      width: 100,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
      width: 100,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      sorter: false,
      width: 150,
    },
    {
      title: "Picture",
      dataIndex: "photo_url",
      sorter: false,
      width: 150,
      render: (photo_url) => (
        <img style={{ width: 80, height: 70 }} src={photo_url} alt="" />
      ),
    },
    {
      title: <div style={{ textAlign: "left" }}>Quantity Kanban</div>,
      align: "right",
      dataIndex: "quantity_kanban",
      sorter: false,
      width: 130,
    },
    {
      title: "Route",
      dataIndex: "route",
      sorter: false,
      width: 100,
    },
    {
      title: "Packaging Name",
      dataIndex: "packaging_name",
      sorter: false,
      width: 150,
    },
    {
      title: "Packaging Type",
      dataIndex: "packaging_type",
      sorter: false,
      width: 150,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      sorter: false,
      width: 100,
    },
    {
      title: "Created_at",
      dataIndex: "created_at",
      sorter: false,
      width: 100,
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    {
      title: "Update By",
      dataIndex: "update_by",
      sorter: false,
      width: 100,
    },
    {
      title: "Update At",
      dataIndex: "update_at",
      sorter: false,
      width: 100,
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 75,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item
                    key="2"
                    //  disabled={kanbanDetails.length > 0}
                    onClick={() => editKanbans(id)}
                  >
                    <EditOutlined /> Edit
                  </Menu.Item>

                  {/* <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item> */}

                  <Menu.Item key="4">
                    <span onClick={() => showModalKanban(id)}>
                      <BarcodeOutlined style={{ marginRight: 3 }} />
                      Generate
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const getKanbans = () => {
    const columns = [
      {
        title: <div style={{ textAlign: "left" }}>Quantity Generate</div>,
        align: "right",
        dataIndex: "qty_generate",
        key: "qty_generate",
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        align: "center",
        key: "created_at",
      },
      {
        title: "Created by",
        dataIndex: "generate_by",
        align: "center",
        key: "generate_by",
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 100,
        render: (id) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <span onClick={() => printPdf(id)}>
                        <PrinterOutlined /> Print
                      </span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={kanbanDetails}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: kanbanDetailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Kanban"
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/master-kanban-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button type="primary" onClick={showModal}>
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={exportKanban}>
                      <ExportOutlined />
                      Export
                    </Button>
                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                    <Modal
                      title="Import Kanban"
                      visible={isModalVisible}
                      footer={null}
                      onCancel={closeModal}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Fragment>
                              <Upload
                                onRemove={handleRemove}
                                beforeUpload={handleBeforeUpload}
                                fileList={[]}
                              >
                                <Button style={{ marginBottom: 10 }}>
                                  Choose File <ExportOutlined />
                                </Button>
                              </Upload>
                              {data.file && (
                                <React.Fragment
                                  children={[
                                    <Button
                                      onClick={handleRemove}
                                      type="danger"
                                      ghost
                                    >
                                      <DeleteOutlined />
                                    </Button>,
                                  ]}
                                >
                                  {data.file.name ? data.file.name : data.file}
                                </React.Fragment>
                              )}
                            </Fragment>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSaveData}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                                backgroundColor: "#1b2086",
                                borderColor: "#1b2086",
                              }}
                            >
                              Upload
                            </Button>
                            <Button htmlType="reset" onClick={handleRemove}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    <Modal
                      title="Generate Kanban"
                      style={{ top: 20 }}
                      visible={isModalVisibleKanban}
                      width={1000}
                      footer={null}
                      onCancel={closeModalKanban}
                    >
                      <Card className="body-data">
                        <div name="control-hooks">
                          <div name="file" label="File">
                            <Form
                              {...layout}
                              name="control-hooks"
                              labelAlign="left"
                            >
                              <Row>
                                <Fragment>
                                  <Col xs={24}>
                                    <Form.Item
                                      validateStatus={
                                        error && error.select ? "error" : false
                                      }
                                      help={
                                        error && error.select
                                          ? error.select[0]
                                          : false
                                      }
                                      label="Select (Job Number/Item Code)"
                                      name="select"
                                    >
                                      <Select
                                        value={data.select}
                                        onChange={(value) =>
                                          handleChange("select", value)
                                        }
                                      >
                                        <Select.Option value="Job Number">
                                          Job Number
                                        </Select.Option>
                                        <Select.Option value="Item Code">
                                          Item Code
                                        </Select.Option>
                                      </Select>
                                    </Form.Item>
                                    <Form.Item
                                      validateStatus={
                                        error && error.qty_generate
                                          ? "error"
                                          : false
                                      }
                                      help={
                                        error && error.qty_generate
                                          ? error.qty_generate[0]
                                          : false
                                      }
                                      label="Kanban Generate"
                                    >
                                      <Input
                                        value={data.qty_generate}
                                        onChange={(e) => {
                                          handleChange(
                                            "qty_generate",
                                            e.target.value
                                          );
                                        }}
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        prevValues.select !==
                                        currentValues.select
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue("select") ===
                                        "Job Number" ? (
                                          <Form.Item
                                            label="Job Number"
                                            validateStatus={
                                              error && error.job_number
                                                ? "error"
                                                : false
                                            }
                                            help={
                                              error && error.job_number
                                                ? error.job_number[0]
                                                : false
                                            }
                                          >
                                            <Input
                                              disabled
                                              value={data.job_number}
                                              onChange={(e) =>
                                                handleChange(
                                                  "job_number",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        ) : null
                                      }
                                    </Form.Item>
                                    <Form.Item
                                      noStyle
                                      shouldUpdate={(
                                        prevValues,
                                        currentValues
                                      ) =>
                                        prevValues.select !==
                                        currentValues.select
                                      }
                                    >
                                      {({ getFieldValue }) =>
                                        getFieldValue("select") ===
                                        "Item Code" ? (
                                          <Form.Item
                                            label="Item Code"
                                            validateStatus={
                                              error && error.item_code
                                                ? "error"
                                                : false
                                            }
                                            help={
                                              error && error.item_code
                                                ? error.item_code[0]
                                                : false
                                            }
                                          >
                                            <Input
                                              disabled
                                              value={data.item_code}
                                              onChange={(e) =>
                                                handleChange(
                                                  "item_code",
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </Form.Item>
                                        ) : null
                                      }
                                    </Form.Item>
                                  </Col>
                                </Fragment>
                              </Row>
                            </Form>
                          </div>
                          <div>
                            <Button
                              loading={loading}
                              onClick={onSubmit}
                              type="primary"
                              htmlType="submit"
                              className="mr-button"
                              style={{
                                marginRight: 10,
                              }}
                            >
                              Submit
                            </Button>
                            <Button htmlType="reset" onClick={handleReset}>
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Card>
                    </Modal>

                    {/* <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button> */}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: getKanbans,
                      onExpandedRowsChange: (data) => handleChangeDetails(data),
                      expandedRowKeys: rowDetails,
                    }}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Kanban;
