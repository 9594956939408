/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function SupplierCustomer() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState({
    file: null,
    type: "",
    code: "",
    alias: "",
    name: "",
    address: "",
    active: "",
  });
  const [selects, setSelects] = useState("Supplier");
  const [loading, setLoading] = useState(false);
  const [supCust, setSupCust] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
    getSupCust();
  }, [keyword, page, sort, selects]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/supplier`, {
      params: {
        keyword,
        select: selects,
        columns: [
          "type",
          "code",
          "name",
          "alias",
          "address",
          "active",
          "flag",
          "phone_number",
          "mobile",
          "pic",
          "representative",
          "keyword",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSupCust = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "SupCust",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSupCust(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/supplier/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleBeforeUploadEvidence = (file) => {
    setData({
      ...data,
      images_url: "",
      images: file,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleRemoveEvidence = () => {
    setData({
      ...data,
      images: null,
    });
  };

  const handleReset = () => {
    setData({
      file: "",
      images: "",
    });
  };

  const columnsSupplier = [
    {
      title: "Type",
      dataIndex: "type",
      sorter: false,
      width: 100,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: false,
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: false,
      width: 200,
    },
    {
      title: "Alias",
      dataIndex: "alias",
      sorter: false,
      width: 90,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: false,
      width: 300,
    },
    {
      title: <div style={{ textAlign: "center" }}>No Urut</div>,
      dataIndex: "no_urut",
      sorter: false,
      width: 100,
      align: "right",
    },
    {
      title: "Flag",
      dataIndex: "flag",
      sorter: false,
      width: 100,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: false,
      width: 150,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: false,
      width: 135,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: false,
      width: 135,
    },
    {
      title: "PIC",
      dataIndex: "pic",
      sorter: false,
      width: 100,
    },
    {
      title: "NPWP",
      dataIndex: "npwp",
      sorter: false,
      width: 100,
    },
    {
      title: "Representative",
      dataIndex: "representative",
      sorter: false,
      width: 125,
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      sorter: false,
      width: 100,
    },
    {
      title: "Active",
      dataIndex: "active",
      width: 80,
      sorter: false,
      render: (active) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              active === "Yes"
                ? require("../../../assets/img/checked.png")
                : require("../../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "Created By",
      width: 120,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 120,
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      width: 120,
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      width: 120,
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/master-supplier-customer-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const columnsCustomer = [
    {
      title: "Type",
      dataIndex: "type",
      sorter: false,
      width: 150,
    },
    {
      title: "Code",
      dataIndex: "code",
      sorter: true,
      width: 150,
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: false,
      width: 200,
    },
    {
      title: "Alias",
      dataIndex: "alias",
      sorter: false,
      width: 90,
    },
    {
      title: "Address",
      dataIndex: "address",
      sorter: false,
      width: 550,
    },
    {
      title: <div style={{ textAlign: "center" }}>No Urut</div>,
      dataIndex: "no_urut",
      sorter: false,
      width: 100,
      align: "right",
    },
    {
      title: "Flag",
      dataIndex: "flag",
      sorter: false,
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: false,
      width: 150,
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
      sorter: false,
      width: 150,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: false,
      width: 150,
    },
    {
      title: "PIC",
      dataIndex: "pic",
      sorter: false,
      width: 150,
    },
    {
      title: "Representative",
      dataIndex: "representative",
      sorter: false,
      width: 150,
    },
    {
      title: "Keyword",
      dataIndex: "keyword",
      sorter: false,
      width: 150,
    },
    {
      title: "Active",
      dataIndex: "active",
      sorter: false,
      render: (active) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              active === "Yes"
                ? require("../../../assets/img/checked.png")
                : require("../../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "Created By",
      width: 150,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 150,
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      width: 150,
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      width: 150,
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      // fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/master-supplier-customer-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const exportSupplier = () => {
    Axios.get(`${url}/supplier/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Supplier Customer" + ".xlsx");
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/supplier/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Supplier & Customer" + ".xlsx");
    });
  };

  const handleChangeSelect = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });

    setSelects(value);
    // getData(type);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/supplier/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          notification.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          // message.error(`Error!: ${err.response.data.message}
          // \n\r
          // Error Details: ${errorsKey.map((error) => {
          //   return errors[error][0] + "\n\r";
          // })}
          // `);
          // if (err.response.status === 422) {
          //   setError(err.response.data.errors);
          // }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Modal
        title="Import Supplier & Customer"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Choose File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                }}
              >
                Upload
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Supplier & Customer"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={24} lg={24} className="align-left"></Col>
                <Col xs={24} md={12} lg={12}>
                  <Space>
                    <Link to="/master-supplier-customer-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button onClick={showModal} type="primary">
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button type="primary" onClick={exportSupplier}>
                      <ExportOutlined />
                      Export
                    </Button>
                    <Button type="primary" onClick={downloadTemplate}>
                      <DownloadOutlined />
                      Download Template
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Col xs={24} md={12} lg={12} className="align-right">
                    <Select
                      placeholder="Select Customer/Supplier"
                      style={{ width: 150 }}
                      onChange={(value) => handleChangeSelect("select", value)}
                      value={selects}
                    >
                      {supCust &&
                        supCust.map((select) => {
                          return (
                            <Option key={select.id} value={select.name}>
                              {select.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                  <Col xs={24} md={12} lg={12} className="align-right">
                    <Search
                      placeholder="Search ..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Col>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 2500 }}
                    columns={
                      selects === "Supplier" ? columnsSupplier : columnsCustomer
                    }
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SupplierCustomer;
