/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function PullingResult() {
  const [keyword, setKeyword] = useState("");
  const [pullings, setPullings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/pulling-result`, {
      params: {
        keyword,
        columns: [
          "finish_goods_customer",
          "finish_goods_part_number",
          "scan_out_finish_goods_qty",
          "created_at",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPullings(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "finish_goods_customer",
      sorter: true,
      width: 300,
    },
    {
      title: "Part Number",
      dataIndex: "finish_goods_part_number",
      sorter: false,
      width: 250,
    },
    {
      title: <div style={{ textAlign: "center" }}> Qty</div>,
      dataIndex: "scan_out_finish_goods_qty",
      sorter: false,
      align: "right",
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: false,
      width: 150,
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Pulling Result"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right"></Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={pullings.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: pullings.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PullingResult;
