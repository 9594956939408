import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Menu,
  Card,
  Dropdown,
  Layout,
  Row,
  Col,
  Table,
  Modal,
  message,
  Input,
  Space,
  Button,
  Select,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";
import {
  CheckCircleOutlined,
  MoreOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;

function SuggestionApprovalPPIC(props) {
  const [data, setData] = useState({
    item_code: "",
    customer: "",
    qty: "",
    select_id: "",
    select_label: "",
    select_name: "",
    comodity: "",
    date: "",
    dataSuggest: [],
    selects: [],
  });
  const [loading, setLoading] = useState(false);
  const [comodity, setComodity] = useState([]);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  const [page, setPage] = useState(1);
  const [perpage, setPerPage] = useState(10);
  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getData();
  }, [sort, data.comodity, date.from, date.to]);

  useEffect(() => {
    getCommodity();
  }, []);

  //filter API berdasarkan commodity
  const getCommodity = async () => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        variable: "Comodity",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  //get Data index table
  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/suggestion-ppic-approval`, {
      params: {
        select: data.comodity,
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        columns: [
          "item_code",
          "customer",
          "qty",
          "adjutment_qty",
          "date",
          "part_number",
          "comodity",
        ],
        // perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTotal(res.data.total);
        setData({
          ...data,
          dataSuggest: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(localStorage.getItem("role_name"));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPage(pagination.pageSize);
  };

  const columns = [
    {
      title: "Adjustment Date",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      width: 100,
      render: (date) => moment(date).format("DD-MM-YYYY HH:mm:SS"),
    },
    {
      title: "Approval Date",
      dataIndex: "approval_date",
      key: "approval_date",
      align: "center",
      width: 100,
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY HH:mm:SS") : "-",
    },
    {
      title: "Delivery Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 100,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Production Date",
      dataIndex: "production_date",
      key: "production_date",
      align: "center",
      width: 100,
      render: (date) => date ? moment(date).format("DD-MM-YYYY") : "-",
    },
    {
      title: "Item Code",
      dataIndex: "part_number",
      key: "part_number",
      align: "center",
      width: 100,
    },
    {
      title: "Comodity",
      dataIndex: "comodity",
      key: "comodity",
      align: "center",
      sorter: "true",
      width: 100,
    },

    {
      title: "Customer",
      key: "customer",
      dataIndex: "customer",
      align: "center",
      width: 100,
    },
    {
      title: <div style={{ textAlign: "center" }}>Suggestion Qty</div>,
      key: "qty",
      sorter: false,
      align: "right",
      width: 65,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.qty}
            type="text"
          />
        );
      },
    },

    {
      title: <div style={{ textAlign: "center" }}>Shift 1</div>,
      key: "shift_1",
      sorter: false,
      width: 65,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.shift_1}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Shift 2</div>,
      key: "shift_2",
      sorter: false,
      width: 65,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.shift_2}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Shift 3</div>,
      key: "shift_3",
      sorter: false,
      width: 65,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.shift_3}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Adjustment Qty</div>,
      key: "adjustment_qty",
      sorter: false,
      align: "right",
      width: 85,
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.adjustment_qty}
            type="text"
          />
        );
      },
    },
    {
      title: "Status",
      width: 50,
      dataIndex: "status_adjusment",
      sorter: false,
      align: "center",
      render: (status_adjusment) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              status_adjusment === 2
                ? require("../../assets/img/checked.png")
                : require("../../assets/img/danger.png")
            }
          />
        );
      },
    },
  ];

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const handleChangeSelect = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });

    console.log(name,moment(value).format('DD MM YYYY'))
  };

  const onSaveData = () => {
    setLoading(true);
    Axios.post(
      `${url}/suggestion-ppic-approval/proses`,
      {
        id: selectedRowKeysData,
        status_adjusment: 2,
        production_date: moment(data.date).format('DD-MM-YYYY'), 
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    setSelectedRowKeysData([]);
    setSelectedRowsData([]);
  };

  const hasSelected = selectedRowsData.length > 0;

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }
  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Suggestion Approval PPIC"
            bodyStyle={{ padding: "0" }}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={12}>
                  <Space>
                    <Link to="/suggestion-approval-ppic-create">
                      <Button type="primary" disabled={hasSelected}>
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button
                      loading={loading}
                      onClick={() => onSaveData()}
                      type="primary"
                      disabled={!hasSelected}
                    >
                      <PlusOutlined />
                      Submit
                    </Button>
                    <DatePicker
                      placeholder="Select Production Date"
                      onChange={(value) => handleChangeSelect("date", value)}
                      value={data.date}
                      format="YYYY-MM-DD"
                    />
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Space>
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[date.from, date.to]}
                      format="DD MMM YYYY"
                      picker="date"
                      // style={{ marginBottom: "2%" }}
                    />
                    <Select
                      style={{ width: 150 }}
                      placeholder="Select Comodity"
                      allowClear={true}
                      onChange={(value) =>
                        handleChangeSelect("comodity", value)
                      }
                    >
                      {comodity &&
                        comodity.map((select) => {
                          return (
                            <Option value={select.name} key={select._id}>
                              {select.name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Space>
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 700 }}
                    columns={columns}
                    size="small"
                    dataSource={data.dataSuggest}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: perpage,
                      current: page,
                      total: total,
                      showSizeChanger: true,
                    }}
                    loading={loading}
                    rowSelection={{
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows, record) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);
                      },
                      getCheckboxProps: (record) => {
                        return {
                          disabled: record.status_adjusment === 2,
                        };
                      },

                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SuggestionApprovalPPIC;
