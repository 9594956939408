import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Select,
  Form,
  Menu,
  Card,
  Button,
  Dropdown,
  Layout,
  Row,
  Col,
  Input,
  Table,
  Modal,
  message,
  DatePicker,
  Space,
  Typography,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { EditOutlined, MoreOutlined, SearchOutlined } from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Option } = Select;
const { Title } = Typography;

function SuggestionPO(props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    adjustment_quantity: "",
    ppn: 0,
    supplier_label: "",
    supplier_name: "",
    supplier_id: "",
    suppliers: [],
    dataSuggest: [],
    itemType: [],
  });
  const [selectIdHeader, setSelectIdHeader] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [suggestId, setSuggestId] = useState(null);
  const [isModalVisibleSuggest, setIsModalVisibleSuggest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowDetails, setRowDetails] = useState([]);
  const [selects, setSelects] = useState(null);
  const [selectTypes, setSelectTypes] = useState(null);
  const [suggest, setSuggest] = useState([]);
  const [itemType, setItemType] = useState([]);
  const [searchedColumn, setSearchedColumn] = useState([]);
  const [sort, setSort] = useState({
    sort: "supplier_name",
    order: "ascend",
  });

  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [date, setDate] = useState("");
  const [generate, setGenerate] = useState({
    supplier_name: "",
    supplier_code: "",
    total_suggestion_qty: "",
    date: moment(new Date()),
    total_amount: "",
    item_code: "",
    total_convert_idr: 0,
    find_month: "",
    find_year: "",
    part_number: "",
  });
  const searchInput = useRef(null);

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  useEffect(() => {
    getData();
    getSuggest();
    getItemType();
  }, [page, sort, selects, selectTypes, data.date]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/suggestion-po`, {
      params: {
        month: data.date ? moment(data.date).format("MM") : "",
        year: data.date ? moment(data.date).format("YYYY") : "",
        category: selects,
        item_type: selectTypes,
        columns: ["item_code", "customer_code"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dataSuggest: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSuggest = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Item Category",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSuggest(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getItemType = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Item Type",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItemType(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleSearchFilter = (
    selectedKeys,
    confirm,
    dataIndex,
    clearFilters
  ) => {
    confirm();

    if (selectedKeys[0]) {
      const newSearchColumn = [...searchedColumn];
      const findSearchColumn = newSearchColumn.find(
        (x) => x.columns === dataIndex
      );
      if (findSearchColumn) {
        findSearchColumn.searchText = selectedKeys[0];
        setSearchedColumn(newSearchColumn);
      } else {
        setSearchedColumn([
          ...searchedColumn,
          {
            columns: dataIndex,
            searchText: selectedKeys[0],
          },
        ]);
      }
    } else {
      handleReset(dataIndex, clearFilters);
    }
  };

  const handleChange = (name, value) => {
    setError(null);
    if (name === "date") {
      setData({
        ...data,
        [name]: value,
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }

    if (name === "date") {
      setDate(value);
    }

  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setPerPageDetail(pagination.pageSize);
  };

  const handleChangeDetails = (item, itemRow) => {
    const index = item[item.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectIdHeader(data2[0]);
    }

    console.log(selectIdHeader, "ini index");
  };

  const handleReset = () => {
    setData({
      ...data,
      adjustment_quantity: "",
      supplier_id: "",
      supplier_label: "",
      supplier_name: "",
    });
  };

  const onSubmit = () => {
    setLoading(true);

    const findData = data.dataSuggest.find((x) => x._id === selectedId);

    if (findData) {
      const newSuggest = [...findData.details];
      const findSuggest = newSuggest.find(
        (x) => x.mbom_material_id === suggestId
      );
      console.log(findSuggest, "findSuggest");
      if (findSuggest) {
        findSuggest.adjustment_quantity = data.adjustment_quantity;
        findSuggest.amount = data.adjustment_quantity * data.purchase_price;
        setData({
          ...data,
          dataSuggest: [...data.dataSuggest, findSuggest],
        });
      }
    }

    closeModalSuggest();
    setLoading(false);
  };

  const showModalSuggest = (id, mbom_id, index) => {
    const suggest = data.dataSuggest[index].details.find(
      (data) => data.mbom_material_id === mbom_id
    );
    setSuggestId(suggest.mbom_material_id);

    setData({
      ...data,
      adjustment_quantity:
        suggest.adjustment_quantity === 0
          ? suggest.suggestion_qty
          : suggest.adjustment_quantity,
      supplier_name: suggest.supplier_name,
      supplier_label: suggest.supplier_name,
      item_code: suggest.item_code,
      purchase_price: suggest.purchase_price,
    });
    setIsModalVisibleSuggest(true);
    setSelectedId(id);
  };

  const handleResetSearch = (dataIndex, clearFilters) => {
    clearFilters();

    const filterSelectedKeys = searchedColumn.filter(
      (x) => x.columns !== dataIndex
    );

    setSearchedColumn(filterSelectedKeys);
    // setSearchText({ searchText: "" });
  };

  const confirmGenerate = () => {
    setLoading(true);

    console.log(
      selectedRowsData,
      selectedRowsData[0].supplier_code,
      "ini supplier code"
    );

    console.log(generate.find_month, generate.find_year);
    console.log(generate.part_number.part_number, "ini part number");

    console.log(
      "ini header",
      selectedRowsData.map((x) => {
        return {
          ...x,
          month: parseInt(generate.find_month),
          year: generate.find_year,
        };
      })
    );

    Axios.post(
      `${url}/purchase-order`,
      {
        details: selectedRowsData.map((x, index) => {
          return {
            ...x,
            month: generate.find_month,
            year: generate.find_year,
            total_convert_idr:
              selectedRowsData[index].amount *
              selectedRowsData[index].exchange_idr,
          };
        }),
        grand_total_amount: data.after_ppn
          ? generate.total_amount + data.after_ppn
          : generate.total_amount + totalPpn,

        total_convert_idr: generate.total_convert_idr,
        subtotal: generate.total_amount,
        vat: parseInt(data.ppn),
        nominal_vat: totalPpn,
        grand_total_suggestion_qty: generate.total_suggestion_qty,
        supplier_name: generate.supplier_name.supplier_name,
        supplier_code: generate.supplier_code.supplier_code,
        item_code: generate.item_code.item_code,
        month: generate.find_month,
        year: generate.find_year,
        part_number: generate.part_number.part_number,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    setSelectedRowsData([]);
    setSelectedRowKeysData([]);

    setVisible(false);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput.current = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchFilter(selectedKeys, confirm, dataIndex, clearFilters)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearchFilter(selectedKeys, confirm, dataIndex, clearFilters)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleResetSearch(dataIndex, clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
  });

  const closeModalSuggest = () => {
    setData({
      ...data,
      adjustment_quantity: "",
      supplier_name: "",
    });
    setIsModalVisibleSuggest(false);
  };

  const closeModal = () => {
    setData({
      ...data,
      ppn: 0,
    });
    setVisible(false);
  };

  const totalPpn = (data.ppn * generate.total_amount) / 100;

  const getSuggestionPO = (item, index) => {
    const columns = [
      {
        title: "Supplier",
        dataIndex: "supplier_name",
        align: "center",
        width: 250,
        key: "supplier_name",
        sorter: false,
        // ...getColumnSearchProps("supplier_name"),
      },
      {
        title: "Material Code",
        dataIndex: "item_code",
        align: "center",
        width: 150,
        key: "item_code",
        // ...getColumnSearchProps("item_code"),
      },
      {
        title: "Material Name",
        dataIndex: "item_name",
        align: "center",
        width: 200,
        key: "item_name",
      },
      {
        title: "Item Type",
        dataIndex: "item_type",
        align: "center",
        key: "item_type",
      },
      {
        title: <div style={{ textAlign: "center" }}>Begin Stock</div>,
        align: "right",
        key: "begin_stock",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              value={data.begin_stock}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Quantity</div>,
        align: "right",
        key: "quantity",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale="DEC"
              value={data.quantity}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Unit Qty</div>,
        align: "center",
        dataIndex: "unit",
        width: 150,
        key: "unit",
      },
      {
        title: <div style={{ textAlign: "center" }}>PPIC MPS</div>,
        align: "right",
        key: "ppc_mps",
        render: (data) => {
          return <AntNumberFormat value={data.ppc_mps} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Suggestion Quantity</div>,
        align: "right",
        key: "suggestion_qty",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale="DEC"
              value={data.suggestion_qty}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Adjustment Quantity</div>,
        align: "right",
        key: "adjustment_quantity",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale="DEC"
              value={
                data.adjustment_quantity === 0
                  ? data.suggestion_qty
                  : data.adjustment_quantity
              }
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Price</div>,
        align: "right",
        width: 100,
        key: "purchase_price",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              prefix={data.currency_name}
              value={data.purchase_price}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Amount</div>,
        align: "right",
        // dataIndex: "amount",
        width: 150,
        key: "amount",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              prefix={data.currency_name}
              value={data.amount}
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Convert IDR</div>,
        align: "right",
        width: 150,
        key: "amount",
        render: (data) => {
          return (
            <AntNumberFormat
              decimalScale={data.currency_name}
              prefix="IDR"
              value={
                data.currency_name !== "IDR"
                  ? data.amount * data.exchange_idr
                  : data.amount
              }
              type="text"
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Forecast</div>,
        children: [
          {
            title: <div style={{ textAlign: "center" }}>N+1</div>,
            align: "right",
            key: "forecast_n1",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n1}
                  type="text"
                />
              );
            },
          },
          {
            title: <div style={{ textAlign: "center" }}>N+2</div>,
            align: "right",
            key: "forecast_n2",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n2}
                  type="text"
                />
              );
            },
          },
          {
            title: <div style={{ textAlign: "center" }}>N+3</div>,
            align: "right",
            key: "forecast_n3",
            render: (data) => {
              return (
                <AntNumberFormat
                  decimalScale={data.currency_name}
                  value={data.forecast_n3}
                  type="text"
                />
              );
            },
          },
        ],
      },
      {
        title: "",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 50,
        render: (data) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <span
                        onClick={() =>
                          showModalSuggest(
                            item._id,
                            data.mbom_material_id,
                            index
                          )
                        }
                      >
                        <EditOutlined />
                        Edit
                      </span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col xs={24} md={24} lg={24} className="align-left">
            Category :
            <Select
              placeholder="Select Category"
              style={{ width: 150, marginLeft: "1%", marginRight: "3%" }}
              onChange={(value) => handleChangeSelect("select", value)}
              value={selects}
              allowClear
            >
              {suggest &&
                suggest.map((select) => {
                  return (
                    <Option key={select.id} value={select.name}>
                      {select.name}
                    </Option>
                  );
                })}
            </Select>
            Item Type :
            <Select
              placeholder="Select Item Type"
              style={{ width: 150, marginLeft: "2%" }}
              onChange={(value) => handleChangeSelect("item_type", value)}
              value={selectTypes}
              allowClear
            >
              {itemType &&
                itemType.map((selects) => {
                  return (
                    <Option key={selects.id} value={selects.name}>
                      {selects.name}
                    </Option>
                  );
                })}
            </Select>
          </Col>
          <Col xs={24} md={24} lg={24} className="align-left">
            <Table
              // scroll={{ x: "calc(700px + 50%)" }}
              columns={columns}
              rowKey={(data) => data.mbom_material_id}
              dataSource={data.dataSuggest[index].details.map((x) => {
                return {
                  ...x,
                  adjustment_quantity:
                    x.adjustment_quantity === 0
                      ? x.suggestion_qty
                      : x.adjustment_quantity,
                };
              })}
              size="default"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: data.dataSuggest.total,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              rowSelection={{
                selectedRowKeys: selectedRowKeysData,
                type: "checkbox",
                onChange: (selectedRowKeys, selectedRows, record) => {
                  setSelectedRowsData(selectedRows);
                  setSelectedRowKeysData(selectedRowKeys);

                  const findData = data.dataSuggest.find(
                    (x) => x._id === selectIdHeader
                  );
                  const find_month = findData.month;
                  const find_year = findData.year;

                  const total_convert_idr = selectedRows.reduce(
                    (total, item) =>
                      parseFloat(item.amount * item.exchange_idr) + total,
                    0
                  );

                  const total_amount = selectedRows.reduce(
                    (total, item) => parseFloat(item.amount) + total,
                    0
                  );

                  const total_suggestion_qty = selectedRows.reduce(
                    (total, item) =>
                      item.adjustment_quantity === 0
                        ? parseFloat(item.suggestion_qty) + total
                        : parseFloat(item.adjustment_quantity) + total,
                    0
                  );

                  console.log(total_suggestion_qty)

                  const supplier_name = selectedRows ? selectedRows[0] : null;
                  const supplier_code = selectedRows ? selectedRows[0] : null;
                  const part_number = selectedRows ? selectedRows[0] : null;
                  const item_code = selectedRows ? selectedRows[0] : null;

                  setGenerate({
                    ...generate,
                    total_suggestion_qty,
                    total_amount,
                    supplier_name,
                    supplier_code,
                    item_code,
                    total_convert_idr,
                    find_month,
                    find_year,
                    part_number,
                  });
                },
                getCheckboxProps: (record) => {
                  const checkDisable = selectedRowsData
                    ? selectedRowsData[0]
                    : null;

                  return {
                    disabled:
                      (checkDisable &&
                        checkDisable.supplier_name !== record.supplier_name) ||
                      (checkDisable &&
                        checkDisable.currency_name !== record.currency_name) ||
                        (checkDisable &&
                          checkDisable.item_type !== record.item_type) ||
                      record.status === 1,
                  };
                },

                columnTitle: selectedRowsData.length > 0 ? "" : <></>,
              }}
              onChange={handleChangeDetailsTable}
              loading={loading}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columnsPreview = [
    {
      title: "Material Code",
      dataIndex: "item_code",
      align: "center",
      key: "item_code",
    },
    {
      title: "Material Name",
      dataIndex: "item_name",
      align: "center",
      key: "item_name",
    },
    {
      title: "Item Category",
      dataIndex: "item_category",
      align: "center",
      key: "item_category",
    },
    {
      title: <div style={{ textAlign: "center" }}>Suggestion Qty</div>,
      align: "right",
      key: "suggestion_qty",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.suggestion_qty}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Adjustment Qty</div>,
      align: "right",
      key: "adjustment_quantity",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.adjustment_quantity}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Price</div>,
      align: "right",
      key: "purchase_price",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            prefix={data.currency_name}
            value={data.purchase_price}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Amount</div>,
      align: "right",
      key: "amount",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            prefix={data.currency_name}
            value={data.amount}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Convert IDR</div>,
      align: "right",
      key: "amount",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            prefix="IDR"
            value={
              data.currency_name !== "IDR"
                ? data.amount * data.exchange_idr
                : data.amount
            }
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Forecast</div>,
      children: [
        {
          title: <div style={{ textAlign: "center" }}>N+1</div>,
          align: "right",
          key: "forecast_n1",
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n1}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>N+2</div>,
          align: "right",
          key: "forecast_n2",
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n2}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>N+3</div>,
          align: "right",
          key: "forecast_n3",
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n3}
                type="text"
              />
            );
          },
        },
      ],
    },
  ];

  const columnsMaterialOrder = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      width: 20,
      align: "center",
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: <div style={{ textAlign: "center" }}>Year</div>,
      key: "year",
      width: 50,
      dataIndex: "year",
      align: "right",
    },
    {
      title: <div style={{ textAlign: "center" }}>Total Qty</div>,
      key: "grand_total_quantity_suggestion",
      width: 50,
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.grand_total_quantity_suggestion}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Total Amount</div>,
      key: "grand_total_amount",
      width: 50,
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.grand_total_amount}
            type="text"
          />
        );
      },
    },
  ];

  const columnsWIP = [
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      width: 20,
      align: "center",
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: "Year",
      key: "year",
      width: 50,
      dataIndex: "year",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>Total Qty</div>,
      key: "grand_total_quantity",
      width: 50,
      dataIndex: "grand_total_quantity",
      align: "right",
      render: (grand_total_quantity) => {
        return <AntNumberFormat value={grand_total_quantity} type="text" />;
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Total Amount</div>,
      key: "grand_total_amount",
      width: 50,
      dataIndex: "grand_total_amount",
      align: "right",
      render: (grand_total_amount) => {
        return <AntNumberFormat value={grand_total_amount} type="text" />;
      },
    },
  ];

  const handleChangeSelect = (name, value, nameField, sources) => {
    if (name === "supplier") {
      const find = data.suppliers.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.name,
      });
    } else if (name === "item_type") {
      setData({
        ...data,
        [name]: value,
      });

      setSelectTypes(value);
    } else {
      setData({
        ...data,
        [name]: value,
      });

      setSelects(value);
    }
  };

  const getSupplier = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/supplier/list`, {
      params: {
        material_code: data.material_code,
        keyword,
        columns: ["supplier"],
        searched_column: searchedColumn,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };

  const hasSelected = selectedRowsData.length > 0;

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <div>
        <Row type="flex" gutter={[10, 20]} justify="space-around">
          <Col xs={24}>
            <Card
              type="inner"
              title="Suggestion PO"
              
            >
              <Content className="content">
                <Row type="flex" gutter={[10, 20]} justify="space-around">
                  <Modal
                    title={
                      <div>
                        <span style={{ fontSize: "22px" }}>
                          <b>Are you sure?</b>{" "}
                        </span>
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          Suggestion PO will be submit to Create PO{" "}
                        </span>
                      </div>
                    }
                    centered
                    visible={visible}
                    onOk={() => confirmGenerate()}
                    onCancel={closeModal}
                    width={1000}
                  >
                    <Row gutter={16}>
                      <Col span={8}>
                        Supplier Name <br />
                        <span style={{ fontSize: 20, fontWeight: "bold" }}>
                          <p>
                            {generate.supplier_name
                              ? generate.supplier_name.supplier_name
                              : null}
                          </p>
                        </span>
                      </Col>
                      <Col span={8}>
                        Grand Total Qty <br />
                        <span style={{ fontSize: 32 }}>
                          <AntNumberFormat
                            value={generate.total_suggestion_qty}
                            type="text"
                          />
                        </span>
                      </Col>
                      <Col span={8}>
                        Grand Amount <br />
                        <span style={{ fontSize: 32 }}>
                          <AntNumberFormat
                            value={generate.total_amount}
                            type="text"
                          />
                        </span>
                      </Col>
                    </Row>
                    <Table
                      scroll={{ y: 200 }}
                      columns={columnsPreview}
                      size="small"
                      dataSource={selectedRowsData}
                      rowKey={(data) => data._id}
                      onChange={handleTableChange}
                      pagination={{
                        pageSize: 10,
                        current: page,
                        total: selectedRowsData.length,
                        showSizeChanger: false,
                      }}
                      loading={loading}
                    />
                    <Col xs={24}>
                      <div style={{ marginLeft: "2%" }}>
                        <Space>
                          <Title
                            style={{
                              fontSize: "100%",
                              fontWeight: "bold",
                            }}
                          >
                            VAT (%) :
                          </Title>
                          <Col xs={12}>
                            <AntNumberFormat
                              placeholder="PPn"
                              value={data.ppn}
                              type="input"
                              suffix="percent"
                              decimalScale="DES"
                              onChange={(e) => {
                                handleChange("ppn", e);
                              }}
                              disabled={
                                generate.supplier_code
                                  ? generate.supplier_code.item_type ===
                                    "Import"
                                  : ""
                              }
                            />
                          </Col>
                        </Space>
                        <br />
                        <b>
                          <Space>
                            <Title
                              style={{
                                fontSize: "100%",
                                marginTop: "8%",
                                fontWeight: "bold",
                              }}
                            >
                              Subtotal :
                            </Title>
                            <AntNumberFormat
                              style={{ marginLeft: "1%" }}
                              value={generate.total_amount}
                              type="text"
                              prefix={
                                generate.supplier_code
                                  ? generate.supplier_code.currency_name
                                  : ""
                              }
                              decimalScale={data.currency_name}
                              onChange={(e) => {
                                handleChange("subtotal", e);
                              }}
                            />
                          </Space>
                        </b>
                        <br />
                        <Space>
                          <Title
                            style={{
                              fontSize: "100%",
                              marginTop: "8%",
                              fontWeight: "bold",
                            }}
                          >
                            PPN Price :
                          </Title>
                          <AntNumberFormat
                            value={totalPpn}
                            type="text"
                            prefix="IDR"
                            decimalScale={data.currency_name}
                          />
                        </Space>
                        <br />
                        <b>
                          <Space>
                            <Title
                              style={{
                                fontSize: "100%",
                                marginTop: "8%",
                                fontWeight: "bold",
                              }}
                            >
                              Grand Total :
                            </Title>
                            <AntNumberFormat
                              style={{ marginLeft: "1%" }}
                              value={
                                data.after_ppn
                                  ? generate.total_amount + data.after_ppn
                                  : generate.total_amount + totalPpn
                              }
                              type="text"
                              prefix={
                                generate.supplier_code
                                  ? generate.supplier_code.currency_name
                                  : ""
                              }
                              decimalScale={data.currency_name}
                              onChange={(e) => {
                                handleChange("grand_total", e);
                              }}
                            />
                          </Space>
                        </b>
                      </div>
                    </Col>
                  </Modal>
                  <Modal
                    title="Edit Suggestion PO"
                    style={{ top: 20 }}
                    visible={isModalVisibleSuggest}
                    width={1000}
                    footer={null}
                    onCancel={closeModalSuggest}
                  >
                    <Card className="body-data">
                      <div name="control-hooks">
                        <div name="file" label="File">
                          <Form
                            {...layout}
                            name="control-hooks"
                            labelAlign="left"
                          >
                            <Row>
                              <Fragment>
                                <Col xs={24}>
                                  <Form.Item
                                    validateStatus={
                                      error && error.item_code ? "error" : false
                                    }
                                    help={
                                      error && error.item_code
                                        ? error.item_code[0]
                                        : false
                                    }
                                    label="Material Code"
                                  >
                                    <Input
                                      disabled
                                      value={data.item_code}
                                      onChange={(e) => {
                                        handleChange(
                                          "item_code",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    validateStatus={
                                      error && error.adjustment_quantity
                                        ? "error"
                                        : false
                                    }
                                    help={
                                      error && error.adjustment_quantity
                                        ? error.adjustment_quantity[0]
                                        : false
                                    }
                                    label="Adjustment Quantity"
                                  >
                                    <AntNumberFormat
                                      type="input"
                                      value={data.adjustment_quantity}
                                      onChange={(e) => {
                                        handleChange("adjustment_quantity", e);
                                      }}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    validateStatus={
                                      error && error.supplier_name
                                        ? "error"
                                        : false
                                    }
                                    help={
                                      error && error.supplier_name
                                        ? error.supplier_name[0]
                                        : false
                                    }
                                    label="Supplier Name"
                                  >
                                    <Select
                                      disabled
                                      labelInValue
                                      onChange={(value) =>
                                        handleChangeSelect("supplier", value)
                                      }
                                      onFocus={() => getSupplier("")}
                                      showSearch
                                      loading={loading}
                                      onSearch={(value) => getSupplier(value)}
                                      filterOption={false}
                                      value={{
                                        key: data.supplier_id,
                                        label: data.supplier_label,
                                      }}
                                    >
                                      {data.suppliers &&
                                        data.suppliers.map((supplier) => {
                                          return (
                                            <Option
                                              value={supplier._id}
                                              key={supplier._id}
                                            >
                                              {`${supplier.code} - ${supplier.name}`}
                                            </Option>
                                          );
                                        })}
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.select !== currentValues.select
                                    }
                                  >
                                    {({ getFieldValue }) =>
                                      getFieldValue("select") ===
                                      "Job Number" ? (
                                        <Form.Item
                                          label="Job Number"
                                          validateStatus={
                                            error && error.job_number
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.job_number
                                              ? error.job_number[0]
                                              : false
                                          }
                                        >
                                          <Input
                                            disabled
                                            value={data.job_number}
                                            onChange={(e) =>
                                              handleChange(
                                                "job_number",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                      prevValues.select !== currentValues.select
                                    }
                                  >
                                    {({ getFieldValue }) =>
                                      getFieldValue("select") ===
                                      "Item Code" ? (
                                        <Form.Item
                                          label="Item Code"
                                          validateStatus={
                                            error && error.item_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_code
                                              ? error.item_code[0]
                                              : false
                                          }
                                        >
                                          <Input
                                            disabled
                                            value={data.item_code}
                                            onChange={(e) =>
                                              handleChange(
                                                "item_code",
                                                e.target.value
                                              )
                                            }
                                          />
                                        </Form.Item>
                                      ) : null
                                    }
                                  </Form.Item>
                                </Col>
                              </Fragment>
                            </Row>
                          </Form>
                        </div>
                        <div>
                          <Button
                            loading={loading}
                            onClick={onSubmit}
                            type="primary"
                            htmlType="submit"
                            className="mr-button"
                            style={{
                              marginRight: 10,
                            }}
                          >
                            Submit
                          </Button>
                          <Button htmlType="reset" onClick={handleReset}>
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Modal>
                  <Col xs={24} md={12} lg={18}>
                    <Button
                      type="primary"
                      onClick={() => setVisible(true)}
                      disabled={!hasSelected}
                    >
                      Submit PO
                    </Button>
                    <span
                      style={{
                        marginLeft: 8,
                      }}
                    >
                      {hasSelected
                        ? `Selected ${selectedRowsData.length} items`
                        : ""}
                    </span>
                  </Col>
                  <Col xs={24} md={12} lg={6} className="align-right">
                    <DatePicker
                      onChange={(value) => handleChange("date", value)}
                      value={date}
                      picker="month"
                      placeholder="Select Month"
                    />
                  </Col>
                  <Col xs={24}>
                    <Row type="flex" gutter={[10, 20]} justify="space-around">
                      <Col xs={24}>
                        <Table
                          columns={
                            selects === "Raw Material"
                              ? columnsMaterialOrder
                              : columnsWIP
                          }
                          size="small"
                          dataSource={data.dataSuggest}
                          rowKey={(data) => data._id}
                          onChange={handleTableChange}
                          pagination={{
                            pageSize: 10,
                            current: page,
                            total: data.total,
                            showSizeChanger: false,
                          }}
                          loading={loading}
                          expandable={{
                            expandedRowRender: (item, index) =>
                              getSuggestionPO(item, index),
                            onExpandedRowsChange: (item) => {
                              handleChangeDetails(item);
                            },
                            expandedRowKeys: rowDetails,
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Content>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default SuggestionPO;