/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  ExportOutlined,
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function ListTrackingPO() {
  const [keyword, setKeyword] = useState("");
  const [poTracking, setPoTracking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const [data, setData] = useState({
    date: moment().startOf("date"),
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, data]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/tracking-po`, {
      params: {
        keyword,
        columns: [
          "po_number",
          "supplier_name",
          "item_code",
          "item_name",
          "item_category",
        ],
        perpage: 10,
        page,
        // date: moment(data.date).format("DD-MM-YYYY"),
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPoTracking(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        date: value,
      });
    } else {
      setData({
        ...data,
        date: undefined,
      });
    }
  };

  const exportItem = () => {
    Axios.get(`${url}/tracking-po/export`, {
      params: {
        keyword,
        columns: [
          "po_number",
          "supplier_name",
          "item_code",
          "item_name",
          "item_category",
        ],
        perpage: 10,
        page,
        // date: moment(data.date).format("DD-MM-YYYY"),
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Tracking PO" + ".xlsx");
    });
  };

  const columns = [
    {
      title: "PO Number",
      dataIndex: "po_number",
      sorter: true,
    },
    {
      title: "Remark",
      sorter: false,
      render: (data) => data.item && data.item.item_category,
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      sorter: false,
    },
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: false,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 100,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {/* <Menu.Item key="0">
    //                                 <Link to={`/user/view/${id}`}><InfoCircleOutlined /> View</Link>
    //                             </Menu.Item> */}
    //               <Menu.Item key="1">
    //                 <Link to={`/user-edit-${id}`}>
    //                   <EditOutlined /> Edit
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item key="3">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="PO Tracking"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                {/* <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <DatePicker
                    style={{ width: 1000 }}
                    onChange={handleChangeDate}
                    picker="date"
                    value={data.date}
                    format="DD MMM YYYY"
                  />
                </Col> */}

                <Col xs={24} md={12} lg={18}>
                  <Button type="primary" onClick={exportItem}>
                    <ExportOutlined />
                    Export
                  </Button>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={poTracking.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: poTracking.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default ListTrackingPO;
