/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Dropdown,
  Menu,
  Upload,
  Form,
  DatePicker,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  DeleteOutlined,
  MoreOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
  LoginOutlined,
  DownloadOutlined,
  UploadOutlined,
  EditFilled,
  FileOutlined,
} from "@ant-design/icons";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Option } = Select;

function Invoicing(props) {
  const [keyword, setKeyword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleRevisi, setIsModalVisibleRevisi] = useState(false);
  const [selectedRevisi, setSelectedRevisi] = useState(false);
  const [isModalVisibleInvoice, setIsModalVisibleInvoice] = useState(false);
  const [rowDetails, setRowDetails] = useState([]);
  const [sort, setSort] = useState({
    sort: "item_code",
    order: "ascend",
  });
  
  const [sortDetail, setSortDetail] = useState({
    sort: "bill_to",
    order: "ascend",
  });

  const [data, setData] = useState({
    dn_manifest: "",
    part_number: "",
    customer: "",
    shipped_to: "",
    quantity: 0,
    price: 0,
    date_delivery: "",
    date_receiving: "",
    status: "",
    dataInvoicing: [],
    customer: [],
    customer_id: "",
    bill_to: "",
    customer_alias: "",
    customer_label: "",
    file: null,
  });

  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date.from, date.to]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/invoice`, {
      params: {
        keyword,
        columns: ["no_invoice", "billed_to"],
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dataInvoicing: res.data.data,
          total: res.data.total,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const handleChangeDetailsTable = (pagination, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);

    console.log(pagination, sorter);
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/certificate/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        props.history.push("/invoicing-compare");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });

    setIsModalVisible(false);
  };

  const closeModalRevisi = () => {
    setData({
      ...data,
      file: null,
    });

    setIsModalVisibleRevisi(false);
  };

  const closeModalInvoice = () => {
    setData({
      ...data,
      dn_manifest: "",
      part_number: "",
      customer: "",
      shipped_to: "",
      quantity: 0,
    });

    setIsModalVisibleInvoice(false);
  };

  const handleChangeDetails = (item, itemRow) => {
    const index = item[item.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
    }
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleRemoveRevisi = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleBeforeUploadRevisi = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const handlePrint = (id) => {
    Axios.get(`${url}/invoice/pdf/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const handlePrintCSV = (id) => {
    Axios.get(`${url}/invoice/csv`, {
      params: {
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        bill_to: data.customer_alias,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Invoice CSV" + ".csv");
      // var blob = new Blob([res.data], { type: "application/csv" });
      // var blobURL = URL.createObjectURL(blob);
      // window.open(blobURL);
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/warehouse-receive-scan/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/certificate/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Certificate" + ".xlsx");
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalRevisi = (id) => {
    setIsModalVisibleRevisi(true);
    setSelectedRevisi(id);
  };

  const onSaveEdit = () => {
    setLoading(true);

    const id = selectedId;
    console.log(id);

    let fd = new FormData();

    fd.set("dn_manifest", data.dn_manifest);
    fd.set("part_number", data.part_number);
    fd.set("customer", data.customer);
    fd.set("shipped_to", data.shipped_to);
    fd.set("quantity", data.quantity);
    fd.set("price", data.price);
    fd.set("date_delivery", data.date_delivery);
    fd.set("date_receiving", data.date_receiving);

    Axios.post(`${url}/invoice/revisi/update/${id}`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModalInvoice();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const onSaveRevisi = () => {
    setLoading(true);

    console.log(selectedRevisi);

    const id = selectedRevisi;
    console.log(id);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/invoice/revisi/import/${id}`, fd, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        // getData();
        closeModalInvoice();
        props.history.push("/invoicing-compare-rev");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
    console.log(data.file);
  };

  const resetModal = () => {
    setData({
      ...data,
      dn_manifest: "",
      part_number: "",
      customer: "",
      shipped_to: "",
      quantity: 0,
      date_delivery: "",
      date_receiving: "",
      status: "",
    });
  };

  const columns = [
    {
      title: "Inv No",
      width: 150,
      sorter: false,
      render: (data) => {
        return data.no_invoice;
      },
    },
    {
      title: "Bill To",
      width: 150,
      dataIndex: "billed_to",
      sorter: false,
    },
    {
      title: "Date",
      width: 150,
      dataIndex: "date",
      sorter: false,
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: <div style={{ textAlign: "left" }}>Sub Total</div>,
      key: "subtotal",
      align: "right",
      width: 150,
      render: (data) => {
        return (
          <AntNumberFormat
            value={data.subtotal}
            type="text"
            prefix={data.currency_name}
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>VAT</div>,
      key: "vat_ppn",
      align: "right",
      width: 85,
      render: (data) => {
        return (
          <AntNumberFormat
            value={data.vat_ppn}
            type="text"
            suffix="percent"
            decimalScale="DES"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Total VAT</div>,
      key: "total_vat",
      align: "right",
      width: 110,
      render: (data) => {
        return (
          <AntNumberFormat
            value={data.total_vat}
            type="text"
            prefix={data.currency_name}
            decimalScale="DES"
          />
        );
      },
    },

    {
      title: <div style={{ textAlign: "left" }}>Amount</div>,
      width: 150,
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            prefix={data.currency_name}
            value={data.grand_total_amount}
            decimalScale="DES"
            type="text"
          />
        );
      },
    },
    {
      title: "",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (data, id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => handlePrint(data._id)}>
                      <PrinterOutlined /> Print
                    </span>
                  </Menu.Item>

                  <Menu.Item key="2" disabled={data.status_revisi === 2}>
                    <span
                      onClick={() =>
                        data.status_revisi !== 2
                          ? showModalRevisi(data._id)
                          : ""
                      }
                    >
                      <EditFilled /> Edit
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  const showModalInvoicing = (id, index) => {
    console.log(id, index, data.dataInvoicing[index]);
    const invoice = data.dataInvoicing[index].details.find(
      (data) => data._id === id
    );

    setData({
      ...data,
      price: invoice.price,
      dn_manifest: invoice.dn_number,
      part_number: invoice.part_number,
      customer: invoice.bill_to,
      shipped_to: invoice.shipped_to,
      date_delivery: moment(invoice.date_delivery),
      date_receiving: moment(invoice.date_receiving),
      quantity: invoice.qty,
    });
    setIsModalVisibleInvoice(true);
    setSelectedId(id);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });

    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const getInvoicing = (item, index) => {
    const columns = [
      {
        title: <div style={{ textAlign: "left" }}>DN Manifest</div>,
        dataIndex: "dn_number",
        key: "dn_number",
        align: "left",
        width: 70,
      },
      {
        title: <div style={{ textAlign: "left" }}>Part Number</div>,
        dataIndex: "part_number",
        align: "left",
        key: "part_number",
        width: 70,
      },
      {
        title: <div style={{ textAlign: "left" }}>Customer</div>,
        dataIndex: "customer_name",
        key: "customer_name",
        width: 70,
        align: "left",
      },
      {
        title: <div style={{ textAlign: "left" }}>Bill To</div>,
        dataIndex: "billed_to",
        key: "billed_to",
        width: 70,
        align: "left",
      },
      {
        title: <div style={{ textAlign: "left" }}>Date Delivery</div>,
        dataIndex: "date_delivery",
        key: "date_delivery",
        align: "left",
        width: 70,
        render: (date_delivery) => moment(date_delivery).format("DD MMM YYYY"),
      },
      {
        title: <div style={{ textAlign: "left" }}>Date Receiving</div>,
        dataIndex: "date_receiving",
        key: "date_receiving",
        align: "left",
        width: 70,
        render: (date_receiving) =>
          moment(date_receiving).format("DD MMM YYYY"),
      },
      {
        title: <div style={{ textAlign: "left" }}>Quantity</div>,
        dataIndex: "qty",
        key: "qty",
        align: "right",
        width: 65,
      },
      {
        title: <div style={{ textAlign: "left" }}>Price</div>,
        key: "price",
        align: "right",
        width: 65,
        render: (data) => {
          return (
            <AntNumberFormat
              value={data.price}
              type="text"
              prefix={data.currency_name}
            />
          );
        },
      },
      {
        title: <div style={{ textAlign: "left" }}>Amount</div>,
        key: "amount",
        align: "right",
        width: 65,
        render: (data) => {
          return (
            <AntNumberFormat
              value={data.amount}
              type="text"
              prefix={data.currency_name}
            />
          );
        },
      },
      {
        title: "",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 50,
        render: (data) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <span onClick={() => showModalInvoicing(data._id, index)}>
                        <EditOutlined />
                        Edit
                      </span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row justify="end" gutter={[0, 20]}>
          <Col xs={24} md={24} lg={24} className="align-left">
            <Table
              scroll={{ x: "700" }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={data.dataInvoicing[index].details}
              size="default"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  return (
    <Fragment>
      <Modal
        title="Edit Invoicing"
        style={{ top: 20 }}
        visible={isModalVisibleInvoice}
        width={1000}
        footer={null}
        onCancel={closeModalInvoice}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Form {...layout} name="control-hooks" labelAlign="left">
                <Row>
                  <Fragment>
                    <Col xs={24}>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.dn_manifest ? "error" : false
                        }
                        help={
                          error && error.dn_manifest
                            ? error.dn_manifest[0]
                            : false
                        }
                        label="DN Manifest"
                      >
                        <Input
                          value={data.dn_manifest}
                          onChange={(e) => {
                            handleChange("dn_manifest", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.part_number ? "error" : false
                        }
                        help={
                          error && error.part_number
                            ? error.part_number[0]
                            : false
                        }
                        label="Part Number"
                      >
                        <Input
                          value={data.part_number}
                          onChange={(e) => {
                            handleChange("part_number", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.customer ? "error" : false
                        }
                        help={
                          error && error.customer ? error.customer[0] : false
                        }
                        label="Customer"
                      >
                        <Input
                          value={data.customer}
                          onChange={(e) => {
                            handleChange("customer", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.shipped_to ? "error" : false
                        }
                        help={
                          error && error.shipped_to
                            ? error.shipped_to[0]
                            : false
                        }
                        label="Shipped To"
                      >
                        <Input
                          value={data.shipped_to}
                          onChange={(e) => {
                            handleChange("shipped_to", e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.quantity ? "error" : false
                        }
                        help={
                          error && error.quantity ? error.quantity[0] : false
                        }
                        label="Quantity"
                      >
                        <AntNumberFormat
                          value={data.quantity}
                          type="input"
                          onChange={(e) => {
                            handleChange("quantity", e);
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.date_delivery ? "error" : false
                        }
                        help={
                          error && error.date_delivery
                            ? error.date_delivery[0]
                            : false
                        }
                        label="Date Delivery"
                      >
                        <DatePicker
                          onChange={(value) =>
                            handleChange("date_delivery", value)
                          }
                          value={data.date_delivery}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        validateStatus={
                          error && error.date_receiving ? "error" : false
                        }
                        help={
                          error && error.date_receiving
                            ? error.date_receiving[0]
                            : false
                        }
                        label="Date Receiving"
                      >
                        <DatePicker
                          onChange={(value) =>
                            handleChange("date_receiving", value)
                          }
                          value={data.date_receiving}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
              </Form>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveEdit}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                }}
              >
                Submit
              </Button>
              <Button htmlType="reset" onClick={resetModal}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Modal
          title="Upload Certificate"
          visible={isModalVisible}
          footer={null}
          onCancel={closeModal}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Fragment>
                  <Upload
                    onRemove={handleRemove}
                    beforeUpload={handleBeforeUpload}
                    fileList={[]}
                  >
                    <Button style={{ marginBottom: 10 }}>
                      Choose File <UploadOutlined />
                    </Button>
                  </Upload>
                  {data.file && (
                    <React.Fragment
                      children={[
                        <Button onClick={handleRemove} type="danger" ghost>
                          <DeleteOutlined />
                        </Button>,
                      ]}
                    >
                      {data.file.name ? data.file.name : data.file}
                    </React.Fragment>
                  )}
                </Fragment>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSaveData}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                  }}
                >
                  Upload
                </Button>
                <Button htmlType="reset" onClick={handleRemove}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>

        <Modal
          title="Upload Certificate Revisi"
          visible={isModalVisibleRevisi}
          footer={null}
          onCancel={closeModalRevisi}
        >
          <Card className="body-data">
            <div name="control-hooks">
              <div name="file" label="File">
                <Fragment>
                  <Upload
                    onRemove={handleRemoveRevisi}
                    beforeUpload={handleBeforeUploadRevisi}
                    fileList={[]}
                  >
                    <Button style={{ marginBottom: 10 }}>
                      Choose File <UploadOutlined />
                    </Button>
                  </Upload>
                  {data.file && (
                    <React.Fragment
                      children={[
                        <Button
                          onClick={handleRemoveRevisi}
                          type="danger"
                          ghost
                        >
                          <DeleteOutlined />
                        </Button>,
                      ]}
                    >
                      {data.file.name ? data.file.name : data.file}
                    </React.Fragment>
                  )}
                </Fragment>
              </div>
              <div>
                <Button
                  loading={loading}
                  onClick={onSaveRevisi}
                  type="primary"
                  htmlType="submit"
                  className="mr-button"
                  style={{
                    marginRight: 10,
                    backgroundColor: "#1b2086",
                    borderColor: "#1b2086",
                  }}
                >
                  Upload
                </Button>
                <Button htmlType="reset" onClick={handleRemoveRevisi}>
                  Reset
                </Button>
              </div>
            </div>
          </Card>
        </Modal>

        <Col xs={24}>
          <Card
            type="inner"
            title="Invoicing"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={16}>
                  <Col xs={24}>
                    <Col xs={12}>
                      <Space>
                        <Button onClick={() => showModal()} type="primary">
                          <LoginOutlined /> Upload Certificate
                        </Button>
                        <Link to="/invoice-create">
                          <Button type="primary">
                            <PlusOutlined />
                            Create Invoice
                          </Button>
                        </Link>
                        <Button
                          onClick={() => downloadTemplate()}
                          type="primary"
                        >
                          <DownloadOutlined /> Download Template Certificate
                        </Button>
                      </Space>
                    </Col>
                    <Col xs={12} style={{ marginTop: "1%" }}>
                      <Space>
                        <Button onClick={() => handlePrintCSV()} type="primary">
                          <DownloadOutlined /> Download CSV
                        </Button>
                        <Link to="/invoicing-compare">
                          <Button type="primary">
                            <FileOutlined />
                            Invoice Compare
                          </Button>
                        </Link>
                      </Space>
                    </Col>
                  </Col>
                </Col>
                <Col xs={24} md={12} lg={8} className="align-right">
                  <Col xs={24}>
                    <Col xs={24} className="align-right">
                      <Space>
                        <RangePicker
                          onChange={handleChangeDate}
                          value={[date.from, date.to]}
                          format="DD MMM YYYY"
                          picker="date"
                          style={{ marginBottom: '2%' }}
                        />
                      </Space>
                    </Col>
                    <Col xs={24} className="align-right">
                      <Space>
                        <Select
                          labelInValue
                          style={{ width: 150 }}
                          onChange={(value) =>
                            handleChangeSelect("customer", value)
                          }
                          onFocus={() => getCustomer("")}
                          showSearch
                          onSearch={(value) => getCustomer(value)}
                          filterOption={false}
                          value={{
                            key: data.customer_id,
                            label: data.customer_label,
                          }}
                        >
                          {data.customer &&
                            data.customer.map((customers) => {
                              return (
                                <Option
                                  value={customers._id}
                                  key={customers._id}
                                >
                                  {customers.alias}
                                </Option>
                              );
                            })}
                        </Select>
                        <Search
                          placeholder="Search ..."
                          onSearch={(value) => handleSearch(value)}
                        />
                      </Space>
                    </Col>
                  </Col>
                </Col>

                <Col md={24} sm={24} xs={24}>
                  <Table
                    scroll={{ x: 800 }}
                    columns={columns}
                    dataSource={data.dataInvoicing}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                    expandable={{
                      expandedRowRender: (item, index) =>
                        getInvoicing(item, index),
                      onExpandedRowsChange: (item) => {
                        handleChangeDetails(item);
                      },
                      expandedRowKeys: rowDetails,
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Invoicing;