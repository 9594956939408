/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Card,
  Col,
  Layout,
  Table,
  Button,
  Modal,
  Upload,
  Dropdown,
  Menu,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import ReactExport from "react-export-excel";

import FilterCom from "../../components/Dashboard/FilterCom";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExceptionOutlined,
  ExportOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Meta } = Card;
const { Dragger } = Upload;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function HeijunkaProd() {
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  // const [date, setDate] = useState(moment('2021-06-17').format('YYYY-MM-DD'));

  const [comodity, setComodity] = useState("");
  const [Fcomodity, setFComodity] = useState([]);

  const [customer, setCustomer] = useState("");
  const [Fcustomer, setFCustomer] = useState([]);

  const [part, setPart] = useState("");
  const [Fpart, setFPart] = useState([]);

  // const [operator, setOperator] = useState('');
  // const [Foperator, setFOperator] = useState([]);

  const [line, setLine] = useState("");
  const [Fline, setFLine] = useState([]);

  const [productionH, setProductionH] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  //#region GetData Heijunka
  useEffect(() => {
    getDataH(date, comodity, customer, part, line);

    if (!loading) {
      let rotationInterval = setInterval(() => {
        getDataH(date, comodity, customer, part, line);
      }, 15000);

      return () => {
        clearInterval(rotationInterval);
      };
    }
  }, [date, comodity, customer, part, line]);

  const getDataH = async () => {
    setLoading(true);

    const results = await Axios.get(`${url}/productionh`, {
      params: {
        date,
        comodity,
        customer,
        part,
        line,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setProductionH(results.data.data);
    setDataExcel(results.data.data);
    setLoading(false);
  };
  //#endregion

  //#region GetFilter

  //#region Comodity
  useEffect(() => {
    getComodity();
  }, [date, customer, part, line, productionH]);

  const getComodity = async () => {
    const res = await Axios.get(`${url}/getProdComodity`, {
      params: {
        date,
        customer,
        part,
        line,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setFComodity(res.data);
  };
  //#endregion

  //#region Customer
  useEffect(() => {
    getCustomer();
  }, [date, comodity, part, line, productionH]);

  const getCustomer = async () => {
    const res = await Axios.get(`${url}/getProdCustomer`, {
      params: {
        date,
        comodity,
        part,
        line,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setFCustomer(res.data);
  };
  //#endregion

  //#region Part Number
  useEffect(() => {
    getPart();
  }, [date, comodity, customer, line, productionH]);

  const getPart = async () => {
    const res = await Axios.get(`${url}/getProdPart`, {
      params: {
        date,
        comodity,
        customer,
        line,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setFPart(res.data);
  };
  //#endregion

  //#region Line Number
  useEffect(() => {
    getLine();
  }, [date, comodity, customer, part, productionH]);

  const getLine = async () => {
    const res = await Axios.get(`${url}/getProdLine`, {
      params: {
        date,
        comodity,
        customer,
        part,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setFLine(res.data);
  };
  //#endregion

  //#endregion

  //#region Delete
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/productionhdetail/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getDataH(date, comodity, customer, part, line);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //#endregion

  //#region Table Settings
  const columns = [
    {
      title: "Time",
      dataIndex: "prod_tm",
      sorter: true,
      align: "center",
    },
    {
      title: "Comodity",
      dataIndex: "comodity",
      sorter: true,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      sorter: true,
    },
    {
      title: "Part Number",
      dataIndex: "part_no",
      sorter: true,
      align: "right",
    },
    {
      title: "Line Number",
      dataIndex: "line_no",
      sorter: true,
    },
    {
      title: "Qty",
      dataIndex: "qty",
      sorter: true,
      align: "right",
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/production-heijunka-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];
  //#endregion

  //#region Handle Modal Ok
  const handleModalOk = () => {
    getDataH(date, comodity, customer, part, line);
    setModal(false);
  };
  //#endregion

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const ModalBtn = (
    <div
      style={{
        display: "flex",
      }}
    >
      <Button
        type="primary"
        onClick={() => setModal(true)}
        style={{
          marginRight: 10,
        }}
      >
        <ImportOutlined />
        Upload Heijunka
      </Button>
      <div>
        <ExcelFile
          element={
            <Button type="primary">
              <ExportOutlined /> Export Data
            </Button>
          }
          filename={`ProductionHeijunka-${moment(date).format("YYYYMMDD")}`}
        >
          <ExcelSheet data={dataExcel} name={`Data`}>
            <ExcelColumn label="Date" value="prod_dt" />
            <ExcelColumn label="Customer" value="customer" />
            <ExcelColumn label="Comodity" value="comodity" />
            <ExcelColumn label="Part Number" value="part_no" />
            <ExcelColumn label="Line Number" value="line_no" />
            {/* <ExcelColumn label="Operator" value="operator"/> */}
            <ExcelColumn label="Quantity" value="qty" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Row className="header-body">
        <Col xs={24}>
          <Card
            type="inner"
            title="Production Heijunka"
            bodyStyle={{ padding: "0" }}
            extra={ModalBtn}
          ></Card>
        </Col>
      </Row>
      <Content className="content">
        <Modal
          title="Upload Data"
          centered
          visible={modal}
          onOk={() => handleModalOk()}
          onCancel={() => setModal(false)}
        >
          <Button href="/files/ProductionTemplate.xlsx">
            Download Template
          </Button>
          <hr
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#eee",
            }}
          />
          <Dragger
            name="file"
            action={url + "/productionUpload"}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }}
          >
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </Modal>
        <Row gutter={[16, 20]}>
          <Col xs={24} md={6} lg={4} className="sidebar-body">
            <FilterCom
              title="Date"
              value={date}
              placeholder="Pilih Tanggal"
              type="date"
              onChange={(Item) => setDate(moment(Item).format("YYYY-MM-DD"))}
            />
            <FilterCom
              title="Comodity"
              type="select"
              option={Fcomodity}
              value={comodity}
              onChange={(Item) => setComodity(Item)}
            />
            <FilterCom
              title="Customer"
              type="select"
              option={Fcustomer}
              value={customer}
              onChange={(Item) => setCustomer(Item)}
            />
            <FilterCom
              title="Part number"
              type="select"
              option={Fpart}
              value={part}
              onChange={(Item) => setPart(Item)}
            />
            {/* <FilterCom
                            title='Operator'
                            type='select'
                            option={Foperator}
                            value={operator}
                            onChange={(Item) => setOperator(Item)}
                        /> */}
            <FilterCom
              title="Line no"
              type="select"
              option={Fline}
              value={line}
              onChange={(Item) => setLine(Item)}
            />
          </Col>
          <Col xs={24} md={18} lg={20} className="main-body">
            <Card
              style={{
                marginBottom: 10,
              }}
            >
              <Table
                columns={columns}
                dataSource={productionH}
                rowKey={(data) => data.id}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default HeijunkaProd;
