/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Layout, Input, Upload, Table, Button, DatePicker, Modal, Select } from 'antd'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import moment from 'moment'
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Dash_deliv.less'
import './Dashboard.less'

const { Content } = Layout
const { Column, ColumnGroup } = Table
const { Meta } = Card;
const { Search } = Input;
const { Dragger } = Upload;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function Dash_deliv() {

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

    const [shift, setShift] = useState('');
    const [Fshift, setFShift] = useState([]);

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);

    //#region Get Data
    useEffect(() => {
        setLoading(true)

        getData(date, shift)

        if (!loading){

            let rotationInterval = setInterval(() => {
                getData(date, shift)
            }, 15000);

            return () => {
                clearInterval(rotationInterval);
            }
            
        }

    }, [date, shift])

    const getData = async () => {

        setLoading(true)

        const results = await Axios.get(`${url}/delivery`, {

            params: {
                date, shift
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            
        }).then(res => {

            return res.data;
    
        })

        const res = results.data;
        setData(res);
        setLoading(false)

    }
    //#endregion

    //#region Shift
    useEffect(() => {

        getShift()

    }, [date])

    const getShift = async () => {

        const res = await Axios.get(`${url}/shiftOnly`, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            
        }).then(res => {

            return res.data;
    
        })
        
        setFShift(res.data);

    }
    //#endregion
    
    //#region Handle Modal Ok
    const handleModalOk = () => {
        getData(date, shift)
        setModal(false)
    }
    //#endregion

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    // console.log(data)

    const headBtn = (
        <div className="headerDeliv">
            <Select
                defaultValue="All"
                showSearch
                placeholder="Select a data"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onChange={(value) => setShift(value)}
                className="select"
            >
                <Select.Option value="">All</Select.Option>
                {Fshift.map((element) => <Select.Option key={element}>{element}</Select.Option>)}
            </Select>
            <DatePicker
                defaultValue={moment(date, 'YYYY-MM-DD')}
                onChange={(value) => setDate(moment(value).format('YYYY-MM-DD'))}
                inputReadOnly={true}
                format={'YYYY-MM-DD'}
                className="datepicker"
                allowClear={false}
            />
            <Button
                type="primary"
                onClick={() => setModal(true)}
                className="headerbtn"
            >
                Upload Truck Data
            </Button>
            <div>
                <ExcelFile element={<Button type="primary" className="headerbtn">Export Data</Button>} filename={`DeliveryTruck-${moment(new Date(date)).format('YYYYMMDD')}`}>
                    <ExcelSheet data={data} name={`Data-${date}`}>
                        <ExcelColumn label="Date" value="date"/>
                        <ExcelColumn label="Customer" value="customer"/>
                        <ExcelColumn label="Truck" value="truck"/>
                        <ExcelColumn label="Route" value="route"/>
                        <ExcelColumn label="Cycle" value="cycle"/>
                        <ExcelColumn label="Arrival Plan" value="arrival_plan"/>
                        <ExcelColumn label="Arrival Actual" value="arrival_actual"/>
                        <ExcelColumn label="Arrival Status" value="arrival_status"/>
                        <ExcelColumn label="Departure Plan" value="departure_plan"/>
                        <ExcelColumn label="Departure Actual" value="departure_actual"/>
                        <ExcelColumn label="Departure Status" value="departure_status"/>
                    </ExcelSheet>
                </ExcelFile>
            </div>
        </div>
    )

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" className="title-dashboard" title="Dashboard Delivery" bodyStyle={{padding: "0"}} extra={headBtn}></Card>
                </Col>
            </Row>
            <Content className="content">
                <Modal
                    title="Upload Truck Plan"
                    centered
                    visible={modal}
                    onOk={() => handleModalOk()}
                    onCancel={() => setModal(false)}
                >
                    <Button href={`/files/DeliveryTemplate.xlsx`}>Download Template</Button>
                    <hr
                        style={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: '#eee',
                        }}
                    />
                    <Dragger
                        name= 'file'
                        action= {url+`/deliveryUpload`}
                        headers={{
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }}
                        data={{
                            date
                        }}
                    >
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    </Dragger>
                </Modal>
                <Row gutter={[16, 20]}>
                    <Col sm={24} className="main-body">
                        <Card>
                            <Table
                                dataSource={data}
                                loading={loading}
                                pagination={false}
                                bordered={true}
                                rowKey='id'
                                size="small"
                                className="tableDeliv"
                                rowClassName={(record, index) => record.status != null ? 'disabletableDeliv' : '' }
                            >
                                <Column
                                    title="No"
                                    key="index"
                                    render={(value, item, index) => index + 1}
                                    align="center"
                                />
                                <Column title="Customer" dataIndex="customer" key="customer" />
                                <Column title="Truck (Route)" dataIndex="truck" key="truck" />
                                <Column title="Route" dataIndex="route" key="route" />
                                <Column title="Cycle" dataIndex="cycle" key="cycle" align="center" />
                                <ColumnGroup title="Arrival">
                                    <Column title="Plan" dataIndex="arrival_plan" key="arrival_plan" align="center" />
                                    <Column title="Actual" dataIndex="arrival_actual" key="arrival_actual" align="center" />
                                    <Column
                                        title="Status"
                                        dataIndex="arrival_status"
                                        key="arrival_status"
                                        align="center"
                                        render={(value, item, index) => <span style={{color: item.arrival_status_color}}>{value}</span>}
                                    />
                                </ColumnGroup>
                                <ColumnGroup title="Departure">
                                    <Column title="Plan" dataIndex="departure_plan" key="departure_plan" align="center" />
                                    <Column title="Actual" dataIndex="departure_actual" key="departure_actual" align="center" />
                                    <Column
                                        title="Status"
                                        dataIndex="departure_status"
                                        key="departure_status"
                                        align="center"
                                        render={(value, item, index) => <span style={{color: item.departure_status_color}}>{value}</span>}
                                    />
                                </ColumnGroup>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}

export default Dash_deliv