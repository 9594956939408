import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";

const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function EditProductionSchedule(props) {
  const [data, setData] = useState({
    customer: "",
    comodity_group: "",
    sub_group: "",
    model: "",
    part_no: "",
    part_name: "",
    item_code: "",
    job_no: "",
    qty_kbn: "",
    stock_em: "",
    plan_oap: "",
    plan_fc_prev: "",
    plan_domestic: "",
    plan_export_ckd: "",
    plan_export_gbu: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const getData = () => {
    Axios.get(`${url}/production-schedule/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          oap: res.data.data.oap,
          fc_prev: res.data.data.fc_prev,
          plan_po: res.data.data.plan_po,
          domestic: res.data.data.domestic,
          export_ckd: res.data.data.export_ckd,
          export_cbu: res.data.data.export_cbu,

          //n1
          oap_n1: res.data.data.oap_n1,
          fc_prev1: res.data.data.fc_prev1,
          plan_po1: res.data.data.plan_po1,
          domestic1: res.data.data.domestic1,
          export_ckd1: res.data.data.export_ckd1,
          export_cbu1: res.data.data.export_cbu1,

          //n2
          oap_n2: res.data.data.oap_n2,
          fc_prev2: res.data.data.fc_prev2,
          plan_po2: res.data.data.plan_po2,
          domestic2: res.data.data.domestic2,
          export_ckd2: res.data.data.export_ckd2,
          export_cbu2: res.data.data.export_cbu2,

          //n3
          oap_n3: res.data.data.oap_n3,
          fc_prev3: res.data.data.fc_prev3,
          plan_po3: res.data.data.plan_po3,
          domestic3: res.data.data.domestic3,
          export_ckd3: res.data.data.export_ckd3,
          export_cbu3: res.data.data.export_cbu3,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleReset = () => {
    setData({
      customer: "",
      comodity_group: "",
      sub_group: "",
      model: "",
      part_no: "",
      part_name: "",
      item_code: "",
      job_no: "",
      qty_kbn: "",
      stock_em: "",
      plan_oap: "",
      plan_fc_prev: "",
      plan_domestic: "",
      plan_export_ckd: "",
      plan_export_gbu: "",
    });
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("customer", data.customer);
    fd.set("comodity_group", data.comodity_group);
    fd.set("sub_group", data.sub_group);
    fd.set("model", data.model);
    fd.set("part_no", data.part_no);
    fd.set("item_code", data.item_code);
    fd.set("job_no", data.job_no);
    fd.set("qty_kbn", data.qty_kbn);
    fd.set("part_name", data.part_name);
    fd.set("stock_em", data.stock_em);
    fd.set("plan_oap", data.plan_oap);
    fd.set("plan_fc_prev", data.plan_fc_prev);
    fd.set("plan_domestic", data.plan_domestic);
    fd.set("plan_export_ckd", data.plan_export_ckd);
    fd.set("plan_export_gbu", data.plan_export_gbu);

    Axios.post(`${url}/production-schedule/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success") {
          message.success(res.data.message);
        } else {
          message.error(res.data.message);
        }
        // setLoading(false);
        props.history.push("/master-production-schedule");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Production Schedule"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-production-schedule">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Production Schedule</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Form labelAlign="left" {...layout} name="control-hooks">
                  <Row>
                    <Fragment>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          validateStatus={
                            error && error.customer ? "error" : false
                          }
                          help={
                            error && error.customer ? error.customer[0] : false
                          }
                          label="Customer"
                        >
                          <Input
                            disabled
                            value={data.customer}
                            onChange={(e) =>
                              handleChange("customer", e.target.value)
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label="Comodity Group"
                          validateStatus={
                            error && error.comodity_group ? "error" : false
                          }
                          help={
                            error && error.comodity_group
                              ? error.comodity_group[0]
                              : false
                          }
                        >
                          <Input
                            disabled
                            value={data.comodity_group}
                            onChange={(e) =>
                              handleChange("comodity_group", e.target.value)
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.sub_group ? "error" : false
                          }
                          help={
                            error && error.sub_group
                              ? error.sub_group[0]
                              : false
                          }
                          label="Sub Group"
                        >
                          <Input
                            disabled
                            value={data.sub_group}
                            onChange={(e) => {
                              handleChange("sub_group", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.model ? "error" : false
                          }
                          help={error && error.model ? error.model[0] : false}
                          label="Model"
                        >
                          <Input
                            disabled
                            value={data.model}
                            onChange={(e) => {
                              handleChange("model", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.part_no ? "error" : false
                          }
                          help={
                            error && error.part_no ? error.part_no[0] : false
                          }
                          label="Part No"
                        >
                          <Input
                            disabled
                            value={data.part_no}
                            onChange={(e) => {
                              handleChange("part_no", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.item_code ? "error" : false
                          }
                          help={
                            error && error.item_code
                              ? error.item_code[0]
                              : false
                          }
                          label="Item Code"
                        >
                          <Input
                            disabled
                            value={data.item_code}
                            onChange={(e) => {
                              handleChange("item_code", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.job_no ? "error" : false
                          }
                          help={error && error.job_no ? error.job_no[0] : false}
                          label="Job No"
                        >
                          <Input
                            disabled
                            value={data.job_no}
                            onChange={(e) => {
                              handleChange("job_no", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.qty_kbn ? "error" : false
                          }
                          help={
                            error && error.qty_kbn ? error.qty_kbn[0] : false
                          }
                          label="QTY KBN"
                        >
                          <Input
                            disabled
                            value={data.qty_kbn}
                            onChange={(e) => {
                              handleChange("qty_kbn", e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          validateStatus={
                            error && error.part_name ? "error" : false
                          }
                          help={
                            error && error.part_name
                              ? error.part_name[0]
                              : false
                          }
                          label="Part Name"
                        >
                          <Input
                            disabled
                            value={data.part_name}
                            onChange={(e) => {
                              handleChange("part_name", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.stock_em ? "error" : false
                          }
                          help={
                            error && error.stock_em ? error.stock_em[0] : false
                          }
                          label="Stock EM"
                        >
                          <Input
                            value={data.stock_em}
                            onChange={(e) => {
                              handleChange("stock_em", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.plan_oap ? "error" : false
                          }
                          help={
                            error && error.plan_oap ? error.plan_oap[0] : false
                          }
                          label="Plan OAP"
                        >
                          <Input
                            value={data.plan_oap}
                            onChange={(e) => {
                              handleChange("plan_oap", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.plan_fc_prev ? "error" : false
                          }
                          help={
                            error && error.plan_fc_prev
                              ? error.plan_fc_prev[0]
                              : false
                          }
                          label="Plan F/C Prev"
                        >
                          <Input
                            value={data.plan_fc_prev}
                            onChange={(e) => {
                              handleChange("plan_fc_prev", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.domestic ? "error" : false
                          }
                          help={
                            error && error.domestic ? error.domestic[0] : false
                          }
                          label="Plan Domestic"
                        >
                          <Input
                            value={data.domestic}
                            onChange={(e) => {
                              handleChange("domestic", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.plan_export_ckd ? "error" : false
                          }
                          help={
                            error && error.plan_export_ckd
                              ? error.plan_export_ckd[0]
                              : false
                          }
                          label="Plan Export CKD"
                        >
                          <Input
                            value={data.plan_export_ckd}
                            onChange={(e) => {
                              handleChange("plan_export_ckd", e.target.value);
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          validateStatus={
                            error && error.plan_export_gbu ? "error" : false
                          }
                          help={
                            error && error.plan_export_gbu
                              ? error.plan_export_gbu[0]
                              : false
                          }
                          label="Plan Export GBU"
                        >
                          <Input
                            value={data.plan_export_gbu}
                            onChange={(e) => {
                              handleChange("plan_export_gbu", e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Fragment>
                  </Row>
                </Form>
                <Row justify="start" style={{ marginTop: "2%" }}>
                  <Col>
                    <Space>
                      <Button
                        type="primary"
                        onClick={onSaveData}
                        loading={loading}
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditProductionSchedule;
