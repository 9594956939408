/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
  Upload,
  Tooltip,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ToolOutlined,
  FileOutlined,
  CheckOutlined,
  CloseOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function PODailyKanban() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [rowDetails, setRowDetails] = useState([]);
  const [data, setData] = useState([
    {
      approved_status: "",
      supplier_name: "",
      model: "",
      date: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [purchasingDataDetails, setPurchasingDataDetails] = useState([]);
  const [purchasingDataDetailsTotal, setPurchasingDataDetailsTotal] =
    useState(0);
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [approveReject, setApproveReject] = useState("");
  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "created_at",
    order: "ascend",
  });
  const [approveSupervisor, setApproveSupervisor] = useState("");

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const roleAdmin = localStorage.getItem("role_name") === "Admin";

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/po-daily-kanban`, {
      params: {
        keyword,
        columns: ["supplier_name", "po_number", "date"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(localStorage.getItem("role_name"));
  };

  const handleApprove = (id, approve) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Purchasing PO will be Approve</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id, approve);
      },
    });
  };

  const confirmApprove = (id, approve) => {
    console.log(id);
    setLoading(true);
    Axios.post(
      `${url}/po-daily-kanban/approved/${id}`,
      {
        approved_status: parseInt(approve),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmReject = (id, approved) => {
    setLoading(true);
    Axios.post(
      `${url}/po-daily-kanban/rejected/${id}`,
      {
        approved_status: approved,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    handleCancel();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsPurchasing = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      // getPurchasingDetails(data2[0]);
    }

    console.log(index);
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleCancel = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const datax = [];

  for (let i = 0; i < 1; i++) {
    datax.push({
      key: i,
      supplier_name: `PT. ABCD Indonesia`,
      po_number: `ASSY COMPT`,
      created_by: `03 Januari 2022`,
    });
  }

  const printPdfSummary = (id) => {
    Axios.get(`${url}/po-daily-kanban/summary/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/po-daily-kanban/generate-pdf/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const columns = [
    {
      title: "Supplier Name",
      dataIndex: "supplier_name",
      sorter: false,
      width: 150,
    },
    // {
    //   title: "Model",
    //   dataIndex: "model",
    //   sorter: false,
    // },
    {
      title: "PO Number",
      dataIndex: "po_number",
      sorter: false,
      width: 150,
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      sorter: false,
      width: 150,
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 50,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => printPdfSummary(id)}>
                      <PrinterOutlined /> Print
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ].filter((filter) => {
    // const approve = filter.render((data) => data.approved_status === 1)

    if (localStorage.getItem("role_name") === "Supervisor") {
      return (
        filter.key !== "approveManager" &&
        filter.key !== "approveGM" &&
        filter.key !== "approveDirektur"
      );
    } else if (localStorage.getItem("role_name") === "Manager") {
      return (
        filter.key !== "approveSupervisor" &&
        filter.key !== "approveGM" &&
        filter.key !== "approveDirektur"
      );
    } else if (localStorage.getItem("role_name") === "General Manager") {
      return (
        filter.key !== "approveSupervisor" &&
        filter.key !== "approveManager" &&
        filter.key !== "approveDirektur"
      );
    } else if (localStorage.getItem("role_name") === "Direktur") {
      return (
        filter.key !== "approveSupervisor" &&
        filter.key !== "approveManager" &&
        filter.key !== "approveGM"
      );
    } else if (localStorage.getItem("role_name") === "Admin") {
      return filter.key !== "approveAdmins";
    }
  });

  const getDailyPO = (item, index) => {
    const columnsPurchasingDetails = [
      {
        title: <div style={{ textAlign: "left" }}>Material Code</div>,
        dataIndex: "item_code",
        align: "left",
        key: "item_code",
        width: 150,
      },
      {
        title: <div style={{ textAlign: "left" }}>Material Name</div>,
        dataIndex: "item_name",
        align: "left",
        key: "item_name",
        width: 150,
      },
      {
        title: <div style={{ textAlign: "left" }}>Qty Daily Kanban</div>,
        key: "qty_daily_order",
        align: "right",
        width: 150,
        render: (data) => {
          return <AntNumberFormat value={data.qty_daily_order} type="text" />;
        },
      },
      {
        title: <div style={{ textAlign: "left" }}>Qty Generate</div>,
        key: "qty_adjustment_purchase",
        align: "right",
        width: 150,
        render: (data) => {
          return (
            <AntNumberFormat value={data.qty_adjustment_purchase} type="text" />
          );
        },
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 100,
        render: (id) => {
          return (
            <Fragment>
              <Dropdown
                className="pointer"
                overlay={
                  <Menu>
                    <Menu.Item key="1">
                      <span onClick={() => printPdf(id)}>
                        <PrinterOutlined /> Print
                      </span>
                    </Menu.Item>
                  </Menu>
                }
                trigger={["click"]}
              >
                <span className="pointer" onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </span>
              </Dropdown>
            </Fragment>
          );
        },
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              scroll={{ x: 850 }}
              style={{ marginTop: 25, marginBottom: 25, marginRight: 30 }}
              columns={columnsPurchasingDetails}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={data.data[index].details}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: purchasingDataDetailsTotal,
                showSizeChanger: true,
              }}
              onChange={handleChangeDetailsTable}
              size="small"
            />
          </Col>
        </Row>
      </>
    );
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          confirmReject(selectedId, approveReject);
        }}
        onCancel={handleCancel}
      >
        <div name="control-hooks">
          Remark :
          <Input
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          />
        </div>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="PO Daily Kanban"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}></Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1000 }}
                    columns={columns}
                    expandable={{
                      expandedRowRender: (item, index) =>
                        getDailyPO(item, index),
                      onExpandedRowsChange: (data) =>
                        handleChangeDetailsPurchasing(data),
                      expandedRowKeys: rowDetails,
                    }}
                    size="small"
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 11,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PODailyKanban;
