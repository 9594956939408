/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";
// import "./User.less";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Warehouse() {
  const [keyword, setKeyword] = useState("");
  const [warehouse, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const [keywordDetails, setKeywordDetails] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [details, setDetails] = useState([]);
  const [detailsTotal, setDetailsTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [rowDetails, setRowDetails] = useState([]);

  const [sortDetail, setSortDetail] = useState({
    sort: "created_at",
    order: "descend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleSearchDetails = (value) => {
    setKeywordDetails(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  useEffect(() => {
    if (selectedId) {
      getDetails();
    }
  }, [keywordDetails, sortDetail, pageDetail, perPageDetail]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/warehouse`, {
      params: {
        keyword,
        columns: ["item_name", "item_code"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setWarehouses(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //   const handleDelete = (id) => {
  //     Modal.confirm({
  //       title: "Are you sure?",
  //       content: (
  //         <div>
  //           <p>Deleted data cannot be restored</p>
  //         </div>
  //       ),
  //       okText: "Delete",
  //       showCancel: true,
  //       onCancel: () => {
  //         return;
  //       },
  //       cancelText: "Cancel",
  //       confirmLoading: loading,
  //       onOk: async () => {
  //         confirmDelete(id);
  //       },
  //     });
  //   };

  //   const confirmDelete = (id) => {
  //     setLoading(true);
  //     Axios.delete(`${url}/user/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     })
  //       .then((res) => {
  //         message.success(res.data.message);
  //         getData();
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           message.error(err.response.data.message);
  //           if (err.response.status == 401) {
  //             localStorage.removeItem("token");
  //           }
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   };

  const handleChangeDetails = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      getDetails(data2[0]);
    }
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const getDetails = (id = selectedId) => {
    setLoadingDetail(true);
    Axios.get(`${url}/warehouse/details/${id}`, {
      params: {
        keyword: keywordDetails,
        columns: ["type"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDetails(res.data.data);
        setDetailsTotal(res.data.total);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoadingDetail(false);
      });
  };

  const getDataDetails = () => {
    const columns = [
      {
        title: "Kanban Number",
        dataIndex: "dn_number",
        sorter: true,
      },
      {
        title: <div style={{ textAlign: "left" }}>Quantity</div>,
        align: "right",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Status",
        dataIndex: "type",
        align: "center",
        key: "type",
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        align: "center",
        key: "created_at",
      },
      {
        title: "Created by",
        dataIndex: "created_by",
        align: "center",
        key: "created_by",
      },
    ];

    return (
      <>
        <Row>
          <Col xs={24} md={12} lg={18}></Col>
          <Col
            xs={24}
            md={12}
            lg={6}
            className="align-right"
            style={{ marginBottom: 15 }}
          >
            <Search
              placeholder="Search ..."
              onSearch={(value) => handleSearchDetails(value)}
            />
          </Col>
          <Col xs={24}>
            <Table
              //scroll={{ x: 1000 }}
              columns={columns}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={details}
              size="small"
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: detailsTotal,
                showSizeChanger: true,
                style: {
                  marginTop: "2rem",
                  marginBottom: 0,
                },
              }}
              onChange={handleChangeDetailsTable}
            />
          </Col>
        </Row>
      </>
    );
  };

  const columns = [
    {
      title: "Item Code",
      dataIndex: "item_code",
      sorter: true,
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      sorter: false,
    },
    {
      title: <div style={{ textAlign: 'left' }}>Stock On Hand</div>,
      // dataIndex: "stock_on_hand",
      sorter: false,
      align: "right",
      render: (data) => {
        return <AntNumberFormat value={data.stock_on_hand} type="text" />;
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      sorter: false,
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
      sorter: false,
      width: 150,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      sorter: false,
      width: 150,
      render: (date) => moment(date).format("DD MMM YYYY"),
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 100,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {/* <Menu.Item key="0">
    //                                 <Link to={`/user/view/${id}`}><InfoCircleOutlined /> View</Link>
    //                             </Menu.Item> */}
    //               <Menu.Item key="1">
    //                 <Link to={`/user-edit-${id}`}>
    //                   <EditOutlined /> Edit
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item key="3">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Stock Warehouse"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  {/* <Space>
                    <Link to="/user-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create User
                      </Button>
                    </Link>
                    <Button type="primary" onClick={getData}>
                      <ReloadOutlined />
                      Refresh
                    </Button>
                  </Space> */}
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={warehouse.data}
                    rowKey={(data) => data.item_code}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: warehouse.total,
                      showSizeChanger: false,
                    }}
                    // expandable={{
                    //   expandedRowRender: getDataDetails,
                    //   onExpandedRowsChange: (data) => handleChangeDetails(data),
                    //   expandedRowKeys: rowDetails,
                    // }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Warehouse;
