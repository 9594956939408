/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Layout,
  Row,
  Col,
  Card,
  message,
  Table,
  Button,
  Modal,
  DatePicker,
  Input,
  Form,
  Space,
  Typography,
  Popover,
  Tag,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import "./InvoicingCompare.less";
import AntNumberFormat from "../../components/AntNumberFormat";
import { EditOutlined } from "@ant-design/icons";
import fileDownload from "js-file-download";

const { Search } = Input;
const { Content } = Layout;
const { Title } = Typography;

function InvoiceCompareRev(props) {
  const [data, setData] = useState({
    pass: "",
    no_pass: "",
    date: moment().startOf("date"),
    ppn: 0,
    no_po: "",
    after_ppn: 0,
    subtotal: 0,
    grand_total: 0,
    no_faktur_pajak: "",
    revisi_invoice_id: "",
  });

  const [generate, setGenerate] = useState({
    total_amount: 0,
    revisi_invoice_id: "",
  });

  const [select, setSelect] = useState("");

  const [dn, setDn] = useState([]);

  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "customer_name",
    order: "ascend",
  });
  const [perpage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/invoice/revisi`, {
      params: {
        columns: [
          "no_manifest",
          "part_number",
          "customer_name",
          "shipped_to",
          "no_faktur_pajak",
        ],
        // perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setTotal(res.data.total);
        const certificate = res.data.data;

        setDn(
          certificate.map((certificate) => {
            return {
              ...certificate,
              amount: certificate.amount,
            };
          })
        );

        console.log(
          "invoice",
          certificate.map((certificate) => {
            return {
              ...certificate,
              amount:
                certificate.currency_name !== "IDR"
                  ? certificate.qty * certificate.exchange_idr
                  : certificate.amount,
            };
          })
        );

        setData({
          ...data,
          pass: res.data.pass,
          no_pass: res.data.no_pass,
          no_faktur_pajak: res.data.data[0].no_faktur_pajak,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
  };

  const handleTableChange = (pagination, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPage(pagination.pageSize);
  };

  const processInvoice = () => {
    setLoading(true);

    Axios.post(
      `${url}/invoice/revisi`,
      {
        details: selectedRowsData,
        date: data.date,
        subtotal: generate.total_amount,
        nominal_ppn: data.after_ppn ? data.after_ppn : totalPpn,
        ppn: parseInt(data.ppn),
        grand_total_amount: data.after_ppn
          ? generate.total_amount + data.after_ppn
          : generate.total_amount + totalPpn,
        revisi_invoice_id: generate.revisi_invoice_id.revisi_invoice_id,
        // no_po: data.no_po,
        no_faktur_pajak: data.no_faktur_pajak,
        currency_name: select.currency_name,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/invoicing");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          // if (err.response.status == 401) {
          //   localStorage.removeItem("token");
          // }
        }
        setLoading(false);
      });
  };

  const handleCancel = () => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Cancel data cannot be restored</p>
        </div>
      ),
      okText: "Yes",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "No",
      confirmLoading: loading,
      onOk: async () => {
        confirmCancel();
      },
    });
  };

  const confirmCancel = () => {
    console.log(selectedRowKeysData);
    setLoading(true);

    Axios.post(
      `${url}/invoice/cancel`,
      {
        id: selectedRowKeysData,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/invoicing");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          // if (err.response.status == 401) {
          //   localStorage.removeItem("token");
          // }
        }
        setLoading(false);
      });
  };

  const handleIcon = (name) => {
    setError(null);
    setData({
      ...data,
      [name]: "click",
    });

    console.log(name);
  };

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        date: value,
      });
    } else {
      setData({
        ...data,
        date: undefined,
      });
    }
  };

  const exportData = () => {
    Axios.get(`${url}/certificate/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Invoice Certificate" + ".csv");
    });
  };

  const hasSelected = selectedRowsData.length > 0;

  const columns = [
    {
      title: "DN",
      align: "center",
      key: "dn",
      children: [
        {
          title: <div style={{ textAlign: "left" }}> No Manifest</div>,
          dataIndex: "dn_number",
          key: "dn_number",
          align: "left",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}> Part Number</div>,
          dataIndex: "part_number",
          align: "left",
          key: "part_number",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}> Customer</div>,
          dataIndex: "customer_name",
          key: "customer_name",
          width: 150,
          align: "left",
        },
        {
          title: <div style={{ textAlign: "left" }}> Shipped To</div>,
          dataIndex: "shipped_to",
          key: "shipped_to",
          align: "left",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}>Quantity</div>,
          dataIndex: "qty_dn",
          key: "qty_dn",
          align: "right",
          width: 80,
        },
        {
          title: <div style={{ textAlign: "left" }}>Date Delivery</div>,
          dataIndex: "date_delivery",
          key: "date_delivery",
          align: "left",
          width: 150,
          render: (date_delivery) =>
            moment(date_delivery).format("DD MMM YYYY"),
        },
        {
          title: <div style={{ textAlign: "left" }}>Date Receiving</div>,
          dataIndex: "date_receiving",
          key: "date_receiving",
          align: "left",
          width: 150,
          render: (date_receiving) =>
            moment(date_receiving).format("DD MMM YYYY"),
        },
        {
          title: <div style={{ textAlign: "left" }}>Status</div>,
          key: "status",
          align: "left",
          width: 65,
          render: (data) => {
            console.log(data,'ini data');
            return data.price !== data.m_price ||
              data.qty !== data.qty_dn ||
              data.date_receiving === "" ? (
              <Popover
                title="What Problem?"
                placement="top"
                arrowPointAtCenter
                content={
                  <>
                    {data.price !== data.m_price &&
                    data.qty !== data.qty_dn &&
                    data.date_receiving === "" ? (
                      <>
                        <p>
                          Price Certificate & Price Master Price{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                        <p>
                          Qty Certificate & Qty DN{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                        <p>
                          Delivery Note{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>Not Received Yet</u>
                          </span>
                        </p>
                      </>
                    ) : data.price !== data.m_price &&
                      data.qty !== data.qty_dn ? (
                      <>
                        <p>
                          Price Certificate & Price Master Price{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                        <p>
                          Qty Certificate & Qty DN{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                      </>
                    ) : data.price !== data.m_price ? (
                      <>
                        <p>
                          Price Certificate & Price Master Price{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                      </>
                    ) : data.qty !== data.qty_dn ? (
                      <>
                        <p>
                          Qty Certificate & Qty DN{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>No Match!</u>
                          </span>
                        </p>
                      </>
                    ) : data.date_receiving === "" ? (
                      <>
                        <p>
                          Delivery Note{" "}
                          <span style={{ color: "#ff4258" }}>
                            <u>Not Received Yet</u>
                          </span>
                        </p>
                      </>
                    ) : data.status_dn === "no pass" ? (
                      <>
                        <p>-</p>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                }
                trigger="click"
              >
                <Tag color="volcano">Check Detail</Tag>
              </Popover>
            ) : (
              <Tag color="green">OK</Tag>
            );
          },
        },
      ],
    },
    {
      title: "Certificate",
      align: "center",
      children: [
        {
          title: <div style={{ textAlign: "left" }}>No Manifest</div>,
          dataIndex: "no_manifest_certificate",
          align: "left",
          key: "no_manifest_certificate",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}>Item Code</div>,
          dataIndex: "item_code",
          key: "item_code",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}>Customer</div>,
          width: 150,
          dataIndex: "customer_certificate",
          key: "customer_certificate",
        },
        {
          title: <div style={{ textAlign: "left" }}>Description</div>,
          dataIndex: "description",
          key: "description",
          width: 150,
        },
        {
          title: <div style={{ textAlign: "left" }}>Quantity</div>,
          dataIndex: "qty",
          key: "qty",
          align: "right",
          width: 80,
        },
        {
          title: <div style={{ textAlign: "left" }}>Amount</div>,
          key: "amount",
          align: "right",
          width: 120,
          render: (data) => {
            return (
              <AntNumberFormat prefix="IDR" value={data.amount} type="text" />
            );
          },
        },
      ],
    },
  ];

  const totalPpn = (data.ppn * generate.total_amount) / 100;

  const handleCancels = () => {
    setVisible(false);

    setData({
      ...data,
      date: moment().startOf("date"),
      ppn: 0,
      after_ppn: 0,
      subtotal: 0,
      grand_total: 0,
      icon: "",
      no_faktur_pajak: "",
    });
  };

  return (
    <Fragment>
      <Modal
        title={
          <div>
            <span style={{ fontSize: "22px" }}></span>
            <br />
            <span style={{ fontSize: "13px" }}>Are you sure? </span>
          </div>
        }
        centered
        visible={visible}
        onOk={() => processInvoice()}
        onCancel={() => handleCancels()}
        width={1000}
      >
        <Row type="flex" gutter={[10, 20]} justify="space-around">
          <Col xs={24} md={24} lg={24} className="align-right">
            <Form>
              <Col xs={24} className="align-right">
                <Space>
                  {/* <Form.Item
                    required
                    validateStatus={error && error.no_po ? "error" : false}
                    help={error && error.no_po ? error.no_po[0] : false}
                  >
                    <Input
                      placeholder="No PO"
                      style={{ width: 150 }}
                      value={data.no_po}
                      type="input"
                      onChange={(e) => handleChange("no_po", e.target.value)}
                    />
                  </Form.Item> */}

                  <Form.Item
                    required
                    validateStatus={
                      error && error.no_faktur_pajak ? "error" : false
                    }
                    help={
                      error && error.no_faktur_pajak
                        ? error.no_faktur_pajak[0]
                        : false
                    }
                  >
                    <Input
                      placeholder="No Faktur Pajak"
                      style={{ width: 150 }}
                      value={data.no_faktur_pajak}
                      type="input"
                      onChange={(e) =>
                        handleChange("no_faktur_pajak", e.target.value)
                      }
                    />
                  </Form.Item>
                </Space>
              </Col>

              <Space>
                <Col xs={24} className="align-right">
                  {select ? (
                    select.currency_name === "IDR" ? (
                      <Col xs={6} className="align-right">
                        <AntNumberFormat
                          placeholder="PPn"
                          value={data.ppn}
                          type="input"
                          suffix="percent"
                          decimalScale="DES"
                          onChange={(e) => {
                            handleChange("ppn", e);
                          }}
                          disabled={data.after_ppn}
                        />
                      </Col>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  <DatePicker
                    value={data.date}
                    onChange={handleChangeDate}
                    inputReadOnly={true}
                    format={"YYYY-MM-DD"}
                    allowClear={false}
                  />
                </Col>
              </Space>
            </Form>
          </Col>
        </Row>

        <Col xs={24}>
          <Table
            style={{ marginTop: 20 }}
            scroll={{ x: 750, y: 350 }}
            columns={columns}
            size="small"
            dataSource={selectedRowsData}
            rowKey={(data) => data._id}
            onChange={handleTableChange}
            pagination={{
              pageSize: 10,
              current: page,
              total: selectedRowsData.length,
              showSizeChanger: false,
            }}
            loading={loading}
          />
          <Col xs={24}>
            <div style={{ marginLeft: "2%" }}>
              <b>
                <Space>
                  <Title
                    style={{
                      fontSize: "100%",
                      marginTop: "8%",
                      fontWeight: "bold",
                    }}
                  >
                    Subtotal :
                  </Title>
                  <AntNumberFormat
                    style={{ marginLeft: "1%" }}
                    value={generate.total_amount}
                    type="text"
                    prefix={select ? select.currency_name : ""}
                    decimalScale={data.currency_name}
                    onChange={(e) => {
                      handleChange("subtotal", e);
                    }}
                  />
                </Space>
              </b>
              <br />
              <b>
                {select ? (
                  select.currency_name === "IDR" ? (
                    <Col xs={12}>
                      <Space>
                        <Title
                          style={{
                            fontSize: "100%",
                            marginTop: "8%",
                            fontWeight: "bold",
                          }}
                        >
                          PPn ({data.ppn}%) :
                        </Title>

                        <AntNumberFormat
                          value={totalPpn}
                          type="text"
                          prefix="IDR"
                          decimalScale={data.currency_name}
                          onChange={(e) => {
                            handleChange("ppn", e);
                          }}
                        />

                        {data.ppn > 0 ? (
                          <EditOutlined
                            onClick={() => {
                              handleIcon("icon");
                            }}
                          />
                        ) : (
                          ""
                        )}

                        {data.icon === "click" && data.ppn > 0 ? (
                          <AntNumberFormat
                            style={{ marginLeft: "1%" }}
                            value={data.after_ppn}
                            type="input"
                            prefix="IDR"
                            decimalScale={data.currency_name}
                            onChange={(e) => {
                              handleChange("after_ppn", e);
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Space>
                    </Col>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </b>
              <b>
                <Space>
                  <Title
                    style={{
                      fontSize: "100%",
                      marginTop: "8%",
                      fontWeight: "bold",
                    }}
                  >
                    Grand Total :
                  </Title>
                  <AntNumberFormat
                    style={{ marginLeft: "1%" }}
                    value={
                      data.after_ppn
                        ? generate.total_amount + data.after_ppn
                        : generate.total_amount + totalPpn
                    }
                    type="text"
                    prefix={select ? select.currency_name : ""}
                    decimalScale={data.currency_name}
                    onChange={(e) => {
                      handleChange("grand_total", e);
                    }}
                  />
                </Space>
              </b>
            </div>
          </Col>
        </Col>
      </Modal>
      <Row>
        <Col xs={24}>
          <Card type="inner" title="Comparison DN & Certificate"></Card>
        </Col>
      </Row>
      <Content className="content">
        <Row gutter={[20, 30]}>
          <Col xs={24}>
            <Card className="body-data">
              {/* <Col xs={24}>
                <Button
                  type="primary"
                  style={{ width: 150, marginBottom: "2%" }}
                  onClick={exportData}
                >
                  {" "}
                  Export{" "}
                </Button>
              </Col> */}

              <Table
                size="small"
                rowClassName={(data) =>
                  data.status_certificate === "pass" ? "row-green" : "row-red"
                }
                dataSource={dn}
                scroll={{ x: 800, y: 400 }}
                columns={columns}
                rowKey={(data) => data._id}
                onChange={handleTableChange}
                pagination={{
                  pageSize: perpage,
                  current: page,
                  total: total,
                  showSizeChanger: true,
                }}
                rowSelection={{
                  selectedRowKeys: selectedRowKeysData,
                  type: "checkbox",
                  onChange: (selectedRowKeys, selectedRows, index) => {
                    setSelectedRowsData(selectedRows);
                    setSelectedRowKeysData(selectedRowKeys);

                    setSelect(selectedRows ? selectedRows[0] : null);

                    const total_amount = selectedRows.reduce(
                      (total, item) => parseFloat(item.amount) + total,
                      0
                    );

                    const revisi_invoice_id = selectedRows
                      ? selectedRows[0]
                      : null;

                    setGenerate({
                      ...generate,
                      total_amount,
                      revisi_invoice_id,
                    });

                    console.log(
                      total_amount,
                      selectedRows,
                      selectedRowKeysData,
                      "ini select"
                    );

                    console.log(selectedRowKeys);
                  },
                  getCheckboxProps: (record) => {
                    const checkDisable = selectedRowsData
                      ? selectedRowsData[0]
                      : null;

                    return {
                      disabled:
                        record.status_certificate === "no pass" ||
                        (checkDisable && checkDisable.po_no !== record.po_no) ||
                        (checkDisable &&
                          checkDisable.currency_name !==
                            record.currency_name) ||
                        (checkDisable &&
                          moment(checkDisable.date_delivery).format("MM") !==
                            moment(record.date_delivery).format("MM")) ||
                        (checkDisable &&
                          moment(checkDisable.date_delivery).format("YYYY") !==
                            moment(record.date_delivery).format("YYYY")),
                    };
                  },

                  columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                }}
              />
            </Card>
          </Col>
          <Col xs={24}>
            <div style={{ marginLeft: "2%" }}>
              <b>Pass : {data.pass}</b>
              <br />
              <b>No Pass : {data.no_pass}</b>
            </div>
          </Col>
          <Col xs={24}>
            <div style={{ marginLeft: "2%" }}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                className="mr-button"
                onClick={() => setVisible(true)}
                disabled={!hasSelected}
              >
                Proses Invoice
              </Button>
              <Button onClick={handleCancel} disabled={!hasSelected}>
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default InvoiceCompareRev;
