/* eslint-disable */ 
import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import Axios from 'axios';
import {url} from '../../../config/global'
import QRCode from 'qrcode.react'
import './QRCodeRoute.less'

function QRCodeRoute(props) {

    // let urlLogo = 'http://sekisobe/logo-header.png'
    let urlLogo = 'http://192.168.0.6:8000/logo-header.png'

    const [data, setData] = useState({
        customer: '',
        truck: '',
        route: '',
        cycle: '',
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    if (props.match.params.id != null){

        useEffect(() => {
            getRoute()
        }, [])
    
        const getRoute = () => {

            Axios.get(`${url}/route`, {

                params: {
                    id: props.match.params.id
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }

            }).then(res => {

                setData({
                    ...data,
                    customer: res.data.data[0].customer,
                    truck: res.data.data[0].truck,
                    route: res.data.data[0].route,
                    cycle: res.data.data[0].cycle,
                })
                setLoading(false)

            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
            })
        }
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    if (loading) {
      return <div style={{
        flex: 1,
        justifyContent: "center",
        flexDirection: "row",
        padding: 10
      }}>
          Loading
      </div>;
    }

    console.log(`${data.customer};${data.route};${data.truck};${data.cycle}`)

    return (
        <div className="centered">
            <div className="qrcontainer">
                <div className="qrheader">
                    <img style={{padding:15}} src={ urlLogo } alt="Sekiso Logo" width={1000} />
                </div>
                <div className="qrbody">
                    <div className="qrbody-left">
                        <div className="qrbody-item">
                            <h1>Customer</h1>
                            <h2>{data.customer}</h2>
                        </div>
                        <div className="qrbody-item">
                            <h1>Route</h1>
                            <h2 style={{fontSize: '3em'}}>{data.route}</h2>
                        </div>
                    </div>
                    <QRCode className="qrbody-qrcode" size={300} value={`${data.customer};${data.route};${data.truck};${data.cycle}`} />
                    <div className="qrbody-right">
                        <div className="qrbody-item">
                            <h1>Truck</h1>
                            <h2>{data.truck}</h2>
                        </div>
                        <div className="qrbody-item">
                            <h1>Cycle</h1>
                            <h2 style={{fontSize: '3em'}}>{data.cycle}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QRCodeRoute