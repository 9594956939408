/* eslint-disable */ 
import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, InputNumber, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, DatePicker, TimePicker, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import {url} from '../../config/global'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function HeijunkaProdManage(props) {

    const [data, setData] = useState({
        prod_dt: '',
        prod_tm: '',
        part_no: '',
        customer: '',
        qty: '',
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    //#region get Data
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        Axios.get(`${url}/productionhdetail`, {

            params: {
                id: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {

            res = res.data.data
            
            setData({
                ...data,
                prod_dt: res.prod_dt,
                prod_tm: res.prod_tm,
                part_no: res.part_no,
                customer: res.customer,
                qty: res.qty,
            })

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }
    //#endregion

    const onSaveData = () => {

        setLoading(true)

        let formData = new FormData()

        formData.set('prod_dt', `${data.prod_dt} ${data.prod_tm}`)
        formData.set('part_no', data.part_no)
        formData.set('customer', data.customer)
        formData.set('qty', data.qty)

        Axios.post(`${url}/productionhdetail/${props.match.params.id}`, formData, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {
            message.success(res.data.message)
            props.history.push('/production-heijunka')

        }).catch(err => {

            if (err.response) {

                message.error(err.response.data.message)

                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }

                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }

            setLoading(false)
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Production Heijunka" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/production-heijunka">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Heijunka</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                                {...layout}
                                name="control-hooks"
                                onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Date"
                                >
                                    <DatePicker
                                        value={moment(data.prod_dt, 'YYYY-MM-DD')}
                                        onChange={(Item) => handleChange('prod_dt', moment(Item).format('YYYY-MM-DD'))}
                                        inputReadOnly={true}
                                        format={'YYYY-MM-DD'}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Time"
                                >
                                    <TimePicker
                                        value={moment(data.prod_tm, 'HH')}
                                        onChange={(Item) => handleChange('prod_tm', `${moment(Item).format('HH')}:00:00`)}
                                        inputReadOnly={true}
                                        format={'HH'}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Part Number"
                                >
                                    <Input value={data.part_no} onChange={(e) => handleChange('part_no', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Customer"
                                >
                                    <Input value={data.customer} onChange={(e) => handleChange('customer', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Qty"
                                >
                                    <InputNumber value={data.qty} onChange={(val) => handleChange('qty', val)} style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default HeijunkaProdManage