/* eslint-disable */ 
import React, { useState, useEffect, Fragment } from 'react'
import {Card, Typography, Form, Layout, Row, Col, Input, message, Table} from 'antd';
import {Link, Redirect} from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import { url}  from '../../config/global'
import './Scan.less'

const { Title } = Typography
const { Search } = Input
const { Content } = Layout

const layout = {
    labelCol: {
        md: 8
    },
    wrapperCol: {
        md: 8
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}


function ScanTruck() {

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [data, setData] = useState([]);
    const [scanData, setScanData] = useState('')
    const [shiftStart, setShiftStart] = useState(0);

    const [loading, setLoading] = useState(false)

    //#region Get Data
    useEffect(() => {
        setLoading(true)

        getData(date)
        getShift1()

        if (!loading){

            let rotationInterval = setInterval(() => {

                getData(date)

            }, 15000);

            return () => {
                clearInterval(rotationInterval);
            }
            
        }

    }, [date])

    const getData = async () => {

        setLoading(true)

        const results = await Axios.get(`${url}/deliverynote`, {

            params: {
                date: {
                    start: date,
                    end: date
                },
                type: 1
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            
        }).then(res => {

            return res.data;
    
        })

        const res = results.data;

        setData(res);
        setLoading(false)

    }

    const getShift1 = async () => {

        setLoading(true)

        const results = await Axios.get(`${url}/shift`, {

            params: {
                shift: 'Shift 1'
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
            
        }).then(res => {

            return res.data;
    
        })

        const res = results.data;

        setShiftStart(res.start);
        setLoading(false)

    }
    //#endregion
 
    //#region Update
    const handleScan = () => {

        setLoading(true)

        let formData = new FormData()

        const CheckHour = moment(new Date()).format('H')

        if (CheckHour >= shiftStart){

            setDate(moment(new Date()).format('YYYY-MM-DD'))

        }

        formData.set('manifest_no', scanData)

        Axios.post(`${url}/deliverynotedetail`, formData, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {

            message.success(res.data.message)

        }).catch(err => {

            if (err.response) {

                message.error(err.response.data.message)

                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    
        setLoading(false)

        getData(date)
        setScanData('')

    }
    //#endregion

    //#region Table Settings
    const columns = [
        {
            title: 'No',
            dataIndex: 'index',
            align: 'center',
            render: (text, record, index) => index + 1,
        },
        {
            title: 'Manifest Number',
            dataIndex: 'manifest_no',
            align: 'center',
        },
        {
            title: 'Delivery Time',
            dataIndex: 'date_delivery',
            align: 'center',
        },
        {
            title: 'Receive Time',
            dataIndex: 'date_receiving',
            align: 'center',
        },
    ]
    //#endregion

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }
    
    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Scan Delivery Note" bodyStyle={{padding: "0"}} extra={moment(new Date()).format('DD MMMM YYYY')}></Card>
                </Col>
            </Row>

            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <div className="containerScan">
                            <Card>
                                <Form 
                                    name="control-hooks"
                                    onFinish={handleScan}
                                    className="formQRCode"
                                >
                                    <Input value={scanData} onChange={(e) => setScanData(e.target.value)} autoFocus={true} placeholder="Manifest No" required={true} />
                                    
                                </Form>
                                <Table
                                    size="small"
                                    dataSource={data}
                                    columns={columns}
                                    rowKey={
                                        data => data.no
                                    }
                                    bordered
                                    scroll={{x: 'auto'}}
                                    pagination={false}
                                    loading={loading}
                                    style={{
                                        margin: '15px 0'
                                    }}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Fragment>
    )
}



export default ScanTruck