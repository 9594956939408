/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  message,
  Space,
  DatePicker,
  Select,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import { ExportOutlined } from "@ant-design/icons";

import fileDownload from "js-file-download";

const { RangePicker } = DatePicker;
const { Search } = Input;
const { Content } = Layout;
const { Option } = Select;

function InvoicingGr() {
  const [keyword, setKeyword] = useState("");
  const [invoicingGr, setInvoicingGr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "date",
    order: "ascend",
  });

  const [data, setData] = useState({
    customer: [],
    customer_id: "",
    customer_alias: "",
    customer_label: "",
  });

  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, date.from, date.to, data.customer_alias]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/invoicing-gr`, {
      params: {
        keyword,
        columns: ["manifest_no"],
        perpage: 10,
        page,
        customer: data.customer_alias,
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setInvoicingGr(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "customer") {
      console.log(value, 'test')
      if(value){
        const find = data.customer.find((x) => x._id === value.key);

        setData({
          ...data,
          [`${name}_id`]: value.value,
          [`${name}_label`]: value.label,
          [`${name}_code`]: find.code,
          [`${name}_name`]: find.name,
          [`${name}_alias`]: find.alias,
        });
      }else{

      setData({
        ...data,
        [`${name}_id`]: "",
        [`${name}_label`]: "",
      });
      }
      

    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDate = (value) => {
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const exportInvoicingGr = () => {
    Axios.get(`${url}/invoicing-gr/export`, {
      params: {
        keyword,
        columns: ["manifest_no"],
        perpage: 10,
        page,
        customer: data.customer_alias,
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Invoicing GR.xlsx");
    });
  };

  const columns = [
    {
      title: "DN Number",
      dataIndex: "manifest_no",
      sorter: false,
    },
    {
      title: "Item Code",
      dataIndex: "part_no",
      sorter: false,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      sorter: false,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: false,
      render: (date) => moment(date).format('DD MMM YYYY'),
    },
    {
      title: "Delivery Date",
      dataIndex: "date_delivery",
      sorter: false,
      width: 150,
      render: (date) =>
        date === "" ? "-" : moment(date).format("DD MMM YYYY"),
    },
    {
      title: "Receive Date",
      dataIndex: "date_receiving",
      sorter: false,
      width: 150,
      render: (date) =>
        date === "" ? "-" : moment(date).format("DD MMM YYYY"),
    },
    {
      title: "No Invoice",
      sorter: true,
      render: (data) =>
        data.invoice_details &&
        data.invoice_details.invoice &&
        data.invoice_details.invoice.no_invoice,
    },
    {
      title: "Invoice Date",
      sorter: false,
      render: (data) =>
        data.invoice_details &&
        data.invoice_details.invoice &&
        moment(data.invoice_details.invoice.date).format("DD MMM YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      width: 150,
      render: (value, item, index) =>
        value == 1 ? (
          <span style={{ color: "#00CC00" }}>Delivery</span>
        ) : value == 2 ? (
          <span style={{ color: "#FF0000" }}>Receive</span>
        )  : value == 3 ? (
          <span style={{ color: "#36e7ff" }}>Invoice</span>
        )
        : (
          <span>Open</span>
        ),
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Invoicing GR"
            bodyStyle={{ padding: "0" }}
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} className="align-right">
                  <RangePicker
                    onChange={handleChangeDate}
                    value={[date.from, date.to]}
                    format="DD MMM YYYY"
                    picker="date"
                  />
                </Col>

                <Col xs={24} md={12} lg={12}>
                  <Button type="primary" onClick={exportInvoicingGr}>
                    <ExportOutlined />
                    Export
                  </Button>
                </Col>
                <Col xs={24} md={12} lg={12} className="align-right">
                  <Space>
                    <Select
                      labelInValue
                      allowClear={true}
                      style={{ width: 150 }}
                      onChange={(value) =>
                        handleChangeSelect("customer", value)
                      }
                      onFocus={() => getCustomer("")}
                      showSearch
                      onSearch={(value) => getCustomer(value)}
                      filterOption={false}
                      placeholder="Select Customer"
                      // value={{
                      //   key: data.customer_id,
                      //   label: data.customer_label,
                      // }}
                    >
                      {data.customer &&
                        data.customer.map((customers) => {
                          return (
                            <Option value={customers._id} key={customers._id}>
                              {customers.alias}
                            </Option>
                          );
                        })}
                    </Select>

                    <Search
                      placeholder="Search ..."
                      onSearch={(value) => handleSearch(value)}
                    />
                  </Space>
                </Col>
                <Col xs={24}>
                  <Table
                    columns={columns}
                    dataSource={invoicingGr.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: invoicingGr.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default InvoicingGr;
