/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Card,
  Col,
  Layout,
  Table,
  Button,
  Modal,
  Upload,
  Space,
  Select,
  DatePicker,
  message,
  Carousel,
  Pagination,
} from "antd";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
// import {} from "react-chartjs-2";
import moment from "moment";
import { extendMoment } from "moment-range";

import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
// import "./GensanKanri.less";
// import "./Dashboard.less";
import fileDownload from "js-file-download";

import FilterCom from "../../components/Dashboard/FilterCom";
import { stringComaToNumber, removePercent } from "../../helper/function";
import { object } from "underscore";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";

import { faker } from "@faker-js/faker";

const { Option } = Select;

const { Content } = Layout;
const { Column, ColumnGroup } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const range = extendMoment(moment);

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

function GensanKanri() {
  //   const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  // const [date, setDate] = useState(moment('2021-06-17').format('YYYY-MM-DD'));

  const [dataExcel, setDataExcel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [comodity, setComodity] = useState([]);
  const [item, setItem] = useState([]);
  const [dataBar, setDataBar] = useState([]);
  const [dataBarAmount, setDataBarAmount] = useState([]);
  const [plan, setPlan] = useState([]);
  const [actual, setActual] = useState([]);
  const [percentage, setPercentage] = useState([]);
  const [amountActual, setAmountActual] = useState([]);
  const [amountPlan, setAmountPlan] = useState([]);
  const [checkAmount, setCheckAmount] = useState([]);
  const [total, setTotal] = useState([]);

  const [errors, setErrors] = useState(null);
  const [page, setPage] = useState(1);
  const [perpage, setPerpage] = useState(4);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [pieMap, setPieMap] = useState([]);

  const [data, setData] = useState({
    date: moment().startOf("month"),
    item_code: "",
    comodity_name: "",
  });

  useEffect(() => {
    getData();
    getRole();
  }, [data]);

  const handleChangeDate = (value) => {
    if (value) {
      setData({
        ...data,
        date: value,
      });
    } else {
      setData({
        ...data,
        date: undefined,
      });
    }
  };

  const handleChangeViewItem = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_code`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_code`]: null,
      });
    }
  };

  const handleChangeViewComodity = (name, value) => {
    setErrors(null);

    if (value) {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });
    } else {
      setData({
        ...data,
        [`${name}_id`]: null,
        [`${name}_name`]: null,
      });
    }
    console.log("value", value);
  };

  const getComodity = async (keyword) => {
    await Axios.get(`${url}/gensan-kanri/list-comodity`, {
      params: {
        keyword,
        columns: ["comodity"],
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getItemCode = async (keyword) => {
    await Axios.get(`${url}/gensan-kanri/list-item`, {
      params: {
        keyword,
        columns: ["item_code"],
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        customer_name: data.customer_name,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItem(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = async (keyword) => {
    setLoading(true);

    let grafik = [];
    let min = [];
    let max = [];
    let labelName = [];
    let plan = [];
    let amountActual = [];
    let label = [];

    await Axios.get(`${url}/gensan-kanri`, {
      params: {
        keyword,
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        // setItem(res.data.data);
        setPlan(res.data.total_plan);
        setActual(res.data.total_actual);
        setPercentage(res.data.percentage);
        setAmountActual(res.data.amount_actual);
        setAmountPlan(res.data.amount_plan);
        setTotal(res.data.total);
        for (const dataObj of res.data.data_bar) {
          labelName.push(dataObj.tanggal);
          min.push(dataObj.min_stock);
          max.push(dataObj.max_stock);
          grafik.push(dataObj.grafik);
          plan.push(dataObj.planning_stock);
        }
        // for (const dataObj of res.data.data_bar_amount) {
        //   label.push(dataObj.label);
        //   amountPlan.push(parseInt(dataObj.amount_plan));
        //   amountActual.push(parseInt(dataObj.amount_actual));
        // }

        // setPieMap(res.data.data_pie);

        setDataBar({
          labels: labelName,
          datasets: [
            {
              label: "Quantity",
              data: grafik,
              type: "line",
              fill: false,
              //   backgroundColor: "#E67E00",
              borderColor: "#0000FF",
            },
            {
              label: "Planning Stock",
              data: plan,
              type: "line",
              fill: false,
              //   backgroundColor: "#E67E00",
              borderColor: "green",
            },
            {
              label: "Min",
              data: min,
              type: "line",
              fill: false,
              //   backgroundColor: "#E67E00",
              borderColor: "red",
            },
            {
              label: "Max",
              data: max,
              type: "line",
              fill: false,
              //   backgroundColor: "#E67E00",
              borderColor: "yellow",
            },
          ],
        });
      })
      .catch((err) => {
        console.log("error", err.response.data.message);
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
    setLoading(false);
  };

  console.log("data_bar", total);

  const getRole = () => {
    setCheckAmount(localStorage.getItem("amount"));
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  };

  const downloadTemplateData = () => {
    setLoadingExport(true);
    Axios.get(`${url}/gensan-kanri/export`, {
      params: {
        limit: perpage,
        page,
        sortBy: `${sort.sort}:${sort.order}`,
        date: moment(data.date).format("YYYY-MM"),
        item_code: data.item_code,
        comodity_name: data.comodity_name,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(
        res.data,
        "Gensan-Kanri" +
          moment(new Date()).format("yyyy-MMM-DD hh:mm:ss") +
          ".xlsx"
      );
      setLoadingExport(false);
    });
    // setLoadingExport(false);
  };

  const ModalBtn = (
    <div
      style={{
        display: "flex",
      }}
    >
      <div>
        <Button
          onClick={downloadTemplateData}
          type="primary"
          style={{
            display: "flex",
            marginBottom: 15,
            backgroundColor: "#1b2086",
            borderColor: "#1b2086",
            marginLeft: "auto",
          }}
          loading={loadingExport}
        >
          Export
        </Button>
      </div>
    </div>
  );

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row className="header-body">
        <Col xs={24}>
          <Card
            type="inner"
            className="title-dashboard"
            title="Gensan Kanri"
            bodyStyle={{ padding: "0" }}
            extra={ModalBtn}
          ></Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Col xs={24} md={12} lg={6} className="align-left">
                <DatePicker
                  style={{ width: 239 }}
                  onChange={handleChangeDate}
                  picker="month"
                  value={data.date}
                  format="MMM YYYY"
                />
              </Col>
              <Col xs={24} md={12} lg={6} style={{ marginTop: 25 }}>
                <Select
                  allowClear="true"
                  style={{
                    width: 239,
                  }}
                  className="width"
                  labelInValue
                  placeholder="Select Comodity"
                  onChange={(value) =>
                    handleChangeViewComodity("comodity", value)
                  }
                  onFocus={() => getComodity("")}
                  showSearch
                  onSearch={(value) => getComodity(value)}
                  filterOption={false}
                >
                  {comodity &&
                    comodity.map((com, index) => {
                      return (
                        <Option value={index} key={index}>
                          {com.comodity}
                        </Option>
                      );
                    })}
                </Select>
              </Col>
              <Col xs={24} md={12} lg={6} style={{ marginTop: 25 }}>
                <Select
                  allowClear="true"
                  style={{
                    width: 239,
                  }}
                  className="width"
                  labelInValue
                  placeholder="Select Item"
                  onChange={(value) => handleChangeViewItem("item", value)}
                  onFocus={() => getItemCode("")}
                  showSearch
                  onSearch={(value) => getItemCode(value)}
                  filterOption={false}
                >
                  {item &&
                    item.map((part, index) => {
                      return (
                        <Option value={index} key={index}>
                          {part.item_code}
                        </Option>
                      );
                    })}
                </Select>
              </Col>

              <Col xs={24} md={24} lg={24} className="main-body">
                <Card
                  style={{
                    marginBottom: 10,
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  {!loading ? (
                    <Bar options={options} data={dataBar} />
                  ) : (
                    <div className="loading">
                      <FontAwesomeIcon
                        icon={faSpinner}
                        className="spinner"
                        size="2x"
                        pulse
                      />
                    </div>
                  )}
                </Card>
              </Col>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default GensanKanri;
