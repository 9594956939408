let url;
// const socketUrl = "http://192.168.1.6:8000/api";
const socketUrl = "https://api.sekiso.kelola.biz/api";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // url = "https://api.dev-sankei.kelola.biz/api";

  // url = "http://192.168.1.6:8000/api"; // mas irfan
  // url = "http://192.168.1.8:8000/api"; // mas Yuli
  // url = "http://192.168.1.7:8000/api"; // Server
  // url = "https://api.sdi.kelola.biz/api";

  // url = "http://192.168.1.6:8000/api";

  // url = "http://192.168.31.39:8000/api";

  //ato
  // url = "http://127.0.0.1:8000/api";

  // url = "http://192.168.18.95:8000/api";
  //ato
  // url = "http://127.0.0.1:8000/api";
  url = "https://api.sekiso.kelola.biz/api";
  //langsung backend sendiri
  //url= 'http://192.168.43.155:8000/api'
  // url = "http://192.168.100.2:8001/api";/
  //url='http://192.168.1.78:8000/api'
} else {
  // url = "https://api.sdi.kelola.biz/api";
  //url = "http://192.168.1.6:8000/api";
  url = "https://api.sekiso.kelola.biz/api";
  // url = "https://api.sdi.kelola.biz/api";
  // url = "http://192.168.1.12:8000/api";
  // url = "http://192.168.18.95:8000/api";
  // url = "http://192.168.18.95:8000/api";
  // url = "http://127.0.0.1:8000/api";
  // url = "http://192.168.1.10:8002/api";
  // url = "http://192.168.43.114:8000/api";
}

export { url, socketUrl };
