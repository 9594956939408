/* eslint-disable */
import React, { Fragment, useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Checkbox,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 8,
    md: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateUser(props) {
  const [data, setData] = useState({
    username: "",
    full_name: "",
    password: "",
    password_confirmation: "",
    role_id: "",
    role_name: "",
    email: "",
    position: "",
    refer_id: "",
    refer_name: "",
    photo: null,
    roles: [],
    amount: "",
    line: "",
  });

  useEffect(() => {
    getPosition();
    getLine();
  }, []);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [positions, setPosition] = useState([]);
  const [lines, setLine] = useState([]);

  const handleRemove = () => {
    setData({
      ...data,
      photo: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      photo: files,
    });

    return false;
  };

  const getPosition = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Position",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setPosition(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getLine = async (keyword) => {
    await Axios.get(`${url}/location/list-line`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setLine(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getRole = async (keyword) => {
    await Axios.get(`${url}/role/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          roles: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_id`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const handleChangeSelectLine = (name, value) => {
    setError(null);
    setData({
      ...data,
      [`${name}_code`]: value.value,
      [`${name}_name`]: value.label,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("username", data.username);
    fd.set("full_name", data.full_name);
    fd.set("password", data.password);
    fd.set("password_confirmation", data.password_confirmation);
    fd.set("role_id", data.role_id);
    fd.set("role_name", data.role_name);
    fd.set("position", data.position);
    fd.set("email", data.email);
    fd.set("amount", data.amount);
    fd.set("line_code", data.line_code);
    fd.set("line_name", data.line_name);
    fd.append("photo", data.photo);

    Axios.post(`${url}/user`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/user");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      username: "",
      full_name: "",
      password: "",
      password_confirmation: "",
      role_id: "",
      amount: "",
      line_code: "",
      line_name: "",
      photo: null,
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="User"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/user">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add User</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} name="control-hooks" onFinish={onSaveData}>
                <Form.Item
                  required
                  label="Username"
                  validateStatus={error && error.username ? "error" : false}
                  help={error && error.username ? error.username[0] : false}
                >
                  <Input
                    value={data.username}
                    onChange={(e) => handleChange("username", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Full Name"
                  validateStatus={error && error.full_name ? "error" : false}
                  help={error && error.full_name ? error.full_name[0] : false}
                >
                  <Input
                    value={data.full_name}
                    onChange={(e) => handleChange("full_name", e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  required
                  label="Password"
                  validateStatus={error && error.password ? "error" : false}
                  help={error && error.password ? error.password[0] : false}
                >
                  <Input.Password
                    value={data.password}
                    onChange={(e) => handleChange("password", e.target.value)}
                  />
                </Form.Item>
                <Form.Item required label="Retype Password">
                  <Input.Password
                    value={data.password_confirmation}
                    onChange={(e) =>
                      handleChange("password_confirmation", e.target.value)
                    }
                  />
                </Form.Item>

                <Form.Item
                  required
                  label="Position"
                  validateStatus={error && error.position ? "error" : false}
                  help={error && error.position ? error.position[0] : false}
                >
                  <Select onChange={(value) => handleChange("position", value)}>
                    {positions &&
                      positions.map((position) => {
                        return (
                          <Option value={position.name} key={position._id}>
                            {position.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item
                  required
                  label="Email"
                  validateStatus={error && error.email ? "error" : false}
                  help={error && error.email ? error.email[0] : false}
                >
                  <Input
                    value={data.email}
                    onChange={(e) => handleChange("email", e.target.value)}
                  />
                </Form.Item>

                <Form.Item required label="Role">
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelect("role", value)}
                    onFocus={() => getRole("")}
                    showSearch
                    onSearch={(value) => getRole(value)}
                    filterOption={false}
                    value={{ key: data.role_id, label: data.role_name }}
                  >
                    {data.roles &&
                      data.roles.map((role) => {
                        return (
                          <Option value={role._id} key={role._id}>
                            {role.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item name="photo" label="Photo">
                  <Fragment>
                    <Upload
                      onRemove={handleRemove}
                      beforeUpload={handleBeforeUpload}
                      fileList={[]}
                    >
                      <Button>
                        Upload <UploadOutlined />
                      </Button>
                    </Upload>
                    {data.photo && (
                      <List>
                        <List.Item
                          actions={[
                            <Button onClick={handleRemove} type="danger" ghost>
                              <DeleteOutlined />
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta description={data.photo.name} />
                        </List.Item>
                      </List>
                    )}
                  </Fragment>
                </Form.Item>
                <Form.Item required label="Line">
                  <Select
                    labelInValue
                    onChange={(value) => handleChangeSelectLine("line", value)}
                    onFocus={() => getLine("")}
                    showSearch
                    onSearch={(value) => getLine(value)}
                    filterOption={false}
                    value={{ key: data.line_code, label: data.line_name }}
                  >
                    {lines &&
                      lines.map((line) => {
                        return (
                          <Option value={line.code} key={line.code}>
                            {line.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                {/* <Form.Item
                  required
                  label="Line"
                  validateStatus={error && error.line ? "error" : false}
                  help={error && error.line ? error.line[0] : false}
                >
                  <Select onChange={(value) => handleChange("line", value)}>
                    {lines &&
                      lines.map((line) => {
                        return (
                          <Option value={line.name} key={line._id}>
                            {line.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item> */}
                <Form.Item
                  label="Amount"
                  validateStatus={error && error.amount ? "error" : false}
                  help={error && error.amount ? error.amount[0] : false}
                >
                  <Checkbox
                    checked={data.amount}
                    onChange={(e) => handleChange("amount", e.target.checked)}
                  >
                    Yes
                  </Checkbox>
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="mr-button"
                  >
                    Submit
                  </Button>
                  <Button htmlType="reset" onClick={handleReset}>
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateUser;
