/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import profile from "../../../assets/img/item.png";
import fileDownload from "js-file-download";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Currency() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([
    {
      currency_name: "",
      exchange_idr: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/currency`, {
      params: {
        keyword,
        columns: ["currency_name", "exchange_idr"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/currency/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/currency/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const columns = [
    {
      title: "Currency Name",
      dataIndex: "currency_name",
      sorter: true,
      width: 150,
    },
    {
      title: <div style={{ textAlign: "left" }}>Exchange IDR</div>,
      dataIndex: "exchange_idr",
      align: "right",
      sorter: false,
      width: 150,
      render: (exchange_idr) => {
        return <AntNumberFormat value={exchange_idr} type="text" />;
      },
    },
    {
      title: "Created By",
      width: 150,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 150,
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      width: 150,
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      width: 150,
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/master-currency-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Currency"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/master-currency-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                  </Space>
                  <Modal
                    title="Import Currency"
                    visible={isModalVisible}
                    footer={null}
                    onCancel={closeModal}
                  >
                    <Card className="body-data">
                      <div name="control-hooks">
                        <div name="file" label="File">
                          <Fragment>
                            <Upload
                              onRemove={handleRemove}
                              beforeUpload={handleBeforeUpload}
                              fileList={[]}
                            >
                              <Button style={{ marginBottom: 10 }}>
                                Choose File <ExportOutlined />
                              </Button>
                            </Upload>
                            {data.file && (
                              <React.Fragment
                                children={[
                                  <Button
                                    onClick={handleRemove}
                                    type="danger"
                                    ghost
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              >
                                {data.file.name ? data.file.name : data.file}
                              </React.Fragment>
                            )}
                          </Fragment>
                        </div>
                        <div>
                          <Button
                            loading={loading}
                            onClick={onSaveData}
                            type="primary"
                            htmlType="submit"
                            className="mr-button"
                            style={{
                              marginRight: 10,
                              backgroundColor: "#1b2086",
                              borderColor: "#1b2086",
                            }}
                          >
                            Upload
                          </Button>
                          <Button htmlType="reset" onClick={handleRemove}>
                            Reset
                          </Button>
                        </div>
                      </div>
                    </Card>
                  </Modal>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Currency;
