import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Form,
  Menu,
  Card,
  Button,
  Dropdown,
  Layout,
  Row,
  Col,
  Input,
  Table,
  Modal,
  Select,
  Pagination,
  message,
  DatePicker,
  Space,
  Typography,
  Popconfirm,
  InputNumber,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";
import { EditOutlined, MoreOutlined, SettingOutlined } from "@ant-design/icons";

const { Content } = Layout;
const { Option } = Select;
const { Search } = Input;
const { RangePicker } = DatePicker;

function SuggestionPPIC(props) {
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    date: "",
    dates: "",
    manifest_no: "",
    part_no: "",
    customer: "",
    comodity: "",
    qty: "",
    adjustment_qty: 0,
    status_adjusment: 0,
    dataSuggest: [],
    total: 0,
    shift_1: 0,
    shift_2: 0,
    shift_3: 0,
  });
  const [keyword, setKeyword] = useState("");
  const [isModalVisibleSuggest, setIsModalVisibleSuggest] = useState(false);
  const [loading, setLoading] = useState(false);
  const [comodity, setComodity] = useState([]);
  const [sort, setSort] = useState({
    sort: "supplier_name",
    order: "ascend",
  });
  const [page, setPage] = useState(1);
  const [date, setDate] = useState({
    from: moment(new Date()).startOf("month"),
    to: moment(new Date()).endOf("month"),
  });

  const layout = {
    labelCol: {
      lg: 9,
      md: 10,
    },
    wrapperCol: {
      lg: 14,
      md: 14,
    },
  };

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort, data.comodity, data.status_adjusment, date.from, date.to]);

  useEffect(() => {
    getCommodity();
  }, []);

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      shift_1: "",
      shift_2: "",
      shift_3: "",
      ...record,
    });
    setEditingKey(record._id);

    console.log(record);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    setLoading(true);
    const row = await form.validateFields();
    const newData = [...data.dataSuggest];
    const index = newData.findIndex((item) => key === item._id);
    const item = newData[index];

    Axios.post(
      `${url}/suggestion-ppic`,
      {
        manifest_no: item.manifest_no,
        date: item.date,
        part_no: item.part_no,
        customer: item.customer,
        qty: item.qty,
        shift_1: row.shift_1,
        shift_2: row.shift_2,
        shift_3: row.shift_3,
        adjustment_qty: row.shift_1 + row.shift_2 + row.shift_3,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        props.history.push("/suggestion-ppic");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    setEditingKey("");
    setLoading(false);

    // try {
    //   const row = await form.validateFields();
    //   const newData = [
    //     ...data.dataSuggest,
    //   ];
    //   const index = newData.findIndex((item) => key === item._id);
    //   if (index > -1) {
    //     const item = newData[index];
    //     newData.splice(index, 1, {
    //       ...item,
    //       ...row,
    //     });
    //     setData({...data, dataSuggest: newData});
    //     setEditingKey("");
    //   } else {
    //     newData.push(row);
    //     setData(newData);
    //     setEditingKey("");
    //   }
    //   const item = newData[index];

    //   console.log(row, row.shift_1);
    // } catch (errInfo) {
    //   console.log("Validate Failed:", errInfo);
    // }
  };

  const handleChangeDate = (value) => {
    console.log(value);
    if (value !== null) {
      setDate({
        from: value[0],
        to: value[1],
      });
    } else {
      setDate({
        from: "",
        to: "",
      });
    }
  };

  const getCommodity = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Comodity",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComodity(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/suggestion-ppic`, {
      params: {
        keyword,
        select: data.comodity,
        status_adjusment: data.status_adjusment,
        start_date: moment(date.from).format("DD-MM-YYYY"),
        end_date: moment(date.to).format("DD-MM-YYYY"),
        columns: ["part_no", "customer", "qty", "date", "comodity"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dataSuggest: res.data.data,
          total: res.data.total,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    console.log(localStorage.getItem("role_name"));
  };

  const handleChangeSelect = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    key,
    record,
    index,
    children,
    ...restProps
  }) => {
    // const inputNode = (
    //   <AntNumberFormat
    //     onChange={(value) => handleChange(dataIndex, value)}
    //     type="input"
    //   />
    // );

    // const handleChangeCell = (name, value) => {
    //   setError(null);
    //   setData({
    //     ...data,
    //     [name]: value,
    //   });

    //   console.log(name, value);
    // };

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input Number!`,
              },
            ]}
          >
            <AntNumberFormat
              type="input"
              // onChange={(value) => handleChangeCell(dataIndex, value)

              // }
            />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });

    console.log(name, value);
  };

  const handleReset = () => {
    setData({
      ...data,
      adjustment_qty: 0,
    });
  };

  const showModalSuggest = (id) => {
    const suggest = data.dataSuggest.find((data) => data._id === id);

    console.log(suggest.date);

    setData({
      ...data,
      manifest_no: suggest.manifest_no,
      shift_1: suggest.shift_1,
      shift_2: suggest.shift_2,
      shift_3: suggest.shift_3,
      date: moment(suggest.date),
      dates: suggest.date,
      part_no: suggest.part_no,
      customer: suggest.customer,
      qty: suggest.qty,
      adjustment_qty: suggest.adjustment_qty,
    });
    setIsModalVisibleSuggest(true);

    console.log(suggest.date, "moment");
  };

  const onSubmit = () => {
    setLoading(true);

    Axios.post(
      `${url}/suggestion-ppic`,
      {
        manifest_no: data.manifest_no,
        date: data.dates,
        part_no: data.part_no,
        customer: data.customer,
        qty: data.qty,
        shift_1: data.shift_1,
        shift_2: data.shift_2,
        shift_3: data.shift_3,
        adjustment_qty: data.adjustment_qty,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
        props.history.push("/suggestion-approval-ppic");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    closeModalSuggest();
    setLoading(false);
  };

  const closeModalSuggest = () => {
    setData({
      ...data,
      date: "",
      manifest_no: "",
      part_no: "",
      customer: "",
      comodity: "",
      qty: "",
      adjustment_qty: 0,
      total: 0,
      shift_1: 0,
      shift_2: 0,
      shift_3: 0,
    });
    setIsModalVisibleSuggest(false);
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Item Code",
      dataIndex: "part_no",
      key: "part_no",
      align: "center",
    },
    {
      title: "Comodity",
      dataIndex: "comodity",
      key: "comodity",
      align: "center",
    },
    {
      title: "Customer",
      key: "customer",
      dataIndex: "customer",
      align: "center",
    },
    {
      title: <div style={{ textAlign: "center" }}>Suggestion Qty</div>,
      key: "qty",
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.qty}
            type="text"
          />
        );
      },
    },
    {
      title: <div style={{ textAlign: "center" }}>Shift 1</div>,
      key: "shift_1",
      dataIndex: "shift_1",
      sorter: false,
      align: "right",
      editable: true,
    },
    {
      title: <div style={{ textAlign: "center" }}>Shift 2</div>,
      key: "shift_2",
      dataIndex: "shift_2",
      sorter: false,
      align: "right",
      editable: true,
    },
    {
      title: <div style={{ textAlign: "center" }}>Shift 3</div>,
      key: "shift_3",
      dataIndex: "shift_3",
      sorter: false,
      align: "right",
      editable: true,
    },
    {
      title: <div style={{ textAlign: "center" }}>Adjustment Qty</div>,
      key: "adjustment_qty",
      sorter: false,
      align: "right",
      render: (data) => {
        return (
          <AntNumberFormat
            decimalScale={data.currency_name}
            value={data.adjustment_qty}
            type="text"
          />
        );
      },
    },
    {
      title: "",
      // dataIndex: "_id",
      align: "center",
      sorter: false,
      fixed: "right",
      width: 180,
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="primary"
              style={{
                marginRight: 8,
              }}
              onClick={() => save(record._id)}
            >
              Save
            </Button>
            <Button onClick={cancel} type="danger">
              Cancel
            </Button>
          </span>
        ) : (
          <Button
            style={{ width: 125 }}
            type="primary"
            disabled={editingKey !== "" || record.status_approve === 2}
            onClick={() => edit(record)}
          >
            Input Shift
          </Button>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "shift_1" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Fragment>
      <div>
        <Row type="flex" gutter={[10, 20]} justify="space-around">
          <Col xs={24}>
            <Card
              type="inner"
              title="Suggestion PPIC"
              
            >
              <Content className="content">
                <Row type="flex" gutter={[10, 20]} justify="space-around">
                  <Col xs={24} className="align-right">
                    <RangePicker
                      onChange={handleChangeDate}
                      value={[date.from, date.to]}
                      format="DD MMM YYYY"
                      picker="date"
                      // style={{ marginBottom: "2%" }}
                    />
                  </Col>
                  <Col xs={24} md={24} lg={24} className="align-right">
                    <Space>
                      <Select
                        style={{ width: 150 }}
                        placeholder="Select Status"
                        allowClear={true}
                        onChange={(value) =>
                          handleChangeSelect("status_adjusment", value)
                        }
                      >
                        <Option value="1">Not Yet</Option>
                        <Option value="2">Already Adjustment</Option>
                      </Select>
                      <Select
                        style={{ width: 150 }}
                        placeholder="Select Comodity"
                        allowClear={true}
                        onChange={(value) =>
                          handleChangeSelect("comodity", value)
                        }
                      >
                        {comodity &&
                          comodity.map((select) => {
                            return (
                              <Option value={select.name} key={select._id}>
                                {select.name}
                              </Option>
                            );
                          })}
                      </Select>
                      <Search
                        placeholder="Search Item Code..."
                        onSearch={(value) => handleSearch(value)}
                      />
                    </Space>
                  </Col>
                  <Col xs={24}>
                    <Row type="flex" gutter={[10, 20]} justify="space-around">
                      <Col xs={24}>
                        <Modal
                          title="Adjustment Suggestion PPIC"
                          style={{ top: 20 }}
                          visible={isModalVisibleSuggest}
                          width={1000}
                          footer={null}
                          onCancel={closeModalSuggest}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          validateStatus={
                                            error && error.date
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.date
                                              ? error.date[0]
                                              : false
                                          }
                                          label="Date"
                                        >
                                          <DatePicker
                                            disabled
                                            onChange={(value) =>
                                              handleChange("date", value)
                                            }
                                            value={data.date}
                                            format="YYYY-MM-DD"
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          validateStatus={
                                            error && error.part_no
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.part_no
                                              ? error.part_no[0]
                                              : false
                                          }
                                          label="Item Code"
                                        >
                                          <Input
                                            disabled
                                            value={data.part_no}
                                            onChange={(e) => {
                                              handleChange(
                                                "part_no",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.customer
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.customer
                                              ? error.customer[0]
                                              : false
                                          }
                                          label="Customer"
                                        >
                                          <Input
                                            disabled
                                            value={data.customer}
                                            onChange={(e) => {
                                              handleChange(
                                                "customer",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.qty ? "error" : false
                                          }
                                          help={
                                            error && error.qty
                                              ? error.qty[0]
                                              : false
                                          }
                                          label="Total Quantity"
                                        >
                                          <AntNumberFormat
                                            disabled
                                            type="input"
                                            value={data.qty}
                                            onChange={(e) => {
                                              handleChange("qty", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.shift_1
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.shift_1
                                              ? error.shift_1[0]
                                              : false
                                          }
                                          label="Shift 1"
                                        >
                                          <AntNumberFormat
                                            type="input"
                                            value={data.shift_1}
                                            onChange={(e) => {
                                              handleChange("shift_1", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.shift_2
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.shift_2
                                              ? error.shift_2[0]
                                              : false
                                          }
                                          label="Shift 2"
                                        >
                                          <AntNumberFormat
                                            type="input"
                                            value={data.shift_2}
                                            onChange={(e) => {
                                              handleChange("shift_2", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.shift_3
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.shift_3
                                              ? error.shift_3[0]
                                              : false
                                          }
                                          label="Shift 3"
                                        >
                                          <AntNumberFormat
                                            type="input"
                                            value={data.shift_3}
                                            onChange={(e) => {
                                              handleChange("shift_3", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          validateStatus={
                                            error && error.adjustment_qty
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.adjustment_qty
                                              ? error.adjustment_qty[0]
                                              : false
                                          }
                                          label="Adjustment Quantity"
                                        >
                                          <AntNumberFormat
                                            disabled
                                            type="input"
                                            value={
                                              data.shift_1 +
                                              data.shift_2 +
                                              data.shift_3
                                            }
                                            onChange={(e) => {
                                              handleChange("adjustment_qty", e);
                                            }}
                                          />
                                        </Form.Item>

                                        <Form.Item
                                          noStyle
                                          shouldUpdate={(
                                            prevValues,
                                            currentValues
                                          ) =>
                                            prevValues.select !==
                                            currentValues.select
                                          }
                                        >
                                          {({ getFieldValue }) =>
                                            getFieldValue("select") ===
                                            "Job Number" ? (
                                              <Form.Item
                                                label="Job Number"
                                                validateStatus={
                                                  error && error.job_number
                                                    ? "error"
                                                    : false
                                                }
                                                help={
                                                  error && error.job_number
                                                    ? error.job_number[0]
                                                    : false
                                                }
                                              >
                                                <Input
                                                  disabled
                                                  value={data.job_number}
                                                  onChange={(e) =>
                                                    handleChange(
                                                      "job_number",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </Form.Item>
                                            ) : null
                                          }
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  disabled={
                                    data.adjustment_qty === 0 ||
                                    !data.adjustment_qty
                                  }
                                  loading={loading}
                                  onClick={onSubmit}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={handleReset}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                        <Form form={form} component={false}>
                          <Table
                            components={{
                              body: {
                                cell: EditableCell,
                              },
                            }}
                            scroll={{ x: 850 }}
                            columns={mergedColumns}
                            size="small"
                            dataSource={data.dataSuggest}
                            rowKey={(data) => data._id}
                            onChange={handleTableChange}
                            pagination={{
                              pageSize: 10,
                              current: page,
                              total: data.total,
                              showSizeChanger: false,
                            }}
                            loading={loading}
                          />
                        </Form>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Content>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

export default SuggestionPPIC;
