import React, { Fragment, useState, useEffect } from "react";
import {
  Space,
  Skeleton,
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  DatePicker,
  message,
  Table,
} from "antd";
import { Redirect, Link } from "react-router-dom";
import {
  BarcodeOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Search } = Input;

const layout = {
  labelCol: {
    lg: 8,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function EditMaterialSplitter(props) {
  const [data, setData] = useState({
    kanban: "",
    item_code: "",
    item_name: "",
    quantity: "",
    unit: "",
    split_quantity: "",
    unit_split_quantity: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "asc",
  });
  const [splitter, setSplitter] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getMaterialSplitter();
  }, []);

  const getMaterialSplitter = () => {
    Axios.get(`${url}/material-splitter/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          kanban: res.data.data.kanban,
          item_code: res.data.data.item_code,
          item_name: res.data.data.item_name,
          quantity: res.data.data.quantity,
          unit: res.data.data.unit,
          split_quantity: res.data.data.split_quantity,
          unit_split_quantity: res.data.data.unit_split_quantity,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);

    setData({
      ...data,
      [name]: value,
    });

    // console.log(name, value);
  };

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const scanData = (e, name) => {
    if (data.kanban) {
      Axios.get(`${url}/kanban/check`, {
        params: {
          dn_number: data.kanban,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
        .then((res) => {
          setData({
            ...data,
            po_number: res.data.data.po_number,
            dn_number: res.data.data.qrcode,
            quantity: res.data.data.quantity_kanban,
            supplier_name: res.data.data.supplier_name,
            item_code: res.data.data.item_code,
            item_name: res.data.data.item_name,
            unit: res.data.data.unit,
            unit_split_quantity: res.data.data.unit,
          });
          console.log(res.data.data_warehouse);
        })
        .catch((err) => {
          if (err.response) {
            message.error(err.response.data.message);
            if (err.response.status == 401) {
              localStorage.removeItem("token");
            }
          }
          setLoading(false);
        });

      console.log(data.rack_stock, data.date, "ini");
    }
  };

  const handleEditDetails = (id) => {
    const findBom = splitter.find((splitter) => splitter._id === id);
    console.log(id);
    setSelectedId(id);

    setData({
      ...data,
      item_label: `${findBom.item_code} - ${findBom.item_name}`,
      item_code: findBom.item_code,
      item_name: findBom.item_name,
      item_category: findBom.item_category,
      spec: findBom.spec,
      unit: findBom.unit,
      quantity: findBom.quantity,
      supplier_label: `${findBom.supplier_code} - ${findBom.supplier_name}`,
      supplier_code: findBom.supplier_code,
      supplier_name: findBom.supplier_name,
      location_label: `${findBom.location_code} - ${findBom.location_name}`,
      location_code: findBom.location_code,
      location_name: findBom.location_name,
      process_label: `${findBom.process_code} - ${findBom.process_name}`,
      process_code: findBom.process_code,
      process_name: findBom.process_name,
      notes: findBom.notes,
    });

    setIsModalVisible(true);
  };

  const handleRemoveDetails = (id) => {
    const details = splitter.filter((splitter) => splitter._id !== id);
    setSplitter(details);
  };

  const columns = [
    {
      align: "left",
      title: "Kanban",
      dataIndex: "kanban",
    },
    {
      align: "left",
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      align: "left",
      title: "Item Name",
      dataIndex: "item_name",
    },
    {
      title: <div style={{ textAlign: "left" }}>Quantity</div>,
      align: "right",
      // dataIndex: "quantity",
      render: (data) => {
        return <AntNumberFormat value={data.quantity} type="text" />;
      },
    },
    {
      align: "left",
      title: "Unit",
      dataIndex: "unit",
    },
    {
      align: "left",
      title: "Split Quantity",
      // dataIndex: "split_quantity",
      render: (data) => {
        return <AntNumberFormat value={data.split_quantity} type="text" />;
      },
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>
        </Fragment>
      ),
    },
  ];

  const onSaveData = () => {
    setLoading(true);
    Axios.post(
      `${url}/material-splitter`,
      {
        kanban: data.kanban,
        item_code: data.item_code,
        item_name: data.item_name,
        quantity: data.quantity,
        unit: data.unit,
        split_quantity: data.split_quantity,
        unit_split_quantity: data.unit_split_quantity,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/material-splitter");

        handleReset();
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      kanban: "",
      item_code: "",
      item_name: "",
      quantity: "",
      unit: "",
      split_quantity: "",
      unit_split_quantity: "",
    });
  };

  const onSaveTemporary = () => {
    console.log(data.quantity, data.split_quantity);
    if (data.split_quantity > parseInt(data.quantity)) {
      message.error("split quantity must be less than equal to quantity");
    } else if (!data.split_quantity) {
      message.error("split quantity is null");
    } else if (
      parseInt(data.split_quantity === 0) ||
      data.split_quantity === "0"
    ) {
      message.error("cannot fill split quantity with 0");
    } else {
      setSplitter([
        ...splitter,
        {
          _id: Math.random(),
          kanban: data.kanban,
          item_name: data.item_name,
          item_code: data.item_code,
          quantity: data.quantity,
          unit: data.unit,
          split_quantity: data.split_quantity,
          unit_split_quantity: data.unit_split_quantity,
        },
      ]);

      setSelectedId("");

      setData({
        ...data,
        quantity: data.quantity - data.split_quantity,
        split_quantity: "",
      });

      setIsModalVisible(false);
    }

    console.log(splitter);
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Material Splitter"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/material-splitter">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Material Splitter</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Form labelAlign="left" {...layout} name="control-hooks">
                  <Row>
                    <Fragment>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          required
                          label="Kanban"
                          validateStatus={
                            error && error.kanban ? "error" : false
                          }
                          help={error && error.kanban ? error.kanban[0] : false}
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled={data.item_code}
                              className="size-icon"
                              placeholder="Kanban"
                              autoFocus
                              type="flex"
                              onPressEnter={(e) => scanData(e, "kanban")}
                              onChange={(e) =>
                                handleChange("kanban", e.target.value)
                              }
                              value={data.kanban}
                              prefix={<BarcodeOutlined />}
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          required
                          label="Item Code"
                          validateStatus={
                            error && error.item_code ? "error" : false
                          }
                          help={
                            error && error.item_code
                              ? error.item_code[0]
                              : false
                          }
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled
                              value={data.item_code}
                              onChange={(e) =>
                                handleChange("item_code", e.target.value)
                              }
                            />
                          </Col>
                        </Form.Item>

                        <Form.Item
                          required
                          label="Item Name"
                          validateStatus={
                            error && error.item_name ? "error" : false
                          }
                          help={
                            error && error.item_name
                              ? error.item_name[0]
                              : false
                          }
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled
                              value={data.item_name}
                              onChange={(e) =>
                                handleChange("item_name", e.target.value)
                              }
                            />
                          </Col>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} lg={12}>
                        <Form.Item
                          required
                          label="Quantity"
                          validateStatus={
                            error && error.quantity ? "error" : false
                          }
                          help={
                            error && error.quantity ? error.quantity[0] : false
                          }
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled
                              value={data.quantity}
                              onChange={(e) =>
                                handleChange("quantity", e.target.value)
                              }
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          required
                          label="Unit"
                          validateStatus={error && error.unit ? "error" : false}
                          help={error && error.unit ? error.unit[0] : false}
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled
                              value={data.unit}
                              onChange={(e) =>
                                handleChange("unit", e.target.value)
                              }
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          required
                          label="Split Quantity"
                          validateStatus={
                            error && error.split_quantity ? "error" : false
                          }
                          help={
                            error && error.split_quantity
                              ? error.split_quantity[0]
                              : false
                          }
                        >
                          <Col md={24} sm={24} xs={24}>
                            <AntNumberFormat
                              defaultValue="0"
                              value={data.split_quantity}
                              type="input"
                              onChange={(e) =>
                                handleChange("split_quantity", e)
                              }
                            />
                          </Col>
                        </Form.Item>
                        <Form.Item
                          required
                          label="Unit Split Quantity"
                          validateStatus={
                            error && error.unit_split_quantity ? "error" : false
                          }
                          help={
                            error && error.unit_split_quantity
                              ? error.unit_split_quantity[0]
                              : false
                          }
                        >
                          <Col md={24} sm={24} xs={24}>
                            <Input
                              disabled
                              value={data.unit_split_quantity}
                              onChange={(e) =>
                                handleChange(
                                  "unit_split_quantity",
                                  e.target.value
                                )
                              }
                            />
                          </Col>
                        </Form.Item>
                      </Col>
                    </Fragment>
                  </Row>
                  <Row justify="start">
                    <Col xs={24}>
                      <Space>
                        <Button
                          type="primary"
                          onClick={onSaveTemporary}
                          loading={loading}
                          htmlType="submit"
                          className="mr-button"
                        >
                          Save Temporary Data
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
                <Table
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                  size="small"
                  columns={columns}
                  scroll={{ x: 500 }}
                  dataSource={splitter}
                  rowKey={(data) => data._id}
                  onChange={handleTableChange}
                  // pagination={{
                  //   pageSize: 10,
                  //   current: page,
                  //   total: bomDetail.total,
                  //   showSizeChanger: false,
                  // }}
                  // loading={loading}
                />

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        type="primary"
                        onClick={onSaveData}
                        loading={loading}
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditMaterialSplitter;