/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Avatar,
  Upload,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ImportOutlined,
  ExportOutlined,
  FileImageOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import profile from "../../../assets/img/item.png";
import fileDownload from "js-file-download";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function Location() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [data, setData] = useState([
    {
      file: null,
      location: "",
      rack: "",
      slot: "",
      code: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/location`, {
      params: {
        keyword,
        columns: [
          "code",
          "name",
          "type",
          "process_name",
          "outsourching_name",
          "active",
        ],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/location/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setData({
      ...data,
      file: null,
    });
    setIsModalVisible(false);
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      file: files,
    });

    return false;
  };

  const handleRemove = () => {
    setData({
      ...data,
      file: null,
    });
  };

  const exportLocation = () => {
    Axios.get(`${url}/location/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Export Master Location" + ".xlsx");
    });
  };

  const downloadTemplate = () => {
    Axios.get(`${url}/location/template/download`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      fileDownload(res.data, "Template Master Location" + ".xlsx");
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.append("file", data.file);

    Axios.post(`${url}/location/import`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData();
        closeModal();
      })
      .catch((err) => {
        if (err.response) {
          const errorsKey = Object.keys(err.response.data.errors);
          const errors = err.response.data.errors;

          notification.open({
            message: `Error!: ${err.response.data.message}`,
            description: (
              <div>
                Error Details:
                {errorsKey.map((error) => {
                  return <div>{errors[error][0]}</div>;
                })}
              </div>
            ),
          });

          // message.error(`Error!: ${err.response.data.message}
          // \n\r
          // Error Details: ${errorsKey.map((error) => {
          //   return errors[error][0] + "\n\r";
          // })}
          // `);
          // if (err.response.status === 422) {
          //   setError(err.response.data.errors);
          // }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      title: "Code",
      width: 100,
      dataIndex: "code",
      sorter: false,
    },
    {
      title: "Name",
      width: 150,
      dataIndex: "name",
      sorter: false,
    },
    {
      title: "Type",
      width: 80,
      dataIndex: "type",
      sorter: false,
    },
    {
      title: "Process Name",
      width: 150,
      dataIndex: "process_name",
      sorter: false,
      render: (process_name) => {
        return process_name === "null" ? "" : process_name;
      },
    },
    {
      title: "Outsourching Name",
      width: 150,
      dataIndex: "outsourching_name",
      sorter: false,
      render: (outsourching_name) => {
        return outsourching_name === "null" ? "" : outsourching_name;
      },
    },
    {
      title: <div style={{ textAlign: "left" }}>Rack</div>,
      align: "right",
      width: 70,
      dataIndex: "rack",
      sorter: false,
    },
    {
      title: "Active",
      width: 85,
      dataIndex: "active",
      sorter: false,
      render: (active) => {
        return (
          <img
            alt="icon"
            width="20"
            src={
              active === "Yes"
                ? require("../../../assets/img/checked.png")
                : require("../../../assets/img/danger.png")
            }
          />
        );
      },
    },
    {
      title: "Created By",
      width: 100,
      dataIndex: "created_by",
      sorter: false,
    },
    {
      title: "Created At",
      width: 100,
      dataIndex: "created_at",
      sorter: false,
      render: (created_at) => moment(created_at).format("DD MMM YYYY"),
    },
    {
      title: "Updated By",
      width: 100,
      dataIndex: "updated_by",
      sorter: false,
    },
    {
      title: "Updated At",
      width: 100,
      dataIndex: "updated_at",
      sorter: false,
      render: (updated_at) => moment(updated_at).format("DD MMM YYYY"),
    },
    {
      title: "",
      dataIndex: "_id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link to={`/master-location-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title="Import Location"
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
      >
        <Card className="body-data">
          <div name="control-hooks">
            <div name="file" label="File">
              <Fragment>
                <Upload
                  onRemove={handleRemove}
                  beforeUpload={handleBeforeUpload}
                  fileList={[]}
                >
                  <Button style={{ marginBottom: 10 }}>
                    Choose File <ExportOutlined />
                  </Button>
                </Upload>
                {data.file && (
                  <React.Fragment
                    children={[
                      <Button onClick={handleRemove} type="danger" ghost>
                        <DeleteOutlined />
                      </Button>,
                    ]}
                  >
                    {data.file.name ? data.file.name : data.file}
                  </React.Fragment>
                )}
              </Fragment>
            </div>
            <div>
              <Button
                loading={loading}
                onClick={onSaveData}
                type="primary"
                htmlType="submit"
                className="mr-button"
                style={{
                  marginRight: 10,
                  backgroundColor: "#1b2086",
                  borderColor: "#1b2086",
                }}
              >
                Upload
              </Button>
              <Button htmlType="reset" onClick={handleRemove}>
                Reset
              </Button>
            </div>
          </div>
        </Card>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Location"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/master-location-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    <Button onClick={showModal} type="primary">
                      <ImportOutlined />
                      Import
                    </Button>
                    <Button onClick={exportLocation} type="primary">
                      <ExportOutlined />
                      Export
                    </Button>
                    <Button onClick={downloadTemplate} type="primary">
                      <DownloadOutlined />
                      Download Template
                    </Button>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 1200 }}
                    columns={columns}
                    dataSource={data.data}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default Location;
