/* eslint-disable */
import React, { Fragment, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  DatePicker,
  Upload,
  List,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import AntNumberFormat from "../../components/AntNumberFormat";
import TextArea from "antd/lib/input/TextArea";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function CreateBeginStock(props) {
  const [data, setData] = useState({
    item_code: "",
    item_label: "",
    item_id: "",
    item_name: "",
    month: "",
    year: "",
    begin_stock: "",
    items: [],
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const getItem = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = data.items.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
        [`model`]: find.model,
      });

      console.log(name, value, find.item_code, find.item_name);
    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });

      console.log(name, value, find.code, find.name);
    } else if (name === "model") {
      const find = data.customer.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.model,
      });

      console.log(name, value, find.code, find.name);
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
      });
    }
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_code", data.item_code);
    fd.set("month", data.month && data.month.format("MM"));
    fd.set("year", data.year && data.year.format("YYYY"));
    fd.set("begin_stock", data.begin_stock);

    Axios.post(`${url}/begin-stock`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/begin-stock");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      month: "",
      item_code: "",
      item_label: "",
      item_id: "",
      item_name: "",
      year: "",
      begin_stock: "",
      items: [],
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  //console.log("bulan", data.month && data.month.format("MM"));

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Begin Stock"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/begin-stock">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Add</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Item Code"
                        validateStatus={
                          error && error.item_code ? "error" : false
                        }
                        help={
                          error && error.item_code ? error.item_code[0] : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("item", value)
                          }
                          onFocus={() => getItem("")}
                          showSearch
                          onSearch={(value) => getItem(value)}
                          filterOption={false}
                          value={{
                            key: data.item_id,
                            label: data.item_label,
                          }}
                        >
                          {data.items &&
                            data.items.map((item) => {
                              return (
                                <Option value={item._id} key={item._id}>
                                  {`${item.item_code} - ${item.item_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="Item Name"
                        validateStatus={
                          error && error.item_name ? "error" : false
                        }
                        help={
                          error && error.item_name ? error.item_name[0] : false
                        }
                      >
                        <Input
                          disabled
                          value={data.item_name}
                          onChange={(e) =>
                            handleChange("item_name", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Begin Stock"
                        validateStatus={
                          error && error.begin_stock ? "error" : false
                        }
                        help={
                          error && error.begin_stock
                            ? error.begin_stock[0]
                            : false
                        }
                      >
                        <AntNumberFormat
                          //defaultValue="0"
                          value={data.begin_stock}
                          type="input"
                          onChange={(e) => {
                            handleChange("begin_stock", e);
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="Month"
                        validateStatus={error && error.month ? "error" : false}
                        help={error && error.month ? error.month[0] : false}
                      >
                        <DatePicker
                          onChange={(value) => handleChange("month", value)}
                          value={data.month}
                          picker="month"
                          format="MM"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Year"
                        validateStatus={error && error.year ? "error" : false}
                        help={error && error.year ? error.year[0] : false}
                      >
                        <DatePicker
                          onChange={(value) => handleChange("year", value)}
                          value={data.year}
                          picker="year"
                          format="YYYY"
                        />
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateBeginStock;
