import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Skeleton,
  Collapse,
  Table,
  Modal,
  Space,
  Select,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Content } = Layout;
const { Option } = Select;
const { Panel } = Collapse;

const layout = {
  labelCol: {
    lg: 9,
    md: 10,
  },
  wrapperCol: {
    lg: 14,
    md: 14,
  },
};

function CreateNoteDeliv(props) {
  const [data, setData] = useState({
    manifest_no: "",
    order_no: "",
    date: "",
    item_code: "",
    item_name: "",
    pcs_kanban: "",
    billed_alias: "",
    shipped_alias: "",
    customer_alias: "",
    kanban: "",
    qty: "",
    items: [],
    customer: [],
    shipped: [],
    billed: [],
    alias: "",
  });

  useEffect(() => {
    getItem();
  }, []);

  const [delivnoteData, setDelivenoteData] = useState({
    manifest_no: "",
    order_no: "",
    date: "",
    customer: "",
    shipped: "",
    billed: "",
    item_code: "",
    qty: "",
    item_name: "",
    items: [],
    pcs_kanban: 0,
    kanban: "",
    qty: 0,
  });

  const [selectedId, setSelectedId] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sort, setSort] = useState({
    sort: "created_at",
    order: "descend",
  });
  const [delivNoteDetails, setDetailsDelivNote] = useState([]);
  const [items, setItems] = useState([]);
  const [valCheckbox, setValCheckbox] = useState(false);

  const getItem = async (keyword) => {
    await Axios.get(`${url}/item/list-item-fg`, {
      params: {
        keyword,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setDelivenoteData({
          ...delivnoteData,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDeliveNote = (name, value) => {
    setDelivenoteData({
      ...delivnoteData,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = delivnoteData.items.find((x) => x._id === value.key);
      setDelivenoteData({
        ...delivnoteData,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
      });
    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });

      console.log(find.alias);
    } else if (name === "shipped") {
      const find = data.shipped.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else if (name === "billed") {
      const find = data.billed.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
        [`${name}_alias`]: find.alias,
      });
    } else {
      const find = delivnoteData.items.find((x) => x._id === value.key);
      setDelivenoteData({
        ...delivnoteData,
        [`${name}_id`]: value.value,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });
    }
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customer: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getShipped = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          shipped: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getBilled = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          billed: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const onSaveTemporaryData = () => {
    if (selectedId) {
      const modifyDetails = [...delivNoteDetails];
      const findDetails = modifyDetails.find((x) => x._id === selectedId);

      findDetails.item_code = delivnoteData.item_code;
      findDetails.item_name = delivnoteData.item_name;
      findDetails.pcs_kanban = delivnoteData.pcs_kanban;
      findDetails.kanban = delivnoteData.kanban;
      findDetails.qty = delivnoteData.pcs_kanban * delivnoteData.kanban;

      setDetailsDelivNote(modifyDetails);
    } else {
      setDetailsDelivNote([
        ...delivNoteDetails,
        {
          _id: Math.random(),
          item_code: delivnoteData.item_code,
          item_name: delivnoteData.item_name,
          pcs_kanban: delivnoteData.pcs_kanban,
          kanban: delivnoteData.kanban,
          qty: delivnoteData.pcs_kanban * delivnoteData.kanban,
        },
      ]);
    }

    setSelectedId("");

    setDelivenoteData({
      ...delivnoteData,
      item_id: "",
      item_code: "",
      item_name: "",
      item_label: "",
      pcs_kanban: "",
      kanban: "",
      qty: "",
    });

    setValCheckbox(false);
    setIsModalVisible(false);
  };

  const handleRemoveDetails = (id) => {
    const details = delivNoteDetails.filter(
      (delivNoteDetails) => delivNoteDetails._id !== id
    );
    setDetailsDelivNote(details);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleReset = () => {
    setData({
      manifest_no: "",
      order_no: "",
      customer: "",
      shipped: "",
      billed: "",
    });

    setDetailsDelivNote([]);
  };

  const onSaveData = () => {
    setLoading(true);
    let fd = new FormData();

    fd.set("data", JSON.stringify(delivNoteDetails));
    fd.set("manifest_no", data.manifest_no);
    fd.set("order_no", data.order_no);
    fd.set("date", data.date);
    fd.set("qty", data.qty);
    fd.set("customer", data.customer_alias);
    fd.set("shipped", data.shipped_alias);
    fd.set("billed", data.billed_alias);

    Axios.post(`${url}/deliverynote`, fd, {
      headers: {
        "Content-Type": "multiitem/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        if (res.data.type === "Success") {
          message.success(res.data.message);
        }
        // setLoading(false);
        props.history.push("/delivery-note");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        } else {
          message.error("Backend not yet Started");
        }
        setLoading(false);
      });
    console.log("INI " + data.alias);
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const resetModal = () => {
    setDelivenoteData({
      ...delivnoteData,
      item_code: "",
      item_name: "",
      pcs_kanban: "",
      kanban: "",
      qty: "",
    });
  };

  const closeModal = () => {
    setDelivenoteData({
      ...delivnoteData,
      item_code: "",
      item_name: "",
      pcs_kanban: "",
      kanban: "",
      qty: "",
    });

    setSelectedId("");
    setIsModalVisible(false);
    setValCheckbox(false);
  };

  const columns = [
    {
      align: "left",
      title: "Item Code",
      dataIndex: "item_code",
    },
    {
      align: "left",
      title: "Item Name",
      dataIndex: "item_name",
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'left' }}>PCS KBN</div>,
      render: (data) => {
        return <AntNumberFormat value={data.pcs_kanban} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'left' }}>Kanban</div>,
      render: (data) => {
        return <AntNumberFormat value={data.kanban} type="text" />;
      },
    },
    {
      align: "right",
      title: <div style={{ textAlign: 'left' }}>QTY</div>,
      render: (data) => {
        return <AntNumberFormat value={data.qty} type="text" />;
      },
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "_id",
      width: 200,
      render: (id) => (
        <Fragment>
          <Button
            onClick={() => handleRemoveDetails(id)}
            size="small"
            type="danger"
          >
            <DeleteOutlined />
            Delete
          </Button>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Delivery Note"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/delivery-note">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create Delivery Note</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          {fetching ? (
            <Skeleton loading={fetching} />
          ) : (
            <Col xs={24} md={12} lg={24}>
              <Card
                className="body-data"
                style={{ borderWidth: 2, borderRadius: 10 }}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={["1", "2"]}
                  expandIcon={({ isActive }) => (
                    <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                >
                  <Panel
                    header="Delivery Note"
                    key="1"
                    className="site-collapse-custom-panel"
                  >
                    <Form labelAlign="left" {...layout} name="control-hooks">
                      <Row>
                        <Fragment>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.manifest_no ? "error" : false
                              }
                              help={
                                error && error.manifest_no
                                  ? error.manifest_no[0]
                                  : false
                              }
                              label="Manifest No"
                            >
                              <Input
                                value={data.manifest_no}
                                onChange={(e) => {
                                  handleChange("manifest_no", e.target.value);
                                }}
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              label="Order No"
                              validateStatus={
                                error && error.order_no ? "error" : false
                              }
                              help={
                                error && error.order_no
                                  ? error.order_no[0]
                                  : false
                              }
                            >
                              <Input
                                value={data.order_no}
                                onChange={(e) =>
                                  handleChange("order_no", e.target.value)
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.date ? "error" : false
                              }
                              help={error && error.date ? error.date[0] : false}
                              label="Date"
                            >
                              <DatePicker
                                onChange={(value) =>
                                  handleChange("date", value)
                                }
                                value={data.date}
                                format="YYYY-MM-DD"
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} md={12} lg={12}>
                            <Form.Item
                              required
                              label="Customer"
                              validateStatus={
                                error && error.customer ? "error" : false
                              }
                              help={
                                error && error.customer
                                  ? error.customer[0]
                                  : false
                              }
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("customer", value)
                                }
                                onFocus={() => getCustomer("")}
                                showSearch
                                onSearch={(value) => getCustomer(value)}
                                filterOption={false}
                                value={{
                                  key: data.customer_id,
                                  label: data.customer_label,
                                }}
                              >
                                {data.customer &&
                                  data.customer.map((customers) => {
                                    return (
                                      <Option
                                        value={customers._id}
                                        key={customers._id}
                                      >
                                        {`${customers.code} - ${customers.alias}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.shipped ? "error" : false
                              }
                              help={
                                error && error.shipped_to
                                  ? error.shipped_to[0]
                                  : false
                              }
                              label="Shipped To"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("shipped", value)
                                }
                                onFocus={() => getShipped("")}
                                showSearch
                                onSearch={(value) => getShipped(value)}
                                filterOption={false}
                                value={{
                                  key: data.shipped_id,
                                  label: data.shipped_label,
                                }}
                              >
                                {data.shipped &&
                                  data.shipped.map((Shippeds) => {
                                    return (
                                      <Option
                                        value={Shippeds._id}
                                        key={Shippeds._id}
                                      >
                                        {`${Shippeds.code} - ${Shippeds.alias}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              required
                              validateStatus={
                                error && error.billed ? "error" : false
                              }
                              help={
                                error && error.billed ? error.billed[0] : false
                              }
                              label="Billed To"
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("billed", value)
                                }
                                onFocus={() => getBilled("")}
                                showSearch
                                onSearch={(value) => getBilled(value)}
                                filterOption={false}
                                value={{
                                  key: data.billed_id,
                                  label: data.billed_label,
                                }}
                              >
                                {data.billed &&
                                  data.billed.map((billeds) => {
                                    return (
                                      <Option
                                        value={billeds._id}
                                        key={billeds._id}
                                      >
                                        {`${billeds.code} - ${billeds.alias}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Fragment>
                      </Row>
                    </Form>
                  </Panel>
                  <Panel
                    header="Details"
                    key="2"
                    className="site-collapse-custom-panel"
                  >
                    <Col xs={24}>
                      <Space>
                        <Button type="primary" onClick={showModal}>
                          <PlusOutlined />
                          Add
                        </Button>

                        <Modal
                          title="Add Details Delivery Note"
                          style={{ top: 20 }}
                          visible={isModalVisible}
                          width={1000}
                          footer={null}
                          onCancel={closeModal}
                        >
                          <Card className="body-data">
                            <div name="control-hooks">
                              <div name="file" label="File">
                                <Form
                                  {...layout}
                                  name="control-hooks"
                                  labelAlign="left"
                                >
                                  <Row>
                                    <Fragment>
                                      <Col xs={24}>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_code
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_code
                                              ? error.item_code[0]
                                              : false
                                          }
                                          label="Item Code"
                                        >
                                          <Select
                                            labelInValue
                                            onChange={(value) =>
                                              handleChangeSelect(
                                                "item",
                                                value
                                                // "item_code",
                                                // data.items
                                              )
                                            }
                                            onFocus={() => getItem("", "items")}
                                            showSearch
                                            onSearch={(value) => getItem(value)}
                                            filterOption={false}
                                            value={{
                                              key: delivnoteData.item_id,
                                              label: delivnoteData.item_code,
                                            }}
                                          >
                                            {delivnoteData.items &&
                                              delivnoteData.items.map(
                                                (item) => {
                                                  return (
                                                    <Option
                                                      value={item._id}
                                                      key={item._id}
                                                    >
                                                      {`${item.item_code} - ${item.item_name}`}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </Select>
                                        </Form.Item>

                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.item_name
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.item_name
                                              ? error.item_name[0]
                                              : false
                                          }
                                          label="Item Name"
                                        >
                                          <Input
                                            disabled
                                            value={delivnoteData.item_name}
                                            onChange={(e) => {
                                              handleChangeDeliveNote(
                                                "item_name",
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.pcs_kanban
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.pcs_kanban
                                              ? error.pcs_kanban[0]
                                              : false
                                          }
                                          label="PCS KBN"
                                        >
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            value={delivnoteData.pcs_kanban}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDeliveNote("pcs_kanban", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.kanban
                                              ? "error"
                                              : false
                                          }
                                          help={
                                            error && error.kanban
                                              ? error.kanban[0]
                                              : false
                                          }
                                          label="Kanban"
                                        >
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            value={delivnoteData.kanban}
                                            type="input"
                                            onChange={(e) => {
                                              handleChangeDeliveNote("kanban", e);
                                            }}
                                          />
                                        </Form.Item>
                                        <Form.Item
                                          required
                                          validateStatus={
                                            error && error.qty ? "error" : false
                                          }
                                          help={
                                            error && error.qty
                                              ? error.qty[0]
                                              : false
                                          }
                                          label="Qty"
                                        >
                                          <AntNumberFormat
                                            //defaultValue="0"
                                            disabled
                                            value={delivnoteData.pcs_kanban * delivnoteData.kanban}
                                            type="input"
                                            onChange={(e) =>
                                              handleChange("qty", e)
                                            }
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Fragment>
                                  </Row>
                                </Form>
                              </div>
                              <div>
                                <Button
                                  loading={loading}
                                  onClick={onSaveTemporaryData}
                                  type="primary"
                                  htmlType="submit"
                                  className="mr-button"
                                  style={{
                                    marginRight: 10,
                                  }}
                                >
                                  Submit
                                </Button>
                                <Button htmlType="reset" onClick={resetModal}>
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card>
                        </Modal>
                      </Space>
                    </Col>
                    <Col xs={24}>
                      <Table
                        style={{ marginTop: "15px", marginBottom: "15px" }}
                        size="small"
                        columns={columns}
                        scroll={{ x: 500 }}
                        dataSource={delivNoteDetails}
                        rowKey={(data) => data._id}
                        onChange={handleTableChange}
                      />
                    </Col>

                    <Row justify="start">
                      <Col>
                        <Space>
                          <Button
                            type="primary"
                            onClick={onSaveData}
                            loading={loading}
                            htmlType="submit"
                            className="mr-button"
                          >
                            Submit
                          </Button>

                          <Button
                            onClick={handleReset}
                            type="default"
                            htmlType="button"
                            className="mr-button"
                          >
                            Reset
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Card>
            </Col>
          )}
        </Row>
      </Content>
    </Fragment>
  );
}

export default CreateNoteDeliv;
