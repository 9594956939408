/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Layout,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  message,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import "./PurchasingPO.less";
import {
  MoreOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import AntNumberFormat from "../../components/AntNumberFormat";

const { Search } = Input;
const { Content } = Layout;
const { Text } = Typography;
import { Bar } from "react-chartjs-2";

function PurchasingPO() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [rowDetails, setRowDetails] = useState([]);
  const [data, setData] = useState({
    dataPo: [],
    approved_status: "",
    supplier_name: "",
    model: "",
    date: "",
    fgLabel: ["AS", "BM", "TB", "HPD", "Total"],
    fgOk: [15, 13, 25, 50, 60],
    fgNg: [5, 10, 15, 35, 30],
  });
  const [loading, setLoading] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleRatioPO, setVisibleRatioPO] = useState(false);
  const [detailsChildren, setDetailsChildren] = useState([]);
  // const [purchasingDataDetails, setPurchasingDataDetails] = useState([]);
  const [purchasingDataDetailsTotal, setPurchasingDataDetailsTotal] =
    useState(0);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  // const [purchasingDataDetailsChildren, setPurchasingDataDetailsChildren] =
  // useState([]);
  const [
    purchasingDataDetailsTotalChildren,
    setPurchasingDataDetailsTotalChildren,
  ] = useState(0);
  const [selectedRowKeysData, setSelectedRowKeysData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageHeader, setPageHeader] = useState(1);
  const [pageDetail, setPageDetail] = useState(1);
  const [perPageHeader, setPerPageHeader] = useState(10);
  const [perPageDetail, setPerPageDetail] = useState(10);
  const [selectedId, setSelectedId] = useState("");
  const [selectedIdChildren, setSelectedIdChildren] = useState("");
  const [approveReject, setApproveReject] = useState("");
  const [salesImport, setSalesImport] = useState([]);
  const [salesLokal, setSalesLokal] = useState([]);
  const [salesDiagram, setSalesDiagram] = useState([]);
  const [chartTarget, setChartTarget] = useState([]);
  const [chartActual, setChartActual] = useState([]);
  const [chartLabel, setChartLabel] = useState([]);

  // const [chartAs, setChartAs] = useState([]);
  // const [chartBm, setChartBm] = useState([]);
  // const [chartTb, setChartTb] = useState([]);
  // const [chartHpd, setChartHpd] = useState([]);
  // const [chartTotal, setChartTotal] = useState([]);

  // const [itemDataBar1, setitemDataBar1] = useState([]);

  const [sort, setSort] = useState({
    sort: "code",
    order: "ascend",
  });

  const [sortHeader, setSortHeader] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const [sortDetail, setSortDetail] = useState({
    sort: "created_at",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  const roleAdmin = localStorage.getItem("role_name") === "Admin";
  const roleGM = localStorage.getItem("role_name") === "GM";
  const roleDirektur = localStorage.getItem("role_name") === "Direktur";

  useEffect(() => {
    getData();
  }, [keyword, page, sort]);

  // useEffect(() => {
  //   // getDataBar();
  // }, []);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/purchase-order`, {
      params: {
        keyword,
        columns: ["supplier_name", "model", "date"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          dataPo: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const printPdf = (id) => {
    Axios.get(`${url}/purchase-order/pdf/download/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }).then((res) => {
      // fileDownload(res.data, "Export Master Kanban" + ".xlsx");
      var blob = new Blob([res.data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    });
  };

  const handleApprove = (id, approve) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Purchasing PO will be Approve</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApprove(id, approve);
      },
    });
  };

  const confirmApprove = (id, approve) => {
    // console.log(id);
    setLoading(true);
    Axios.post(
      `${url}/purchase-order/approved/${id}`,
      {
        approved_status: parseInt(approve),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApproveAll = (approve) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Purchasing PO will be Approve All</p>
        </div>
      ),
      okText: "Approve",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmApproveAll(approve, selectedRowKeysData);
      },
    });
  };

  const confirmApproveAll = (approve, id) => {
    setLoading(true);
    Axios.post(
      `${url}/purchase-order/approved/all/${id}`,
      {
        role: localStorage.getItem("role_name"),
        approve_all: selectedRowsData,
        approved_status: parseInt(approve),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        if (res.data.type === "error"){
          message.error(res.data.message);         
        }else{
          message.success(res.data.message);
        }
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const confirmReject = (id, approved) => {
    setLoading(true);
    Axios.post(
      `${url}/purchase-order/rejected/${id}`,
      {
        approved_status: approved,
        remark: data.remark,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    )
      .then((res) => {
        message.success(res.data.message);
        getData();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    handleCancel();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const handleChangeDetailsPurchasing = (data) => {
    const index = data[data.length - 1];
    setRowDetails([index]);
    if (index) {
      const data2 = index.split(";");
      setSelectedId(data2[0]);
      // setSelectedId('71n8nmwq8we8me91kwe80qj');
      // getPurchasingDetails(data2[0]);
    }
  };

  const handleChangeHeadersTable = (pagination, filter, sorter) => {
    setPageHeader(pagination.current);
    setSortHeader({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageHeader(pagination.pageSize);
  };

  const handleChangeDetailsTable = (pagination, filter, sorter) => {
    setPageDetail(pagination.current);
    setSortDetail({
      sort: sorter.field,
      order: sorter.order,
    });
    setPerPageDetail(pagination.pageSize);
  };

  const showModal = (id, approveReject) => {
    setSelectedId(id);
    setApproveReject(approveReject);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setData({
      ...data,
      status: "",
      remark: "",
    });
    setIsModalVisible(false);
  };

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const columns = [
    {
      title: <div style={{ textAlign: "center" }}>Month</div>,
      dataIndex: "month",
      sorter: false,
      align: "center",
      width: 150,
      render: (month) => moment(month).format("MMMM"),
    },
    {
      title: <div style={{ textAlign: "center" }}>Year</div>,
      dataIndex: "year",
      align: "right",
      sorter: false,
      width: 150,
    },
    {
      title: "Summary",
      // dataIndex: "summary",
      sorter: false,
      align: "center",
      width: 150,
      render: (data) => {
        return (
          <Button
            type="primary"
            onClick={() => modalRatioPO(data.month, data.year)}
          >
            Check
          </Button>
        );
      },
    },
  ];

  // const getDataBar = () => {
  //   setLoading(true);

  //   let itemQuantity = [];
  //   let itemName = [];
  //   Axios.get(`${url}/dashboard/bar1`, {
  //     params: {
  //       keyword,
  //       // columns: ["item_id", "item_name", "qty", "stock_min", "stock_max"],
  //       sort: "commodity",
  //       order: "ascend",
  //     },

  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     },
  //   })
  //     .then((res) => {
  //       setData(res.data.data);
  //       setChartAs(res.data.data[0].chartAs);
  //       setChartBm(res.data.data[1].chartBm);
  //       setChartTb(res.data.data[2].chartTb);
  //       setChartHpd(res.data.data[3].chartHpd);
  //       setChartTotal(res.data.data[4].chartTotal);

  //       setitemDataBar1({
  //         labels: itemName,
  //         datasets: [
  //           {
  //             label: "Target",
  //             data: itemQuantity,
  //             fill: false,
  //             backgroundColor: "#17A8F5",
  //             borderColor: "#17A8F5 ",
  //           },
  //           {
  //             label: "Actual",
  //             data: itemQuantity,
  //             fill: false,
  //             backgroundColor: "#17A8F5",
  //             borderColor: "#17A8F5 ",
  //           },
  //         ],
  //       });
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         if (error.response.status === 401) {
  //           localStorage.removeItem("token");
  //         }
  //         message.error(error.response.data.message);
  //       }
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  const columnsRatioLokal = [
    {
      title: (
        <div style={{ textAlign: "center", fontSize: "24px" }}>
          RATIO PURCHASE TO SALES {moment(month).format("MMMM")} {year} (Local)
        </div>
      ),
      children: [
        {
          title: <div style={{ textAlign: "center" }}>Sales</div>,
          align: "center",
          key: "sales",
          // width: 150,
          children: [
            {
              // title: "month",
              dataIndex: "comodity",
              key: "comodity",
              align: "center",
            },
            {
              // title: "Total Sales",
              dataIndex: "sales",
              key: "sales",
              align: "right",
              render: (sales) => {
                return (
                  <AntNumberFormat
                    // decimalScale={data.currency_name}
                    value={sales}
                    type="text"
                  />
                );
              },
            },
            {
              // title: "Persentase",
              dataIndex: "percent_sales",
              key: "percent_sales",
              align: "center",
              render: (percent_sales) => {
                return (
                  <AntNumberFormat
                    suffix="percent"
                    value={percent_sales}
                    type="text"
                  />
                );
              },
            },
          ],
        },
        {
          title: <div style={{ textAlign: "center" }}>Purchase</div>,
          // width: 150,
          children: [
            {
              // title: "Commodity",
              dataIndex: "comodity",
              key: "comodity",
              align: "center",
            },
            {
              // title: "Total PO",
              dataIndex: "purchase",
              key: "purchase",
              align: "right",
              render: (purchase) => {
                return (
                  <AntNumberFormat
                    // decimalScale={data.currency_name}
                    value={purchase}
                    type="text"
                  />
                );
              },
            },
            {
              // title: "Persentase PO",
              dataIndex: "percent_purchase",
              key: "percent_purchase",
              align: "center",
              render: (percent_purchase) => {
                return (
                  <AntNumberFormat
                    suffix="percent"
                    value={percent_purchase}
                    type="text"
                  />
                );
              },
            },
          ],
        },
        {
          title: <div style={{ textAlign: "center" }}>Ratio</div>,
          dataIndex: "ratio",
          align: "center",
          key: "ratio",
          // width: 150,
          render: (ratio) => {
            return (
              <AntNumberFormat
                decimalScale="DES"
                suffix="percent"
                value={ratio}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>KPI</div>,
          dataIndex: "kpi",
          align: "center",
          key: "kpi",
          render(kpi) {
            return {
              props: {
                style: { background: "#A1F3FF" },
              },

              children: (
                <AntNumberFormat value={kpi} suffix="percent" type="text" />
              ),
            };
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>Result</div>,
          dataIndex: "result",
          align: "center",
          key: "result",
        },
        {
          title: <div style={{ textAlign: "center" }}>Gap</div>,
          dataIndex: "gap",
          align: "center",
          key: "gap",
          render: (gap) => {
            return (
              <AntNumberFormat
                // decimalScale={data.currency_name}
                suffix="percent"
                value={gap}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>Judgement</div>,
          dataIndex: "judgement",
          align: "center",
          key: "judgement",
          render: (judgement) => {
            return (
              <>
                <Fragment>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      // judgement === "100" ?
                      require("../../assets/img/rec.png")
                      // : ""
                    }
                  />
                </Fragment>
              </>
            );
          },
        },
      ],
    },
  ];

  const columnsRatioImport = [
    {
      title: (
        <div style={{ textAlign: "center", fontSize: "24px" }}>
          RATIO PURCHASE TO SALES {moment(month).format("MMMM")} {year} (Import)
        </div>
      ),
      children: [
        {
          title: <div style={{ textAlign: "center" }}>Sales</div>,
          align: "center",
          key: "sales",
          width: 100,
          children: [
            {
              dataIndex: "month_sales",
              key: "month_sales",
              align: "center",
              // render: (month_sales) => moment(month_sales.toString()).format("MMMM"),
              render(month_sales) {
                return {
                  props: {
                    style: { background: "#FFD321" },
                  },
                  children: (
                    <b>{moment(month_sales.toString()).format("MMMM")}</b>
                  ),
                };
              },
            },
            {
              dataIndex: "sales",
              key: "sales",
              align: "right",
              render: (sales) => {
                return (
                  <AntNumberFormat
                    // decimalScale={data.currency_name}
                    value={sales}
                    type="text"
                  />
                );
              },
            },
          ],
        },
        {
          title: <div style={{ textAlign: "center" }}>Purchase Order</div>,
          align: "center",
          width: 100,
          children: [
            {
              dataIndex: "month_purchase",
              key: "month_purchase",
              align: "center",
              render(month_purchase) {
                return {
                  props: {
                    style: { background: "#FFD321" },
                  },
                  children: (
                    <b>{moment(month_purchase.toString()).format("MMMM")}</b>
                  ),
                };
              },
            },
            {
              dataIndex: "purchase",
              key: "purchase",
              align: "right",
              render: (purchase) => {
                return (
                  <AntNumberFormat
                    // decimalScale={data.currency_name}
                    value={purchase}
                    type="text"
                  />
                );
              },
            },
          ],
        },
        {
          title: <div style={{ textAlign: "center" }}>Ratio</div>,
          dataIndex: "ratio",
          align: "center",
          key: "ratio",
          // width: 150,
          render: (ratio) => {
            return (
              <AntNumberFormat suffix="percent" value={ratio} type="text" />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>KPI</div>,
          dataIndex: "kpi",
          align: "center",
          key: "kpi",
          render(kpi) {
            return {
              props: {
                style: { background: "#A1F3FF" },
              },
              children: (
                <AntNumberFormat value={kpi} suffix="percent" type="text" />
              ),
            };
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>Result</div>,
          dataIndex: "result",
          align: "center",
          key: "result",
        },
        {
          title: <div style={{ textAlign: "center" }}>Gap</div>,
          dataIndex: "gap",
          align: "center",
          key: "gap",
          render: (gap) => {
            return (
              <AntNumberFormat
                // decimalScale={data.currency_name}
                suffix="percent"
                value={gap}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>Judgement</div>,
          // dataIndex: "judgement",
          align: "center",
          key: "judgement",
          render: (judgement) => {
            return (
              <>
                <Fragment>
                  <img
                    alt="icon"
                    width="20"
                    src={
                      // judgement === "100"
                      //   ?
                      require("../../assets/img/rec.png")
                      // : ""
                    }
                  />
                </Fragment>
              </>
            );
          },
        },
      ],
    },
  ];

  const columnsDetails = [
    {
      title: <div style={{ textAlign: "center" }}>Material Code SII</div>,
      dataIndex: "item_code",
      align: "left",
      key: "item_code",
      width: 150,
    },
    {
      title: <div style={{ textAlign: "center" }}>Parts No</div>,
      dataIndex: "item_code",
      align: "left",
      key: "item_code",
      width: 150,
    },
    {
      title: <div style={{ textAlign: "center" }}>Parts Name</div>,
      dataIndex: "item_name",
      align: "left",
      key: "item_name",
      width: 150,
    },
    {
      title: <div style={{ textAlign: "center" }}>QTY PO</div>,
      children: [
        {
          title: <div style={{ textAlign: "center" }}>Pcs</div>,
          // dataIndex: "pcs",
          align: "right",
          key: "pcs",
          width: 150,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.suggestion_qty}
                type="text"
              />
            );
          },
        },
      ],
    },
    {
      title: <div style={{ textAlign: "center" }}>Unit Price</div>,
      children: [
        {
          title: <div style={{ textAlign: "center" }}>Rp</div>,
          // dataIndex: "price",
          align: "right",
          key: "price",
          width: 150,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.price}
                type="text"
              />
            );
          },
        },
      ],
    },
    {
      title: <div style={{ textAlign: "center" }}>Total Amount</div>,
      children: [
        {
          title: <div style={{ textAlign: "center" }}>Rp</div>,
          // dataIndex: "amount",
          align: "right",
          key: "amount",
          width: 175,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.amount}
                type="text"
              />
            );
          },
        },
      ],
    },
    {
      title: <div style={{ textAlign: "center" }}>Forecast</div>,
      children: [
        {
          title: <div style={{ textAlign: "center" }}>N+1</div>,
          //dataIndex: "n_1",
          align: "right",
          key: "forecast_n1",
          width: 150,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n1}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>N+2</div>,
          //dataIndex: "n_2",
          align: "right",
          key: "forecast_n2",
          width: 150,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n2}
                type="text"
              />
            );
          },
        },
        {
          title: <div style={{ textAlign: "center" }}>N+3</div>,
          //dataIndex: "n_3",
          align: "right",
          key: "forecast_n3",
          width: 150,
          render: (data) => {
            return (
              <AntNumberFormat
                decimalScale={data.currency_name}
                value={data.forecast_n3}
                type="text"
              />
            );
          },
        },
      ],
    },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 100,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               <Menu.Item key="1">
    //                 <Link to={`/purchasing-po/details-edit-${id}`}>
    //                   <EditOutlined /> Edit
    //                 </Link>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  const purchasingDetails = (item, indeks) => {
    const columnsHeader = [
      {
        title: "Supplier Name",
        dataIndex: "supplier_name",
        sorter: false,
        width: 150,
      },
      // {
      //   title: "Model",
      //   dataIndex: "model",
      //   sorter: false,
      // },
      {
        title: "PO Number",
        dataIndex: "po_number",
        sorter: false,
        width: 150,
        // render: (id, index) => <div> <a onClick={() => setVisible(true)}>{id} </a></div>,
        render: (id, index) => (
          <div>
            {" "}
            <a onClick={() => purchasingDetailsChildren(id, index, indeks)}>
              {id}{" "}
            </a>
          </div>
        ),
      },
      {
        title: "Publisher Date",
        dataIndex: "publisher_date",
        sorter: false,
        render: (date) => moment(date).format("DD-MM-YYYY"),
        width: 150,
      },
      {
        title: "Delivery Request Date",
        dataIndex: "delivery_request_date",
        sorter: false,
        render: (date) => moment(date).format("DD-MM-YYYY"),
        width: 150,
      },
      {
        title: "Approval",
        children: [
          {
            title: "Supervisor",
            dataIndex: "approved_status",
            align: "center",
            width: 100,
            render: (approved_status, data) => {
              return (
                <>
                  <Fragment>
                    <Tooltip
                      placement="top"
                      title={data.approved_supervisor_by}
                    >
                      <img
                        alt="icon"
                        width="20"
                        src={
                          approved_status === 0
                            ? require("../../assets/img/sand-clock.png")
                            : approved_status === 1 ||
                              approved_status === 2 ||
                              approved_status === 3 ||
                              approved_status === 4
                            ? require("../../assets/img/checked.png")
                            : approved_status === -2
                            ? require("../../assets/img/warning.png")
                            : approved_status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    </Tooltip>
                  </Fragment>
                </>
              );
            },
          },
          {
            title: "Manager",
            dataIndex: "approved_status",
            align: "center",
            width: 100,
            render: (approved_status, data) => {
              return (
                <>
                  <Fragment>
                    <Tooltip placement="top" title={data.approved_manager_by}>
                      <img
                        alt="icon"
                        width="20"
                        src={
                          approved_status === 0
                            ? require("../../assets/img/sand-clock.png")
                            : approved_status === 2 ||
                              approved_status === 3 ||
                              approved_status === 4
                            ? require("../../assets/img/checked.png")
                            : approved_status === -2
                            ? require("../../assets/img/warning.png")
                            : approved_status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    </Tooltip>
                  </Fragment>
                </>
              );
            },
          },
          {
            title: "GM",
            dataIndex: "approved_status",
            align: "center",
            width: 100,
            render: (approved_status, data) => {
              return (
                <>
                  <Fragment>
                    <Tooltip placement="top" title={data.approved_gm_by}>
                      <img
                        alt="icon"
                        width="20"
                        src={
                          approved_status === 0
                            ? require("../../assets/img/sand-clock.png")
                            : approved_status === 3 || approved_status === 4
                            ? require("../../assets/img/checked.png")
                            : approved_status === -2
                            ? require("../../assets/img/warning.png")
                            : approved_status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    </Tooltip>
                  </Fragment>
                </>
              );
            },
          },
          {
            title: "Direktur",
            dataIndex: "approved_status",
            align: "center",
            width: 100,
            render: (approved_status, data) => {
              return (
                <>
                  <Fragment>
                    <Tooltip placement="top" title={data.approved_direktur_by}>
                      <img
                        alt="icon"
                        width="20"
                        src={
                          approved_status === 0
                            ? require("../../assets/img/sand-clock.png")
                            : approved_status === 4
                            ? require("../../assets/img/checked.png")
                            : approved_status === -2
                            ? require("../../assets/img/warning.png")
                            : approved_status === -1
                            ? require("../../assets/img/danger.png")
                            : require("../../assets/img/sand-clock.png")
                        }
                      />
                    </Tooltip>
                  </Fragment>
                </>
              );
            },
          },
        ],
      },
      {
        title: "Notes",
        dataIndex: "notes",
        align: "center",
        width: 150,
      },
      {
        title: <div style={{ textAlign: "center" }}>Approve / Reject</div>,
        key: "approveSupervisor",
        dataIndex: "_id",
        align: "center",
        sorter: false,
        fixed: "right",
        width: 130,
        render: (id, data) => {
          return (
            <>
              {data.approved_status === 0 ||
              data.approved_status === -1 ||
              data.approved_status === 1 ||
              data.approved_status === 2 ||
              data.approved_status === 3 ||
              data.approved_status === 4 ||
              roleAdmin ? (
                <Fragment>
                  <Button
                    style={{ align: "center" }}
                    disabled={data.approved_status > 0 && !roleAdmin}
                    onClick={() => handleApprove(id, 1)}
                    size="small"
                    type="primary"
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    disabled={data.approved_status > 0 && !roleAdmin}
                    style={{ marginLeft: 5, align: "center" }}
                    onClick={() => showModal(id, -1)}
                    size="small"
                    type="danger"
                  >
                    <CloseOutlined />
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Approve / Reject</div>,
        key: "approveManager",
        dataIndex: "_id",
        align: "center",
        sorter: false,
        fixed: "right",
        width: 130,
        render: (id, data) => {
          return (
            <>
              {data.approved_status === 1 ||
              data.approved_status === 2 ||
              data.approved_status === 3 ||
              data.approved_status === 4 ||
              localStorage.getItem("role_name") === "Admin" ? (
                <Fragment>
                  <Button
                    style={{ align: "center" }}
                    disabled={data.approved_status > 1 && !roleAdmin}
                    onClick={() => handleApprove(id, 2)}
                    size="small"
                    type="primary"
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    disabled={data.approved_status > 1 && !roleAdmin}
                    style={{ marginLeft: 5, align: "center" }}
                    onClick={() => showModal(id, -1)}
                    size="small"
                    type="danger"
                  >
                    <CloseOutlined />
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Approve / Reject</div>,
        key: "approveGM",
        dataIndex: "_id",
        align: "center",
        sorter: false,
        fixed: "right",
        width: 130,
        render: (id, data) => {
          return (
            <>
              {data.approved_status === 2 ||
              data.approved_status === 3 ||
              data.approved_status === 4 ||
              localStorage.getItem("role_name") === "Admin" ? (
                <Fragment>
                  <Button
                    style={{ align: "center" }}
                    disabled={data.approved_status > 2 && !roleAdmin}
                    onClick={() => handleApprove(id, 3)}
                    size="small"
                    type="primary"
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    disabled={data.approved_status > 2 && !roleAdmin}
                    style={{ marginLeft: 5, align: "center" }}
                    onClick={() => showModal(id, -1)}
                    size="small"
                    type="danger"
                  >
                    <CloseOutlined />
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        title: <div style={{ textAlign: "center" }}>Approve / Reject</div>,
        key: "approveDirektur",
        dataIndex: "_id",
        align: "center",
        sorter: false,
        fixed: "right",
        width: 130,
        render: (id, data) => {
          return (
            <>
              {data.approved_status === 3 ||
              data.approved_status === 4 ||
              localStorage.getItem("role_name") === "Admin" ? (
                <Fragment>
                  <Button
                    style={{ align: "center" }}
                    disabled={data.approved_status > 3 && !roleAdmin}
                    onClick={() => handleApprove(id, 4)}
                    size="small"
                    type="primary"
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    disabled={data.approved_status > 3 && !roleAdmin}
                    style={{ marginLeft: 5, align: "center" }}
                    onClick={() => showModal(id, -1)}
                    size="small"
                    type="danger"
                  >
                    <CloseOutlined />
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        title: "",
        dataIndex: "_id",
        align: "right",
        sorter: false,
        fixed: "right",
        width: 50,
        render: (id) => {
          return (
            <>
              {localStorage.getItem("role_name") === "Admin" ? (
                <Fragment>
                  <Dropdown
                    className="pointer"
                    overlay={
                      <Menu>
                        <Menu.Item key="1">
                          <Link to={`/purchasing-po-edit-${id}`}>
                            <EditOutlined /> Edit
                          </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                          <span onClick={() => printPdf(id)}>
                            <PrinterOutlined /> Print
                          </span>
                        </Menu.Item>
                      </Menu>
                    }
                    trigger={["click"]}
                  >
                    <span
                      className="pointer"
                      onClick={(e) => e.preventDefault()}
                    >
                      <MoreOutlined />
                    </span>
                  </Dropdown>
                </Fragment>
              ) : (
                ""
              )}
            </>
          );
        },
      },
    ].filter((filter) => {
      // const approve = filter.render((data) => data.approved_status === 1)

      if (localStorage.getItem("role_name") === "Supervisor") {
        return (
          filter.key !== "approveManager" &&
          filter.key !== "approveGM" &&
          filter.key !== "approveDirektur"
        );
      } else if (localStorage.getItem("role_name") === "Manager") {
        return (
          filter.key !== "approveSupervisor" &&
          filter.key !== "approveGM" &&
          filter.key !== "approveDirektur"
        );
      } else if (localStorage.getItem("role_name") === "General Manager") {
        return (
          filter.key !== "approveSupervisor" &&
          filter.key !== "approveManager" &&
          filter.key !== "approveDirektur" &&
          filter.key !== "approveGM"
        );
      } else if (localStorage.getItem("role_name") === "Direktur") {
        return (
          filter.key !== "approveSupervisor" &&
          filter.key !== "approveManager" &&
          filter.key !== "approveDirektur" &&
          filter.key !== "approveGM"
        );
      } else if (localStorage.getItem("role_name") === "Admin") {
        return filter.key !== "approveAdmins";
      }

      // console.log(approve)
    });

    return (
      <>
        <Row>
          <Col xs={24}>
            <Table
              scroll={{ x: 1500, y: 400 }}
              style={{ marginTop: 25, marginBottom: 25, marginRight: 30 }}
              columns={columnsHeader}
              rowKey={(data) => data._id}
              loading={loadingDetail}
              dataSource={data.dataPo[indeks].details}
              pagination={{
                pageSize: perPageDetail,
                current: pageDetail,
                total: purchasingDataDetailsTotal,
                showSizeChanger: true,
              }}
              onChange={handleChangeHeadersTable}
              size="small"
              rowSelection={
                localStorage.getItem("role_name") === "Direktur" ||
                localStorage.getItem("role_name") === "Admin"
                  ? {
                      selectedRowKeys: selectedRowKeysData,
                      type: "checkbox",
                      onChange: (selectedRowKeys, selectedRows) => {
                        setSelectedRowsData(selectedRows);
                        setSelectedRowKeysData(selectedRowKeys);

                        // console.log(
                        //   selectedRows,
                        //   selectedRowKeys,
                        //   "ini select"
                        // );
                      },
                      columnTitle: selectedRowsData.length > 0 ? "" : <></>,
                      getCheckboxProps: (record) => {
                        return {
                          disabled: record.approved_status === 4,
                        };
                      },
                    }
                  : ""
              }
            />
          </Col>
        </Row>
      </>
    );
  };

  const purchasingDetailsChildren = (id, index, indeks) => {
    // console.log(id, indeks, "ini tuh id");
    // console.log("yang ini index", index);
    // console.log("yang ini index & details", index.details);
    setVisible(true);
    setDetailsChildren(index.details);
  };

  const modalRatioPO = (month, year) => {
    setVisibleRatioPO(true);

    setMonth(month);
    setYear(year);

    setLoading(true);
    Axios.get(`${url}/summary-po-import`, {
      params: {
        month: month,
        year: year,
        item_type: "Import",
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSalesImport(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });

    Axios.get(`${url}/summary-po-local`, {
      params: {
        month: month,
        year: year,
        // keyword: keywordDetails,
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setSalesLokal(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });

    Axios.get(`${url}/summary-po-diagram`, {
      params: {
        month: month,
        year: year,
        // keyword: keywordDetails,
        columns: ["item_code"],
        perpage: perPageDetail,
        page: pageDetail,
        sort: sortDetail.sort,
        order: sortDetail.order,
      },

      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setChartLabel(res.data.label);
        setChartActual(res.data.kpi);
        setChartTarget(res.data.gap);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
        } else {
          message.error("Backend not yet Started");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  var number = [
    chartTarget,
    chartActual,
  ];
  var maximal = Math.max.apply(Math, number);

  const options = {
    plugins: {
      datalabels: {
        font: {
          size: "30",
        },
        labels: {
          title: {
            color: "Black",
            align: "top",
            anchor: "end",
          },
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 0,
            max: maximal + 100,
          },
          scaleLabel: {
            display: true,
            labelString:
              "RATIO PURCHASE TO SALES " +
              moment(month).format("MMMM") +
              " " +
              `${year}`,
            fontSize: 18,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontSize: 30,
          },
          title: {},
        },
      ],
    },
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Modal
        title={
          <div>
            <span style={{ fontSize: "13px" }}>Ratio Purchasing PO </span>
          </div>
        }
        centered
        visible={visibleRatioPO}
        onCancel={() => setVisibleRatioPO(false)}
        onOk={() => setVisibleRatioPO(false)}
        width={1000}
      >
        <Table
          bordered
          columns={columnsRatioImport}
          rowKey={(data) => data._id}
          loading={loadingDetail}
          dataSource={salesImport}
          // pagination={{
          //   pageSize: perPageDetail,
          //   current: pageDetail,
          //   total: purchasingDataDetailsTotalChildren,
          //   showSizeChanger: true,
          // }}
          // onChange={handleChangeDetailsTable}
          size="small"
        />

        <Table
          style={{ marginTop: "2%", marginBottom: "2%" }}
          bordered
          columns={columnsRatioLokal}
          rowKey={(data) => data._id}
          loading={loadingDetail}
          dataSource={salesLokal}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: purchasingDataDetailsTotalChildren,
            showSizeChanger: true,
          }}
          onChange={handleChangeDetailsTable}
          size="small"
          summary={(pageData) => {
            let totalSales = 0;
            let totalPurchase = 0;
            let percentSales = 0;
            let percentPurchase = 0;
            let ratioLokal = 0;
            let kpiLokal = 0;
            let gapLokal = 0;
            let result = "";
            pageData.forEach(
              ({
                sales,
                purchase,
                percent_sales,
                percent_purchase,
                ratio,
                kpi,
                gap,
              }) => {                
                totalSales += sales;
                totalPurchase += purchase;
                percentSales += percent_sales;
                percentPurchase += percent_purchase;
                ratioLokal += ratio;
                kpiLokal += kpi;
                gapLokal += gap;
              }
            );

            return (
              <>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>Total</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                    <div style={{ textAlign: "right", fontWeight: "bold"}}>
                      <AntNumberFormat type="text" value={totalSales} />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      <AntNumberFormat
                        type="text"
                        suffix="percent"
                        value={percentSales}
                      />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>Total</div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4}>
                    <div style={{ textAlign: "right", fontWeight: "bold"}}>
                      <AntNumberFormat type="text" value={totalPurchase} />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      <AntNumberFormat
                        suffix="percent"
                        type="text"
                        value={percentPurchase}
                      />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={6}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      <AntNumberFormat
                        type="text"
                        decimalScale="DES"
                        suffix="percent"
                        value={ratioLokal/pageData.length}
                      />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={7}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      <AntNumberFormat
                        type="text"
                        suffix="percent"
                        value={kpiLokal/pageData.length}
                      />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={8}>
                    <div style={{ textAlign: "right", fontWeight: "bold" }}>
                      <AntNumberFormat type="text" value={result} />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={9}>
                    <div style={{ textAlign: "center", fontWeight: "bold" }}>
                      <AntNumberFormat
                        type="text"
                        suffix="percent"
                        value={gapLokal/pageData.length}
                      />
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={10}>
                    <div style={{ textAlign: "center"}}>
                      <Fragment>
                        <img
                          alt="icon"
                          width="20"
                          src={
                            // judgement === "100" ?
                            require("../../assets/img/rec.png")
                            // : ""
                          }
                        />
                      </Fragment>
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </>
            );
          }}
        />

        <Bar
          // data={itemDataBar1}
          data={{
            labels: chartLabel,
            datasets: [
              {
                label: "Target",
                data: chartTarget,
                backgroundColor: "rgba(8, 146, 68, .7)",
                borderColor: "rgba(8, 146, 68, 1)",
                borderWidth: 1,
              },
              {
                label: "Actual",
                data: chartActual,
                backgroundColor: "rgba(255, 70, 71, .7)",
                borderColor: "rgba(255, 70, 71, 1)",
                borderWidth: 1,
              },
            ],
          }}
          options={options}
        />
      </Modal>
      <Modal
        title={
          <div>
            <span style={{ fontSize: "13px" }}>Details Purchasing PO </span>
          </div>
        }
        centered
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
        width={1000}
      >
        <Table
          columns={columnsDetails}
          rowKey={(data) => data._id}
          loading={loadingDetail}
          dataSource={detailsChildren}
          pagination={{
            pageSize: perPageDetail,
            current: pageDetail,
            total: purchasingDataDetailsTotalChildren,
            showSizeChanger: true,
          }}
          onChange={handleChangeDetailsTable}
          size="small"
        />
      </Modal>
      <Modal
        title="Why Reject?"
        visible={isModalVisible}
        onOk={() => {
          confirmReject(selectedId, approveReject);
        }}
        onCancel={handleCancel}
      >
        <div name="control-hooks">
          Remark :
          <Input
            value={data.remark}
            onChange={(e) => handleChange("remark", e.target.value)}
          />
        </div>
      </Modal>

      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Purchasing PO"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/purchasing-po-create">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                    {localStorage.getItem("role_name") === "Direktur" ||
                    localStorage.getItem("role_name") === "GM" ||
                    localStorage.getItem("role_name") === "Admin" ? (
                      <Button
                        type="primary"
                        onClick={() =>
                          handleApproveAll(
                            roleGM ? 3 : roleDirektur ? 4 : roleAdmin ? 4 : ""
                          )
                        }
                        disabled={!selectedRowsData.length > 0}
                      >
                        <CheckOutlined />
                        Approve All
                      </Button>
                    ) : (
                      ""
                    )}
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    scroll={{ x: 600 }}
                    columns={columns}
                    expandable={
                      localStorage.getItem("role_name") === "Supervisor" ||
                      localStorage.getItem("role_name") === "Manager" ||
                      localStorage.getItem("role_name") === "GM" ||
                      localStorage.getItem("role_name") === "Direktur" ||
                      localStorage.getItem("role_name") === "Admin"
                        ? {
                            expandedRowRender: (item, index) =>
                              purchasingDetails(item, index),
                            onExpandedRowsChange: (data) =>
                              handleChangeDetailsPurchasing(data),
                            expandedRowKeys: rowDetails,
                          }
                        : ""
                    }
                    size="small"
                    dataSource={data.dataPo}
                    // dataSource={datax}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 11,
                      current: page,
                      total: data.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default PurchasingPO;
