/* eslint-disable */ 
import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, Upload, List, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import moment from 'moment';
import {url} from '../../../config/global'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function ManageShift(props) {

    const [data, setData] = useState({
        nameShift: '',
        startShift: '',
        endShift: '',
    })

    const [type, setType] = useState('Add Shift')

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    if (props.match.params.id != null){

        useEffect(() => {
            getUser()
            setType('Edit Shift');
        }, [])
    
        const getUser = () => {
            Axios.get(`${url}/shift`, {

                params: {
                    id: props.match.params.id
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }

            }).then(res => {

                setData({
                    ...data,
                    nameShift: res.data.data.name,
                    startShift: res.data.data.start,
                    endShift: res.data.data.end,
                })

            }).catch(err => {
                if (err.response) {
                    message.error(err.response.data.message)
                    if (err.response.status == 401) {
                        localStorage.removeItem('token')
                    }
                }
            })
        }
    }

    console.log(data)

    const onSaveData = () => {

        setLoading(true)

        let URL = props.match.params.id != null ? `${url}/shift/${props.match.params.id}` : `${url}/shift`;

        let formData = new FormData()

        formData.set('name', data.nameShift)
        formData.set('start', data.startShift)
        formData.set('end', data.endShift)

        Axios.post(URL, formData, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {
            message.success(res.data.message)
            props.history.push('/shift')

        }).catch(err => {

            if (err.response) {

                message.error(err.response.data.message)

                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }

                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }

            setLoading(false)
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Shift" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/shift">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>{type}</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                                {...layout}
                                name="control-hooks"
                                onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Shift Name"
                                >
                                    <Input value={data.nameShift} onChange={(e) => handleChange('nameShift', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Shift Start"
                                >
                                    <Input value={data.startShift} onChange={(e) => handleChange('startShift', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Shift End"
                                >
                                    <Input value={data.endShift} onChange={(e) => handleChange('endShift', e.target.value)} />
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default ManageShift