/* eslint-disable */ 
import React, { Fragment, useEffect, useState } from 'react'
import { Row, Card, Col, Layout, Table, Button, Modal, Upload } from 'antd'
import { Redirect } from 'react-router-dom'
import Axios from 'axios'
import { url } from '../../config/global'
import { Bar } from 'react-chartjs-2'
import moment from 'moment'
import { extendMoment } from 'moment-range';
 
import ReactExport from "react-export-excel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import './Dash_prod.less'
import './Dashboard.less'

import FilterCom from '../../components/Dashboard/FilterCom';
import { stringComaToNumber, removePercent } from "../../helper/function";
import { object } from 'underscore'

const { Content } = Layout
const { Column, ColumnGroup } = Table;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const range = extendMoment(moment);

function Dash_prod() {

    const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    // const [date, setDate] = useState(moment('2021-06-17').format('YYYY-MM-DD'));
    const [hours, setHours] = useState([]);

    //#region Filter State
    const [shift, setShift] = useState('');
    const [Fshift, setFShift] = useState([]);

    const [comodity, setComodity] = useState('');
    const [Fcomodity, setFComodity] = useState([]);

    const [customer, setCustomer] = useState('');
    const [Fcustomer, setFCustomer] = useState([]);

    const [part, setPart] = useState('');
    const [Fpart, setFPart] = useState([]);

    // const [operator, setOperator] = useState('');
    // const [Foperator, setFOperator] = useState([]);

    const [line, setLine] = useState('');
    const [Fline, setFLine] = useState([]);
    //#endregion

    const [production, setProduction] = useState([]);
    const [productionH, setProductionH] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);
    const [loading, setLoading] = useState(false);

    //#region GetData

        //#region GetData Heijunka
        useEffect(() => {

            getDataH(date, comodity, customer, part, line, shift)

            if (!loading){

                let rotationInterval = setInterval(() => {
                    getDataH(date, comodity, customer, part, line, shift)
                }, 15000);
    
                return () => {
                    clearInterval(rotationInterval);
                }
                
            }

        }, [date, comodity, customer, part, line, shift])

        const getDataH = async () => {

            setLoading(true)

            const results = await Axios.get(`${url}/productionh`, {

                params: {
                    date, comodity, customer, part, line, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })

            var result = [];

            const res = results.data;

            const range = moment.range(res.start, res.end);
            setHours( Array.from( range.by('hour', { excludeEnd: false }) ) );

            if (res.length != 0){
                res.data.reduce(function(res, value) {
                    if ( !res[value.prod_dt] ) {
                        res[value.prod_dt] = { prod_dt: value.prod_dt, qty: 0, type: 'Heijunka' };
                        result.push(res[value.prod_dt])
                    }
                    res[value.prod_dt].qty += parseFloat(value.qty);
                    return res;
                }, {});
            }

            setProductionH(result)
            setLoading(false)

        }
        //#endregion

        //#region GetData Actual
        useEffect(() => {

            getData(date, comodity, customer, part, line, shift)

            if (!loading){

                let rotationInterval = setInterval(() => {
                    getData(date, comodity, customer, part, line, shift)
                }, 15000);
    
                return () => {
                    clearInterval(rotationInterval);
                }
                
            }

        }, [date, comodity, customer, part, line, shift])

        const getData = async () => {

            setLoading(true)

            const results = await Axios.get(`${url}/production`, {

                params: {
                    date, comodity, customer, part, line, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })

            var result = [];

            const res = results.data;

            if (res.length != 0){
                res.data.reduce(function(res, value) {
                    if ( !res[value.prod_dt] ) {
                        res[value.prod_dt] = { prod_dt: value.prod_dt, qty: 0, type: 'Actual' };
                        result.push(res[value.prod_dt])
                    }
                    res[value.prod_dt].qty += parseInt(value.qty);
                    return res;
                }, {});
            }

            setDataExcel(res.data)
            setProduction(result)
            setLoading(false)

        }
        //#endregion
    
    //#endregion

    //#region GetFilter

        //#region Comodity
        useEffect(() => {

            getComodity()

        }, [date, customer, part, line, shift])

        const getComodity = async () => {

            const res = await Axios.get(`${url}/getProdComodity`, {

                params: {
                    date, customer, part, line, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })
            
            setFComodity(res.data);

        }
        //#endregion

        //#region Customer
        useEffect(() => {

            getCustomer()

        }, [date, comodity, part, line, shift])

        const getCustomer = async () => {

            const res = await Axios.get(`${url}/getProdCustomer`, {

                params: {
                    date, comodity, part, line, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })
            
            setFCustomer(res.data);

        }
        //#endregion

        //#region Part Number
        useEffect(() => {

            getPart()

        }, [date, comodity, customer, line, shift])

        const getPart = async () => {

            const res = await Axios.get(`${url}/getProdPart`, {

                params: {
                    date, comodity, customer, line, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })
            
            setFPart(res.data);

        }
        //#endregion

        //#region Operator
        // useEffect(() => {

        //     getOperator()

        // }, [date, comodity, customer, part, line, shift])

        // const getOperator = async () => {

        //     const res = await Axios.get(`${url}/getProdOperator`, {

        //         params: {
        //             date, comodity, customer, part, line, shift
        //         },
        //         headers: {
        //             Authorization: `Bearer ${localStorage.getItem('token')}`
        //         }
                
        //     }).then(res => {

        //         return res.data;
        
        //     })
            
        //     setFOperator(res.data);

        // }
        //#endregion

        //#region Line Number
        useEffect(() => {

            getLine()

        }, [date, comodity, customer, part, shift])

        const getLine = async () => {

            const res = await Axios.get(`${url}/getProdLine`, {

                params: {
                    date, comodity, customer, part, shift
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })
            
            setFLine(res.data);

        }
        //#endregion

        //#region Shift
        useEffect(() => {

            getShift()

        }, [date, comodity, customer, part, line])

        const getShift = async () => {

            const res = await Axios.get(`${url}/shiftOnly`, {

                params: {
                    date, comodity, customer, part, line
                },
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
                
            }).then(res => {

                return res.data;
        
            })
            
            setFShift(res.data);

        }
        //#endregion
        
    //#endregion
    
    //#region Olah Data
    let TotalA
    let TotalH
    let TotalP

    let ChartT = [];
    let DataTable = [];
    let DataTable_tmp = [];
    let ColumnTable = [];
    let CT;

    CT = {
        title: 'Time',
        dataIndex: 'Type',
        key: 'type',
        colSpan: 2,
    }
    ColumnTable.push(CT);

    CT = {
        title: 'Value',
        dataIndex: 'Value',
        key: 'value',
        colSpan: 0,
        align: 'center',
    }
    ColumnTable.push(CT);

    //#region Heijunka
    let dataH = [];
    let dataHA = [];
    let dataTH = [];
    let ObjTH;
    let dataTHA = [];
    let ObjTHA;

    let ObjH = {
        'Type': 'Heijunka',
        'Value': 'Pcs',
    }
    ObjTH = {...ObjTH, ...ObjH};
    
    let ObjHA = {
        'Type': 'Heijunka Acc',
        'Value': 'Pcs',
    }
    ObjTHA = {...ObjTHA, ...ObjHA};

    hours.map((m, i) => {

        let o = m.format('HH')

        var qtyH = 0;
        var qtyHA = 0;
        var lastqtyH = 0;
        
        let ObjDefault = {
            [o]: 0
        }

        ObjTH = {...ObjTH, ...ObjDefault};
        ObjTHA = {...ObjTHA, ...ObjDefault};

        for (let i = 0; i < productionH.length; i++) {

            const time = moment(new Date(productionH[i].prod_dt)).format('HH')

            if (time == o){
    
                //#region Data Chart
                qtyH = productionH[i].qty.toFixed(0)
        
                if (i == 0){

                    qtyHA = productionH[i].qty.toFixed(0)
        
                } else {

                    qtyHA = lastqtyH + productionH[i].qty;
                    
                }
                //#endregion
        
                //#region Data Table
                let ObjH = {
                    [o]: qtyH
                }
                ObjTH = {...ObjTH, ...ObjH};
        
                let ObjHA = {
                    [o]: qtyHA
                }
                ObjTHA = {...ObjTHA, ...ObjHA};
                //#endregion

            } else {
                
                if( time > o ){

                    qtyHA = qtyHA != 0 ? qtyHA : lastqtyH;

                    let ObjH = {
                        [o]: qtyH
                    }

                    let ObjHA = {
                        [o]: qtyHA
                    }

                    ObjTH = {...ObjTH, ...ObjH};
                    ObjTHA = {...ObjTHA, ...ObjHA};

                } else {

                    lastqtyH += productionH[i].qty;

                }
                
            }

        }

        qtyHA = qtyHA != 0 ? qtyHA : lastqtyH;

        qtyHA = parseFloat(qtyHA).toFixed(0)

        let ObjHA = {
            [o]: parseFloat(qtyHA).toFixed(0)
        }
        ObjTHA = {...ObjTHA, ...ObjHA};

        dataH.push(qtyH);
        dataHA.push(qtyHA);

    })

    TotalH = dataH.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
    TotalH = TotalH.toFixed(0)

    dataTH.push(ObjTH)
    dataTHA.push(ObjTHA)
    //#endregion

    //#region Actual
    let dataA = [];
    let dataAA = [];
    let dataTA = [];
    let ObjTA;
    let dataTAA = [];
    let ObjTAA;

    let ObjA = {
        'Type': 'Actual',
        'Value': 'Pcs',
    }
    ObjTA = {...ObjTA, ...ObjA};

    let ObjAA = {
        'Type': 'Actual Acc',
        'Value': 'Pcs',
    }
    ObjTAA = {...ObjTAA, ...ObjAA};

    hours.map((m, i) => {

        let o = m.format('HH')

        var qtyA = 0;
        var qtyAA = 0;
        var lastqtyA = 0;
        
        let ObjDefault = {
            [o]: 0
        }

        ObjTA = {...ObjTA, ...ObjDefault};
        ObjTAA = {...ObjTAA, ...ObjDefault};

        for (let i = 0; i < production.length; i++) {

            const time = moment(new Date(production[i].prod_dt)).format('HH')

            if (time == o){
    
                //#region Data Chart
                qtyA = production[i].qty;
                
                if (i == 0){

                    qtyAA = production[i].qty;
        
                } else {
                    
                    qtyAA = lastqtyA + production[i].qty;

                }
                //#endregion
        
                //#region Data Table
                let ObjA = {
                    [o]: qtyA
                }

                ObjTA = {...ObjTA, ...ObjA};
        
                let ObjAA = {
                    [o]: qtyAA
                }

                ObjTAA = {...ObjTAA, ...ObjAA};
                //#endregion

            } else {
                
                if( time > o ){

                    qtyAA = qtyAA != 0 ? qtyAA : lastqtyA;

                    let ObjA = {
                        [o]: qtyA
                    }

                    let ObjAA = {
                        [o]: qtyAA
                    }

                    ObjTA = {...ObjTA, ...ObjA};
                    ObjTAA = {...ObjTAA, ...ObjAA};

                } else {

                    lastqtyA += production[i].qty;

                }
                
            }

        }

        qtyAA = qtyAA != 0 ? qtyAA : lastqtyA;

        let ObjAA = {
            [o]: qtyAA
        }
        ObjTAA = {...ObjTAA, ...ObjAA};

        dataA.push(qtyA);
        dataAA.push(qtyAA);

    })

    TotalA = dataA.reduce((a, b) => a + b, 0);
    
    dataTA.push(ObjTA)
    dataTAA.push(ObjTAA)
    //#endregion

    //#region Percent
    let dataTP = [];
    let ObjTP;
    
    let dataTPA = [];
    let ObjTPA;

    let ObjP = {
        'Type': 'Percentage',
        'Value': '%',
    }
    ObjTP = {...ObjTP, ...ObjP};
    
    let ObjPA = {
        'Type': 'Percentage Acc',
        'Value': '%',
    }
    ObjTPA = {...ObjTPA, ...ObjPA};

    hours.map((m, i) => {

        let o = m.format('HH')

        if (dataA[i] != 0 && dataH[i] != 0){

            let ObjP = {
                [o]: Math.trunc(dataA[i] / dataH[i] * 100) + '%'
            }
            ObjTP = {...ObjTP, ...ObjP};

        } else if (dataA[i] != 0 || dataH[i] != 0){

            let ObjP = {
                [o]: '0%'
            }
            ObjTP = {...ObjTP, ...ObjP};

        } else {

            let ObjP = {
                [o]: '-'
            }
            ObjTP = {...ObjTP, ...ObjP};

        }

        if (dataAA[i] != 0 && dataHA[i] != 0){
        
            let ObjPA = {
                [o]: Math.trunc(dataAA[i] / dataHA[i] * 100) + '%'
            }
            ObjTPA = {...ObjTPA, ...ObjPA};

        } else if (dataAA[i] != 0 || dataHA[i] != 0){

            let ObjPA = {
                [o]: '0%'
            }
            ObjTPA = {...ObjTPA, ...ObjPA};

        } else {
        
            let ObjPA = {
                [o]: '-'
            }
            ObjTPA = {...ObjTPA, ...ObjPA};

        }

    })

    TotalP = TotalA != 0 && TotalH != 0 ? Math.trunc(TotalA / TotalH * 100) + '%' : 0 + '%';

    dataTP.push(ObjTP)
    dataTPA.push(ObjTPA)
    //#endregion

    //#endregion

    //#region ColumnTable
    hours.map((m, i) => {

        ChartT.push( m.format('HH:mm') );

        CT = {
            title: ChartT[i],
            dataIndex: m.format('HH'),
            key: m.format('HH'),
            align: 'right',
            render: (text) => {

                let result;
                if ( typeof text == 'string' && text.includes('%', text.length - 1) && text.replace('%', '') < 100 ){
                    result = <span className="below100">{text}</span>;
                } else {
                    result = text;
                }
                return result;
            }
        }
        ColumnTable.push(CT);
    })
    //#endregion

    DataTable = DataTable_tmp.concat(dataTH);
    DataTable = DataTable.concat(dataTHA);
    DataTable = DataTable.concat(dataTA);
    DataTable = DataTable.concat(dataTAA);
    DataTable = DataTable.concat(dataTP);
    DataTable = DataTable.concat(dataTPA);

    if (!localStorage.getItem('token')) {
      return <Redirect to="/login" />
    }

    const ModalBtn = (
        <div
            style={{
                display: 'flex'
            }}
        >
            <div>
                <ExcelFile element={<Button type="primary">Export Data</Button>} filename={`Production-${moment(new Date()).format('YYYYMMDD')}`}>
                    <ExcelSheet data={dataExcel} name={`Data`}>
                        <ExcelColumn label="Date" value="prod_dt"/>
                        <ExcelColumn label="Comodity" value="comodity"/>
                        <ExcelColumn label="Customer" value="customer"/>
                        <ExcelColumn label="Part Number" value="part_no"/>
                        {/* <ExcelColumn label="Operator" value="operator"/> */}
                        <ExcelColumn label="Line Number" value="line_no"/>
                        <ExcelColumn label="Quantity" value="qty"/>
                    </ExcelSheet>
                </ExcelFile>
            </div>
        </div>
    )

    return (
        <Fragment>
            <Row className="header-body">
                <Col xs={24}>
                    <Card type="inner" className="title-dashboard" title="Production VS Actual Control" bodyStyle={{padding: "0"}} extra={ModalBtn}></Card>
                </Col>
            </Row>
            <Content className="content">
                <Row gutter={[16, 20]}>
                    <Col xs={24} md={6} lg={4} className="sidebar-body">
                        <FilterCom
                            title='Date'
                            value={date}
                            placeholder='Pilih Tanggal'
                            type='date'
                            onChange={(Item) => setDate(moment(Item).format('YYYY-MM-DD'))}
                        />
                        <FilterCom
                            title='Shift'
                            type='select'
                            option={Fshift}
                            value={shift}
                            onChange={(Item) => setShift(Item)}
                        />
                        <FilterCom
                            title='Comodity'
                            type='select'
                            option={Fcomodity}
                            value={comodity}
                            onChange={(Item) => setComodity(Item)}
                        />
                        <FilterCom
                            title='Customer'
                            type='select'
                            option={Fcustomer}
                            value={customer}
                            onChange={(Item) => setCustomer(Item)}
                        />
                        <FilterCom
                            title='Part number'
                            type='select'
                            option={Fpart}
                            value={part}
                            onChange={(Item) => setPart(Item)}
                        />
                        {/* <FilterCom
                            title='Operator'
                            type='select'
                            option={Foperator}
                            value={operator}
                            onChange={(Item) => setOperator(Item)}
                        /> */}
                        <FilterCom
                            title='Line no'
                            type='select'
                            option={Fline}
                            value={line}
                            onChange={(Item) => setLine(Item)}
                        />
                    </Col>
                    <Col xs={24} md={18} lg={20} className="main-body">
                        <Card
                            style={{
                                marginBottom: 10,
                            }}
                        >
                            <Row gutter={[16, 20]}>
                                <Col xs={24} md={18} lg={20}>
                                    <Table
                                        size="small"
                                        dataSource={DataTable}
                                        columns={ColumnTable}
                                        rowKey={
                                            data => data.no
                                        }
                                        bordered
                                        scroll={{x: 'auto'}}
                                        pagination={false}
                                        loading={loading}
                                    />
                                </Col>
                                <Col xs={24} md={6} lg={4}>

                                    <div className="contentTotal">
                                        <Card size="small" title="PLAN">
                                            <h2>{TotalH}</h2>
                                        </Card>

                                        <Card size="small" title="ACTUAL">
                                            <h2>{TotalA}</h2>
                                        </Card>

                                        <Card size="small" title="PERCENTAGE">
                                            <h2 className={`${removePercent(TotalP) < 100 ? 'below100' : ''}`}>{TotalP}</h2>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>

                        </Card>
                        
                        <Card
                            style={{
                                marginBottom: 10,
                            }}
                        >
                            {
                                !loading ? (
                                <Bar
                                    data={{
                                        labels: ChartT,
                                        datasets: [
                                            {
                                                label: 'Heijunka',
                                                data: dataH,
                                                backgroundColor: 'rgba(255, 108, 0, .5)',
                                                borderColor: 'rgba(255, 108, 0, 1)',
                                                borderWidth: 1,
                                                yAxisID: 'YKiri'
                                            },
                                            {
                                                label: 'Heijunka Acc',
                                                data: dataHA,
                                                backgroundColor: 'transparent',
                                                borderColor: 'rgba(0, 228, 255, 1)',
                                                borderWidth: 1,
                                                type: 'line',
                                                yAxisID: 'YKanan'
                                            },
                                            {
                                                label: 'Actual',
                                                data: dataA,
                                                backgroundColor: 'rgba(0, 89, 255, .5)',
                                                borderColor: 'rgba(0, 89, 255, 1)',
                                                borderWidth: 1,
                                                yAxisID: 'YKiri'
                                            },
                                            {
                                                label: 'Actual Acc',
                                                data: dataAA,
                                                backgroundColor: 'transparent',
                                                borderColor: 'rgba(255, 216, 0, 1)',
                                                borderWidth: 1,
                                                type: 'line',
                                                yAxisID: 'YKanan'
                                            }
                                        ]
                                    }}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            xAxes: [
                                                {
                                                    gridLines: {
                                                        display: false
                                                    },
                                                },
                                            ],
                                            yAxes: [
                                                {
                                                    display: true,
                                                    position: 'left',
                                                    scaleLabel: {
                                                        display: true,
                                                        beginAtZero: true,
                                                    },
                                                    id: "YKiri"
                                                },
                                                {
                                                    display: true,
                                                    position: 'right',
                                                    scaleLabel: {
                                                        display: true,
                                                        beginAtZero: true,
                                                    },
                                                    id: "YKanan"
                                                }
                                            ]
                                        },
                                    }}
                                />
                                ) : (<div className="loading"><FontAwesomeIcon icon={faSpinner} className="spinner" size='2x' pulse /></div>)
                            }
                            
                        </Card> 
                    </Col>
                </Row>
            </Content>
        </Fragment>
    );
}


export default Dash_prod