/* eslint-disable */ 
import React from 'react'
import { Card, Input, DatePicker, Select } from 'antd'
import moment from 'moment'
import { isNull } from 'underscore';

function FilterCom({title, value, placeholder, type, onChange, option}) {


    let input;
    let SelectOption;

    if (option == null || option == ''){
        SelectOption = '';

    } else {
        
        SelectOption = option.map((element) => <Select.Option key={element}>{element}</Select.Option>)

    };

    if (type == 'text'){
        input = <Input
            value={value}
            placeholder={placeholder}
            style={{ width: '100%' }}
        />

    } else if (type == 'date') {
        input = <DatePicker
            defaultValue={moment(value, 'YYYY-MM-DD')}
            style={{ width: '100%' }}
            onChange={onChange}
            inputReadOnly={true}
            allowClear={false}
        />

    } else if (type == 'select') {
        input = <Select
            defaultValue="All"
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a data"
            optionFilterProp="children"
            filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChange}
        >
            <Select.Option value="">All</Select.Option>
            {SelectOption}
        </Select>

    }
    
    return (
        <Card
            title={title}
            headStyle={{fontSize: 14}}
            style={{
                marginBottom: 10,
            }}
        >
            {input}
        </Card>
    )
}

export default FilterCom
