import React from "react";
import NumberFormat from "react-number-format";

function AntNumberFormat(props) {
  const otherConfig = props.onChange
    ? {
        onValueChange: (e) => props.onChange(e.floatValue),
      }
    : null;
  return (
    <NumberFormat
      disabled={props.disabled ? props.disabled : false}
      displayType={props.type === "text" ? "text" : "input"}
      value={props.value}
      decimalSeparator="."
      thousandSeparator=","
      suffix={props.suffix === "percent" ? '%' : ''}
      decimalScale={
        props.decimalScale === "JPY"
          ? 2
          : props.decimalScale === "USD"
          ? 2
          : props.decimalScale === "IDR"
          ? 2
          : props.decimalScale === "SGD"
          ? 2
          : props.decimalScale === "DEC" 
          ? 3 
          : props.decimalScale === "DES" 
          ? 2 
          : 0
      }
      prefix={
        props.prefix === "JPY"
          ? "¥"
          : props.prefix === "USD"
          ? "$"
          : props.prefix === "IDR"
          ? "Rp."
          : props.prefix === "SGD"
          ? "S$"
          : ""
      }

      //className="ant-input text-right"
      className={`${props.type === "input" ? "ant-input" : ""} text-right`}
      // onValueChange={
      //   props.onChange ? (e) => props.onChange(e.floatValue) : true
      // }
      {...otherConfig}
    />
  );
}

export default AntNumberFormat;
