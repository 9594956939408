/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  message,
  Space,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import moment from "moment";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};

function EditKanbanMaterial(props) {
  const [data, setData] = useState({
    kanban_type: "",
    job_type: "",
    part_number: "",
    item_id: "",
    item_name: "",
    item_label: "",
    item_comodity: "",
    item_category: "",
    item_photo: "",
    item_photo_url: "",
    unit: "",
    qr_code: "",
    customer_code: "",
    quantity_kanban: "",
    picture: "",
    location: "",
    location_id: "",
    locations: [],
    suppliers: [],
    comoditys: [],
    lines: [],
    line_id: "",
    line_number: "",
    line_label: "",
    case_mark: "",
    comodity_id: "",
    comodity: "",
    supplier_id: "",
    supplier_name: "",
    items: [],
    packagings: [],
    packaging_id: "",
    packaging_label: "",
    packaging_type: "",
    packaging_name: "",
    customer: [],
    customer_id: "",
    customer_label: "",
    customer_name: "",
    supplier_id: "",
    supplier_label: "",
    supplier_name: "",
    supplier_code: "",
    routes: [],
    route_id: "",
    route_label: "",
    route_code: "",
    route_name: "",
  });

  useEffect(() => {
    getData();
  }, []);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    if (name === "kanban_type") {
      setError(null);

      setData({
        ...data,
        [name]: value,
      });

      console.log(name, value, "kanban");
    } else {
      setError(null);
      setData({
        ...data,
        [name]: value,
      });
      console.log(name, value);
    }
  };

  const getData = () => {
    Axios.get(`${url}/kanban/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          //Material
          kanban_type: res.data.data.kanban_type,
          item_category: res.data.data.category,
          location_label: res.data.data.location,
          location_name: res.data.data.location,
          rack: res.data.data.rack,
          production_date: moment(res.data.data.production_date),
          item_label: `${res.data.data.item_code} - ${res.data.data.item_name}`,
          item_code: res.data.data.item_code,
          item_name: res.data.data.item_name,
          quantity_kanban: res.data.data.quantity_kanban,
          supplier_name: res.data.data.supplier_name,
          qr_code: res.data.data.qr_code,
        });
        console.log(data.kanban_type, "ini kanbans");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "item") {
      const find = data.items.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.item_code,
        [`${name}_name`]: find.item_name,
        [`${name}_comodity`]: find.comodity,
        [`${name}_category`]: find.item_category,
        [`${name}_photo`]: find.picture,
        [`${name}_photo_url`]: find.photo_url,
        [`supplier_name`]: find.supplier_name,
        [`supplier_code`]: find.supplier_code,
        [`location_name`]: find.location_name,
        [`packaging_type`]: find.packaging_type,
        [`packaging_name`]: find.packaging_name,
        [`unit`]: find.unit,
      });

      console.log(name, value, find.item_code, find.item_name, find.comodity);
    } else if (name === "line") {
      const find = data.lines.find((x) => x._id === value.key);

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_number`]: find.name,
      });

      console.log(name, value);
    } else if (name === "comodity") {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });

      console.log(name, value);
    } else if (name === "location") {
      const find = data.locations.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.name,
        [`rack`]: find.rack,
      });
      console.log(name, value);
    } else if (name === "packaging") {
      const find = data.packagings.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_type`]: find.packaging_type,
        [`${name}_name`]: find.packaging_name,
      });

      console.log(name, value, find.item_code, find.item_name, find.comodity);
    } else if (name === "customer") {
      const find = data.customer.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.name,
      });

      console.log(name, value, "ini customer");
    } else if (name === "supplier") {
      const find = data.suppliers.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.name,
      });

      console.log(name, value, "ini supplier");
    } else if (name === "route") {
      const find = data.routes.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_name`]: find.process_name,
      });

      console.log(name, value, "ini route");
    } else {
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_name`]: value.label,
      });

      console.log(name, value);
    }
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("kanban_type", data.kanban_type);
    fd.set("category", data.item_category);
    fd.set("location", data.location_name);
    fd.set("rack", data.rack);
    fd.set("qr_code", data.qr_code);
    fd.set("production_date", data.production_date);
    fd.set("item_code", data.item_code);
    fd.set("item_name", data.item_name);
    fd.set("quantity_kanban", data.quantity_kanban);
    fd.set("supplier_name", data.supplier_name);
    fd.set("supplier_code", data.supplier_code);
    fd.set("unit", data.unit);

    Axios.post(`${url}/kanban/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-kanban");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      kanban_type: "",
      job_type: "",
      part_number: "",
      item_id: "",
      item_name: "",
      item_label: "",
      item_comodity: "",
      item_category: "",
      qr_code: "",
      customer_code: "",
      quantity_kanban: "",
      picture: "",
      location: "",
      location_id: "",
      locations: [],
      suppliers: [],
      comoditys: [],
      lines: [],
      line_id: "",
      line_number: "",
      line_label: "",
      case_mark: "",
      comodity_id: "",
      comodity: "",
      supplier_id: "",
      supplier_name: "",
      items: [],
      packagings: [],
      packaging_id: "",
      packaging_label: "",
      packaging_type: "",
      packaging_name: "",
      customer: [],
      customer_id: "",
      customer_label: "",
      customer_name: "",
      supplier_id: "",
      supplier_label: "",
      supplier_name: "",
      routes: [],
      route_id: "",
      route_label: "",
      route_code: "",
      route_name: "",
    });
  };

  const getSupplier = async (keyword) => {
    await Axios.get(`${url}/supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getItem = async (keyword, type) => {
    setLoading(true);
    await Axios.get(`${url}/item/list`, {
      params: {
        keyword,
        item_category: type,
        columns: ["item_code", "item_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          items: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getLocation = async (keyword) => {
    await Axios.get(`${url}/location/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          locations: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Kanban"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-kanban">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Kanban</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Kanban Type"
                        validateStatus={
                          error && error.kanban_type ? "error" : false
                        }
                        help={
                          error && error.kanban_type
                            ? error.kanban_type[0]
                            : false
                        }
                      >
                        <Input
                          disabled
                          value={data.kanban_type}
                          onChange={(e) =>
                            handleChange("kanban_type", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Item Code"
                        validateStatus={error && error.item ? "error" : false}
                        help={error && error.item ? error.item[0] : false}
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Select
                            labelInValue
                            onChange={(value) =>
                              handleChangeSelect("item", value)
                            }
                            onFocus={() => getItem("", "raw_material")}
                            showSearch
                            onSearch={(value) => getItem(value)}
                            filterOption={false}
                            value={{
                              key: data.item_id,
                              label: data.item_label,
                            }}
                          >
                            {data.items &&
                              data.items.map((item) => {
                                return (
                                  <Option value={item._id} key={item._id}>
                                    {`${item.item_code} - ${item.item_name}`}
                                  </Option>
                                );
                              })}
                          </Select>
                        </Col>
                      </Form.Item>

                      <Form.Item
                        required
                        label="Item Name"
                        validateStatus={
                          error && error.item_name ? "error" : false
                        }
                        help={
                          error && error.item_name ? error.item_name[0] : false
                        }
                      >
                        <Input
                          disabled
                          value={data.item_name}
                          onChange={(e) =>
                            handleChange("item_name", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Category"
                        validateStatus={
                          error && error.item_category ? "error" : false
                        }
                        help={
                          error && error.item_category
                            ? error.item_category[0]
                            : false
                        }
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Input
                            disabled
                            value={data.item_category}
                            onChange={(e) =>
                              handleChange("item_category", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Item>

                      <Form.Item
                        required
                        label="Supplier Name"
                        validateStatus={
                          error && error.supplier_name ? "error" : false
                        }
                        help={
                          error && error.supplier_name
                            ? error.supplier_name[0]
                            : false
                        }
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Input
                            disabled
                            value={data.supplier_name}
                            onChange={(e) =>
                              handleChange("supplier_name", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Item>

                      <Form.Item
                        required
                        label="Unit"
                        validateStatus={error && error.unit ? "error" : false}
                        help={error && error.unit ? error.unit[0] : false}
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Input
                            disabled
                            value={data.unit}
                            onChange={(e) =>
                              handleChange("unit", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Location"
                        validateStatus={
                          error && error.location_name ? "error" : false
                        }
                        help={
                          error && error.location_name
                            ? error.location_name[0]
                            : false
                        }
                      >
                        <Col md={24} sm={24} xs={24}>
                          <Input
                            disabled
                            value={data.location_name}
                            onChange={(e) =>
                              handleChange("location_name", e.target.value)
                            }
                          />
                        </Col>
                      </Form.Item>

                      <Form.Item
                        required
                        label="Rack"
                        validateStatus={error && error.rack ? "error" : false}
                        help={error && error.rack ? error.rack[0] : false}
                      >
                        <Input
                          disabled
                          value={data.rack}
                          onChange={(e) => handleChange("rack", e.target.value)}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="QR Code"
                        validateStatus={
                          error && error.qr_code ? "error" : false
                        }
                        help={error && error.qr_code ? error.qr_code[0] : false}
                      >
                        <Input
                          value={data.qr_code}
                          onChange={(e) =>
                            handleChange("qr_code", e.target.value)
                          }
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        validateStatus={
                          error && error.quantity_kanban ? "error" : false
                        }
                        help={
                          error && error.quantity_kanban
                            ? error.quantity_kanban[0]
                            : false
                        }
                        label="Quantity Kanban"
                      >
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.quantity_kanban}
                          type="input"
                          onChange={(e) => handleChange("quantity_kanban", e)}
                        />
                      </Form.Item>

                      <Form.Item
                        required
                        label="Production Date"
                        validateStatus={
                          error && error.production_date ? "error" : false
                        }
                        help={
                          error && error.production_date
                            ? error.production_date[0]
                            : false
                        }
                      >
                        <DatePicker
                          onChange={(value) =>
                            handleChange("production_date", value)
                          }
                          value={data.production_date}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>

                      {/* Form 2 Right */}

                      {/* Form 3 Right */}
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="mr-button"
                      >
                        Submit
                      </Button>
                      <Button htmlType="reset" onClick={handleReset}>
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditKanbanMaterial;
