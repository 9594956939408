import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Card,
  Col,
  Layout,
  Table,
  Button,
  Modal,
  Upload,
  Dropdown,
  Menu,
  DatePicker,
  Input,
  message,
  Space,
} from "antd";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../config/global";
import moment from "moment";
import ReactExport from "react-export-excel";

import FilterCom from "../../components/Dashboard/FilterCom";
import {
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Meta } = Card;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;
const { Search } = Input;

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function NoteDeliv() {
  const [date, setDate] = useState({
    start: moment(new Date()).format("YYYY-MM-DD"),
    end: moment(new Date()).format("YYYY-MM-DD"),
  });
  // const [date, setDate] = useState(moment('2021-07-07').format('YYYY-MM-DD'));
  const [deliveryNote, setDeliveryNote] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [printing, setPrinting] = useState(false);

  //#region GetData
  useEffect(() => {
    getData(date);

    if (!loading) {
      let rotationInterval = setInterval(() => {
        getData(date);
      }, 15000);

      return () => {
        clearInterval(rotationInterval);
      };
    }
  }, [date, search]);

  const getData = async () => {
    setLoading(true);

    const results = await Axios.get(`${url}/deliverynote`, {
      params: {
        date,
        search,
        columns: ["part_number", "part_name"],
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      return res.data;
    });

    setDeliveryNote(results.data);
    setDataExcel(results.data);
    setLoading(false);
  };
  //#endregion

  //#region Delete
  const handleDelete = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: (
        <div>
          <p>Deleted data cannot be restored</p>
        </div>
      ),
      okText: "Delete",
      showCancel: true,
      onCancel: () => {
        return;
      },
      cancelText: "Cancel",
      confirmLoading: loading,
      onOk: async () => {
        confirmDelete(id);
      },
    });
  };

  const confirmDelete = (id) => {
    setLoading(true);
    Axios.delete(`${url}/deliverynotedetail/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        getData(date);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  //#endregion

  //download template

  const downloadTemplate = (data) => {
    setPrinting(true);
    let manifest_no = data.manifest_no;
    Axios.get(`${url}/delivery-note/surat-jalan/print`, {
      params: {
        no_manifest: manifest_no,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    })
      .then((res) => {
        var blob = new Blob([res.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .finally(() => {
        setPrinting(false);
      });
  };

  //end download template

  //#region Table Settings
  const columns = [
    {
      title: "No Manifest",
      dataIndex: "manifest_no",
    },
    {
      title: "Part Number",
      dataIndex: "part_no",
    },
    {
      title: "Customer",
      dataIndex: "customer",
    },
    {
      title: "Billed To",
      dataIndex: "billed_to",
    },
    {
      title: "Shipped To",
      dataIndex: "shipped_to",
    },
    {
      title: "Qty",
      dataIndex: "qty",
      align: "right",
    },
    {
      title: "Date Delivery",
      dataIndex: "date_delivery",
    },
    {
      title: "Date Receiving",
      dataIndex: "date_receiving",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value, item, index) =>
        value == "Delivery" ? (
          <span style={{ color: "#ffff00" }}>{value}</span>
        ) : value == "Receive" ? (
          <span style={{ color: "#1aff1a" }}>{value}</span>
        ) : value == "Invoice" ? (
          <span style={{ color: "#00aaff" }}>{value}</span>  
        ) :(
          <span style={{ color: "#ff0000" }}>{value}</span>
        ),
    },

    {
      title: "",
      dataIndex: "id",
      align: "right",
      sorter: false,
      fixed: "right",
      width: 100,
      render: (id, data) => {
        return (
          <Fragment>
            <Dropdown
              className="pointer"
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <span onClick={() => downloadTemplate(data)}>
                      <ExportOutlined /> Print
                    </span>
                  </Menu.Item>
                  <Menu.Item disabled={data.status !== "Open"} key="2">
                    <Link to={`/delivery-note-edit-${id}`}>
                      <EditOutlined /> Edit
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <span onClick={() => handleDelete(id)}>
                      <DeleteOutlined style={{ marginRight: 3 }} />
                      Delete
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <span className="pointer" onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </span>
            </Dropdown>
          </Fragment>
        );
      },
    },
  ];
  //#endregion

  //#region Handle Modal Ok
  const handleModalOk = () => {
    getData(date);
    setModal(false);
  };
  //#endregion

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  const handleSearch = (value) => {
    setSearch(value);
  };

  console.log(deliveryNote);

  const ModalBtn = (
    <div
      style={{
        display: "flex",
      }}
    >
      <Space>
        <Link to="delivery-note-create">
          <Button
            type="primary"
            // onClick={() => setModal(true)}
            style={{
              marginRight: 10,
            }}
          >
            <PlusOutlined />
            Create
          </Button>
        </Link>
      </Space>

      <Button
        type="primary"
        onClick={() => setModal(true)}
        style={{
          marginRight: 10,
        }}
      >
        <ImportOutlined />
        Upload Plan
      </Button>
      <div>
        <ExcelFile
          element={
            <Button type="primary">
              {" "}
              <ExportOutlined /> Export Data
            </Button>
          }
          filename={`DeliveryNote-${moment(date.start).format(
            "YYYYMMDD"
          )}-${moment(date.end).format("YYYYMMDD")}`}
        >
          <ExcelSheet data={dataExcel} name={`Data-${date.start}`}>
            <ExcelColumn label="DN/Manifest No." value="manifest_no" />
            <ExcelColumn label="Date" value="date" />
            <ExcelColumn label="Item Code" value="part_no" />
            <ExcelColumn label="Customer/Vendor Name" value="customer" />
            <ExcelColumn label="Shipped TO" value="shipped_to" />
            <ExcelColumn label="Qty" value="qty" />
            <ExcelColumn label="Date Delivery" value="date_delivery" />
            <ExcelColumn label="Date Receiving" value="date_receiving" />
            <ExcelColumn label="Status" value="status" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </div>
  );

  return (
    <Fragment>
      <Row className="header-body">
        <Col xs={24}>
          <Card
            type="inner"
            title="Delivery Note"
            bodyStyle={{ padding: "0" }}
            extra={ModalBtn}
          ></Card>
        </Col>
      </Row>
      <Content className="content">
        <Modal
          title="Upload Data"
          centered
          visible={modal}
          onOk={() => handleModalOk()}
          onCancel={() => setModal(false)}
        >
          <Button href="/files/DeliveryNoteTemplateNew.xlsx">
            Download Template
          </Button>
          <hr
            style={{
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "#eee",
            }}
          />
          <Dragger
            name="file"
            action={url + "/deliverynoteUpload"}
            headers={{
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            }}
          >
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </Modal>
        <Row>
          <Col xs={24} className="main-body">
            <Card className="body-data">
              <Row
                type="flex"
                gutter={[10, 20]}
                justify="space-around"
                style={{ marginBottom: 10 }}
              >
                <Col xs={24} md={12} lg={18}>
                  <RangePicker
                    defaultValue={[
                      moment(date.start, "YYYY-MM-DD"),
                      moment(date.end, "YYYY-MM-DD"),
                    ]}
                    allowClear={false}
                    format={"YYYY-MM-DD"}
                    inputReadOnly={true}
                    onChange={(value) =>
                      setDate({
                        ...date,
                        start: moment(value[0]).format("YYYY-MM-DD"),
                        end: moment(value[1]).format("YYYY-MM-DD"),
                      })
                    }
                    style={{
                      marginRight: 10,
                    }}
                    ranges={{
                      Today: [moment(), moment()],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>
              </Row>
              <Table
                columns={columns}
                dataSource={deliveryNote}
                rowKey={(data) => data.id}
                loading={loading}
              />
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default NoteDeliv;
