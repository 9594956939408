/* eslint-disable */
import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Typography,
  Menu,
  Layout,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  Table,
  message,
  Space,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import moment from "moment";
import { url } from "../../config/global";
import {
  UserOutlined,
  MoreOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
// import "./User.less";

const { Title } = Typography;
const { Search } = Input;
const { Content } = Layout;

function SuratJalan() {
  const [keyword, setKeyword] = useState("");
  const [suratJalan, setSuratJalan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    sort: "full_name",
    order: "ascend",
  });

  const handleSearch = (value) => {
    setKeyword(value);
  };

  useEffect(() => {
    //getData();
  }, [keyword, page, sort]);

  const getData = () => {
    setLoading(true);
    Axios.get(`${url}/surat-jalan`, {
      params: {
        keyword,
        columns: ["item_name", "item_code"],
        perpage: 10,
        page,
        sort: sort.sort,
        order: sort.order,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setWarehouses(res.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setPage(pagination.current);
    setSort({
      sort: sorter.field,
      order: sorter.order,
    });
  };

  const dataSource = [
    {
      key: "1",
      no_dn_sii: "60311/SII-SJ/08/22",
      date: "2022-08-01",
      ship_to: "PT Toyota Astra Motor",
      bill_to: "PT Astra Daihatsu Motor",
      dn_no: "SC2073",
      po_no: "SC2073",
    },
    {
      key: "2",
      no_dn_sii: "60312/SII-SJ/08/22",
      date: "2022-08-02",
      ship_to: "PT Nankatsu",
      bill_to: "PT Shutetsu",
      dn_no: "SC2011",
      po_no: "SC2011",
    },
  ];

  const columns = [
    {
      title: "No DN SII",
      dataIndex: "no_dn_sii",
      sorter: true,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: false,
    },
    {
      title: "Ship to",
      dataIndex: "ship_to",
      sorter: false,
    },
    {
      title: "Bill to",
      dataIndex: "bill_to",
      sorter: false,
      width: 150,
    },
    {
      title: "DN No",
      dataIndex: "dn_no",
      sorter: false,
      width: 150,
    },
    {
      title: "PO No",
      dataIndex: "po_no",
      sorter: false,
      width: 150,
    },
    // {
    //   title: "Created At",
    //   dataIndex: "created_at",
    //   sorter: false,
    //   width: 150,
    //   render: (date) => moment(date).format("DD MMM YYYY"),
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   sorter: false,
    //   width: 150,
    // },
    // {
    //   title: "Updated At",
    //   dataIndex: "updated_at",
    //   sorter: false,
    //   width: 150,
    //   render: (date) => moment(date).format("DD MMM YYYY"),
    // },
    // {
    //   title: "",
    //   dataIndex: "_id",
    //   align: "right",
    //   sorter: false,
    //   fixed: "right",
    //   width: 100,
    //   render: (id) => {
    //     return (
    //       <Fragment>
    //         <Dropdown
    //           className="pointer"
    //           overlay={
    //             <Menu>
    //               {/* <Menu.Item key="0">
    //                                 <Link to={`/user/view/${id}`}><InfoCircleOutlined /> View</Link>
    //                             </Menu.Item> */}
    //               <Menu.Item key="1">
    //                 <Link to={`/user-edit-${id}`}>
    //                   <EditOutlined /> Edit
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item key="3">
    //                 <span onClick={() => handleDelete(id)}>
    //                   <DeleteOutlined />
    //                   Delete
    //                 </span>
    //               </Menu.Item>
    //             </Menu>
    //           }
    //           trigger={["click"]}
    //         >
    //           <span className="pointer" onClick={(e) => e.preventDefault()}>
    //             <MoreOutlined />
    //           </span>
    //         </Dropdown>
    //       </Fragment>
    //     );
    //   },
    // },
  ];

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Surat Jalan"
            bodyStyle={{ padding: "0" }}
            
          ></Card>
        </Col>
      </Row>

      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Row type="flex" gutter={[10, 20]} justify="space-around">
                <Col xs={24} md={12} lg={18}>
                  <Space>
                    <Link to="/create-surat-jalan">
                      <Button type="primary">
                        <PlusOutlined />
                        Create
                      </Button>
                    </Link>
                  </Space>
                </Col>
                <Col xs={24} md={12} lg={6} className="align-right">
                  <Search
                    placeholder="Search ..."
                    onSearch={(value) => handleSearch(value)}
                  />
                </Col>

                <Col xs={24}>
                  <Table
                    columns={columns}
                    //dataSource={suratJalan.data}
                    dataSource={dataSource}
                    rowKey={(data) => data._id}
                    onChange={handleTableChange}
                    pagination={{
                      pageSize: 10,
                      current: page,
                      total: suratJalan.total,
                      showSizeChanger: false,
                    }}
                    loading={loading}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default SuratJalan;
