/* eslint-disable */ 
import React, { Fragment, useState, useEffect } from 'react'
import { Form, Input, Button, Select, Layout, Row, Col, Card, Breadcrumb, Typography, DatePicker, Checkbox, message } from 'antd';
import { Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import moment from 'moment';
import {url} from '../../config/global'

const { Title } = Typography
const { Option } = Select;
const { Content } = Layout

const layout = {
    labelCol: {
        lg: 8,
        md: 10
    },
    wrapperCol: {
        lg: 8,
        md: 14
    },
}
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
}

function PlanInvenManage(props) {

    const [data, setData] = useState({
        prod_dt: '',
        part_no: '',
        planing: '',
        work_days: '',
        min_stock: '',
        max_stock: '',
        effect_lastdate: false,
    })

    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    //#region get Data
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        Axios.get(`${url}/inventoryhdetail`, {

            params: {
                id: props.match.params.id
            },
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {

            res = res.data.data
            
            setData({
                ...data,
                prod_dt: res.prod_dt,
                part_no: res.part_no,
                planing: res.planing,
                work_days: res.work_days,
                min_stock: res.min_stock,
                max_stock: res.max_stock,
            })

        }).catch(err => {
            if (err.response) {
                message.error(err.response.data.message)
                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }
        })
    }
    //#endregion

    const onSaveData = () => {

        setLoading(true)

        let formData = new FormData()

        formData.set('prod_dt', data.prod_dt)
        formData.set('part_no', data.part_no)
        formData.set('planing', data.planing)
        formData.set('work_days', data.work_days)
        formData.set('min_stock', data.min_stock)
        formData.set('max_stock', data.max_stock)
        formData.set('effect_lastdate', data.effect_lastdate ? 1 : 0)

        Axios.post(`${url}/inventoryhdetail/${props.match.params.id}`, formData, {

            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }

        }).then(res => {
            message.success(res.data.message)
            props.history.push('/inventory-plan')

        }).catch(err => {

            if (err.response) {

                message.error(err.response.data.message)

                if (err.response.status === 422) {
                    setError(err.response.data.errors)
                }

                if (err.response.status == 401) {
                    localStorage.removeItem('token')
                }
            }

            setLoading(false)
        })
    }

    const handleChange = (name, value) => {
        setError(null)
        setData({
            ...data,
            [name]: value
        })
    }

    console.log(data);

    if (!localStorage.getItem('token')) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <Row>
                <Col xs={24}>
                    <Card type="inner" title="Plan Inventory" extra={moment(new Date()).format('DD MMMM YYYY')}>
                        <Row type="flex" justify="left">
                            <Breadcrumb>
                                <Breadcrumb.Item>
                                    <Link to="/inventory-plan">Back</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>Edit Plan</Breadcrumb.Item>
                            </Breadcrumb>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Content className="content">
                <Row>
                    <Col xs={24}>
                        <Card className="body-data">
                            <Form 
                                {...layout}
                                name="control-hooks"
                                onFinish={onSaveData}
                            >
                                <Form.Item
                                    required
                                    label="Date"
                                >
                                    <DatePicker
                                        value={moment(data.prod_dt, 'YYYY-MM-DD')}
                                        onChange={(Item) => handleChange('prod_dt', moment(Item).format('YYYY-MM-DD'))}
                                        inputReadOnly={true}
                                        format={'YYYY-MM-DD'}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Part Number"
                                >
                                    <Input value={data.part_no} onChange={(e) => handleChange('part_no', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Planing"
                                >
                                    <Input value={data.planing} onChange={(e) => handleChange('planing', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Hari Kerja"
                                >
                                    <Input value={data.work_days} onChange={(e) => handleChange('work_days', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Minimal Stock"
                                >
                                    <Input value={data.min_stock} onChange={(e) => handleChange('min_stock', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    required
                                    label="Maximal Stock"
                                >
                                    <Input value={data.max_stock} onChange={(e) => handleChange('max_stock', e.target.value)} />
                                </Form.Item>
                                <Form.Item
                                    label="Effect to last date"
                                    initialValue={false}
                                >
                                    <Checkbox onChange={(e) => handleChange('effect_lastdate', e.target.checked)}>Checkbox</Checkbox>
                                </Form.Item>
                                <Form.Item {...tailLayout}>
                                    <Button loading={loading} type="primary" htmlType="submit" className="mr-button">
                                        Submit
                                    </Button>
                                </Form.Item>
                                   
                            </Form>
                        </Card>
                    </Col>
                </Row>
                
            </Content>
        </Fragment>
    )
}

export default PlanInvenManage