/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Upload,
  List,
  message,
  Space,
  DatePicker,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Axios from "axios";
import moment from "moment";
import { url } from "../../../config/global";
import AntNumberFormat from "../../../components/AntNumberFormat";

const { Title } = Typography;
const { Option, OptGroup } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditItem(props) {
  const [data, setData] = useState({
    item_code: "",
    item_name: "",
    item_category: "",
    item_category_id: "",
    item_type: "",
    part_name: "",
    part_number: "",
    comodity: "",
    comodity_id: "",
    sub_comodity: "",
    unique_code: "",
    min: 0,
    max: 0,
    pcs_hour: 0,
    model: "",
    standard: 0,
    lot: 0,
    date: "",
    unit: "",
    picture: null,
    location_label: "",
    location_id: "",
    location_name: "",
    location_code: "",
    items: [],
    comoditys: [],
    locations: [],
    suppliers: [],
    supplier_id: "",
    supplier_label: "",
    supplier_name: [],
    supplier_code: [],
    customers: [],
    customer_id: "",
    customer_label: "",
    customer_name: "",
    customer_code: "",
    supcust: [],
    packagings: [],
    packaging_id: "",
    packaging_label: "",
    packaging_type: "",
    packaging_name: "",
  });

  const [itemType, setItemType] = useState([]);
  const [comoditys, setComoditys] = useState([]);
  const [units, setUnits] = useState([]);
  const [item_category, setItemCategory] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSupplier, setLoadingSupplier] = useState(false);

  useEffect(() => {
    getItem();
    getItemType();
    getComodity();
    getUnit();
  }, []);

  const getItem = () => {
    Axios.get(`${url}/item/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          item_code: res.data.data.item_code,
          item_name: res.data.data.item_name,
          item_category: res.data.data.item_category,
          item_type: res.data.data.item_type,
          part_number: res.data.data.part_number,
          part_name: res.data.data.part_name,
          comodity: res.data.data.comodity,
          sub_comodity: res.data.data.sub_comodity,
          model: res.data.data.model,
          unique_code: res.data.data.unique_code,
          location_label: res.data.data.location,
          pcs_hour: res.data.data.pcs_hour,
          min: res.data.data.min,
          max: res.data.data.max,
          standard: res.data.data.standard,
          lot: res.data.data.lot,
          supplier_label: `${res.data.data.supplier_code} - ${res.data.data.supplier_name}`,
          supplier_code: res.data.data.supplier_code,
          supplier_name: res.data.data.supplier_name,

          customer_label: `${res.data.data.customer_code} - ${res.data.data.customer_name}`,
          customer_code: res.data.data.customer_code,
          customer_name: res.data.data.customer_name,

          supcust_label: `${res.data.data.supcust_code} - ${res.data.data.supcust_name}`,
          supcust_code: res.data.data.supcust_code,
          supcust_name: res.data.data.supcust_name,

          packaging_type: res.data.data.packaging_type,
          packaging_label: `${res.data.data.packaging_type} - ${res.data.data.packaging_name} `,

          date: moment(res.data.data.date),
        });
        getItemCategory();
        getComodity();
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getUnit = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Unit",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setUnits(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getItemType = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Item Type",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItemType(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getPackaging = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/packaging/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          packagings: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeSelect = (name, value) => {
    if (name === "location") {
      const find = data.locations.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_code`]: find.code,
        [`${name}_name`]: find.name,
      });

      console.log(name, value, find.location_name, "cek location_name");
    } else if (name === "supplier") {
      const find = data.suppliers.filter((test) => value.includes(test._id));

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.value,
        [`${name}_code`]: find.map((x) => x.code),
        [`${name}_name`]: find.map((x) => x.name),
      });

      console.log(name, value, "ini supplier");
    } else if (name === "customer") {
      const find = data.customers.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`supplier_name`]: find.name,
        [`supplier_code`]: find.code,
      });

      console.log(name, value, find.name, find.code, "ini customer");
    } else if (name === "supcust") {
      const find = data.supcust.filter((test) => value.includes(test._id));

      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.value,
        [`supplier_code`]: find.map((x) => x.code),
        [`supplier_name`]: find.map((x) => x.name),
      });

      console.log(name, value, "ini customer");
    } else if (name === "packaging") {
      const find = data.packagings.find((x) => x._id === value.key);
      // console.log(code, "code");
      setData({
        ...data,
        [`${name}_id`]: value.value,
        [`${name}_label`]: value.label,
        [`${name}_type`]: find.packaging_type,
        [`${name}_name`]: find.packaging_name,
      });
    }
  };

  const getLocation = async (keyword) => {
    setLoading(true);
    await Axios.get(`${url}/location/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          locations: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            // handleRemoveDetails();
          }
        }
      });
    setLoading(false);
  };

  const getItemCategory = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Item Category",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setItemCategory(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getComodity = async (keyword) => {
    await Axios.get(`${url}/settings/find`, {
      params: {
        keyword,
        variable: "Comodity",
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setComoditys(res.data.data);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getSupplierCustomer = async (keyword) => {
    setLoadingSupplier(true);
    await Axios.get(`${url}/supplier-customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          supcust: res.data.data,
        });
        setLoadingSupplier(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoadingSupplier(false);
      });
  };

  const getCustomer = async (keyword) => {
    await Axios.get(`${url}/customer/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          customers: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const getSupplier = async (keyword) => {
    setLoadingSupplier(true);
    await Axios.get(`${url}/supplier/list`, {
      params: {
        keyword,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          suppliers: res.data.data,
        });
        setLoadingSupplier(false);
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 401) {
            localStorage.removeItem("token");
          }
        }
        setLoadingSupplier(false);
      });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("item_code", data.item_code);
    fd.set("item_name", data.item_name);
    fd.set("item_category", data.item_category);
    fd.set("item_type", data.item_type);
    fd.set("part_name", data.part_name);
    fd.set("part_number", data.part_number);
    fd.set("unit", data.unit);
    fd.set("comodity", data.comodity);
    fd.set("sub_comodity", data.sub_comodity);
    fd.set("location_code", data.location_code);
    fd.set("location_name", data.location_name);
    fd.set("packaging_type", data.packaging_type);
    fd.set("packaging_name", data.packaging_name);
    fd.set("model", data.model);
    fd.set("unique_code", data.unique_code);
    fd.set("pcs_hour", data.pcs_hour);
    fd.set("min", data.min);
    fd.set("max", data.max);
    fd.set("standard", data.standard);
    fd.set("lot", data.lot);
    fd.set("supplier_code", data.supplier_code);
    fd.set("supplier_name", data.supplier_name);
    fd.set("date", data.date);
    fd.append("picture", data.picture);

    Axios.post(`${url}/item/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-item");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleRemove = () => {
    setData({
      ...data,
      picture: null,
    });
  };

  const handleBeforeUpload = (files) => {
    setData({
      ...data,
      picture: files,
    });

    return false;
  };

  const handleReset = () => {
    setData({
      pcs_hour: "",
      item_code: "",
      item_name: "",
      item_category: "",
      item_category_id: "",
      item_type: "",
      part_name: "",
      part_number: "",
      comodity: "",
      comodity_id: "",
      sub_comodity: "",
      min: 0,
      max: 0,
      model: "",
      standard: 0,
      lot: 0,
      date: "",
      unit: "",
      unique_code: "",
      picture: null,
      location_label: "",
      location_id: "",
      location_name: "",
      location_code: "",
      items: [],
      comoditys: [],
      locations: [],
      suppliers: [],
      supplier_id: "",
      supplier_label: "",
      supplier_name: [],
      supplier_code: [],
      customers: [],
      customer_id: "",
      customer_label: "",
      customer_name: "",
      customer_code: "",
      supcust: [],
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Item"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-item">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Edit Master Item</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item label="Item Code" required>
                        <Input
                          value={data.item_code}
                          onChange={(e) =>
                            handleChange("item_code", e.target.value)
                          }
                          placeholder="input Item Code"
                        />
                      </Form.Item>
                      <Form.Item label="Item Name" required>
                        <Input
                          value={data.item_name}
                          onChange={(e) =>
                            handleChange("item_name", e.target.value)
                          }
                          placeholder="input Item Name"
                        />
                      </Form.Item>
                      <Form.Item
                        label="Item Category"
                        name="item_category"
                        required
                      >
                        <Select
                          placeholder="Select Item Category"
                          value={data.item_category}
                          onChange={(value) =>
                            handleChange("item_category", value)
                          }
                        >
                          {item_category &&
                            item_category.map((item) => {
                              return (
                                <Option key={item.id} value={item.name}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Item Type">
                        <Select
                          placeholder="Select Item Type"
                          value={data.item_type}
                          onChange={(value) => handleChange("item_type", value)}
                        >
                          {itemType &&
                            itemType.map((item) => {
                              return (
                                <Option key={item.id} value={item.name}>
                                  {item.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Part Name" required>
                        <Input
                          value={data.part_name}
                          onChange={(e) =>
                            handleChange("part_name", e.target.value)
                          }
                          placeholder="input Part Name"
                        />
                      </Form.Item>
                      <Form.Item label="Part Number" required>
                        <Input
                          value={data.part_number}
                          onChange={(e) =>
                            handleChange("part_number", e.target.value)
                          }
                          placeholder="input Part Number"
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Packaging Type"
                        validateStatus={
                          error && error.packaging_type ? "error" : false
                        }
                        help={
                          error && error.packaging_type
                            ? error.packaging_type[0]
                            : false
                        }
                      >
                        <Select
                          labelInValue
                          onChange={(value) =>
                            handleChangeSelect("packaging", value)
                          }
                          onFocus={() => getPackaging("")}
                          showSearch
                          onSearch={(value) => getPackaging(value)}
                          filterOption={false}
                          value={{
                            key: data.packaging_id,
                            label: data.packaging_label,
                          }}
                        >
                          {data.packagings &&
                            data.packagings.map((packaging) => {
                              return (
                                <Option
                                  value={packaging._id}
                                  key={packaging._id}
                                >
                                  {`${packaging.packaging_type} - ${packaging.packaging_name}`}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Comodity" required>
                        <Select
                          placeholder="Select Comodity"
                          value={data.comodity}
                          onChange={(value) => handleChange("comodity", value)}
                        >
                          {comoditys &&
                            comoditys.map((comodity) => {
                              return (
                                <Option
                                  value={comodity.name}
                                  key={comodity._id}
                                >
                                  {comodity.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item value={data.sub_comodity} label="Sub Comodity">
                        <Input
                          value={data.sub_comodity}
                          onChange={(e) =>
                            handleChange("sub_comodity", e.target.value)
                          }
                          placeholder="input Sub Comodity"
                        />
                      </Form.Item>
                      <Form.Item
                        value={data.location_name}
                        label="Location"
                        required
                      >
                        <Select
                          labelInValue
                          placeholder="Select Location"
                          onChange={(value) =>
                            handleChangeSelect("location", value)
                          }
                          onFocus={() => getLocation("")}
                          showSearch
                          onSearch={(value) => getLocation(value)}
                          filterOption={false}
                          value={{
                            key: data.location_id,
                            label: data.location_label,
                          }}
                        >
                          {data.locations &&
                            data.locations.map((location) => {
                              return (
                                <Option value={location._id} key={location._id}>
                                  {location.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item label="Unit" required>
                        <Select
                          value={data.unit}
                          placeholder="Select Unit"
                          onChange={(value) => handleChange("unit", value)}
                        >
                          {units &&
                            units.map((unit) => {
                              return (
                                <Option value={unit.name} key={unit._id}>
                                  {unit.name}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.item_category !==
                          currentValues.item_category
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("item_category") === "Finish Goods" ? (
                            <Form.Item
                              required
                              label="Customer"
                              validateStatus={
                                error && error.customer ? "error" : false
                              }
                              help={
                                error && error.customer
                                  ? error.customer[0]
                                  : false
                              }
                            >
                              <Select
                                labelInValue
                                onChange={(value) =>
                                  handleChangeSelect("customer", value)
                                }
                                onFocus={() => getCustomer("")}
                                showSearch
                                onSearch={(value) => getCustomer(value)}
                                filterOption={false}
                                value={{
                                  key: data.customer_id,
                                  label: data.customer_label,
                                }}
                              >
                                {data.customers &&
                                  data.customers.map((customer) => {
                                    return (
                                      <Option
                                        value={customer._id}
                                        key={customer._id}
                                      >
                                        {`${customer.code} - ${customer.name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.item_category !==
                          currentValues.item_category
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("item_category") === "Raw Material" ||
                          getFieldValue("item_category") === "Sub Material" ? (
                            <Form.Item
                              required
                              label="Supplier"
                              validateStatus={
                                error && error.supplier_name ? "error" : false
                              }
                              help={
                                error && error.supplier_name
                                  ? error.supplier_name[0]
                                  : false
                              }
                            >
                              <Select
                                placeholder="Select Supplier"
                                disabled={
                                  data.item_category !== "Raw Material" &&
                                  data.item_category !== "Sub Material"
                                }
                                allowClear
                                // mode="multiple"
                                onChange={(value) =>
                                  handleChangeSelect("supplier", value)
                                }
                                onFocus={() => getSupplier("")}
                                showSearch
                                onSearch={(value) => getSupplier(value)}
                                loading={loadingSupplier}
                                filterOption={false}
                                // value={data.supplier_label}
                              >
                                {data.suppliers &&
                                  data.suppliers.map((supplier) => {
                                    return (
                                      <Option
                                        value={supplier._id}
                                        key={supplier._id}
                                      >
                                        {`${supplier.code} - ${supplier.name}`}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.item_category !==
                          currentValues.item_category
                        }
                      >
                        {({ getFieldValue }) =>
                          getFieldValue("item_category") === "WIP" ? (
                            <Form.Item
                              required
                              label="Supplier/customer"
                              validateStatus={
                                error && error.supcust ? "error" : false
                              }
                              help={
                                error && error.supcust
                                  ? error.supcust[0]
                                  : false
                              }
                            >
                              <Select
                                placeholder="Select Supplier/Customer"
                                allowClear
                                // mode="multiple"
                                onChange={(value) =>
                                  handleChangeSelect("supcust", value)
                                }
                                onFocus={() => getSupplierCustomer("")}
                                showSearch
                                onSearch={(value) => getSupplierCustomer(value)}
                                loading={loadingSupplier}
                                filterOption={false}
                                // value={data.supplier_label}
                              >
                                {data.supcust &&
                                  data.supcust.map((supcustomers) => {
                                    return (
                                      <OptGroup label={supcustomers.type}>
                                        <Option
                                          value={supcustomers._id}
                                          key={supcustomers._id}
                                        >
                                          {`${supcustomers.code} - ${supcustomers.name}`}
                                        </Option>
                                      </OptGroup>
                                    );
                                  })}
                              </Select>
                            </Form.Item>
                          ) : null
                        }
                      </Form.Item>
                      <Form.Item label="Model">
                        <Input
                          value={data.model}
                          onChange={(e) =>
                            handleChange("model", e.target.value)
                          }
                          placeholder="input Model"
                        />
                      </Form.Item>

                      <Form.Item label="Unique Code">
                        <Input
                          value={data.unique_code}
                          onChange={(e) =>
                            handleChange("unique_code", e.target.value)
                          }
                          placeholder="input unique_code"
                        />
                      </Form.Item>

                      
                      <Form.Item
                        validateStatus={error && error.pcs_hour ? "error" : false}
                        help={error && error.pcs_hour ? error.pcs_hour[0] : false}
                        label="PCS Hour"
                      >
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.pcs_hour}
                          type="input"
                          onChange={(e) => handleChange("pcs_hour", e)}
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={error && error.min ? "error" : false}
                        help={error && error.min ? error.min[0] : false}
                        label="Min"
                      >
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.min}
                          type="input"
                          onChange={(e) => handleChange("min", e)}
                        />
                      </Form.Item>

                      <Form.Item
                        validateStatus={error && error.max ? "error" : false}
                        help={error && error.max ? error.max[0] : false}
                        label="Max"
                      >
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.max}
                          type="input"
                          onChange={(e) => handleChange("max", e)}
                        />
                      </Form.Item>
                      <Form.Item label="Standard">
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.standard}
                          type="input"
                          onChange={(e) => handleChange("standard", e)}
                        />
                      </Form.Item>

                      <Form.Item label="Lot">
                        <AntNumberFormat
                          defaultValue="0"
                          value={data.lot}
                          type="input"
                          onChange={(e) => handleChange("lot", e)}
                        />
                      </Form.Item>
                      <Form.Item label="Date" required>
                        <DatePicker
                          onChange={(value) => handleChange("date", value)}
                          value={data.date}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item label="File" required>
                        <Fragment>
                          <Upload
                            onRemove={handleRemove}
                            beforeUpload={handleBeforeUpload}
                            fileList={[]}
                          >
                            <Button>
                              Upload <UploadOutlined />
                            </Button>
                          </Upload>
                          {data.picture && (
                            <List>
                              <List.Item
                                actions={[
                                  <Button
                                    onClick={handleRemove}
                                    type="danger"
                                    ghost
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              >
                                <List.Item.Meta
                                  description={data.picture.name}
                                />
                              </List.Item>
                            </List>
                          )}
                        </Fragment>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>
                <Form.Item>
                  <Row justify="start">
                    <Col xs={24}>
                      <Space>
                        <Button
                          type="primary"
                          loading={loading}
                          className="mr-button"
                          htmlType="submit"
                        >
                          Submit
                        </Button>

                        <Button
                          onClick={handleReset}
                          type="default"
                          htmlType="button"
                          className="mr-button"
                        >
                          Reset
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditItem;
