/* eslint-disable */
import React, { Fragment, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Card,
  Breadcrumb,
  Typography,
  Space,
  message,
  Radio,
} from "antd";
import { Link, Redirect } from "react-router-dom";
import Axios from "axios";
import { url } from "../../../config/global";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const { Title } = Typography;
const { Option } = Select;
const { Content } = Layout;

const layout = {
  labelCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
  wrapperCol: {
    xs: 24,
    lg: 24,
    md: 24,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

function EditSupplierCustomer(props) {
  const [data, setData] = useState({
    code: "",
    name: "",
    alias: "",
    email: "",
    phone_number: "",
    mobile: "",
    pic: "",
    npwp: "",
    representative: "",
    flag: "",
    keyword: "",
    type: "",
    address: "",
    active: "",
    no_urut: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSupplierCustomer();
  }, []);

  const getSupplierCustomer = () => {
    Axios.get(`${url}/supplier/${props.match.params.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        setData({
          ...data,
          code: res.data.data.code,
          name: res.data.data.name,
          alias: res.data.data.alias,
          type: res.data.data.type,
          address: res.data.data.address,
          email: res.data.data.email,
          no_urut: res.data.data.no_urut,
          phone_number: res.data.data.phone_number.toString(),
          mobile: res.data.data.mobile,
          pic: res.data.data.pic,
          npwp: res.data.data.npwp,
          flag: res.data.data.flag,
          representative: res.data.data.representative,
          keyword: res.data.data.keyword,
          active: res.data.data.active,
        });
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
      });
  };

  const handleChange = (name, value) => {
    setError(null);
    setData({
      ...data,
      [name]: value,
    });
  };

  const onSaveData = () => {
    setLoading(true);

    let fd = new FormData();

    fd.set("name", data.name);
    fd.set("type", data.type);
    fd.set("code", data.code);
    fd.set("alias", data.alias);
    fd.set("email", data.email);
    fd.set("no_urut", data.no_urut);
    fd.set("npwp", data.npwp);
    fd.set("phone_number", data.phone_number);
    fd.set("mobile", data.mobile);
    fd.set("pic", data.pic);
    fd.set("flag", data.flag);
    fd.set("representative", data.representative);
    fd.set("keyword", data.keyword);
    fd.set("address", data.address);
    fd.set("active", data.active);

    Axios.post(`${url}/supplier/${props.match.params.id}`, fd, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => {
        message.success(res.data.message);
        props.history.push("/master-supplier-customer");
      })
      .catch((err) => {
        if (err.response) {
          message.error(err.response.data.message);
          if (err.response.status === 422) {
            setError(err.response.data.errors);
          }
          if (err.response.status == 401) {
            localStorage.removeItem("token");
          }
        }
        setLoading(false);
      });
  };

  const handleReset = () => {
    setData({
      code: "",
      name: "",
      email: "",
      alias: "",
      phone_number: "",
      mobile: "",
      pic: "",
      npwp: "",
      flag: "",
      representative: "",
      keyword: "",
      type: "",
      address: "",
      active: "",
      no_urut,
    });
  };

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      <Row>
        <Col xs={24}>
          <Card
            type="inner"
            title="Master Supplier & Customer"
            
          >
            <Row type="flex" justify="left">
              <Breadcrumb>
                <Breadcrumb.Item>
                  <Link to="/master-supplier-customer">Back</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  Edit Master Supplier & Customer
                </Breadcrumb.Item>
              </Breadcrumb>
            </Row>
          </Card>
        </Col>
      </Row>
      <Content className="content">
        <Row>
          <Col xs={24}>
            <Card className="body-data">
              <Form {...layout} layout="vertical" onFinish={onSaveData}>
                <Row gutter={[30, 30]}>
                  <Fragment>
                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        required
                        label="Type"
                        validateStatus={error && error.type ? "error" : false}
                        help={error && error.type ? error.type[0] : false}
                      >
                        <Radio.Group
                          onChange={(e) => handleChange("type", e.target.value)}
                          value={data.type}
                        >
                          <Radio value="Supplier">Supplier</Radio>
                          <Radio value="Customer">Customer</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        required
                        label="Code"
                        validateStatus={error && error.code ? "error" : false}
                        help={error && error.code ? error.code[0] : false}
                      >
                        <Input
                          value={data.code}
                          onChange={(e) => handleChange("code", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Name"
                        validateStatus={error && error.name ? "error" : false}
                        help={error && error.name ? error.name[0] : false}
                      >
                        <Input
                          value={data.name}
                          onChange={(e) => handleChange("name", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Alias"
                        validateStatus={error && error.alias ? "error" : false}
                        help={error && error.alias ? error.alias[0] : false}
                      >
                        <Input
                          value={data.alias}
                          onChange={(e) =>
                            handleChange("alias", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Email"
                        validateStatus={error && error.email ? "error" : false}
                        help={error && error.email ? error.email[0] : false}
                      >
                        <Input
                          value={data.email}
                          onChange={(e) =>
                            handleChange("email", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="No Urut"
                        validateStatus={
                          error && error.no_urut ? "error" : false
                        }
                        help={error && error.no_urut ? error.no_urut[0] : false}
                      >
                        <Input
                          value={data.no_urut}
                          onChange={(e) =>
                            handleChange("no_urut", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        validateStatus={
                          error && error.phone_number ? "error" : false
                        }
                        help={
                          error && error.phone_number
                            ? error.phone_number[0]
                            : false
                        }
                        label="Phone Number"
                      >
                        <PhoneInput
                          value={data.phone_number}
                          onChange={(e) => handleChange("phone_number", e)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Mobile"
                        validateStatus={error && error.mobile ? "error" : false}
                        help={error && error.mobile ? error.mobile[0] : false}
                      >
                        <PhoneInput
                          value={data.mobile}
                          onChange={(e) => handleChange("mobile", e)}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} md={12} lg={12}>
                      <Form.Item
                        label="PIC"
                        validateStatus={error && error.pic ? "error" : false}
                        help={error && error.pic ? error.pic[0] : false}
                      >
                        <Input
                          value={data.pic}
                          onChange={(e) => handleChange("pic", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="NPWP"
                        validateStatus={error && error.npwp ? "error" : false}
                        help={error && error.npwp ? error.npwp[0] : false}
                      >
                        <Input
                          value={data.npwp}
                          onChange={(e) => handleChange("npwp", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Flag"
                        validateStatus={error && error.flag ? "error" : false}
                        help={error && error.flag ? error.flag[0] : false}
                      >
                        <Input
                          value={data.flag}
                          onChange={(e) => handleChange("flag", e.target.value)}
                        />
                      </Form.Item>
                      <Form.Item
                        label="Representative"
                        validateStatus={
                          error && error.representative ? "error" : false
                        }
                        help={
                          error && error.representative
                            ? error.representative[0]
                            : false
                        }
                      >
                        <Input
                          value={data.representative}
                          onChange={(e) =>
                            handleChange("representative", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label="Keyword"
                        validateStatus={
                          error && error.keyword ? "error" : false
                        }
                        help={error && error.keyword ? error.keyword[0] : false}
                      >
                        <Input
                          value={data.keyword}
                          onChange={(e) =>
                            handleChange("keyword", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Address"
                        validateStatus={
                          error && error.address ? "error" : false
                        }
                        help={error && error.address ? error.address[0] : false}
                      >
                        <TextArea
                          value={data.address}
                          onChange={(e) =>
                            handleChange("address", e.target.value)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        required
                        label="Active"
                        validateStatus={error && error.active ? "error" : false}
                        help={error && error.active ? error.active[0] : false}
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleChange("active", e.target.value)
                          }
                          value={data.active}
                        >
                          <Radio value="Yes">Yes</Radio>
                          <Radio value="No">No</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Fragment>
                </Row>

                <Row justify="start">
                  <Col xs={24}>
                    <Space>
                      <Button
                        type="primary"
                        loading={loading}
                        className="mr-button"
                        htmlType="submit"
                      >
                        Submit
                      </Button>

                      <Button
                        onClick={handleReset}
                        type="default"
                        htmlType="button"
                        className="mr-button"
                      >
                        Reset
                      </Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </Content>
    </Fragment>
  );
}

export default EditSupplierCustomer;
